import { Box, chakra, Flex, HStack, shouldForwardProp, Show, Stack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import Link from 'next/link';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import TextButton from '../action/TextButton';
import useScrollLock from 'use-scroll-lock';
import useBrowserSize from '../../hooks/useBrowserSize';
import { NavPillar, NavSiteSection } from './Navigation';
import Body from '../typography/Body';
import { useRouter } from 'next/router';
import IconExternalLink from '../trinket/icon/IconExternalLink';

interface Props {
    pages: NavSiteSection[];
    topPages: ReactNode[];
    pillars: NavPillar[];
    pillarKey: string;
}

const NavigationMenu: React.FC<Props> = ({ pages, topPages, pillars, pillarKey, ...rest }) => {
    const theme = useTheme();
    const container = useRef<HTMLDivElement>();
    const size = useBrowserSize();
    const steppedMenu = useMemo(() => size === 'xs' || size === 's', [size]);
    const [currentSection, setCurrentSection] = useState(null);
    const router = useRouter();
    useScrollLock(true, container);

    const currentPillar = pillars.find(pillar => pillar.key === pillarKey);
    const otherPillars = pillars.filter(pillar => pillar.key !== pillarKey);

    return (
        <Box
            ref={container}
            position="fixed"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            overflow="scroll"
            bg={theme.tokens.SyntaxBackgroundPrimaryDarkest}
            color={theme.tokens.SyntaxTextColorOnDark}
            flexDirection="column"
            zIndex="99"
            justifyContent={['start', null, 'center']}
            {...rest}
        >
            <AnimatePresence initial={false}>
                <HStack
                    mx="auto"
                    p={[0, null, 10, 17]}
                    pt={[14, null, 0]}
                    alignItems="stretch"
                    divider={
                        <Box
                            w={0}
                            borderLeft={`${theme.borderWidths.xxl} solid ${theme.tokens.BorderColorPrimaryLighter}`}
                        />
                    }
                    spacing="5vw"
                    flexGrow={[1, null, 0]}
                >
                    {((steppedMenu && !currentSection) || !steppedMenu) && (
                        <Flex flexDirection="column">
                            <Stack
                                as={motion.div}
                                spacing={[6, null, 8]}
                                flexGrow="1"
                                minW="260px"
                                w={['87vw', null, '20vw']}
                                position="relative"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition="0.2s"
                                fontSize={4}
                            >
                                <Show below="m">
                                    <Body fontSize={7} fontWeight="700">
                                        {currentPillar.title}
                                    </Body>
                                </Show>

                                {pages.map(p => (
                                    <Box key={p.key} pl={[4, null, 0]}>
                                        <TextButton
                                            onClick={() =>
                                                steppedMenu ? router.push(p.href) : setCurrentSection(p.key)
                                            }
                                            size="l"
                                            iconRight={null}
                                            colored={p.key === currentSection}
                                        >
                                            <Body fontSize={[5, null, 7]}>{p.title}</Body>
                                        </TextButton>
                                    </Box>
                                ))}
                            </Stack>
                            <Show below="m">
                                <Stack justifySelf="flex-end" mb={12} mt={8} pb={6} spacing={6}>
                                    {otherPillars.map(pillar => (
                                        <Flex
                                            key={pillar.key}
                                            alignItems="center"
                                            as="a"
                                            href={pillar.href}
                                            target="_blank"
                                        >
                                            <Body fontSize={7} fontWeight="700">
                                                {pillar.title}
                                            </Body>
                                            <Box w={6} h={6} ml="auto">
                                                <IconExternalLink />
                                            </Box>
                                        </Flex>
                                    ))}
                                </Stack>
                            </Show>
                        </Flex>
                    )}
                    {!steppedMenu && (
                        <Flex
                            as={motion.div}
                            flexGrow="1"
                            w={['87vw', null, '30vw']}
                            maxW={['none', null, '300px']}
                            flexDir="row"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition="0.2s"
                        >
                            {pages.map(p => (
                                <AnimationBox
                                    key={`section_${p.key}`}
                                    w="100%"
                                    mr="-100% !important"
                                    initial={false}
                                    animate={{
                                        pointerEvents: p.key === currentSection ? 'auto' : 'none',
                                        opacity: p.key === currentSection ? 1 : 0,
                                        x: p.key === currentSection ? 0 : '-15%',
                                    }}
                                    transition={
                                        {
                                            opacity: { duration: 0.4 },
                                            x: { duration: 0.4 },
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        } as any
                                    }
                                >
                                    <Stack spacing={8}>
                                        <Link href={p.href}>
                                            <TextButton size="l" justifyContent="space-between">
                                                {p.title}
                                            </TextButton>
                                        </Link>
                                        <Stack spacing={6}>
                                            {p.subPages.map(sp => (
                                                <Link key={sp.key} href={sp.href}>
                                                    <TextButton size="m" justifyContent="space-between">
                                                        {sp.title}
                                                    </TextButton>
                                                </Link>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </AnimationBox>
                            ))}
                        </Flex>
                    )}
                </HStack>
            </AnimatePresence>
            <Box
                position="fixed"
                bottom={0}
                width="100%"
                px={3}
                py={4}
                display={['flex', null, 'none']}
                flexDirection="row"
                justifyContent="space-between"
                boxShadow={`0px -4px 24px ${theme.tokens.SyntaxOverlayColorDefault}`}
                zIndex={10}
                bg={theme.tokens.SyntaxBackgroundPrimaryDarkest}
            >
                {topPages}
            </Box>
        </Box>
    );
};

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default NavigationMenu;
