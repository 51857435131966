import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { PropsWithChildren, ReactNode } from 'react';
import Wrapper from '../layout/Wrapper';
import Img, { ImgProps } from '../media/Img';
import Heading from '../typography/Heading';

interface Props extends Omit<BoxProps, 'title'>, PropsWithChildren {
    tag?: ReactNode;
    title: ReactNode;
    image: ImgProps;
}

const CompositionHero: React.FC<Props> = ({ tag, title, image, children, ...rest }) => {
    const theme = useTheme();

    return (
        <Box
            // h={[null, null, null, '90vh']}
            color={theme.tokens.SyntaxTextColorOnPrimary}
            bg={theme.tokens.SyntaxBackgroundPrimaryDefault}
            pos="relative"
            overflow="hidden"
            {...rest}
        >
            <Box>
                <Box pos="relative" w={['100%', null, null, '60%']} aspectRatio={16 / 10}>
                    <Img fill {...image} style={{ objectFit: 'cover' }} />
                </Box>
                <Wrapper
                    position={['static', null, null, 'relative']}
                    zIndex="2"
                    h={['auto', null, null, '100%']}
                    minH={['0px', null, null, '400px']}
                >
                    <Stack spacing={6} maxW={['auto', null, null, '60%']} py={[8, 10, 13]}>
                        {tag}
                        <Stack spacing={4}>
                            <Heading variant={1}>{title}</Heading>
                        </Stack>
                    </Stack>
                </Wrapper>
            </Box>
            <Box
                display="flex"
                alignItems="stretch"
                position={['relative', null, null, 'absolute']}
                h={[null, null, null, '100%']}
                top={0}
                left={['0', null, null, '60%']}
                w={['100%', null, null, '40%']}
                overflow="hidden"
                sx={{
                    '& > *': {
                        margin: 0,
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default CompositionHero;
