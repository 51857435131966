/**
 * Generates an acronym from a given string.
 *
 * This function takes a string as input, and then generates an acronym by taking the first letter of each word in the string.
 *
 * @param {string} string - The input string from which to generate the acronym.
 * @returns {string} The generated acronym.
 */
const getAcronym = (string: string) => {
    const splitString = string.trim().split(' ');
    if (splitString.length > 1) {
        return `${splitString.map(word => word.charAt(0)).join('.')}.`;
    }
    return `${splitString[0].charAt(0)}.`;
};

export default getAcronym;
