import { Tab as ChakraTab, TabProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props extends TabProps {
    children: ReactNode;
}
const Tab: FC<Props> = ({ children, _selected, ...rest }) => (
    <ChakraTab
        {...rest}
        minH={11}
        border="none"
        _selected={_selected ?? { color: 'black', border: 'none', bg: 'white' }}
    >
        {children}
    </ChakraTab>
);

export default Tab;
