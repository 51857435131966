import { FC, useState } from 'react';
import { AspectRatio, Box, GridProps } from '@chakra-ui/react';
import MediaSlider, { Image, Video } from './MediaSlider';
import Img from './Img';

type Media = Image | Video;

interface Props extends GridProps {
    media: Media[];
}

const StillsCarousel: FC<Props> = ({ media, ...rest }) => {
    const [activeMedia, setActiveMedia] = useState<Media>(media[0]);

    return (
        <>
            <Box display={['none', null, null, 'flex']} flexDir="column" gap={4} {...rest}>
                <Box>
                    <AspectRatio ratio={16 / 9}>
                        <Img {...activeMedia} fill />
                    </AspectRatio>
                </Box>
                <Box>
                    <MediaSlider
                        media={media.map(m => ({
                            ...m,
                            onClick: () => setActiveMedia(m),
                            isActive: m.src === activeMedia.src,
                        }))}
                        mediaWidth="182px"
                    />
                </Box>
            </Box>
            <Box display={['block', null, null, 'none']}>
                <MediaSlider media={media} />
            </Box>
        </>
    );
};

export default StillsCarousel;
