import { useCallback, useEffect, useRef, useState } from 'react';

const useFontLoaded = (fontFamily: string, interval = 500) => {
    const intervalTimer = useRef<number | null>(null);
    const [fontLoaded, setFontLoaded] = useState<boolean | null>(null);

    const fontLoadedListener = useCallback(() => {
        let hasLoaded = false;

        try {
            hasLoaded = document.fonts.check(`12px "${fontFamily}"`);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.info('CSS font loading API error', error);
            setFontLoaded(false);
            clearInterval(intervalTimer.current);
        }

        if (hasLoaded) {
            setFontLoaded(true);
            clearInterval(intervalTimer.current);
        }
    }, [fontFamily]);

    useEffect(() => {
        clearInterval(intervalTimer.current);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        intervalTimer.current = setInterval(fontLoadedListener, interval) as any;
        fontLoadedListener();
        return () => clearInterval(intervalTimer.current);
    }, [fontLoadedListener, interval]);

    return fontLoaded;
};

export default useFontLoaded;
