import React, { ReactElement, ReactNode, useMemo } from 'react';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import useBrowserSize, { browserSizes } from '../../hooks/useBrowserSize';
import Grid from '../layout/Grid';
import Award from '../meta/Award';
import { Props as MetaProps } from '../meta/Meta';
import Tag from '../navigation/Tag';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
import Img, { ImgProps } from '../media/Img';

export interface Props {
    award?: ReactNode;
    title: ReactNode;
    meta: ReactElement<MetaProps>;
    editions?: string[];
    description?: ReactNode;
    image: ImgProps;
    wide?: boolean;
    translatedEditionTitle?: ReactNode;
    note?: ReactNode;
}

const FilmListItem: React.FC<Props> = ({
    award,
    title,
    image,
    meta,
    editions,
    description,
    note,
    translatedEditionTitle,
    wide = false,
}) => {
    const theme = useTheme();
    const browserSize = useBrowserSize();
    const aspectRatio = useMemo(
        () => (browserSizes.indexOf(browserSize) <= browserSizes.indexOf('s') ? 16 / 9 : 3 / 2),
        [browserSize]
    );
    const hasHover = description ? true : undefined;

    return (
        <Container
            as="article"
            sx={{
                '&:hover picture': { transform: 'scale(1.05)' },
                '& picture': { transition: 'transform 1400ms cubic-bezier(0.16, 1, 0.3, 1)' },
            }}
        >
            <Grid>
                <Box gridColumn={wide ? ['1/-1', null, '1/6', '1/5', '1/4'] : ['1/-1', null, '1/6', '1/5', '1/4']}>
                    <AspectRatio.Root ratio={aspectRatio}>
                        <Img fill {...image} />
                    </AspectRatio.Root>
                </Box>
                <Flex
                    gridColumn={wide ? ['1/-1', null, '6/-2', '5/-2', '4/-2'] : ['1/-1', null, '6/-2', '5/-2', '4/-2']}
                    alignItems="center"
                    p={[theme.tokens.Sizing5, null, 0]}
                    py={[theme.tokens.Sizing5, null, theme.tokens.Sizing3]}
                >
                    <Stack spacing={theme.tokens.Sizing3}>
                        <Stack spacing={theme.tokens.Sizing1}>
                            {award && <Award>{award}</Award>}
                            <Heading as="h2" variant={4} clamp={2}>
                                {title}
                            </Heading>
                        </Stack>
                        <Stack data-mouse-off={hasHover}>{meta}</Stack>
                        {description && (
                            <Stack data-mouse-on={hasHover}>
                                <Description clamp={browserSize === 'm' ? 2 : 3}>{description}</Description>
                            </Stack>
                        )}
                        {note && <Box data-mouse-off={hasHover}>{note}</Box>}
                        {editions && (
                            <Stack data-mouse-off={hasHover}>
                                <Editions flexWrap="wrap" alignItems="baseline" gap={1}>
                                    <Label>{translatedEditionTitle ?? 'Editions'}</Label>
                                    {editions.map(edition => (
                                        <Tag size="s" key={edition}>
                                            {edition}
                                        </Tag>
                                    ))}
                                </Editions>
                            </Stack>
                        )}
                    </Stack>
                </Flex>
            </Grid>
        </Container>
    );
};

const Container = styled(Box)(
    ({ theme }) => css`
        outline: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        color: ${theme.tokens.SyntaxTextColorDefault};
        text-decoration: none;

        img {
            object-fit: cover;
        }

        @media (min-width: ${theme.tokens.MediaQueryS}) {
            [data-mouse-on] {
                display: none;
            }
            [data-mouse-off] {
                display: block;
            }

            &:hover {
                [data-mouse-on] {
                    display: block;
                }
                [data-mouse-off] {
                    display: none;
                }
            }
        }
    `
);
Container.defaultProps = {
    as: 'article',
};

const Editions = styled(Flex)();

const Label = styled(Body)(
    ({ theme }) => css`
        font-size: ${theme.tokens.FontSize1};
        line-height: ${theme.tokens.LineHeightXs};
        margin-right: ${theme.tokens.Sizing2};
    `
);

const Description = styled(Body)(
    ({ theme }) => css`
        font-size: ${theme.tokens.FontSize2};
        line-height: ${theme.tokens.LineHeightS};
    `
);

export default FilmListItem;
