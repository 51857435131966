import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { AnchorHTMLAttributes } from 'react';
import IconUserOutline from '../trinket/icon/IconUserOutline';
import IconMenu from '../trinket/icon/IconMenu';
import IconSearch from '../trinket/icon/IconSearch';
import { Box, Button, Flex, SkeletonText, Spinner } from '@chakra-ui/react';
import Link from 'next/link';
import IconHome from '../trinket/icon/IconHome';
import { NavSiteSection } from './Navigation';
import IconShoppingCart from '../trinket/icon/IconShoppingCart';

interface Props {
    pages: NavSiteSection[];
    loggedIn?: boolean;
    isLoginLoading?: boolean;
    homePath?: string;
    myIdfaPath?: string;
    pillarKey?: string;
    handleLoginClick?: (e: React.MouseEvent) => void;
    handleMyIdfaClick?: () => void;
    isCartLoading?: boolean;
    handleCartClick?: (e: React.MouseEvent) => void;
    handleMenuClick?: () => void;
    showSearch?: boolean;
    isLoading: boolean;
}

const NavigationPagesBar: React.FC<Props> = ({
    pages,
    handleMenuClick,
    handleLoginClick,
    handleMyIdfaClick,
    isLoginLoading,
    handleCartClick,
    isCartLoading,
    loggedIn,
    homePath,
    myIdfaPath,
    pillarKey,
    showSearch = true,
    isLoading,
    ...rest
}) => {
    const theme = useTheme();
    return (
        <Flex
            justifyContent="space-between"
            bg={theme.tokens.SyntaxBackgroundPrimaryLightest}
            color={theme.tokens.SyntaxTextColorDefault}
            h="100%"
            px={2}
            flexDir="row"
            {...rest}
        >
            <Flex display={['none', 'none', 'none', 'none', 'flex']}>
                <Link key="home" href={homePath || '/'}>
                    <NavigationBottomItem>
                        <IconHome />
                    </NavigationBottomItem>
                </Link>
                {isLoading &&
                    new Array(4).fill(0).map((_, i) => (
                        <NavigationBottomItem key={i}>
                            <SkeletonText skeletonHeight={4} noOfLines={1} width="70px" />
                        </NavigationBottomItem>
                    ))}

                {!isLoading &&
                    pages?.map(p => (
                        <Link key={p.key} href={p.href}>
                            <NavigationBottomItem>{p.title}</NavigationBottomItem>
                        </Link>
                    ))}
            </Flex>
            <Flex justifyContent="space-between" width={['100%', '100%', '100%', '100%', 'auto']}>
                {showSearch ? (
                    <NavigationBottomItem onClick={handleMenuClick}>
                        <IconSearch />
                    </NavigationBottomItem>
                ) : (
                    <Box />
                )}

                <Flex>
                    {isCartLoading && handleCartClick && (
                        <NavigationBottomItem disabled>
                            <Spinner width={5} height={5} />
                        </NavigationBottomItem>
                    )}
                    {!isCartLoading && handleCartClick && (
                        <NavigationBottomItem as="a" onClick={handleCartClick} display="flex">
                            <IconShoppingCart width={5} />
                        </NavigationBottomItem>
                    )}
                    {isLoginLoading && (
                        <NavigationBottomItem display={['flex', 'flex', 'none']} disabled>
                            <Spinner width={5} height={5} />
                        </NavigationBottomItem>
                    )}
                    {!isLoginLoading &&
                        (loggedIn ? (
                            <NavigationBottomItem
                                as="button"
                                onClick={handleMyIdfaClick}
                                referrerPolicy="unsafe-url"
                                display={['flex', 'flex', 'none']}
                            >
                                <StyledIconUser width={5} />
                            </NavigationBottomItem>
                        ) : (
                            <NavigationBottomItem
                                as="button"
                                onClick={handleLoginClick}
                                display={['flex', 'flex', 'none']}
                            >
                                <StyledIconUser width={5} />
                            </NavigationBottomItem>
                        ))}
                    <NavigationBottomItem onClick={handleMenuClick} mr={-2}>
                        <Box display={['none', 'none', 'inline']}>Menu</Box>
                        <IconMenu />
                    </NavigationBottomItem>
                </Flex>
            </Flex>
        </Flex>
    );
};

const StyledIconUser = styled(IconUserOutline)`
    vertical-align: middle;
`;

const NavigationBottomItem = styled(Button)<AnchorHTMLAttributes<HTMLAnchorElement>>(
    ({ theme, disabled }) => css`
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 ${theme.tokens.Sizing4};
        font-size: ${theme.tokens.SyntaxBodyTextLRegularFontSize};
        line-height: ${theme.tokens.SyntaxBodyTextLRegularLineHeight};
        font-family: ${theme.tokens.SyntaxBodyTextLRegularFontFamily};
        font-weight: ${theme.tokens.FontWeightL};
        color: inherit;
        text-decoration: none;
        transition: all 0.2s;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 0;
        background-color: transparent;

        &:hover {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryLight};
        }
        &:focus {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryLight};
            outline: none;
        }
        &:active {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryDefault};
        }

        & > svg {
            height: ${theme.tokens.Sizing5};
            margin: 0 -${theme.tokens.Sizing1};
        }

        & > * + * {
            margin-left: ${theme.tokens.Sizing2} !important;
        }

        ${disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}

        @media (min-width: ${theme.tokens.MediaQueryXs}) {
            padding: 0 ${theme.tokens.Sizing6};
        }
    `
);

export default NavigationPagesBar;
