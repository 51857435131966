import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13.313 2.275a1.5 1.5 0 0 0-2.626 0l-9.75 18A1.5 1.5 0 0 0 2.25 22.5h19.5a1.5 1.5 0 0 0 1.313-2.225l-9.75-18ZM12 15V8.25"
        />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M12 18.75a.375.375 0 0 1 0-.75M12 18.75a.375.375 0 0 0 0-.75"
        />
    </svg>
);

export default SvgComponent;
