import { IntlShape } from 'react-intl';

interface ShowShape {
    composition?: {
        fullTitle?: string | null;
    };
    audience?: {
        key?: string | null;
        translation?: string | null;
    };
    introPaper?: {
        translation?: string | null;
    };
}

const formatShowTitle = (show: ShowShape, intl: IntlShape) => {
    const parts = [];

    if (show.composition?.fullTitle) {
        parts.push(
            intl.formatMessage({ defaultMessage: 'Onderdeel van {value}' }, { value: show.composition.fullTitle })
        );
    }

    if (show.introPaper) {
        parts.push(show.introPaper.translation);
    }

    if (show.audience.key !== 'Public') {
        parts.push(show.audience.translation);
    }

    return parts.join(', ');
};

export default formatShowTitle;
