import React from 'react';
import TextButton, { Props as TextButtonProps } from '../action/TextButton';
import IconCaret from '../trinket/icon/IconCaret';
import Card, { CardProps } from './Card';
import SmallCard from './SmallCard';
import { Box } from '@chakra-ui/react';

interface SmallCardProps extends Omit<CardProps, 'image' | 'size'> {
    size: 's';
    image?: never;
}

interface MediumCardProps extends Omit<CardProps, 'image' | 'size'> {
    size: 'm';
    image?: never;
}

interface LargeCardProps extends CardProps {
    size: 'l';
}

type Props = (SmallCardProps | MediumCardProps | LargeCardProps) & { textButtonProps?: TextButtonProps };

const PageCard: React.FC<Props> = ({ title, textButtonProps, size = 'm', ...rest }) =>
    size === 'l' ? (
        <Card title={title} image={rest.image} {...rest}>
            {textButtonProps && <TextButton size="s" {...textButtonProps} as={Box} pb={7} />}
        </Card>
    ) : size === 'm' ? (
        <Card title={title} titleVariant={4} image={rest.image} {...rest}>
            {textButtonProps && <TextButton size="s" {...textButtonProps} as={Box} />}
        </Card>
    ) : (
        <SmallCard icon={textButtonProps?.iconRight || <IconCaret direction="right" />} {...rest}>
            {title}
        </SmallCard>
    );

export default PageCard;
