import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const usePrivatePage = () => {
    const { status } = useSession();
    const { push } = useRouter();

    useEffect(() => {
        if (status === 'unauthenticated') {
            push('/login');
        }
    }, [push, status]);
};

export default usePrivatePage;
