import React from 'react';

import { Stack, StackProps, Text } from '@chakra-ui/react';

import Video, { Props as VideoProps } from '../media/Video';
import { useTheme } from '@emotion/react';

interface Props extends StackProps, VideoProps {
    caption?: string;
}

const ContentBlockVideo: React.FC<Props> = ({ caption, ratio = 16 / 9, url, videoProps, ...rest }) => {
    const theme = useTheme();
    return (
        <Stack mb={6} spacing={4} color={theme.tokens.SyntaxTextColorNeutralMuted} data-content-video {...rest}>
            <Video ratio={ratio} url={url} videoProps={videoProps} />
            {caption && <Text fontSize={2}>{caption}</Text>}
        </Stack>
    );
};

export default ContentBlockVideo;
