import { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import dynamic from 'next/dynamic';
// importing react-player directly causes hydration error in Next: https://github.com/cookpete/react-player/issues/1428
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export interface Props extends BoxProps, Pick<React.ComponentProps<typeof ReactPlayer>, 'url'> {
    primary?: boolean;
}

const VideoTile: FC<Props> = ({ primary = true, children, url, ...rest }) => {
    const theme = useTheme();
    return (
        <Box
            primary={primary}
            p={[6, 10]}
            display="flex"
            flexDirection="column"
            w="100%"
            justifyContent="center"
            pos="relative"
            overflow="hidden"
            color={theme.tokens.SyntaxTextColorOnDark}
            {...rest}
        >
            <Box
                pos="absolute"
                inset={0}
                bg={theme.tokens.SyntaxBackgroundNeutralDarkest}
                display="flex"
                justifyContent="center"
            >
                <ReactPlayer
                    muted
                    playsinline
                    playing
                    loop
                    url={url}
                    width="auto"
                    height="100%"
                    config={{ vimeo: { playerOptions: { background: true } } }}
                    style={{ aspectRatio: 16 / 9 }}
                />
                <Box
                    pos="absolute"
                    inset={0}
                    bgGradient={`linear(to-b, rgba(0,0,0,0), ${theme.tokens.SyntaxOverlayColorDefault}) 83%`}
                />
            </Box>
            {children}
        </Box>
    );
};

export default VideoTile;
