import { motion, useSpring } from 'framer-motion';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { AspectRatio, Box, HStack, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled';

import Wrapper from '../layout/Wrapper';
import IconCaret from '../trinket/icon/IconCaret';
import Img, { ImgProps } from './Img';
import { useTheme } from '@emotion/react';
import IconPlay from '../trinket/icon/IconPlay';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export interface Image extends Omit<ImgProps, 'fill'> {
    type: 'image';
    onClick?: MouseEventHandler<HTMLDivElement>;
    isActive?: boolean;
}

export interface Video {
    type: 'video';
    src: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    isActive?: boolean;
}

interface Props {
    media: (Image | Video)[];
    mediaWidth?: string;
}

const isVideo = (media: Props['media'][number]): media is Video => media.type === 'video';

const MediaSlider: React.FC<Props> = ({ media, mediaWidth, ...rest }) => {
    const { tokens } = useTheme();
    const [showPrev, setShowPrev] = useState(false);
    const [showNext, setShowNext] = useState(true);
    const [sliderBounds, setSliderBounds] = useState(0);
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const constraints = useRef<HTMLDivElement>();
    const slider = useRef<HTMLDivElement>();
    const x = useSpring(0, { stiffness: 800, damping: 50 });

    const next = useCallback(() => {
        x.stop();
        const newX = x.get() - (wrapperWidth + 15);
        x.set(newX < sliderBounds ? sliderBounds : newX);
    }, [sliderBounds, wrapperWidth, x]);

    const prev = useCallback(() => {
        x.stop();
        const newX = x.get() + (wrapperWidth + 15);
        x.set(newX >= 0 ? 0 : newX);
    }, [wrapperWidth, x]);

    const updateSliderBounds = useCallback(() => {
        const currentWrapperWidth = (slider.current.parentNode as HTMLDivElement).clientWidth;
        setSliderBounds((slider.current.clientWidth - currentWrapperWidth) * -1);
        setWrapperWidth(currentWrapperWidth);
        x.set(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [debouncedUpdatedSliderBounds] = useDebounce(updateSliderBounds, 400);

    const showButtons = sliderBounds < 0;

    // update bounds on resize
    useEffect(() => {
        updateSliderBounds();
        window.addEventListener('resize', debouncedUpdatedSliderBounds);
        return () => window.removeEventListener('resize', debouncedUpdatedSliderBounds);
    }, [debouncedUpdatedSliderBounds, updateSliderBounds]);

    // track x position
    useEffect(
        () =>
            x.onChange(latest => {
                setShowPrev(latest < -1);
                setShowNext(latest > sliderBounds);
            }),
        [x, sliderBounds]
    );

    return (
        <Box w="100%" overflow="hidden" pos="relative" {...rest}>
            {showButtons && (
                <Box pos="absolute" top="50%" left="0" transform="translateY(-50%)" zIndex={1}>
                    <motion.div
                        animate={{
                            opacity: showPrev ? 1 : 0,
                            transition: {
                                duration: 0.2,
                            },
                        }}
                    >
                        <SquareButton aria-label="previous" icon={<IconCaret direction="left" />} onClick={prev} />
                    </motion.div>
                </Box>
            )}
            <Wrapper innerRef={constraints}>
                <motion.div
                    ref={slider}
                    drag={showButtons ? 'x' : false}
                    initial={{ x: 0 }}
                    style={{ x, display: 'inline-block' }}
                    dragConstraints={constraints}
                >
                    <HStack spacing="15px">
                        {media.map(m => (
                            <Box
                                key={m.src as string}
                                w={['90vw', null, mediaWidth ?? '390px']}
                                flexShrink={0}
                                color={tokens.SyntaxTextColorOnDark}
                            >
                                <AspectRatio
                                    ratio={16 / 9}
                                    onClick={m.onClick}
                                    cursor="pointer"
                                    border={
                                        m.isActive ? `2px solid ${tokens.ColorDarkBlue60}` : `2px solid transparent`
                                    }
                                >
                                    {isVideo(m) ? (
                                        <VideoThumbnail src={m.src} />
                                    ) : (
                                        <Img fill {...m} style={{ pointerEvents: 'none' }} />
                                    )}
                                </AspectRatio>
                            </Box>
                        ))}
                    </HStack>
                </motion.div>
            </Wrapper>
            {showButtons && (
                <Box pos="absolute" top="50%" right="0" transform="translateY(-50%)" zIndex={1}>
                    <motion.div
                        animate={{
                            opacity: showNext ? 1 : 0,
                            transition: {
                                duration: 0.4,
                            },
                        }}
                    >
                        <SquareButton aria-label="next" icon={<IconCaret direction="right" />} onClick={next} />
                    </motion.div>
                </Box>
            )}
        </Box>
    );
};

const VideoThumbnail: React.FC<{ src: string }> = ({ src, ...rest }) => {
    const { tokens } = useTheme();
    return (
        <Box
            bg={tokens.SyntaxBackgroundNeutralDarkest}
            sx={{
                '& .react-player__play-icon': {
                    display: 'none',
                },
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    background: tokens.SyntaxOverlayColorDefault,
                    inset: 0,
                    zIndex: 1,
                },
            }}
            {...rest}
        >
            <ReactPlayer url={src} width="100%" height="100%" style={{ pointerEvents: 'none' }} light />
            <Box pos="absolute" top="50%" left="50%" transform="translateX(-50%) translateY(-50%)" zIndex={1}>
                <IconButton aria-label="play" icon={<IconPlay />} variant="outline" />
            </Box>
            {/* Todo: display video length based on video url */}
            {/* <Box pos="absolute" top={3} right={3} zIndex={1}>
            <Tag>{m.length}</Tag>
        </Box> */}
        </Box>
    );
};

const SquareButton = styled(IconButton)`
    border-radius: 0;
`;

export default MediaSlider;
