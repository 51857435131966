import { Box, Flex, Stack, chakra, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Image from 'next/image';
import Link, { LinkProps } from 'next/link';
import React, { PropsWithChildren, ReactNode, useCallback, useRef } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { MetaProps } from '../..';
import ProgrammeCard from '../card/ProgrammeCard';
import { ImgProps } from '../media/Img';

interface Slide
    extends Omit<ImgProps, 'as' | 'onClick' | 'href' | 'onMouseEnter' | 'onTouchStart' | 'title' | 'src'>,
        LinkProps {
    image?: ImgProps;
    title: ReactNode;
    meta?: Omit<MetaProps, 'size'>;
}

interface Props extends PropsWithChildren, BoxProps {
    children: ReactNode;
    textButton?: ReactNode;
    slides: Slide[];
    logo?: ImgProps;
}

const ProgrammePart: React.FC<Props> = ({ children, textButton, slides, logo, ...rest }) => {
    const { tokens } = useTheme();
    const swiperRef = useRef<SwiperRef>();
    const handleMouseEnter = useCallback(() => swiperRef.current?.swiper?.autoplay?.stop(), []);
    const handleMouseLeave = useCallback(() => swiperRef.current?.swiper?.autoplay?.start(), []);

    return (
        <Flex
            pb={[9, null, 12, null, '120px']}
            pt={[9, null, 12, null, '120px']}
            pl={[6, null, 12, null, '120px']}
            color={tokens.SyntaxTextColorNeutral}
            bg={tokens.SyntaxPillarColorFestivalDefault}
            alignItems={[null, null, null, null, 'center']}
            overflow="hidden"
            flexDir={['column', null, null, null, 'row']}
            {...rest}
        >
            <Stack spacing={6} maxW="415px" mb={[9, null]} mr={[null, null, 7, null, '120px']} pr={[6, 0]}>
                <Stack spacing={5}>{children}</Stack>
                <Flex justify="space-between">
                    {textButton}
                    {logo && (
                        <Box color={tokens.SyntaxTextColorNeutral} pos="relative">
                            <Image src={logo.src} alt={logo.alt} style={{ objectFit: 'contain' }} />
                        </Box>
                    )}
                </Flex>
            </Stack>
            <ChakraSwiper
                ref={swiperRef}
                autoplay={{
                    delay: 3000,
                }}
                speed={2000}
                modules={[Autoplay]}
                slidesPerView={1.3}
                loop
                width={['100vw', null, null, null, 'calc(100vw - 655px)']}
                position="relative"
                ml="inherit"
                breakpoints={{
                    320: {
                        slidesPerView: 1.4,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 1.3,
                        spaceBetween: 48,
                    },
                    1024: {
                        slidesPerView: 1.3,
                        spaceBetween: 30,
                    },
                    1600: {
                        slidesPerView: 'auto',
                        spaceBetween: 48,
                    },
                }}
            >
                {slides.map(({ id, href, ...restSlide }) => (
                    <SwiperSlide
                        key={id}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={css({
                            maxWidth: '640px',
                        })}
                    >
                        <Link href={href}>
                            <ProgrammeCard {...restSlide} />
                        </Link>
                    </SwiperSlide>
                ))}
            </ChakraSwiper>
        </Flex>
    );
};

const ChakraSwiper = chakra(Swiper);

export default ProgrammePart;
