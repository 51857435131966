import { Breadcrumb, Breadcrumbs } from 'designsystem';
import { useMemo } from 'react';
import breadcrumbsFromAncestors from '../../lib/breadcrumbsFromAncestors';

interface CurrentPageInTree extends Partial<Breadcrumb> {
    ancestors: Partial<Breadcrumb>[];
}

interface Props extends Omit<React.ComponentProps<typeof Breadcrumbs>, 'breadcrumbs' | 'homeBreadcrumb'> {
    breadcrumbsQueryResults?: {
        currentPageInTree?: CurrentPageInTree;
    };
    parentUri: string;
    currentPage: Partial<Breadcrumb>;
}

const ObjectPageBreadcrumbs: React.FC<Props> = ({ breadcrumbsQueryResults, parentUri, currentPage, ...rest }) => {
    const { currentPageInTree: parentPageInTree } = breadcrumbsQueryResults || {};
    // adding a null object as a placeholder for the current page in the crumbs
    const splitPathWithPlaceholder = useMemo(() => [...parentUri.split('/'), null], [parentUri]);

    const breadcrumbs: React.ComponentProps<typeof Breadcrumbs>['breadcrumbs'] = useMemo(
        () =>
            breadcrumbsFromAncestors(splitPathWithPlaceholder, currentPage, [
                parentPageInTree,
                ...(parentPageInTree?.ancestors || []),
            ]),
        [currentPage, parentPageInTree, splitPathWithPlaceholder]
    );

    return <Breadcrumbs breadcrumbs={breadcrumbs} homeBreadcrumb={{ title: '', uri: '/' }} {...rest} />;
};

export default ObjectPageBreadcrumbs;
