import groupBy from 'lodash.groupby';

type EditionTag = {
    id: string;
    name: string;
    year: number;
    selected: boolean;
};

interface Edition {
    id?: string;
    name?: string;
    year?: number;
    editionType?: {
        description?: string;
    };
    [key: string | number | symbol]: unknown;
}

export interface FilmEdition {
    id?: string;
    edition?: Edition;
}

export const filterIrrelevantEdition = <Type extends Edition>(edition: Type, allowedTypes: string[]) =>
    !allowedTypes.includes(edition.editionType?.description);

export const sortEditions = <Type extends Edition>(editions: Type[]) => {
    const editionsGroupedByYear = groupBy(editions, 'year');
    const editionYears = Object.keys(editionsGroupedByYear).reverse();
    let sortedEditions: Required<typeof editions> = [];

    for (const editionYear of editionYears) {
        sortedEditions = [...sortedEditions, ...editionsGroupedByYear[editionYear].sort(sortYearEditions)];
    }

    return sortedEditions;
};

export const sortYearEditions = (a: { name?: string }, b: { name?: string }) => {
    if (a.name.startsWith('IDFA') && !b.name.startsWith('IDFA')) {
        // if year is equal, sort by editions starting with 'IDFA'
        return -1;
    }

    if (!a.name.startsWith('IDFA') && b.name.startsWith('IDFA')) {
        // if year is equal, sort by editions starting with 'IDFA'
        return 1;
    }

    if (a.name < b.name) {
        // if year is equal, and both editions start with 'IDFA' sort alphabetically
        return -1;
    }

    if (a.name > b.name) {
        // if year is equal, and both editions start with 'IDFA' sort alphabetically
        return 1;
    }

    return 0;
};

const getEditionTags = (
    currentEdition: FilmEdition,
    otherEditions: FilmEdition[],
    allowedEditionTypes: string[]
): EditionTag[] => {
    const currentEditionNavItem = {
        id: currentEdition?.id,
        name: currentEdition?.edition.name,
        year: currentEdition?.edition.year,
        selected: true,
    };
    const otherNavItems = otherEditions
        ?.filter(film => !allowedEditionTypes.includes(film.edition.editionType?.description))
        .map(film => ({
            id: film.id,
            name: film.edition.name,
            year: film.edition.year,
            selected: false,
        }));

    return sortEditions([currentEditionNavItem, ...otherNavItems]);
};

export default getEditionTags;
