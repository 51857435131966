import React, { ComponentProps, ReactElement, ReactNode } from 'react';

import { Box, BoxProps, Button, Flex, Stack, VStack } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ConditionalWrap from 'conditional-wrap';

import Grid from '../layout/Grid';
import { Props as MetaProps } from '../meta/Meta';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
import TextButton from '../action/TextButton';
import { useIntl } from 'react-intl';
import IconTrash from '../trinket/icon/IconTrash';
import IconExternalLink from '../trinket/icon/IconExternalLink';
import ColorSchemeProvider from '../ColorSchemeProvider';
import { useRouter } from 'next/router';

interface Props extends Omit<BoxProps, 'title'> {
    title: ReactNode;
    dateRange: {
        startDate: Date;
        endDate: Date;
    };
    meta: ReactElement<MetaProps>;
    availabilityTag?: ReactNode;
    removeFromCalendarButton?: Omit<ComponentProps<typeof TextButton>, 'size'>;
    buyTicketsButton?: Omit<ComponentProps<typeof Button>, 'variant'> & Partial<Pick<ComponentProps<'a'>, 'href'>>;
    toTicketsButton?: Omit<ComponentProps<typeof Button>, 'variant'>;
    meetingButton?: Omit<ComponentProps<typeof TextButton>, 'size'>;
}

const CalendarListItem: React.FC<Props> = ({
    title,
    dateRange,
    meta,
    availabilityTag,
    removeFromCalendarButton,
    buyTicketsButton,
    toTicketsButton,
    meetingButton,
    ...rest
}) => {
    const { locale } = useRouter();
    const { tokens } = useTheme();
    const { formatDate } = useIntl();
    const currentYear = new Date().getFullYear();
    const past = new Date() > dateRange.endDate;
    const { formats, formatDateTimeRange } = useIntl();
    const { href: buyTicketHref, ...buyTicketButtonProps } = buyTicketsButton ?? {};
    return (
        <Container {...rest}>
            <Grid gap={['0', null, undefined]}>
                <Box
                    gridColumn={['1/-1', null, '1/4', '1/3', '1/3']}
                    bg={tokens.SyntaxBackgroundPrimaryDefault}
                    color={tokens.SyntaxTextColorOnPrimary}
                    display="flex"
                    justifyContent={[null, null, 'center']}
                    p={[5, null, null, 0]}
                    alignItems="center"
                    suppressHydrationWarning
                >
                    <VStack spacing={1} alignItems={['flex-start', null, 'center']}>
                        <Body fontSize={9} lineHeight="xxs" fontWeight="xxl" variant={2} clamp={1}>
                            <span suppressHydrationWarning>
                                {formatDate(dateRange.startDate, {
                                    day: 'numeric',
                                })}
                            </span>
                        </Body>
                        <Body fontWeight="xl">
                            <span suppressHydrationWarning>
                                {formatDate(dateRange.startDate, {
                                    month: 'short',
                                    year:
                                        dateRange.startDate.getFullYear() !== currentYear ||
                                        dateRange.endDate.getFullYear() !== currentYear
                                            ? 'numeric'
                                            : undefined,
                                })}
                            </span>
                        </Body>
                    </VStack>
                </Box>
                <Flex
                    gridColumn={['1/-1', null, '4/-1', '3/-1', '3/-1']}
                    alignItems={['stretch', null, null, 'center']}
                    p={[5, null, 0]}
                    py={[5, null, 9]}
                    pr={[5, null, 'var(--gap)']}
                    justifyContent="space-between"
                    color={past ? tokens.SyntaxColorDisabledForeground : undefined}
                    flexDirection={['column', null, null, 'row']}
                    gap={[5, null, null, 'var(--gap)']}
                >
                    <Stack spacing={2}>
                        <Heading as="h2" variant={4} clamp={2}>
                            {title}
                        </Heading>
                        <Body fontWeight="xl">
                            <span suppressHydrationWarning>
                                {formatDateTimeRange(
                                    dateRange.startDate,
                                    dateRange.endDate,
                                    formats.time.short
                                ).replaceAll(':', locale === 'nl' ? '.' : ':')}
                            </span>
                        </Body>
                        {meta}
                        {availabilityTag}
                        {!past && meetingButton && (
                            <ColorSchemeProvider colorScheme="neutral">
                                <TextButton colored size="s" iconRight={<IconExternalLink />} {...meetingButton} />
                            </ColorSchemeProvider>
                        )}
                    </Stack>
                    <Stack spacing={5} flexShrink="0">
                        {removeFromCalendarButton && (
                            <TextButton
                                fontWeight="m"
                                size="s"
                                iconRight={<IconTrash color={tokens.ColorNegative50} />}
                                {...removeFromCalendarButton}
                            />
                        )}
                        {!past && buyTicketsButton && (
                            <ConditionalWrap
                                condition={!!buyTicketHref}
                                wrap={children => (
                                    <Box as="a" href={buyTicketHref} target="_blank" rel="noreferrer" m="0 auto">
                                        {children}
                                    </Box>
                                )}
                            >
                                <Button
                                    variant="solid"
                                    bgColor="neutralblack."
                                    color="neutralwhite."
                                    {...buyTicketButtonProps}
                                />
                            </ConditionalWrap>
                        )}
                        {toTicketsButton && <Button variant="outline" cursor="pointer" {...toTicketsButton} />}
                    </Stack>
                </Flex>
            </Grid>
        </Container>
    );
};

const Container = styled(Box)(
    ({ theme: { tokens } }) => css`
        outline: ${tokens.BorderWidthM} solid ${tokens.BorderColorNeutralDefault};
        color: ${tokens.SyntaxTextColorDefault};
        text-decoration: none;
    `
);
Container.defaultProps = {
    as: 'article',
};

export default CalendarListItem;
