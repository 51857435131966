import { Box, BoxProps, chakra, HStack, shouldForwardProp } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import Img, { ImgProps } from '../media/Img';

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

interface Slide {
    key: string;
    image: ImgProps;
}

interface Props extends BoxProps {
    slides: Slide[];
    slideIndex: number;
}

const BackgroundCarousel: React.FC<Props> = ({ slides, slideIndex, ...rest }) => (
    <AnimatePresence initial={false}>
        {slides.map(
            (slide, i) =>
                i === slideIndex && (
                    <AnimationBox
                        key={`${slide.key}_image`}
                        as={motion.div}
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        transition={{ duration: 1 } as any}
                        {...rest}
                    >
                        <Img fill {...slide.image} style={{ objectFit: 'cover' }} />
                    </AnimationBox>
                )
        )}
    </AnimatePresence>
);

interface DotsProps extends Omit<BoxProps, 'onClick'> {
    slides: number;
    currentSlide: number;
    onClick: (index: number) => void;
}

export const CarouselDots: React.FC<DotsProps> = ({ slides = 1, currentSlide = 0, onClick, ...rest }) => {
    const DotStyles: BoxProps = {
        w: 2,
        h: 2,
        bg: 'currentcolor',
        borderRadius: '100%',
        position: 'relative',
        _before: {
            content: '""',
            display: 'block',
            w: '100%',
            h: '100%',
            mt: -2,
            ml: -2,
            p: 2,
            boxSizing: 'content-box',
            transform: 'translateZ(0)',
        },
    };
    const theme = useTheme();
    return (
        <HStack spacing={2} {...rest}>
            {[...Array(slides).keys()].map(slide =>
                currentSlide === slide ? (
                    <Box
                        key={slide}
                        {...DotStyles}
                        bg={null}
                        sx={{
                            '&:after': {
                                content: "''",
                                pos: 'absolute',
                                top: 0,
                                left: 0,
                                display: 'block',
                                w: '100%',
                                h: '100%',
                                border: `${theme.borderWidths.l} solid currentColor`,
                                m: `calc(${theme.borderWidths.l}*-1)`,
                                boxSizing: 'content-box',
                                borderRadius: 'inherit',
                            },
                        }}
                        zIndex={-1}
                    />
                ) : (
                    <Box key={slide} {...DotStyles} cursor="pointer" onClick={() => onClick(slide)} />
                )
            )}
        </HStack>
    );
};

export default BackgroundCarousel;
