import { TableOfContents as TOC } from 'designsystem';
import { useEffect, useState } from 'react';

interface Props extends Omit<React.ComponentProps<typeof TOC>, 'contents'> {
    parentNodeOfTitles: Parameters<typeof document.querySelector>[0];
}

const TableOfContents: React.FC<Props> = ({ parentNodeOfTitles, ...rest }) => {
    const [contents, setContents] = useState<React.ComponentProps<typeof TOC>['contents']>();

    useEffect(() => {
        setContents(
            [
                ...document.querySelectorAll(
                    `${parentNodeOfTitles} h2[id],
                    ${parentNodeOfTitles} h3[id],
                    ${parentNodeOfTitles} h4[id],
                    ${parentNodeOfTitles} h5[id]`
                ),
            ]?.map(HTMLTitle => ({
                key: HTMLTitle.getAttribute('id'),
                title: HTMLTitle.textContent,
                hash: HTMLTitle.getAttribute('id'),
            }))
        );
    }, [parentNodeOfTitles]);

    return contents && contents.length > 2 ? <TOC {...rest} contents={contents} /> : null;
};

export default TableOfContents;
