import { ComponentSingleStyleConfig } from '@chakra-ui/react';

/**
 * Theme for a Chakra Input. Include this into your theme.
 */
const TextareaTheme: ComponentSingleStyleConfig = {
    baseStyle: ({ theme }) => ({
        font: 'inherit',
        fontSize: theme.tokens.SyntaxBodyTextLRegularFontSize,
        lineHeight: theme.tokens.SyntaxBodyTextSRegularLineHeight,
        boxSizing: 'border-box',
        p: 2,
        minH: 17,
        border: `${theme.tokens.BorderWidthM} solid ${theme.tokens.SyntaxColorInputEnabledBorder}`,
        w: '100%',
        transition: 'all 0.2s',
        '&:placeholder': {
            fontFamily: 'inherit',
        },
        _focus: {
            borderColor: theme.tokens.SyntaxColorInputFocusBorder,
            boxShadow: `0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight}`,
            outline: 'none',
        },
        _hover: {
            borderColor: theme.tokens.SyntaxColorInputHoverBorder,
        },
        _invalid: {
            borderColor: theme.tokens.SyntaxColorInputInvalidBorder,
            _focus: {
                borderColor: theme.tokens.SyntaxColorInputInvalidBorder,
                boxShadow: `0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight}`,
            },
        },
        _disabled: {
            bg: theme.tokens.SyntaxColorInputDisabledBackground,
            color: theme.tokens.SyntaxColorInputDisabledBorder,
            borderColor: theme.tokens.SyntaxColorInputDisabledBorder,
            _hover: {
                borderColor: theme.tokens.SyntaxColorInputDisabledBorder,
            },
        },
    }),
    sizes: {},
};

export default TextareaTheme;
