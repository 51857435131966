import { FC, PropsWithChildren } from 'react';
import HtmlMapper, { TagMap } from 'react-html-map';
import IconExternalLink from '../trinket/icon/IconExternalLink';
import Body from '../typography/Body';
import { Box, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';

interface Props {
    html: string;

    // optionally provide a TagMap with extra mappings
    override?: TagMap;
}

const Paragraph = ({ children, ...rest }: PropsWithChildren) => (
    <Body as="p" mb={3} {...rest}>
        {children}
    </Body>
);

const HtmlContent: FC<Props> = ({ html, override = {} }) => (
    <HtmlMapper html={html} decodeEntities>
        {{
            br: () => <br />,
            p: Paragraph,
            em: ({ children }) => (
                <Body as="span" style={{ fontStyle: 'italic' }}>
                    {children}
                </Body>
            ),
            strong: ({ children }) => (
                <Body as="span" style={{ fontWeight: '600' }}>
                    {children}
                </Body>
            ),
            b: ({ children }) => (
                <Body as="span" style={{ fontWeight: '600' }}>
                    {children}
                </Body>
            ),
            a: ({ children, href, style, ...rest }) => (
                /* TODO: remove $PRIMARY_SITE_URL in html content in the BE */
                <a href={href?.replace('$PRIMARY_SITE_URL', '') ?? '#'} {...rest}>
                    {children}
                    {rest.target === '_blank' && (
                        <IconExternalLink
                            style={{
                                width: '1.25em',
                                height: '1.25em',
                                display: 'inline-block',
                                verticalAlign: '-14%',
                            }}
                        />
                    )}
                </a>
            ),
            ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
            ol: ({ children }) => <OrderedList>{children}</OrderedList>,
            li: ({ children }) => (
                <ListItem mt={0}>
                    <Body>{children}</Body>
                </ListItem>
            ),
            span: ({ children }) => <span>{children}</span>,
            div: ({ children }) => <Box>{children}</Box>,
            ...override,
        }}
    </HtmlMapper>
);

export default HtmlContent;
