import { AspectRatio, Box, BoxProps, chakra, shouldForwardProp, useInterval } from '@chakra-ui/react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import shuffle from 'lodash.shuffle';
import Img, { ImgProps } from '../media/Img';

interface Sponsor extends ImgProps {
    key: string;
    href: string;
    title: string;
}

interface Props extends BoxProps {
    sponsors: Sponsor[];
}

const Banner: React.FC<Props> = ({ sponsors, ...rest }) => {
    const [index, setIndex] = useState(0);
    const s = useMemo(() => shuffle(sponsors), [sponsors]);

    useInterval(() => {
        setIndex((index + 1) % sponsors.length);
    }, 5000);

    return (
        <Box
            w="100%"
            position="relative"
            _after={{
                content: "''",
                pointerEvents: 'none',
                boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.05)',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
            {...rest}
        >
            <AspectRatio ratio={1} w="100%">
                <AnimatePresence initial={false}>
                    {s.map(
                        ({ key, href, title, ...sponsorRest }, i) =>
                            i === index && (
                                <AnimationBox
                                    key={key}
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    bottom={0}
                                    left={0}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    transition={{ duration: 0.4 } as any}
                                >
                                    <a target="_blank" href={href} rel="noreferrer">
                                        <Img fill title={title} {...sponsorRest} />
                                    </a>
                                </AnimationBox>
                            )
                    )}
                </AnimatePresence>
            </AspectRatio>
        </Box>
    );
};

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default Banner;
