import { FC, HTMLAttributes } from 'react';

const IconProfanity: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    <svg viewBox="0 0 184 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="91.8213" cy="91.5" r="91.5" fill="black" />
        <path
            d="M55.9868 103.73C55.9868 99.8483 60.6801 97.9043 63.4249 100.649L78.5772 115.801C80.2787 117.503 80.2787 120.262 78.5772 121.963L63.4249 137.116C60.6801 139.86 55.9868 137.916 55.9868 134.035V103.73Z"
            fill="white"
            stroke="white"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="38.5073"
            y="49.7651"
            width="106.891"
            height="69.1169"
            rx="34.5585"
            fill="white"
            stroke="white"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M62.4857 74.2054L57.9888 94.4418"
            stroke="black"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M72.6019 74.2054L68.105 94.4418"
            stroke="black"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.8652 78.7025H75.9773"
            stroke="black"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M54.6167 89.9448H73.7288"
            stroke="black"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M89.0269 71.957V83.1994"
            stroke="black"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M89.0275 89.9452C88.3604 89.9452 87.7084 90.143 87.1537 90.5136C86.5991 90.8842 86.1668 91.4109 85.9115 92.0272C85.6562 92.6435 85.5895 93.3217 85.7196 93.9759C85.8497 94.6301 86.171 95.2311 86.6426 95.7028C87.1143 96.1745 87.7153 96.4957 88.3695 96.6258C89.0238 96.756 89.7019 96.6892 90.3182 96.4339C90.9345 96.1786 91.4612 95.7463 91.8318 95.1917C92.2024 94.6371 92.4002 93.985 92.4002 93.3179C92.3886 92.427 92.0295 91.5759 91.3995 90.9459C90.7695 90.3159 89.9184 89.9568 89.0275 89.9452V89.9452Z"
            fill="black"
        />
        <path
            d="M123.445 97.9112V92.3987C125.67 91.1274 127.412 89.1546 128.397 86.7883C129.383 84.4221 129.556 81.7963 128.891 79.3211C128.225 76.8459 126.759 74.6608 124.719 73.1077C122.681 71.5546 120.184 70.7208 117.622 70.7368H113.739C111.176 70.7208 108.68 71.5546 106.641 73.1077C104.602 74.6608 103.136 76.8459 102.47 79.3211C101.805 81.7963 101.978 84.4221 102.964 86.7883C103.949 89.1546 105.691 91.1274 107.916 92.3987V97.9112H123.445Z"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M120.403 81.7745C120.541 81.752 120.684 81.7595 120.819 81.7973C120.955 81.8348 121.081 81.9016 121.188 81.9927C121.295 82.0838 121.381 82.1971 121.44 82.325C121.499 82.4526 121.53 82.5915 121.53 82.7323C121.53 82.8731 121.499 83.0121 121.44 83.1399C121.381 83.2675 121.295 83.3809 121.188 83.472C121.081 83.5631 120.955 83.6299 120.819 83.6674C120.684 83.7052 120.541 83.7129 120.403 83.6902C120.175 83.6529 119.969 83.5362 119.82 83.3607C119.671 83.1852 119.589 82.9627 119.589 82.7323C119.589 82.5022 119.671 82.2794 119.82 82.1039C119.969 81.9285 120.175 81.8118 120.403 81.7745Z"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110.671 81.7745C110.81 81.752 110.952 81.7595 111.088 81.7973C111.223 81.8348 111.349 81.9016 111.456 81.9927C111.563 82.0838 111.65 82.1971 111.709 82.325C111.768 82.4526 111.798 82.5915 111.798 82.7323C111.798 82.8731 111.768 83.0121 111.709 83.1399C111.65 83.2675 111.563 83.3809 111.456 83.472C111.349 83.5631 111.223 83.6299 111.088 83.6674C110.952 83.7052 110.81 83.7129 110.671 83.6902C110.444 83.6529 110.237 83.5362 110.088 83.3607C109.939 83.1852 109.857 82.9627 109.857 82.7323C109.857 82.5022 109.939 82.2794 110.088 82.1039C110.237 81.9285 110.444 81.8118 110.671 81.7745Z"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110.828 83.7029C110.292 83.7029 109.857 83.2683 109.857 82.7324C109.857 82.1964 110.292 81.7618 110.828 81.7618"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110.828 83.7029C111.364 83.7029 111.798 83.2683 111.798 82.7324C111.798 82.1964 111.364 81.7618 110.828 81.7618"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M120.559 83.7029C120.023 83.7029 119.589 83.2683 119.589 82.7324C119.589 82.1964 120.023 81.7618 120.559 81.7618"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M120.561 83.7029C121.097 83.7029 121.532 83.2683 121.532 82.7324C121.532 82.1964 121.097 81.7618 120.561 81.7618"
            stroke="black"
            strokeWidth="6.5035"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconProfanity;
