import { DocschoolFilterEnum } from '../../gql/api';
import { QueryParamConfig, useQueryParams } from 'use-query-params';
import { FilterTag, Wrap, WrapItem } from 'designsystem';
import useSearchState from '../../hooks/useSearchState';

interface Props {
    queryParams: Parameters<typeof useSearchState<Record<string, QueryParamConfig<string[], string[]>>>>[0];
    collectionPath: string;
}

const SelectedFilters: React.FC<Props> = ({ queryParams, collectionPath, ...rest }) => {
    const [queryFilters, setQueryFilters] = useQueryParams(queryParams);
    const activeFilters = (Object.entries(queryFilters) as [DocschoolFilterEnum, string[]][]).filter(
        ([, values]) => values.length > 0
    );

    return (
        <Wrap mt={[0, null, -6]} mb={[4]} position="relative" spacing={2} {...rest}>
            {activeFilters.flatMap(([activeFilter, activeFilterValues]) =>
                activeFilterValues.map(filterValue => (
                    <WrapItem key={filterValue}>
                        <FilterTag
                            onClear={() =>
                                setQueryFilters(latestValues => ({
                                    [activeFilter]: latestValues[activeFilter].filter(filter => filter !== filterValue),
                                }))
                            }
                        >
                            {
                                // remove category from display label if present
                                filterValue.split('|')[1] ?? filterValue
                            }
                        </FilterTag>
                    </WrapItem>
                ))
            )}
        </Wrap>
    );
};
export default SelectedFilters;
