import { Body, NavigationTopItem } from 'designsystem';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import gtm from '../lib/gtm';

const LanguageSwitch: FC<{ uri: string | undefined }> = ({ uri }) => {
    const { locale, asPath, isFallback } = useRouter();

    return (
        <NavigationTopItem key="lang-switch" as="div" disabled={isFallback}>
            <Link
                href={uri ? `/${uri}` : asPath}
                locale={locale === 'nl' ? 'en' : 'nl'}
                prefetch={false}
                onClick={() =>
                    gtm.event('change_language', {
                        page_language: locale === 'nl' ? 'en' : 'nl',
                    })
                }
            >
                <Body
                    as="span"
                    color={locale === 'en' ? 'neutralwhite' : 'grey.60'}
                    fontSize={4}
                    fontWeight={['700', null, '500']}
                >
                    EN
                </Body>
                <Body color="grey.60" as="span" fontSize={4} fontWeight={['700', null, '500']}>
                    /
                </Body>
                <Body
                    as="span"
                    color={locale === 'nl' ? 'neutralwhite' : 'grey.60'}
                    fontSize={4}
                    fontWeight={['700', null, '500']}
                >
                    NL
                </Body>
            </Link>
        </NavigationTopItem>
    );
};

export default LanguageSwitch;
