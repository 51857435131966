import React, { HTMLAttributes } from 'react';

const IconCheck: React.FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2178 6L20 7.78217L9.30236 18.4798L4 13.1775L5.79284 11.3973L9.3025 14.9069L18.2178 6Z"
            fill="currentColor"
        />
    </svg>
);

export default IconCheck;
