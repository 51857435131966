import { Box } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import React from 'react';
import ColorSchemeProvider from '../ColorSchemeProvider';
import IconPlayModal from '../trinket/icon/IconPlayModal';
import SmallCard, { CardProps } from './SmallCard';

const ExperienceCard: React.FC<CardProps> = ({ children, ...rest }) => {
    const { tokens } = useTheme();
    return (
        <ColorSchemeProvider colorScheme="neutral">
            <SmallCard
                variant="primary"
                size="s"
                leftElement={
                    <Box
                        w={9}
                        h={9}
                        p={3}
                        backgroundColor={tokens.ColorNeutralWhite}
                        color={tokens.SyntaxTextColorDefault}
                        borderRadius="lg"
                    >
                        <IconPlayModal />
                    </Box>
                }
                {...rest}
            >
                {children}
            </SmallCard>
        </ColorSchemeProvider>
    );
};

export default ExperienceCard;
