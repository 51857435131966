import { Box, BoxProps, Flex, TabList, chakra } from '@chakra-ui/react';
import { FC, PropsWithChildren, ReactNode, useCallback, useRef } from 'react';
import Wrapper from '../layout/Wrapper';
import Body from '../typography/Body';
import Hero from './Hero';
import Tab from '../action/Tab';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

interface FestivalProgrammeHeroProps extends PropsWithChildren, BoxProps {
    topChildren?: ReactNode;
    downloadButton?: ReactNode;
    tabs?: string[];
}

const ChakraSwiper = chakra(Swiper);
const StyledSwiperSlide = styled(SwiperSlide)(
    ({ theme }) => css`
        width: auto;
        @media (min-width: ${theme.breakpoints.m}) {
            width: 150px;
        }
    `
);

const FestivalProgrammeHero: FC<FestivalProgrammeHeroProps> = ({
    topChildren,
    downloadButton,
    tabs,
    children,
    ...rest
}) => {
    const swiperRef = useRef<SwiperRef>();
    const handleMouseEnter = useCallback(() => swiperRef.current?.swiper?.autoplay?.stop(), []);
    const handleMouseLeave = useCallback(() => swiperRef.current?.swiper?.autoplay?.start(), []);
    const theme = useTheme();

    return (
        <Hero
            topChildren={topChildren}
            pb={[0, null, 0]}
            bgColor={theme.tokens.SyntaxBackgroundPrimaryDefault}
            {...rest}
        >
            {children}
            {downloadButton && (
                <Box alignItems="center" display={['block', null, 'none']} pb={[6, null, 0]}>
                    {downloadButton}
                </Box>
            )}
            {(tabs || downloadButton) && (
                <Wrapper bgColor={theme.tokens.SyntaxBackgroundPrimaryDefault} w="90vw">
                    <Flex w="100%">
                        {tabs && (
                            <TabList border="none" display="flex" minH={11} minW="67%">
                                {tabs?.length > 0 && (
                                    <Flex w={['100%', null, '50%']} minW="fit-content">
                                        <ChakraSwiper
                                            ref={swiperRef}
                                            speed={1000}
                                            slidesPerView="auto"
                                            width={['100vw', null, null, null, 'calc(100vw - 655px)']}
                                            overflow="hidden"
                                            ml={['-18px', null, null, 0]}
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 3.35,
                                                },
                                                768: {
                                                    slidesPerView: 3.35,
                                                },
                                                1024: {
                                                    slidesPerView: 'auto',
                                                },
                                                1600: {
                                                    slidesPerView: 'auto',
                                                },
                                            }}
                                        >
                                            {tabs.map((tab, i) => (
                                                <StyledSwiperSlide
                                                    key={`tab-${i}`}
                                                    onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <Tab minW="fit-content" w="100%">
                                                        <Body fontWeight={600}>{tab}</Body>
                                                    </Tab>
                                                </StyledSwiperSlide>
                                            ))}
                                        </ChakraSwiper>
                                    </Flex>
                                )}
                            </TabList>
                        )}
                        {downloadButton && (
                            <Box ml="auto" alignItems="center" display={['none', null, 'block']}>
                                {downloadButton}
                            </Box>
                        )}
                    </Flex>
                </Wrapper>
            )}
        </Hero>
    );
};

export default FestivalProgrammeHero;
