import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import ModalTheming from './ModalTheme';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

const drawerTheme = defineMultiStyleConfig({
    baseStyle: ModalTheming.baseStyle,
    sizes: ModalTheming.sizes,
    defaultProps: {
        size: 'md',
    },
    variants: {
        neutral: {
            header: {
                bg: 'transparent',
                '& > *': {
                    bg: 'transparent !important',
                },
            },
            closeButton: {
                bg: 'transparent',
                _focus: {
                    bg: 'transparent',
                },
            },
            body: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            },
        },
        cookie: {
            dialogContainer: {
                pointerEvents: 'none',
            },
            dialog: {
                bg: 'black',
                color: 'white',
                pointerEvents: 'auto',
                boxShadow: '0px -24px 8px rgba(0, 0, 0, 0.04);',
            },
            body: {
                p: 7,
            },
        },
    },
});

export default drawerTheme;
