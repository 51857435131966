import React, { ReactElement, ReactNode } from 'react';

import styled from '@emotion/styled';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import Heading from '../typography/Heading';
import { Props as MetaProps } from '../meta/Meta';
import { css, useTheme } from '@emotion/react';
import useBrowserSize from '../../hooks/useBrowserSize';
import { Box, Flex, FlexProps, Stack } from '@chakra-ui/react';
import Img, { ImgProps } from '../media/Img';

export interface Props extends Omit<FlexProps, 'title'> {
    title: ReactNode;
    meta: ReactElement<MetaProps>;
    image: ImgProps;
}

const SmallFilmListItem: React.FC<Props> = ({ title, image, meta, ...rest }) => {
    const theme = useTheme();
    const size = useBrowserSize();

    return (
        <Container
            as="article"
            sx={{
                '&:hover picture': { transform: 'scale(1.05)' },
                '& picture': { transition: 'transform 1400ms cubic-bezier(0.16, 1, 0.3, 1)' },
            }}
            {...rest}
        >
            <ImageBox width={[theme.tokens.Sizing16, `25%`, `100%`]} maxWidth="208px">
                <AspectRatio.Root ratio={size === 's' || size === 'xs' ? 4 / 3 : 3 / 2}>
                    <Img fill placeholder="blur" {...image} />
                </AspectRatio.Root>
            </ImageBox>
            <Flex alignItems="center" p={theme.tokens.Sizing3} px={[theme.tokens.Sizing3, null, theme.tokens.Sizing7]}>
                <Stack spacing={[0, theme.tokens.Sizing1, theme.tokens.Sizing3]}>
                    <Heading as="h2" variant={4} clamp={size === 'xs' || size === 's' ? 1 : 2}>
                        {title}
                    </Heading>
                    <Stack>{meta}</Stack>
                </Stack>
            </Flex>
        </Container>
    );
};

const Container = styled(Flex)(
    ({ theme }) => css`
        outline: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        color: ${theme.tokens.SyntaxTextColorDefault};
        text-decoration: none;

        img {
            object-fit: cover;
        }
    `
);

const ImageBox = styled(Box)`
    flex-shrink: 0;
`;

export default SmallFilmListItem;
