import React, { HTMLAttributes } from 'react';

const IconMenu: React.FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M3 4.5H21V6.80769H3V4.5Z" fill="currentColor" />
        <path d="M3 10.8455H21V13.1531H3V10.8455Z" fill="currentColor" />
        <path d="M3 17.1923H21V19.5H3V17.1923Z" fill="currentColor" />
    </svg>
);

export default IconMenu;
