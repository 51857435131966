import React, { ReactElement, ReactNode, useMemo } from 'react';

import { Box, CheckboxProps, Flex, RadioProps, Stack } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as Accordion from '@radix-ui/react-accordion';
import * as Collapsible from '@radix-ui/react-collapsible';

import TextButton from '../action/TextButton';
import IconAdd from '../trinket/icon/IconAdd';
import IconCaret from '../trinket/icon/IconCaret';
import IconModal from '../trinket/icon/IconModal';
import IconRemove from '../trinket/icon/IconRemove';
import Body from '../typography/Body';

interface Props extends Accordion.AccordionItemProps {
    heading: ReactNode;
    selectedFilters?: string;
    filters: ReactElement<RadioProps>[] | ReactElement<CheckboxProps>[] | ReactElement<Props>[];
    onModal?: () => void;
    isFull?: boolean;
    translations: {
        chooseA: ReactNode;
        show: ReactNode;
        more: ReactNode;
        less: ReactNode;
    };
}

const FilterSectionExpandable: React.FC<Props> = ({
    heading,
    selectedFilters,
    filters,
    onModal,
    isFull,
    translations,
    ...args
}) => {
    const asModal = useMemo(() => !isFull && filters.length > 20, [filters.length, isFull]);
    const theme = useTheme();

    return (
        <Container data-accordion {...args}>
            <Accordion.AccordionHeader asChild>
                <Header>
                    <Box py={4}>
                        <Flex alignItems="center">
                            <GroupTitle flexGrow={1}>{heading}</GroupTitle>
                            <AccordionArrow width={5} height={5}>
                                <IconCaret direction="down" />
                            </AccordionArrow>
                        </Flex>

                        <Box>
                            <Body data-accordion-show-closed style={{ color: theme.tokens.ColorNeutral60 }}>
                                {selectedFilters}
                            </Body>
                        </Box>
                    </Box>
                </Header>
            </Accordion.AccordionHeader>
            <Accordion.AccordionContent>
                <Box pb={6}>
                    {asModal ? (
                        <TextButton
                            colored
                            iconLeft={<IconModal />}
                            iconRight={null}
                            size="s"
                            tabIndex={0}
                            onClick={onModal}
                            onKeyDown={e => (e.key === 'Enter' || e.key === 'Space') && onModal()}
                        >
                            {/* TODO: make this whole section a translation to fix the "a" / "an" issue */}
                            {translations.chooseA}{' '}
                            <Box as="span" textTransform="lowercase">
                                {heading}
                            </Box>
                        </TextButton>
                    ) : (
                        <Collapsible.Root data-collapsible>
                            <Stack spacing={6}>
                                <Stack spacing={5}>
                                    {!isFull ? filters.slice(0, 5) : filters}
                                    {!isFull && (
                                        <Collapsible.Content>
                                            <Stack spacing={6}>{filters.slice(5)}</Stack>
                                        </Collapsible.Content>
                                    )}
                                </Stack>
                                {!isFull && filters.slice(5).length > 0 && (
                                    <TriggerContainer>
                                        <TextButton
                                            data-collapsible-show-closed
                                            colored
                                            iconLeft={<IconAdd />}
                                            iconRight={null}
                                            size="s"
                                        >
                                            {translations.show} {filters.slice(5).length} {translations.more}
                                        </TextButton>
                                        <TextButton
                                            data-collapsible-show-open
                                            colored
                                            iconLeft={<IconRemove />}
                                            iconRight={null}
                                            size="s"
                                        >
                                            {translations.less}
                                        </TextButton>
                                    </TriggerContainer>
                                )}
                            </Stack>
                        </Collapsible.Root>
                    )}
                </Box>
            </Accordion.AccordionContent>
        </Container>
    );
};

const Header = styled(Accordion.AccordionTrigger)(
    ({ theme }) => css`
        -webkit-appearance: none;
        border: none;
        background: transparent;
        padding: 0;
        display: block;
        width: 100%;
        text-align: left;

        &:focus {
            outline: none;

            svg {
                border-color: ${theme.tokens.SyntaxColorInputFocusBorder};
                box-shadow: 0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight};
                outline: none;
            }
        }
    `
);

const Container = styled(Accordion.AccordionItem)(
    () => css`
        &[data-accordion][data-state='closed'] [data-accordion-show-open] {
            display: none;
        }
        &[data-accordion][data-state='open'] [data-accordion-show-closed] {
            display: none;
        }

        [data-collapsible][data-state='closed'] [data-collapsible-show-open] {
            display: none;
        }
        [data-collapsible][data-state='open'] [data-collapsible-show-closed] {
            display: none;
        }
    `
);

const GroupTitle = styled(Body)(
    ({ theme }) => css`
        font-weight: ${theme.tokens.FontWeightXl};
    `
);

const AccordionArrow = styled(Box)`
    flex-shrink: 0;
    transition: all 0.2s;
    [data-state='open'] & {
        transform: rotateX(180deg);
    }
`;

const TriggerContainer = styled(Collapsible.CollapsibleTrigger)(
    ({ theme }) => `
    -webkit-appearance: none;
    border: none;
    background: transparent;
    padding: 0;

    &:focus {
        border-color: ${theme.tokens.SyntaxColorInputFocusBorder};
        box-shadow: 0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight};
        outline: none;
    }
`
);

export default FilterSectionExpandable;
