import { Box, Flex, FlexProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import IconCalendarAdd from '../trinket/icon/IconCalendarAdd';
import Body from '../typography/Body';

interface Props extends FlexProps {
    addedToCalendar: boolean;
    iconColor?: string;
    buttonLabel?: string;
    isLoading: boolean;
}

const CalendarButton: FC<Props> = ({ addedToCalendar, isLoading, iconColor, buttonLabel, ...rest }) => (
    <Flex
        as="button"
        alignItems="center"
        justifyContent="center"
        borderRadius="9999px"
        border={addedToCalendar ? 'none' : '1px solid'}
        bgColor={addedToCalendar ? 'yellow.60' : 'transparent'}
        opacity={!isLoading ? 1 : 0.5}
        pointerEvents={!isLoading ? 'auto' : 'none'}
        transition="opacity .10s"
        {...rest}
    >
        {buttonLabel && <Body mr={2}>{buttonLabel}</Body>}
        <Box w={6} h={6}>
            <IconCalendarAdd color={iconColor} />
        </Box>
    </Flex>
);
export default CalendarButton;
