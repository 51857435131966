import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';

export type BrowserSize = 'xs' | 's' | 'm' | 'l' | 'xl';

export const browserSizes: BrowserSize[] = ['xs', 's', 'm', 'l', 'xl'];

const useBrowserSize = () => {
    const [browserSize, setBrowserSize] = useState<BrowserSize>('l');
    const theme = useTheme();

    useEffect(() => {
        const mqls = {
            xs: window.matchMedia(`(max-width: ${theme.tokens.MediaQueryXs})`), // 1280
            s: window.matchMedia(`(max-width: ${theme.tokens.MediaQueryS})`), // 720
            m: window.matchMedia(`(max-width: ${theme.tokens.MediaQueryM})`),
            l: window.matchMedia(`(max-width: ${theme.tokens.MediaQueryL})`),
            xl: window.matchMedia(`(max-width: ${theme.tokens.MediaQueryXl})`),
        };
        function checkBrowserSize() {
            if (mqls.xs.matches) {
                setBrowserSize('xs');
            } else if (mqls.s.matches) {
                setBrowserSize('s');
            } else if (mqls.m.matches) {
                setBrowserSize('m');
            } else if (mqls.l.matches) {
                setBrowserSize('l');
            } else {
                setBrowserSize('xl');
            }
        }
        function mqEvent(this: MediaQueryList) {
            checkBrowserSize();
        }
        checkBrowserSize();
        // mqls.addListener(mqEvent);
        Object.values(mqls).map(mq => mq.addEventListener('change', mqEvent));

        return () => {
            Object.values(mqls).map(mq => mq.removeEventListener('change', mqEvent));
        };
    }, [
        theme.tokens.MediaQueryL,
        theme.tokens.MediaQueryM,
        theme.tokens.MediaQueryS,
        theme.tokens.MediaQueryXl,
        theme.tokens.MediaQueryXs,
    ]);

    return browserSize;
};

export default useBrowserSize;
