import Link, { LinkProps } from 'next/link';
import React, { PropsWithChildren, ReactNode, useCallback, useRef } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { Box, BoxProps, chakra, Stack, useMediaQuery } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import Img, { ImgProps } from '../media/Img';
import Body from '../typography/Body';
import Heading from '../typography/Heading';

interface Slide
    extends Omit<ImgProps, 'as' | 'onClick' | 'href' | 'onMouseEnter' | 'onTouchStart' | 'title'>,
        Omit<LinkProps, 'title'> {
    id: string;
    title: ReactNode;
    subtitle: ReactNode;
}

interface Props extends PropsWithChildren, Omit<BoxProps, 'title'> {
    title: ReactNode;
    body: ReactNode;
    textButton?: ReactNode;
    slides: Slide[];
}

const LinkCarousel: React.FC<Props> = ({ title, body, textButton, slides, ...rest }) => {
    const { tokens } = useTheme();
    const small = useMediaQuery(`(max-width: ${tokens.MediaQueryS})`)[0];
    const swiperRef = useRef<SwiperRef>();
    const handleMouseEnter = useCallback(() => swiperRef.current?.swiper?.autoplay?.stop(), []);
    const handleMouseLeave = useCallback(() => swiperRef.current?.swiper?.autoplay?.start(), []);

    return (
        <Box
            p={[9, null, null, 15]}
            color={tokens.SyntaxTextColorOnDark}
            bg={tokens.SyntaxBackgroundNeutralDarkest}
            {...rest}
        >
            <Stack spacing={6} mb={[9, null, null, 15]}>
                <Stack spacing={5}>
                    <Heading variant={2}>{title}</Heading>
                    <Body>{body}</Body>
                </Stack>
                {textButton}
            </Stack>
            <ChakraSwiper
                ref={swiperRef}
                autoplay={{
                    delay: 3000,
                }}
                speed={2000}
                slidesPerView={small ? 1 : 3}
                loop
                modules={[Autoplay]}
                w="100vw"
                pos="relative"
                left="50%"
                ml="-50vw"
            >
                {slides.map(slide => (
                    <SwiperSlide key={slide.id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <Link href={slide.href} target={slide.target}>
                            <Box>
                                <Img width={600} height={400} src={slide.src} />
                                <Box
                                    pos="absolute"
                                    bg={`linear-gradient(rgba(0,0,0,0), ${tokens.SyntaxOverlayColorDefault})`}
                                    px={7}
                                    py={6}
                                    inset={0}
                                    display="flex"
                                    justifyContent="flex-end"
                                    flexDir="column"
                                    transition="0.4s"
                                    opacity="0"
                                    _hover={{
                                        opacity: 1,
                                    }}
                                >
                                    <Body fontWeight="xxl">{slide.title}</Body>
                                    <Body>{slide.subtitle}</Body>
                                </Box>
                            </Box>
                        </Link>
                    </SwiperSlide>
                ))}
            </ChakraSwiper>
        </Box>
    );
};

const ChakraSwiper = chakra(Swiper);

export default LinkCarousel;
