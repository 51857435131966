import React, { HTMLAttributes } from 'react';

const IconHome: React.FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 9L12 3L20 9V19H4V9ZM10 12H14V19H10V12Z" fill="currentColor" />
    </svg>
);

export default IconHome;
