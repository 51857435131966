import { Box, BoxProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import dynamic from 'next/dynamic';
import { ComponentProps, ReactNode, useState } from 'react';
import TextButton from '../action/TextButton';
import IconPlay from '../trinket/icon/IconPlay';
// importing react-player directly causes hydration error in Next: https://github.com/cookpete/react-player/issues/1428
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface Props extends Omit<BoxProps, 'title'> {
    video: ComponentProps<typeof ReactPlayer>;
    label: ReactNode;
}

const VideoSection: React.FC<Props> = ({ video, label, ...rest }) => {
    const { tokens } = useTheme();
    const [playing, setPlaying] = useState(false);
    return (
        <Box
            pos="relative"
            color={tokens.SyntaxTextColorOnDark}
            bg={tokens.SyntaxBackgroundNeutralDarkest}
            _before={{
                content: "''",
                pos: 'absolute',
                inset: 0,
                bg: `linear-gradient(rgba(0, 0, 0, 0), ${tokens.SyntaxOverlayColorDefault})`,
                zIndex: 2,
                opacity: playing ? 0 : 1,
                pointerEvents: 'none',
            }}
            onClick={() => setPlaying(true)}
            h="56.25vw"
            maxH="80vh"
            overflow="hidden"
            {...rest}
        >
            <ReactPlayer
                width="100%"
                height="100%"
                controls
                playIcon={<div />}
                playing={playing}
                onPlay={() => {
                    setPlaying(true);
                }}
                onPause={() => {
                    setPlaying(false);
                }}
                style={{
                    transform: playing ? 'scale(1)' : 'scale(1.3)',
                    transition: '1s',
                }}
                {...video}
            />
            {!playing && (
                <TextButton
                    pos="absolute"
                    bottom={10}
                    left={10}
                    zIndex={2}
                    iconRight={null}
                    iconLeft={
                        <Box
                            sx={{ aspectRatio: 1 }}
                            w={10}
                            borderWidth={tokens.BorderWidthXl}
                            borderStyle="solid"
                            borderRadius={tokens.BorderRadiusL}
                            mr={4}
                            p={3}
                        >
                            <IconPlay />
                        </Box>
                    }
                >
                    {label}
                </TextButton>
            )}
        </Box>
    );
};

export default VideoSection;
