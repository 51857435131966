import {
    ButtonBack,
    ButtonNext,
    CarouselContext,
    CarouselProvider,
    CarouselProviderProps,
    DotGroup,
    Slide,
    SlideProps,
    Slider,
} from 'pure-react-carousel';
import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import IconCaret from '../trinket/icon/IconCaret';

interface Props {
    visibleSlides: number;
    slides: { slide: ReactElement<SlideProps>; caption?: string }[];
    naturalSlideWidth: CarouselProviderProps['naturalSlideWidth'];
    naturalSlideHeight: CarouselProviderProps['naturalSlideHeight'];
    sliderProps?: Omit<CarouselProviderProps, 'naturalSlideWidth' | 'naturalSlideHeight'>;
    showButtons?: boolean;
}

const Caption: React.FC<{ captions: string[] }> = ({ captions, ...rest }) => {
    const { state, subscribe, unsubscribe, getStoreState } = useContext(CarouselContext);
    const [currentSlide, setCurrentSlide] = useState(state.currentSlide);
    useEffect(() => {
        function onChange() {
            setCurrentSlide(getStoreState().currentSlide);
        }
        subscribe(onChange);
        return () => unsubscribe(onChange);
    }, [subscribe, unsubscribe, getStoreState]);

    return (
        <Text fontSize={2} {...rest}>
            {captions[currentSlide]}
        </Text>
    );
};

const Carousel: React.FC<Props> = ({ visibleSlides = 1, slides, sliderProps, showButtons = false, ...rest }) => {
    const captions = useMemo(() => slides.map(slide => slide.caption), [slides]);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <CarouselProvider
            totalSlides={slides.length}
            visibleSlides={visibleSlides}
            step={visibleSlides}
            dragStep={visibleSlides}
            infinite
            {...sliderProps}
            {...rest}
        >
            <Stack spacing={4} ref={containerRef}>
                <Box position="relative">
                    {showButtons && (
                        <ButtonBack>
                            <SquareButton as="div" aria-label="previous" icon={<IconCaret direction="left" />} />
                        </ButtonBack>
                    )}
                    <Slider>
                        {slides.map(({ slide }, index) => (
                            <Slide index={index} key={index}>
                                <Box w="100%" h="100%">
                                    {slide}
                                </Box>
                            </Slide>
                        ))}
                    </Slider>
                    {showButtons && (
                        <ButtonNext>
                            <SquareButton as="div" aria-label="next" icon={<IconCaret direction="right" />} />
                        </ButtonNext>
                    )}
                </Box>
                <HStack spacing={5}>
                    {slides.length > visibleSlides && <StyledDotGroup visibleSlides={visibleSlides} />}
                    <Caption captions={captions} />
                </HStack>
            </Stack>
            {/* adding carousel styles as this library requires adding a css file, and we don't like that. */}
            <Global
                styles={css`
                    .carousel__slider {
                        position: relative;
                        overflow: hidden;
                        touch-action: pan-y pinch-zoom;
                    }
                    .carousel__slider-tray {
                        display: block;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        transition: transform 0.5s;
                        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
                        will-change: transform;
                    }
                    .carousel__slide {
                        position: relative;
                        display: block;
                        box-sizing: border-box;
                        height: 0;
                        margin: 0;
                        list-style-type: none;
                        float: left;
                    }
                    .carousel__inner-slide {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    .carousel__back-button,
                    .carousel__next-button {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 1;
                    }
                    .carousel__back-button {
                        left: 0;
                    }
                    .carousel__next-button {
                        right: 0;
                    }
                `}
            />
        </CarouselProvider>
    );
};

export const StyledDotGroup = styled(DotGroup)<Pick<Props, 'visibleSlides'>>(
    ({ theme, visibleSlides }) => css`
        display: flex;

        & > * + * {
            margin-left: ${theme.tokens.Sizing2};
        }

        .carousel__dot,
        .carousel__dot:disabled {
            color: currentColor;
        }

        .carousel__dot {
            border: none;
            display: none;
            appearance: none;
            -webkit-appearance: none;
            width: ${theme.tokens.Sizing2};
            height: ${theme.tokens.Sizing2};
            padding: 0;
            border-radius: 100%;
            background: currentColor;
            outline: none;
            position: relative;

            /* add a clickable area */
            &:before {
                content: '';
                position: absolute;
                top: calc(${theme.tokens.Sizing2} * -1);
                right: calc(${theme.tokens.Sizing2} * -1);
                bottom: calc(${theme.tokens.Sizing2} * -1);
                left: calc(${theme.tokens.Sizing2} * -1);
            }
        }

        .carousel__dot--selected {
            background: transparent;
            outline: ${theme.borderWidths.l} solid currentColor;
        }

        .carousel__dot:nth-of-type(${visibleSlides}n - ${visibleSlides - 1}) {
            display: revert;
        }
    `
);

const SquareButton = styled(IconButton)`
    border-radius: 0;
`;

export default Carousel;
