import { FC, useMemo } from 'react';
import { Body, Box, FilmListItem, HtmlContent, Meta, PersonListItem, ReportListItem } from 'designsystem';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { SearchArchiveHitsQuery } from '../../gql/api';
import {
    getHighestRankingAward,
    getTitleFromEditionType,
    getMetaForFilmOrProjectHit,
    getMetaForPerson,
    getMetaForReport,
    slugifyTitle,
    EditionType,
    sortEditions,
    useGetApiImageProps,
} from 'shared';

type Hits = SearchArchiveHitsQuery['searchArchive']['hits'];

const ArchiveSearchHit: FC<{ hit: Hits[0] }> = ({ hit }) => {
    const getImageProps = useGetApiImageProps();
    const imageProps = useMemo(
        () =>
            getImageProps(
                hit.__typename === 'CollapsedFilmHit'
                    ? hit.film.publications.favoriteImage
                    : hit.mainAccreditation?.publications?.favoriteImage,
                hit.__typename === 'Person' ? hit.fullName : hit.film.fullPreferredTitle
            ),
        [getImageProps, hit]
    );
    const sortedEditions = useMemo(() => {
        if (hit.__typename !== 'CollapsedFilmHit') {
            return undefined;
        }
        return sortEditions(hit.editions).map(edition => ({
            ...edition,
            name: getTitleFromEditionType(edition.name as EditionType, true),
        }));
    }, [hit]);

    return (
        <>
            {hit.__typename === 'Person' && (
                <Link href={`/person/${hit.id}/${slugifyTitle(hit.fullName)}`}>
                    <PersonListItem
                        name={hit.fullName}
                        image={imageProps}
                        meta={<Meta size="s" {...getMetaForPerson(hit)} />}
                    />
                </Link>
            )}

            {hit.__typename === 'CollapsedFilmHit' && !hit.film.isAlternativeContent && (
                <Link href={`/film/${hit.film.id}/${slugifyTitle(hit.film.fullPreferredTitle)}`}>
                    <FilmListItem
                        title={hit.film.fullPreferredTitle}
                        meta={<Meta size="s" {...getMetaForFilmOrProjectHit({ filmOrProject: hit.film })} />}
                        image={imageProps}
                        description={
                            (hit.film.intro?.translation || hit.film.description?.translation) && (
                                <DescWrapper>
                                    <HtmlContent
                                        html={hit.film.intro?.translation || hit.film.description.translation}
                                    />
                                </DescWrapper>
                            )
                        }
                        editions={sortedEditions.length > 1 && sortedEditions?.map(edition => edition.name)}
                        award={getHighestRankingAward(hit.film.awards)?.translation}
                        translatedEditionTitle={<FormattedMessage defaultMessage="Festival history" />}
                    />
                </Link>
            )}
            {/* a report is a film with isAlternativeContent === true */}
            {hit.__typename === 'CollapsedFilmHit' && hit.film.isAlternativeContent && (
                <Link href={`/report/${hit.film.id}/${slugifyTitle(hit.film.fullPreferredTitle)}`}>
                    <ReportListItem
                        title={hit.film.fullPreferredTitle}
                        meta={<Meta size="s" {...getMetaForReport(hit.film)} />}
                        image={imageProps}
                        description={
                            hit.film.description?.translation && (
                                <DescWrapper>
                                    <HtmlContent html={hit.film.description.translation} />
                                </DescWrapper>
                            )
                        }
                    />
                </Link>
            )}
        </>
    );
};

const DescWrapper = styled(Box)(
    ({ theme }) => css`
        ${Body} {
            font-size: ${theme.tokens.FontSize2};
            line-height: ${theme.tokens.LineHeightS};
        }
    `
);

export default ArchiveSearchHit;
