import React, { useMemo } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import Carousel from '../navigation/Carousel';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';
import getAspectRatio from '../../utils/getAspectRatio';

interface Props extends BoxProps {
    images: (Omit<ImgProps, 'fill'> & { key: string; caption: string })[];
}
const ContentBlockCarousel: React.FC<Props> = ({ images, ...rest }) => {
    const averageRatio = useMemo(
        () =>
            images.reduce<number>((total, { width, height }) => total + getAspectRatio(width, height), 0) /
            images.length,
        [images]
    );

    const theme = useTheme();

    return (
        <Box mb={6} color={theme.tokens.SyntaxTextColorNeutralMuted} {...rest}>
            <Carousel
                visibleSlides={1}
                naturalSlideWidth={averageRatio > 1 ? averageRatio : 1}
                naturalSlideHeight={1}
                showButtons
                slides={images.map(({ key, caption, width, height, ...image }) => ({
                    slide: (
                        <SlideImage
                            fill
                            style={{
                                objectFit:
                                    Math.abs(getAspectRatio(width, height) - averageRatio) > 0.4 ? 'contain' : 'cover',
                            }}
                            {...image}
                        />
                    ),
                    caption,
                }))}
            />
        </Box>
    );
};

const SlideImage = styled(Img)(
    ({ theme }) => `
    background: ${theme.tokens.SyntaxBackgroundNeutralDark};
`
);

export default ContentBlockCarousel;
