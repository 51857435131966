import { FC } from 'react';

import { BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ColorSchemeProvider, { Props as ColorSchemeProps } from '../ColorSchemeProvider';

export interface Props extends Omit<ColorSchemeProps, 'children'>, BoxProps {
    primary?: boolean;
    size?: 's' | 'm';
}

const Tile: FC<Props> = ({ primary = true, colorScheme, children, size = 'm', ...rest }) => (
    <TileContainer
        colorScheme={colorScheme}
        primary={primary}
        p={size === 'm' ? [6, 10] : [5, 8]}
        display="flex"
        flexDirection="column"
        w="100%"
        justifyContent="center"
        boxSizing="border-box"
        {...rest}
    >
        {children}
    </TileContainer>
);

const TileContainer = styled(ColorSchemeProvider)<Pick<Props, 'primary'>>(
    ({ theme, primary }) => css`
        background-color: ${primary ? theme.tokens.TilePrimaryBackground : theme.tokens.TileNeutralBackground};
        color: ${primary ? theme.tokens.TilePrimaryForeground : theme.tokens.TileNeutralForeground};

        .chakra-button {
            background-color: ${primary && theme.tokens.TilePrimaryForeground};
            color: ${primary && theme.tokens.TilePrimaryBackground};
        }
    `
);

export default Tile;
