import React from 'react';
import { MetaProps } from '../..';
import Meta from '../meta/Meta';
import Card, { CardProps } from './Card';

interface Props extends CardProps {
    meta: Omit<MetaProps, 'size'>;
}

const NewsCard: React.FC<Props> = ({ meta, ...rest }) => (
    <Card {...rest}>
        <Meta size="m" {...meta} />
    </Card>
);

export default NewsCard;
