import { useEffect, useRef } from 'react';
import isEqual from 'lodash.isequal';

const useDeepEffect: typeof useEffect = (effectFunc, deps) => {
    // 1° Step
    const isFirst = useRef(true);
    const prevDeps = useRef(deps);

    useEffect(() => {
        // 2° Step
        const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

        // 3° Step
        if (isFirst.current || !isSame) {
            effectFunc();
        }
        // 4° Step
        isFirst.current = false;
        prevDeps.current = deps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export default useDeepEffect;
