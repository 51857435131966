import { ComponentPropsWithoutRef, ComponentPropsWithRef } from 'react';
import { IntlProvider } from 'react-intl';
import { dateFormat, timeFormat } from '../../constants/dateFormats';

export const defaultIntlProviderProps: Partial<ComponentPropsWithoutRef<typeof IdfaIntlProvider>> = {
    timeZone: 'Europe/Amsterdam',
    defaultFormats: {
        date: dateFormat,
        time: timeFormat,
    },
    formats: {
        date: dateFormat,
        time: timeFormat,
    },
};

const IdfaIntlProvider: React.FC<ComponentPropsWithRef<typeof IntlProvider>> = ({ ...rest }) => (
    <IntlProvider {...defaultIntlProviderProps} {...rest} />
);

export default IdfaIntlProvider;
