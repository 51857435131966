import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    Body,
    Box,
    Button,
    ConfirmationTile,
    FilterSections,
    Flex,
    Grid,
    Heading,
    Hero,
    HStack,
    HtmlContent,
    IconButton,
    IconFilters,
    IconWarn,
    Intro,
    Pagination,
    SearchInput,
    Skeleton,
    useDisclosure,
    useMediaQuery,
    Wrapper,
} from 'designsystem';
import {
    ArchiveFilterEnum,
    SearchArchiveFiltersQueryVariables,
    useFilmDetailQuery,
    useSearchArchiveCountsQuery,
    useSearchArchiveFiltersQuery,
} from '../../gql/api';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient, useQueries } from '@tanstack/react-query';
import SearchNavTiles from '../SearchNavTiles';
import SearchFilterSections from '../archiveSearch/SearchFilterSections';
import SelectedFilterSections from '../archiveSearch/SelectedFilterSections';
import loadIntlMessages from '../../utils/loadIntlMessages';
import styled from '@emotion/styled';
import useArchiveSearch from '../../hooks/useArchiveSearch';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    cmsImageToOpenGraph,
    fetchApiData,
    FilmCardQuery,
    FilmCardQueryVariables,
    gtm,
    useFilmCardQuery,
    useSearchState,
} from 'shared';
import FilterModal from '../FilterModal';
import ArchiveFiltersModal from '../archiveSearch/ArchiveFiltersModal';
import { ContentPage } from '../../pages/[...uri]';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import SearchResults from '../SearchResults';
import ArchiveSearchHit from '../archiveSearch/ArchiveSearchHit';
import archiveFilterMessages from '../../constants/archiveFilterMessages';
import InstituteBreadcrumbs from '../InstituteBreadcrumbs';
import { NextSeo } from 'next-seo';
import striptags from 'striptags';
import { useRouter } from 'next/router';
import { useDebounce } from 'use-debounce';

export const SEARCH_RESULTS_AMOUNT = 10;

type Filters = ReturnType<typeof useArchiveSearch>['filters'];
export type ArchiveContentPage = Extract<ContentPage, { __typename: 'instituteContentPages_archiveSearchPage_Entry' }>;

export const ArchiveFilterQueryParams: Record<ArchiveFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(ArchiveFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const ArchivePage: FC<{ data: ArchiveContentPage }> = ({ data }) => {
    const { locale } = useRouter();
    const filterModalProps = useDisclosure();
    const filterSectionsModalProps = useDisclosure();
    const searchState = useSearchState(ArchiveFilterQueryParams);
    const { query, setQuery, isSearching, hasQueryFilters, page, setPage, inputRef } = searchState;
    const { totalHits, totalPages, isFetching, isError, filters, hits, offset } = useArchiveSearch(searchState);
    const [isLargeScreen] = useMediaQuery('(min-width: 1280px)');
    const [selectedFilter, setSelectedFilter] = useState<Filters[number] | null>(null);
    const { formatMessage } = useIntl();
    const onModal = useCallback(
        (filter: Filters[number]) => {
            setSelectedFilter(filter);
            filterModalProps.onOpen();
        },
        [filterModalProps]
    );
    const [debouncedQuery] = useDebounce(query, 400);

    /** TODO: split the string temporarily as the BE doesn't return an array yet */
    const featuredFilmsResults = useQueries({
        queries: Array.isArray(data.featuredFilms)
            ? data.featuredFilms.map(featuredFilmId => {
                  const variables = {
                      id: featuredFilmId,
                  };
                  return {
                      queryKey: useFilmCardQuery.getKey(variables),
                      queryFn: () =>
                          fetchApiData<FilmCardQuery, FilmCardQueryVariables>({
                              locale,
                              query: useFilmDetailQuery.document,
                              variables,
                          }),
                  };
              })
            : [],
    });

    useEffect(() => {
        if (debouncedQuery !== '') {
            gtm.event('search_collection', {
                search_term: debouncedQuery,
                collection: 'archive',
            });
        }
    }, [debouncedQuery]);
    return (
        <Wrapper>
            <NextSeo
                title={formatMessage({ defaultMessage: 'IDFA Archief', id: 'meta-archive-title' })}
                description={striptags(data.introText)}
                openGraph={{
                    images: cmsImageToOpenGraph(data.navThumbnail),
                }}
                titleTemplate="IDFA Institute | %s"
            />
            <Grid>
                <Box gridColumnStart={[1, null, null, 1]} gridColumnEnd={[-1, null, null, -1]}>
                    <Hero topChildren={<InstituteBreadcrumbs />} w={null} m={0} maxW="unset">
                        <Box maxWidth={[null, null, '580px']}>
                            <Heading mt={['72px', null, '112px']} variant={1} as="h1">
                                {data.title}
                            </Heading>
                            <Box mt={[4, null, 7]}>
                                <HtmlContent
                                    html={data.introText}
                                    override={{
                                        p: ({ children }) => <Intro>{children}</Intro>,
                                    }}
                                />
                            </Box>
                            <HStack mt={7} spacing={6}>
                                <Box flexGrow={1}>
                                    <SearchInput
                                        placeholder={formatMessage({
                                            defaultMessage: 'Zoek door alles',
                                        })}
                                        value={query}
                                        ref={inputRef}
                                        onChange={e => {
                                            inputRef.current.value = e.currentTarget.value;
                                            setQuery(e.currentTarget.value);
                                        }}
                                        onClear={() => setQuery('')}
                                    />
                                </Box>
                                {isSearching && (
                                    <>
                                        <IconButton
                                            display={['flex', null, 'none']}
                                            aria-label={formatMessage({
                                                defaultMessage: 'Toon filters',
                                            })}
                                            onClick={filterSectionsModalProps.onOpen}
                                            icon={<StyledIconFilters />}
                                        />
                                        <Button
                                            position={[null, null, null, 'absolute']}
                                            right="0"
                                            display={['none', null, 'flex', null, 'none']}
                                            onClick={filterSectionsModalProps.onOpen}
                                            rightIcon={<StyledIconFilters />}
                                        >
                                            {formatMessage({
                                                defaultMessage: 'Toon filters',
                                            })}
                                        </Button>
                                    </>
                                )}
                            </HStack>
                        </Box>
                    </Hero>
                </Box>
            </Grid>
            {!isSearching ? (
                <SearchNavTiles
                    featuredFilms={featuredFilmsResults
                        .map(featureFilmResult => featureFilmResult?.data?.film)
                        .filter(Boolean)}
                />
            ) : (
                <Grid mb={8} mt={[null, null, 8]}>
                    <Box
                        background="neutral.10"
                        display={['none', null, null, null, 'flex']}
                        flexDir="column"
                        alignSelf="flex-start"
                        gridColumnStart={1}
                        gridColumnEnd={[null, null, null, null, 5]}
                        p={10}
                    >
                        {!isError && (
                            <>
                                <Heading variant={4} mb={4}>
                                    <Skeleton as="span" isLoaded={!isFetching}>
                                        {totalHits}
                                    </Skeleton>{' '}
                                    <FormattedMessage
                                        defaultMessage="{count, plural, =0 {zoekresultaten} one {zoekresultaat} other {zoekresultaten}}"
                                        values={{ count: totalHits }}
                                    />
                                    {query ? `: “${query}”` : ''}
                                </Heading>
                                <FilterSections defaultValue={ArchiveFilterEnum.ArchiveType}>
                                    <SelectedFilterSections filters={filters} />
                                    <SearchFilterSections onModal={onModal} filters={filters} />
                                </FilterSections>
                            </>
                        )}
                    </Box>
                    <Box gridColumnStart={[1, null, null, null, 5]} gridColumnEnd={-1}>
                        {!isError ? (
                            <>
                                <Box display={[null, null, 'none']} mb={5}>
                                    <Body>{Math.min(totalHits, SEARCH_RESULTS_AMOUNT)} of </Body>
                                    <Skeleton as="span" isLoaded={!isFetching}>
                                        <span>{totalHits}</span>
                                    </Skeleton>
                                </Box>
                                <SearchResults
                                    queryParams={ArchiveFilterQueryParams}
                                    isLoading={isFetching}
                                    HitComponent={ArchiveSearchHit}
                                    getId={hit => (hit.__typename === 'CollapsedFilmHit' ? hit.film.id : hit.id)}
                                    hits={hits}
                                    hasFilters={hasQueryFilters}
                                    offset={offset}
                                />
                            </>
                        ) : (
                            <ConfirmationTile maxWidth="100%" icon={<IconWarn />}>
                                <Heading variant={4}>
                                    <FormattedMessage defaultMessage="Er is iets mis gegaan" />
                                </Heading>
                                <Body>
                                    <FormattedMessage defaultMessage="Probeer het later opnieuw" />
                                </Body>
                            </ConfirmationTile>
                        )}
                        {totalPages > 1 && (
                            <Flex justifyContent="center" my={[5, null, 9]}>
                                <Pagination
                                    currentPage={page - 1}
                                    setCurrentPage={(p: number) => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                        setPage(p + 1);
                                    }}
                                    totalPages={totalPages}
                                />
                            </Flex>
                        )}
                    </Box>
                </Grid>
            )}

            <ArchiveFiltersModal
                filters={filters}
                hasQueryFilters={hasQueryFilters}
                totalHits={totalHits}
                onModal={onModal}
                blockScrollOnMount={isLargeScreen}
                closeLabel={<FormattedMessage defaultMessage="Sluiten" description="Close modal" />}
                {...filterSectionsModalProps}
            />
            <FilterModal
                filter={selectedFilter}
                filterMessages={archiveFilterMessages}
                queryParams={ArchiveFilterQueryParams}
                closeLabel={<FormattedMessage defaultMessage="Sluiten" description="Close modal" />}
                {...filterModalProps}
            />
        </Wrapper>
    );
};

const StyledIconFilters = styled(IconFilters)`
    // chakra overrides the width/height which is very cool and not annoying - so that's why this is needed.
    width: ${({ theme }) => theme.sizes[5]} !important;
    height: ${({ theme }) => theme.sizes[5]} !important;
`;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    await queryClient.prefetchQuery(useSearchArchiveCountsQuery.getKey(), () =>
        fetchApiData({
            locale,
            query: useSearchArchiveCountsQuery.document,
            variables: {},
        })
    );

    const vars: SearchArchiveFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchArchiveFiltersQuery.getKey(vars), () =>
        fetchApiData({
            locale,
            query: useSearchArchiveFiltersQuery.document,
            variables: vars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default ArchivePage;
