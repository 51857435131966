import { FC, PropsWithChildren } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const Actions: FC<PropsWithChildren> = ({ children }) => {
    const [bottomRef, bottomVisible] = useInView({ threshold: 1 });
    const theme = useTheme();
    return (
        <>
            <Box height={[11, null, 12]} ref={bottomRef} />
            <Box
                height={[null, null, 17]}
                bottom={0}
                left={0}
                right={0}
                position="absolute"
                pointerEvents="none"
                transition="opacity .20s"
                willChange="opacity"
                background={[
                    'none',
                    null,
                    `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${theme.tokens.SyntaxBackgroundNeutralDefault} 60.42%)`,
                ]}
                opacity={bottomVisible ? 0 : 1}
            />
            <Flex
                bgImage={`linear-gradient(transparent 0%, ${theme.tokens.SyntaxBackgroundNeutralDefault} 75%)`}
                justifyItems="flex-end"
                bottom={0}
                left={0}
                right={0}
                py={6}
                px={6}
                position="absolute"
            >
                <Wrapper flexGrow={1} justifyContent="space-evenly" alignItems="flex-end" spacing={4}>
                    {children}
                </Wrapper>
            </Flex>
        </>
    );
};

const Wrapper = styled(HStack)`
    > * {
        flex: 1;
    }
`;
export default Actions;
