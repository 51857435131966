import { Collection } from '../constants/collection';
import { EditionType } from '../constants/editionTypes';

export type PillarType = 'festival' | 'professionals' | 'institute';

const mapEditionTypeToCollection: Record<PillarType, Partial<Record<EditionType, Collection>>> = {
    professionals: {
        IDFA: 'doclab',
        'IDFA Fund': 'bertha-fund',
        'IDFA Forum': 'forum',
        IDFAcademy: 'project-space',
        'Docs for Sale': 'docs-for-sale',
    },
    institute: {
        'IDFA TV': 'docschool',
    },
    festival: {},
};

const getCollectionFromEditionType = (type: EditionType, pillarType: PillarType | 'professional') =>
    mapEditionTypeToCollection[pillarType === 'professional' ? 'professionals' : pillarType]?.[type] ?? 'film';

export default getCollectionFromEditionType;
