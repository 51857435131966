import { useDisclosure } from 'designsystem';
import { useCallback, useEffect, useRef, useState } from 'react';
import cookies from 'js-cookie';
import NewsletterSignup from '../NewsletterSignup';
import { CookieConsent, getNewsletterFormProps } from '../../index';
import { useRouter } from 'next/router';
import { addMonths } from 'date-fns';

const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
const consentCookieName = 'CookieConsent';

if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.gtag = (...args) => window.dataLayer.push(args);
}

const GlobalModals = () => {
    const cookieDisclosureProps = useDisclosure();
    const newsletterDisclosureProps = useDisclosure();
    const shouldShowNewsletter = useRef(cookies.get('hideNewletterPopup') !== '1');
    const { locale } = useRouter();
    const [expires] = useState(addMonths(new Date(), 6));

    const onCloseNewsletter = () => {
        cookies.set('hideNewletterPopup', '1', { expires, domain: cookieDomain });
        newsletterDisclosureProps.onClose();
        shouldShowNewsletter.current = false;
    };

    useEffect(() => {
        // set initial state
        window.gtag('consent', 'default', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'granted',
        });

        const value = cookies.get(consentCookieName);

        if (!value) {
            cookieDisclosureProps.onOpen();
        } else if (shouldShowNewsletter.current) {
            newsletterDisclosureProps.onOpen();
        }

        // if already consented before, update consent without showing the drawer
        if (value === 'true') {
            window.gtag('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted',
            });
        }
    }, [cookieDisclosureProps, newsletterDisclosureProps]);

    const onCloseCookieConsent = useCallback(() => {
        cookieDisclosureProps.onClose();

        if (shouldShowNewsletter.current) {
            newsletterDisclosureProps.onOpen();
        }
    }, [cookieDisclosureProps, newsletterDisclosureProps]);

    const onConsent = useCallback(() => {
        window.dataLayer.push({ event: 'cookie_consent' });
        window.gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted',
        });

        cookies.set(consentCookieName, 'true', {
            expires,
            domain: cookieDomain,
        });
        onCloseCookieConsent();
    }, [expires, onCloseCookieConsent]);

    const onReject = useCallback(() => {
        cookies.set(consentCookieName, 'false', {
            expires,
            domain: cookieDomain,
        });
        onCloseCookieConsent();
    }, [expires, onCloseCookieConsent]);
    return (
        <>
            <NewsletterSignup
                {...newsletterDisclosureProps}
                onClose={onCloseNewsletter}
                formProps={getNewsletterFormProps(locale)}
            />
            <CookieConsent
                {...cookieDisclosureProps}
                onConsent={onConsent}
                onReject={onReject}
                onClose={onCloseCookieConsent}
            />
        </>
    );
};

export default GlobalModals;
