import { FC, HTMLAttributes } from 'react';

const IconSexuality: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    <svg viewBox="0 0 184 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="91.8213" cy="91.5" r="91.5" fill="black" />
        <path
            d="M62.5214 113.494C63.2073 113.844 63.9873 113.966 64.7474 113.842C65.5075 113.717 66.2079 113.353 66.7463 112.802C72.8916 106.273 92.326 84.4572 89.7911 70.4767C89.8203 66.8332 88.5536 63.2979 86.2172 60.502C83.8808 57.7062 80.6268 55.8316 77.0361 55.2132C73.4454 54.5947 69.7517 55.2726 66.6146 57.1258C63.4775 58.9789 61.1011 61.8867 59.9097 65.33C59.641 65.938 59.2249 66.4692 58.699 66.8757C58.1731 67.2822 57.5541 67.551 56.898 67.6578C56.242 67.7646 55.5697 67.706 54.942 67.4873C54.3144 67.2686 53.7512 66.8968 53.3035 66.4054C51.0709 63.5297 47.8896 61.5403 44.3267 60.7919C40.7638 60.0434 37.0509 60.5846 33.8499 62.3189C30.6488 64.0531 28.1677 66.8678 26.8488 70.2612C25.5299 73.6545 25.4589 77.406 26.6485 80.8468C29.2602 94.8273 54.6094 109.576 62.5214 113.494Z"
            fill="white"
        />
        <path
            d="M121.699 112.605L114.33 110.631C113.742 110.469 113.201 110.169 112.753 109.756C112.305 109.343 111.963 108.828 111.754 108.256C111.542 107.677 111.469 107.057 111.542 106.445C111.614 105.833 111.83 105.247 112.171 104.734L123.776 88.1514C124.095 87.7176 124.269 87.1935 124.271 86.6548C124.273 86.116 124.105 85.5904 123.789 85.1538C121.576 82.6774 118.556 81.066 115.267 80.6061C111.363 80.1453 107.428 81.1593 104.233 83.4495C101.038 85.7396 98.8139 89.1406 97.9966 92.9856C95.206 103.4 104.741 118.908 111.069 127.712C111.185 127.874 111.337 128.007 111.513 128.1C111.689 128.193 111.885 128.243 112.084 128.247C112.29 128.237 112.49 128.182 112.671 128.085C112.852 127.987 113.009 127.851 113.13 127.685L122.679 114.447C122.82 114.3 122.922 114.119 122.975 113.921C123.028 113.724 123.03 113.516 122.982 113.318C122.868 113.081 122.683 112.885 122.454 112.757C122.224 112.63 121.96 112.577 121.699 112.605V112.605Z"
            fill="white"
        />
        <path
            d="M123.328 105.144L131.041 107.211C131.626 107.374 132.163 107.674 132.609 108.086C133.055 108.498 133.396 109.011 133.603 109.582C133.811 110.152 133.879 110.764 133.803 111.367C133.726 111.969 133.507 112.544 133.163 113.045L121.392 129.267C121.232 129.496 121.151 129.77 121.161 130.049C121.172 130.328 121.275 130.596 121.453 130.81C121.611 131.042 121.846 131.209 122.116 131.281C122.387 131.354 122.674 131.327 122.927 131.205C132.859 126.758 149.191 118.077 152.047 107.416C153.247 103.683 153.012 99.6358 151.389 96.0659C149.765 92.496 146.87 89.6592 143.267 88.1095C140.33 86.9467 137.088 86.8009 134.058 87.6955C133.497 87.8578 133.011 88.212 132.685 88.6963L122.596 103.158C122.473 103.327 122.393 103.524 122.364 103.732C122.335 103.939 122.358 104.151 122.43 104.347C122.503 104.544 122.623 104.719 122.779 104.858C122.936 104.997 123.124 105.096 123.328 105.144Z"
            fill="white"
        />
    </svg>
);

export default IconSexuality;
