import { FilterSections } from 'designsystem';
import { ComponentProps, PropsWithChildren } from 'react';

import SearchFilterSections from './SearchFilterSections';
import SelectedFilters from './SelectedFilters';

export interface Filter {
    filter: string;
    amounts: {
        amount: number;
        key: string;
    }[];
    inputType?: 'radio' | 'checkbox';
}

type Props = PropsWithChildren & ComponentProps<typeof FilterSections>;

const Filters = ({ children, defaultValue, ...rest }: Props) => (
    <FilterSections defaultValue={defaultValue} {...rest}>
        {children}
    </FilterSections>
);

Filters.Sections = SearchFilterSections;
Filters.Selected = SelectedFilters;

export default Filters;
