import { useState, useRef, useCallback, useEffect } from 'react';

const useShowVideoControls = () => {
    const [showControls, setShowControls] = useState(true);
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const handleMouseMove = useCallback(() => {
        clearTimeout(timer.current);
        setShowControls(true);
        timer.current = setTimeout(() => {
            setShowControls(false);
        }, 3000);
    }, []);

    useEffect(() => {
        handleMouseMove();
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            clearTimeout(timer.current);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handleMouseMove]);

    return showControls;
};

export default useShowVideoControls;
