import React, { AnchorHTMLAttributes, ReactNode, useCallback, useEffect, useState } from 'react';

import { Box, chakra, Flex, shouldForwardProp, Show, useMediaQuery } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import NavigationMenu from './NavigationMenu';
import NavigationPagesBar from './NavigationPagesBar';
import NavigationSearchBar from './NavigationSearchBar';
import NavigationTopBar, { NavigationBottomItem } from './NavigationTopBar';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { isValidMotionProp, motion } from 'framer-motion';
import NavigationCloseButton from './NavigationCloseButton';
import IconUserOutline from '../trinket/icon/IconUserOutline';
import ConditionalWrap from 'conditional-wrap';
import Body from '../typography/Body';
import useScrolled from '../../hooks/useScrolled';

export interface NavPage {
    key: string;
    title: ReactNode;
    href: string;
    selected?: boolean;
}

export interface NavPillar extends NavPage {
    color: string;
}

export interface NavSiteSection extends NavPage {
    subPages: NavPage[];
}

interface Props {
    logo: ReactNode;
    festivalDate?: ReactNode;
    pillars: NavPillar[];
    pillarKey: string;
    pages: NavSiteSection[];
    searchBarPlaceholder: string;
    topPages: ReactNode[];
    loggedIn?: boolean;
    handleMyIdfaClick?: () => void;
    closeLabel: ReactNode;
    overviewLabel: ReactNode;
    showSearch?: boolean;
    handleLoginClick?: (e: React.MouseEvent) => void;
    isLoginLoading?: boolean;
    handleCartClick?: (e: React.MouseEvent) => void;
    isCartLoading?: boolean;
    handleSearchSubmit?: (searchInput: string) => void;
    isLoading: boolean;
    userName?: string;
}

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Navigation: React.FC<Props> = ({
    logo,
    festivalDate,
    topPages,
    closeLabel,
    searchBarPlaceholder,
    pillars,
    pillarKey,
    handleSearchSubmit,
    handleLoginClick,
    handleCartClick,
    showSearch = true,
    isLoading,
    ...rest
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const theme = useTheme();
    const router = useRouter();
    const handleRouteChange = useCallback(() => {
        setMenuOpen(false);
        setIsSearchActive(false);
    }, []);

    useEffect(() => {
        router.events.on('routeChangeStart', handleRouteChange);
        return () => router.events.off('routeChangeStart', handleRouteChange);
    }, [handleRouteChange, router.events]);

    const small = useMediaQuery(`(max-width: ${theme.tokens.MediaQueryS})`)[0];

    const scrolled = useScrolled();

    return (
        <>
            <AnimationBox
                width="100%"
                flexDirection={['column', null, 'row']}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                pos="sticky"
                top={0}
                zIndex="100"
                mb={!isLoading && rest.pages?.length === 0 ? -11 : 0}
                {...rest}
            >
                <Flex height="100%" width="100%">
                    {(!small || !isSearchActive) && (
                        <LogoContainer
                            h={[11, null, 15]}
                            as={Link}
                            href="/"
                            bg={theme.tokens.SyntaxBackgroundPrimaryDefault}
                            pos="relative"
                            _before={{
                                content: "''",
                                display: 'block',
                                pos: 'absolute',
                                bg: 'inherit',
                                inset: 0,
                                zIndex: 1,
                            }}
                        >
                            {logo}
                            {festivalDate && (
                                <AnimationBox
                                    color={theme.tokens.SyntaxTextColorOnDark}
                                    bg={theme.tokens.SyntaxBackgroundNeutralDarkest}
                                    px={[3, null, 5]}
                                    py={[1, null, 3]}
                                    zIndex="0"
                                    animate={{
                                        y: scrolled || menuOpen ? '-101%' : '0',
                                    }}
                                >
                                    <Body fontWeight="xl" fontSize={[1, null, 4]} nowrap>
                                        {festivalDate}
                                    </Body>
                                </AnimationBox>
                            )}
                        </LogoContainer>
                    )}
                    <Flex width="100%" flexDirection="column">
                        <NavigationTopBar
                            pillars={pillars}
                            topPages={topPages}
                            display={['none', null, 'flex']}
                            handleLoginClick={handleLoginClick}
                            {...rest}
                        />
                        {menuOpen ? (
                            <Flex
                                h="100%"
                                w="100%"
                                bg={[theme.tokens.SyntaxBackgroundPrimaryDarker, null, 'transparent']}
                            >
                                {showSearch && (
                                    <NavigationSearchBar
                                        handleSearchSubmit={handleSearchSubmit}
                                        toggleSearch={() => setIsSearchActive(!isSearchActive)}
                                        isSearchActive={!small || isSearchActive}
                                        placeholder={searchBarPlaceholder}
                                    />
                                )}
                                <Flex ml="auto">
                                    {!isSearchActive && (
                                        <Show below="m">
                                            <NavigationBottomItem
                                                as="a"
                                                bg={[null, null, theme.tokens.SyntaxColorActionEnabledBackground]}
                                                minW={['auto', null, '160px']}
                                                justifyContent="center"
                                                onClick={handleLoginClick}
                                            >
                                                <IconUserOutline color={theme.tokens.ColorNeutralWhite} />
                                            </NavigationBottomItem>
                                        </Show>
                                    )}
                                    <Box cursor="pointer">
                                        <NavigationCloseButton
                                            handleClose={() => {
                                                setMenuOpen(false);
                                                setIsSearchActive(false);
                                            }}
                                            closeLabel={closeLabel}
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                        ) : (
                            <ConditionalWrap
                                condition={rest.pages?.length <= 0 && !isLoading}
                                wrap={child => <Show below="s">{child}</Show>}
                            >
                                <NavigationPagesBar
                                    handleLoginClick={handleLoginClick}
                                    handleCartClick={handleCartClick}
                                    handleMenuClick={() => {
                                        setMenuOpen(true);
                                    }}
                                    pillarKey={pillarKey}
                                    showSearch={showSearch}
                                    isLoading={isLoading}
                                    {...rest}
                                />
                            </ConditionalWrap>
                        )}
                    </Flex>
                </Flex>
            </AnimationBox>
            {menuOpen && <NavigationMenu topPages={topPages} pillars={pillars} pillarKey={pillarKey} {...rest} />}
        </>
    );
};

const LogoContainer = styled(Box)<AnchorHTMLAttributes<HTMLAnchorElement>>`
    & > svg {
        position: relative;
        z-index: 1;
        height: 100%;
    }

    &:focus {
        outline: none;
    }
`;

export default Navigation;
