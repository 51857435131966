import { Select as SelectComponent, SelectProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import React from 'react';
import IconCaret from '../trinket/icon/IconCaret';

const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
    const theme = useTheme();
    return <SelectComponent icon={<IconCaret direction="down" />} iconSize={theme.sizes[6]} ref={ref} {...props} />;
});

Select.displayName = 'Select';

export default Select;
