import { FormatDateOptions } from 'react-intl';

export const dateFormat: Record<string, FormatDateOptions> = {
    short: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'Europe/Amsterdam',
    },
    medium: {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        timeZone: 'Europe/Amsterdam',
    },
    long: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'Europe/Amsterdam',
    },
};

export const timeFormat: Record<string, FormatDateOptions> = {
    short: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Europe/Amsterdam',
    },
    long: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Europe/Amsterdam',
    },
};
