import { ContentPage } from '../../pages/[...uri]';
import React, { FC } from 'react';
import DocschoolForm from '../forms/DocschoolForm';
import { Body, Box, Heading, HtmlContent, Stack, Wrapper } from 'designsystem';
import loadIntlMessages from '../../utils/loadIntlMessages';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import { FormDetailQuery, FormDetailQueryVariables, useFormDetailQuery } from '../../gql/cms';
import { cmsImageToOpenGraph, fetchCraftData, PreviewData } from 'shared';
import siteHandleByLocale from '../../utils/siteHandleByLocale';
import RecurringDonationForm from '../forms/RecurringDonationForm';
import { NextSeo } from 'next-seo';
import striptags from 'striptags';
import SingleDonationForm from '../forms/SingleDonationForm';
import InstituteBreadcrumbs from '../InstituteBreadcrumbs';
import SingleDonationFriendForm from '../forms/SingleDonationFriendForm';

export type FormPage = Extract<ContentPage, { __typename: 'instituteContentPages_formPage_Entry' }>;

const Form: FC<{ data: FormPage }> = ({ data }) => (
    <Wrapper py={[6, null, 7]}>
        <NextSeo
            title={data.title}
            description={striptags(data.introText)}
            openGraph={{
                images: cmsImageToOpenGraph(data.navThumbnail),
            }}
        />

        <Box mb={[12, null, '112px']}>
            <InstituteBreadcrumbs />
        </Box>
        <Stack maxW={['100%', null, '50%']} mb={[9, null, 13]} spacing={7}>
            <Heading variant={2} as="h1">
                {data.title}
            </Heading>
            <Body>
                <HtmlContent html={data.introText} />
            </Body>
        </Stack>

        {data?.formType === 'docschoolRegistration' && (
            <DocschoolForm
                formConfirmationHeading={data.formConfirmationHeading}
                formConfirmationMessage={data.formConfirmationMessage}
            />
        )}
        {data?.formType === 'recurringDonation' && (
            <RecurringDonationForm
                formConfirmationHeading={data.formConfirmationHeading}
                formConfirmationMessage={data.formConfirmationMessage}
            />
        )}
        {data?.formType === 'singleDonation' && (
            <SingleDonationForm
                formConfirmationHeading={data.formConfirmationHeading}
                formConfirmationMessage={data.formConfirmationMessage}
            />
        )}
        {data?.formType === 'singleDonationFriend' && (
            <SingleDonationFriendForm
                formConfirmationHeading={data.formConfirmationHeading}
                formConfirmationMessage={data.formConfirmationMessage}
            />
        )}
    </Wrapper>
);
export const getStaticProps: GetStaticProps = async ({ params: { uri }, locale, previewData }) => {
    const queryClient = new QueryClient();

    const variables: FormDetailQueryVariables = {
        uri: [...uri].join('/'),
        site: siteHandleByLocale[locale],
    };

    await queryClient.fetchQuery(useFormDetailQuery.getKey(variables), () =>
        fetchCraftData<FormDetailQuery, FormDetailQueryVariables>({
            query: useFormDetailQuery.document,
            variables,
            previewData: previewData as PreviewData,
        })
    );

    return {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
    };
};

export default Form;
