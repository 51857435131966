import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import Heading from '../typography/Heading';
import { css } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';

export interface CardProps extends Omit<BoxProps, 'title'>, PropsWithChildren {
    image?: ImgProps;
    title: ReactNode;
    titleVariant?: ComponentProps<typeof Heading>['variant'];
    variant?: 'primary' | 'neutral' | 'white';
    size?: 'm' | 'l';
}

const Card: React.FC<CardProps> = ({
    variant = 'neutral',
    image,
    title,
    titleVariant = 3,
    children,
    size = 'm',
    ...rest
}) => (
    <Container
        as="article"
        variant={variant}
        sx={{
            '&:hover picture': { transform: 'scale(1.05)' },
            '& picture': { transition: 'transform 1400ms cubic-bezier(0.16, 1, 0.3, 1)' },
        }}
        {...rest}
    >
        {image && (
            <AspectRatio.Root ratio={16 / 9}>
                <Img fill {...image} />
            </AspectRatio.Root>
        )}

        <Flex py={7} px={size === 'm' ? ['6.4%', '10%', null, 8] : ['6.4%', '8%', null, 9]} flex={1}>
            <Stack flex={1} spacing={0}>
                <Heading variant={titleVariant} clamp={3} mb={5} as="h3">
                    {title}
                </Heading>
                {children}
            </Stack>
        </Flex>
    </Container>
);

const Container = styled(Box)<Pick<CardProps, 'variant'>>(
    ({ theme, variant }) => css`
        display: flex;
        flex-direction: column;
        flex: 1;
        text-decoration: none;
        color: ${theme.tokens.SyntaxTextColorDefault};
        ${variant === 'primary' &&
        css`
            background: ${theme.tokens.CardPrimaryBackground};
            color: ${theme.tokens.CardPrimaryForeground};
        `}
        ${variant === 'neutral' &&
        css`
            background: ${theme.tokens.CardNeutralBackground};
            color: ${theme.tokens.CardNeutralForeground};
        `};
        ${variant === 'white' &&
        css`
            background: ${theme.tokens.CardWhiteBackground};
            color: ${theme.tokens.CardWhiteForeground};
            outline: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        `};
    `
);

export default Card;
