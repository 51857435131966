import React, { PropsWithChildren, ReactElement } from 'react';
import { ColorScheme } from '../constants/colorSchemes';

export interface Props extends PropsWithChildren {
    colorScheme: ColorScheme;
}

const ColorSchemeProvider: React.FC<Props> = ({ colorScheme, children, ...rest }) => (
    <>
        {React.Children.map(children, child =>
            React.cloneElement(child as ReactElement, {
                'data-scheme-neutral': colorScheme === 'neutral' ? true : undefined,
                'data-scheme-institute': colorScheme === 'institute' ? true : undefined,
                'data-scheme-professionals':
                    colorScheme === 'professionals' || colorScheme === 'professional' ? true : undefined,
                'data-scheme-festival': colorScheme === 'festival' ? true : undefined,
                'data-scheme-collection': colorScheme === 'collection' ? true : undefined,
                ...rest,
            })
        )}
    </>
);

export default ColorSchemeProvider;
