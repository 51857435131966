import { Checkbox as CheckboxComponent, CheckboxProps } from '@chakra-ui/react';
import IconCheck from '../trinket/icon/IconCheck';
import IconRemove from '../trinket/icon/IconRemove';
import React, { FC } from 'react';

interface Props extends CheckboxProps {
    isIndeterminate?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
    <CheckboxComponent icon={<CheckboxIcon />} {...props} ref={ref} />
));

Checkbox.displayName = 'Checkbox';

const CheckboxIcon: FC<Props> = ({ isIndeterminate, isChecked }) =>
    isIndeterminate ? <IconRemove /> : isChecked ? <IconCheck /> : null;

export default Checkbox;
