import { useTheme } from '@emotion/react';
import { Body, Button, ConfirmationTile, Heading, IconCheck, IconClose, Stack } from 'designsystem';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SingleDonationFriendInput, useSingleDonationFriendMutation } from '../../gql/api';
import DonationFormSection from './DonationFormSection';
import FormErrorMessage from './FormErrorMessage';
import {
    addressFormSection,
    contactFormSection,
    contributionFormSection,
    nameFormSection,
} from '../../utils/donationFormSection';
import { gtm } from 'shared';

interface SingleDonationFriendProps {
    formConfirmationHeading: string;
    formConfirmationMessage: string;
}

const SingleDonationFriendForm: FC<SingleDonationFriendProps> = ({
    formConfirmationHeading,
    formConfirmationMessage,
}) => {
    const { asPath, push, query } = useRouter();
    const theme = useTheme();

    const formMethods = useForm<SingleDonationFriendInput & { serverError?: string }>({
        defaultValues: {
            contributionInEuros: 36,
        },
        mode: 'onBlur',
    });

    const { handleSubmit, clearErrors, setError } = formMethods;

    const { mutate, isLoading, isSuccess } = useSingleDonationFriendMutation({
        onSuccess(data) {
            if (data.initiateSingleDonationFriend.redirectUrl) {
                push(data.initiateSingleDonationFriend.redirectUrl);
            }
        },
    });

    useEffect(() => {
        if (isSuccess) {
            window.scrollTo(0, 0);
        }
    }, [isSuccess]);

    return (
        <>
            {!query.paymentStatus && (
                <FormProvider {...formMethods}>
                    <form
                        onSubmit={handleSubmit((values: SingleDonationFriendInput) => {
                            if (isLoading) {
                                return;
                            }
                            clearErrors('serverError');
                            try {
                                mutate({
                                    input: {
                                        ...values,
                                        successUrl: `${process.env.NEXT_PUBLIC_BASE_URL}${asPath}?paymentStatus=success`,
                                        failureUrl: `${process.env.NEXT_PUBLIC_BASE_URL}${asPath}?paymentStatus=fail`,
                                    },
                                });
                                gtm.event('form_submit', {
                                    form_name: 'word-vriend-eenmalig',
                                    value: values.contributionInEuros,
                                });
                            } catch (err) {
                                setError('serverError', err);
                            }
                        })}
                    >
                        <Stack spacing={[8, null, 10]} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            <DonationFormSection section={nameFormSection} />
                            <DonationFormSection section={addressFormSection} />
                            <DonationFormSection section={contactFormSection} />
                        </Stack>

                        <Stack spacing={10} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            <Heading variant={2}>
                                <FormattedMessage defaultMessage="Bijdrage" />
                            </Heading>
                            <DonationFormSection
                                section={contributionFormSection}
                                contributionOptions={[36, 60, 100]}
                            />
                        </Stack>

                        <Button type="submit" isLoading={isLoading}>
                            <FormattedMessage defaultMessage="Verstuur" />
                        </Button>
                        <FormErrorMessage fieldName="serverError" />
                    </form>
                </FormProvider>
            )}
            {query.paymentStatus === 'success' && (
                <ConfirmationTile
                    id="confirmation"
                    maxWidth={['100%', null, '50%']}
                    icon={<IconCheck color={theme.tokens.ColorRed60} />}
                >
                    <Heading variant={4}>{formConfirmationHeading}</Heading>
                    <Body>{formConfirmationMessage}</Body>
                </ConfirmationTile>
            )}
            {query.paymentStatus === 'fail' && (
                <ConfirmationTile
                    id="confirmation"
                    maxWidth={['100%', null, '50%']}
                    icon={<IconClose color={theme.tokens.ColorRed60} />}
                >
                    <Heading variant={4}>
                        <FormattedMessage defaultMessage="Betaling mislukt" />
                    </Heading>
                    <Body>
                        <FormattedMessage defaultMessage="Er is iets misgegaan bij het verwerken van de betaling" />
                    </Body>
                </ConfirmationTile>
            )}
        </>
    );
};

export default SingleDonationFriendForm;
