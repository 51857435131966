import {
    Box,
    Flex,
    Grid,
    Heading,
    HtmlContent,
    Intro,
    ListDivider,
    MetaProps,
    NewsCard,
    NewsListItem,
    Pagination,
    SkeletonListItem,
    VStack,
    Wrapper,
} from 'designsystem';
import { NextSeo } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import Link from 'next/link';
import { ReactNode } from 'react';
import striptags from 'striptags';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import { useGetCmsImageProps } from '../..';
import { CmsImage } from '../../hooks/useGetCmsImageProps';
import { MetaArticle } from '../../lib/getMeta';

const ITEMS_PER_PAGE = 10;

interface Article extends MetaArticle {
    id?: string;
    href?: string;
    title?: string;
    headerImage?: CmsImage[];
    meta?: Omit<MetaProps, 'size'>;
}

interface Props {
    pageTitle: string;
    pageIntro?: string;
    pageOpenGraphImages?: OpenGraphMedia[];
    loading: boolean;
    featuredArticles?: Article[];
    articles?: Article[];
    articleCount?: number;
    breadcrumbs: ReactNode;
}

const ArticlesOverviewTemplate: React.FC<Props> = ({
    pageTitle,
    pageIntro,
    pageOpenGraphImages,
    loading,
    featuredArticles,
    articles,
    articleCount,
    breadcrumbs,
}) => {
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
    const handlePageChange = (pageIndex: number) => setPage(pageIndex + 1);
    const getImgProps = useGetCmsImageProps();
    return (
        <Wrapper py={[6, null, 7]}>
            <NextSeo
                title={pageTitle}
                description={striptags(pageIntro)}
                openGraph={{
                    images: pageOpenGraphImages,
                }}
            />
            <Grid>
                <Box gridColumn={['1 / -1', null, null, null, '2 / -2']} mb={[5, null, 9]}>
                    <Box mb={[12, null, '112px']}>{breadcrumbs}</Box>
                    <VStack spacing={[4, null, 7]} alignItems="flex-start" maxWidth={[null, null, '580px']}>
                        <Heading variant={1} as="h1">
                            {pageTitle}
                        </Heading>
                        <HtmlContent
                            html={pageIntro}
                            override={{
                                p: ({ children }) => <Intro>{children}</Intro>,
                            }}
                        />
                    </VStack>
                </Box>
                {featuredArticles && (
                    <Grid gridColumn="1/ -1" mb={[5, null, 13]}>
                        <Flex
                            flexDir={['column', null, 'row']}
                            alignItems={['stretch', null, 'flex-start']}
                            gridColumn="1 / -1"
                            mx={['-5.55%', null, 0]}
                        >
                            {featuredArticles[0] && (
                                <Box zIndex={1} flexGrow="1" w="100%">
                                    <Link href={featuredArticles[0].href || ''}>
                                        <NewsCard
                                            variant="primary"
                                            image={getImgProps(featuredArticles[0].headerImage[0])}
                                            title={featuredArticles[0].title}
                                            meta={featuredArticles[0].meta}
                                        />
                                    </Link>
                                </Box>
                            )}
                            {featuredArticles[1] && (
                                <Box mt={[0, null, 14]} flexGrow="1" w="100%">
                                    <Link href={featuredArticles[1].href || ''}>
                                        <NewsCard
                                            variant="white"
                                            image={getImgProps(featuredArticles[1].headerImage[0])}
                                            title={featuredArticles[1].title}
                                            meta={featuredArticles[1].meta}
                                        />
                                    </Link>
                                </Box>
                            )}
                        </Flex>
                    </Grid>
                )}
                <Box gridColumn={['1 / -1', null, null, null, '2 / -2']}>
                    <VStack spacing={[6, null, 9]} divider={<ListDivider />}>
                        {!loading &&
                            articles.map(article => (
                                <Box key={article.id} width="100%">
                                    <Link href={article.href}>
                                        <NewsListItem
                                            image={getImgProps(article.headerImage[0])}
                                            title={article.title}
                                            meta={article.meta}
                                        />
                                    </Link>
                                </Box>
                            ))}
                        {loading && (
                            <Box width="100%">
                                <SkeletonListItem />
                            </Box>
                        )}
                    </VStack>
                    {articleCount > ITEMS_PER_PAGE && (
                        <Flex justifyContent="center" mt={[6, null, 9]}>
                            <Pagination
                                currentPage={page - 1}
                                setCurrentPage={handlePageChange}
                                totalPages={Math.ceil(articleCount / ITEMS_PER_PAGE)}
                            />
                        </Flex>
                    )}
                </Box>
            </Grid>
        </Wrapper>
    );
};

export default ArticlesOverviewTemplate;
