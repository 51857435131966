import { useDisclosure } from 'designsystem';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { getATOrderHistoryUrl } from '../../lib/getActiveTicketsUrl';
import isLoggedIn from '../../lib/isLoggedIn';
import TicketModal from '../modals/TicketModal';
import { FormattedMessage } from 'react-intl';
import ATIFrame from '../activetickets/ATIFrame';
import useSignIn from '../../hooks/useSignIn';

export interface OrderHistoryContextValues {
    onOpenOrderHistory: () => void;
}

const OrderHistoryContext = createContext<OrderHistoryContextValues>({
    onOpenOrderHistory: () => {},
});

export const OrderHistoryProvider: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
    const { locale } = useRouter();
    const { status } = useSession();
    const loggedIn = useMemo(() => isLoggedIn(status), [status]);

    const orderHistoryDisclosureProps = useDisclosure();
    const { onOpen: onOrderHistoryOpen } = orderHistoryDisclosureProps;
    const { signIn } = useSignIn();
    const onOpenOrderHistory: OrderHistoryContextValues['onOpenOrderHistory'] = useCallback(() => {
        if (loggedIn) {
            onOrderHistoryOpen();
        } else {
            signIn();
        }
    }, [loggedIn, onOrderHistoryOpen, signIn]);

    const values: OrderHistoryContextValues = useMemo(
        () => ({
            onOpenOrderHistory,
        }),
        [onOpenOrderHistory]
    );

    return (
        <OrderHistoryContext.Provider value={values} {...rest}>
            {children}
            <TicketModal
                title={<FormattedMessage defaultMessage="Bestelhistorie" id="orderHistory" />}
                {...orderHistoryDisclosureProps}
            >
                <ATIFrame
                    src={getATOrderHistoryUrl({ locale })}
                    w="100%"
                    h="100%"
                    onContinue={orderHistoryDisclosureProps.onClose}
                />
            </TicketModal>
        </OrderHistoryContext.Provider>
    );
};

export const useOrderHistory = () => useContext(OrderHistoryContext);

export default OrderHistoryProvider;
