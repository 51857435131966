/* eslint-disable */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useApi } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum ArchiveFilterEnum {
    ArchiveType = 'ARCHIVE_TYPE',
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    EditionYear = 'EDITION_YEAR',
    IdfaAwards = 'IDFA_AWARDS',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    Nationality = 'NATIONALITY',
    Premiere = 'PREMIERE',
    Professions = 'PROFESSIONS',
    ReportType = 'REPORT_TYPE',
    Section = 'SECTION',
    Tags = 'TAGS',
    TvPrice = 'TV_PRICE',
}

export type ArchiveFilterInput = {
    key?: InputMaybe<ArchiveFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum DocschoolFilterEnum {
    CountriesOfProduction = 'COUNTRIES_OF_PRODUCTION',
    EducationLevel = 'EDUCATION_LEVEL',
    FullyDutchSpoken = 'FULLY_DUTCH_SPOKEN',
    LengthInMinutes = 'LENGTH_IN_MINUTES',
    Theme = 'THEME',
    YearOfProduction = 'YEAR_OF_PRODUCTION',
}

export type DocschoolFilterInput = {
    key?: InputMaybe<DocschoolFilterEnum>;
    value: Array<Scalars['String']>;
};

export enum EducationLevelEnum {
    HavoVwo = 'HAVO_VWO',
    HboWoPabo = 'HBO_WO_PABO',
    Mbo = 'MBO',
    PrimaryEducation = 'PRIMARY_EDUCATION',
    SpecialPrimaryEducation = 'SPECIAL_PRIMARY_EDUCATION',
    SpecialSecondaryEducation = 'SPECIAL_SECONDARY_EDUCATION',
    Vmbo = 'VMBO',
}

export enum FormErrorEnum {
    FindockApiError = 'FINDOCK_API_ERROR',
    FindockInvalidIban = 'FINDOCK_INVALID_IBAN',
    FindockInvalidInputData = 'FINDOCK_INVALID_INPUT_DATA',
    SubmissionFailed = 'SUBMISSION_FAILED',
    ValidationFailed = 'VALIDATION_FAILED',
}

export enum ImageFormatEnum {
    Jpeg = 'JPEG',
    Webp = 'WEBP',
}

export enum InstituteFilterEnum {
    Docschool = 'DOCSCHOOL',
    Information = 'INFORMATION',
    News = 'NEWS',
}

export enum TicketAvailabilityStatusEnum {
    HighAvailability = 'HIGH_AVAILABILITY',
    LowAvailability = 'LOW_AVAILABILITY',
    SoldOut = 'SOLD_OUT',
}

export type DocschoolApplicationInput = {
    city: Scalars['String'];
    educationLevel: EducationLevelEnum;
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    schoolName: Scalars['String'];
    subject?: InputMaybe<Scalars['String']>;
};

export type InstituteFilmViewInput = {
    filmId: Scalars['String'];
};

export type RecurringDonationInput = {
    bankAccountOwner: Scalars['String'];
    city: Scalars['String'];
    contributionInEuros: Scalars['Int'];
    country: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    houseNumber: Scalars['String'];
    iban: Scalars['String'];
    lastName: Scalars['String'];
    lastNamePrefix?: InputMaybe<Scalars['String']>;
    phone: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type SingleDonationFriendInput = {
    city: Scalars['String'];
    contributionInEuros: Scalars['Int'];
    country: Scalars['String'];
    email: Scalars['String'];
    failureUrl: Scalars['String'];
    firstName: Scalars['String'];
    houseNumber: Scalars['String'];
    lastName: Scalars['String'];
    lastNamePrefix?: InputMaybe<Scalars['String']>;
    phone: Scalars['String'];
    street: Scalars['String'];
    successUrl: Scalars['String'];
    zipCode: Scalars['String'];
};

export type SingleDonationInput = {
    contributionInEuros: Scalars['Int'];
    email: Scalars['String'];
    failureUrl: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    lastNamePrefix?: InputMaybe<Scalars['String']>;
    successUrl: Scalars['String'];
};

export type CinemaItemCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CinemaItemCardQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        yearOfProduction?: number | null;
        fullPreferredTitle?: string | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
    } | null;
    composition?: {
        __typename?: 'Composition';
        id: string;
        fullTitle?: string | null;
        films?: Array<{
            __typename?: 'Film';
            yearOfProduction?: number | null;
            fullPreferredTitle?: string | null;
            credits?: Array<{
                __typename?: 'Credit';
                fullName?: string | null;
                role?: { __typename?: 'LookupValue'; key?: string | null } | null;
            }> | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
            stills?: Array<{
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            }> | null;
        } | null;
    } | null;
};

export type DocschoolFilmCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DocschoolFilmCardQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        yearOfProduction?: number | null;
        fullPreferredTitle?: string | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
};

export type FestivalItemCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type FestivalItemCardQuery = {
    __typename?: 'InstituteQuery';
    currentFestivalPart?:
        | {
              __typename: 'Composition';
              id: string;
              fullTitle?: string | null;
              description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
              publications?: {
                  __typename?: 'PublicationCollection';
                  favoriteImage?: {
                      __typename?: 'Image';
                      blurHash?: string | null;
                      webp?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                      jpeg?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                  } | null;
                  stills?: Array<{
                      __typename?: 'Image';
                      blurHash?: string | null;
                      webp?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                      jpeg?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                  }> | null;
              } | null;
          }
        | {
              __typename: 'Film';
              id: string;
              yearOfProduction?: number | null;
              fullPreferredTitle?: string | null;
              credits?: Array<{
                  __typename?: 'Credit';
                  fullName?: string | null;
                  role?: { __typename?: 'LookupValue'; key?: string | null } | null;
              }> | null;
              publications?: {
                  __typename?: 'PublicationCollection';
                  favoriteImage?: {
                      __typename?: 'Image';
                      blurHash?: string | null;
                      webp?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                      jpeg?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                  } | null;
              } | null;
          }
        | null;
};

export type FilmCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type FilmCardQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        yearOfProduction?: number | null;
        fullPreferredTitle?: string | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null } | null;
        }> | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
};

export type ArchiveFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type BerthaFundFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    fundingAmount?: string | null;
    lengthInMinutes?: number | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultCreditFragment = {
    __typename?: 'Credit';
    fullName?: string | null;
    email?: string | null;
    role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
};

export type DocLabFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    lengthInMinutes?: number | null;
    yearOfProduction?: number | null;
    description?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        experiences?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        docLabVideo?: { __typename?: 'Video'; id: string; title?: string | null; value: string } | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    newMediaType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocsForSaleFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    docsForSaleSeries?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    docsForSaleSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    viewingReport?: { __typename?: 'ViewingReport'; pdf: string; csv: string } | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DocschoolFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    educationLevel?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        teacherMaterial?: Array<{ __typename?: 'Document'; title?: string | null; url: string }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    kijkwijzer?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultEditionFragment = {
    __typename?: 'Edition';
    id: string;
    name: string;
    year: number;
    editionType: { __typename?: 'EditionType'; id: string; description: string };
};

export type FestivalFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    isAlternativeContent?: boolean | null;
    isDocLab?: boolean | null;
    logline?: string | null;
    yearOfProduction?: number | null;
    lengthInMinutes?: number | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    sections?: Array<{
        __typename?: 'EditionSection';
        id: string;
        name?: string | null;
        description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        items?: Array<
            | {
                  __typename: 'Composition';
                  id: string;
                  fullTitle?: string | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  isDocLab?: boolean | null;
                  yearOfProduction?: number | null;
                  lengthInMinutes?: number | null;
                  id: string;
                  fullPreferredTitle?: string | null;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  credits?: Array<{
                      __typename?: 'Credit';
                      fullName?: string | null;
                      role?: { __typename?: 'LookupValue'; translation?: string | null; key?: string | null } | null;
                  }> | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
        > | null;
    }> | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
    } | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        person?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    colour?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    format?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    shows?: Array<{
        __typename?: 'Show';
        fullTitle?: string | null;
        startOn?: string | null;
        endOn?: string | null;
        isOngoingProgram?: boolean | null;
        isVideoOnDemand?: boolean | null;
        location?: string | null;
        ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
        externalSaleLink?: string | null;
        id: string;
        noSale?: boolean | null;
        intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        composition?: { __typename?: 'Composition'; fullTitle?: string | null } | null;
        introPaper?: { __typename?: 'LookupValue'; translation?: string | null } | null;
        audience?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
        accessibility?: Array<{ __typename?: 'CustomFieldOption'; translation?: string | null }> | null;
    }> | null;
};

export type FilmCardFilmFragment = {
    __typename?: 'Film';
    id: string;
    yearOfProduction?: number | null;
    fullPreferredTitle?: string | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type FilmHitFragment = {
    __typename: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    sortedTitle?: string | null;
    logline?: string | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type ForumFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    logline?: string | null;
    startProduction?: string | null;
    monthOfProduction?: number | null;
    yearOfProduction?: number | null;
    startRelease?: string | null;
    lengths?: string | null;
    totalBudget?: number | null;
    financingInPlace?: number | null;
    requestedFinancing?: number | null;
    interestedInFollowingProducers?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    productionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        forumPerson?: {
            __typename?: 'Person';
            id: string;
            fullName?: string | null;
            publications?: {
                __typename?: 'PublicationCollection';
                favoriteImage?: {
                    __typename?: 'Image';
                    blurHash?: string | null;
                    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                } | null;
            } | null;
            mainAccreditation?: {
                __typename?: 'Accreditation';
                publications?: {
                    __typename?: 'PublicationCollection';
                    favoriteImage?: {
                        __typename?: 'Image';
                        blurHash?: string | null;
                        webp?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                        jpeg?: Array<{
                            __typename?: 'ImageTransform';
                            url: string;
                            width: number;
                            height: number;
                        }> | null;
                    } | null;
                } | null;
            } | null;
        } | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    forumSynopsisEntry?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumProjectDescriptionEntry?: {
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    } | null;
    standAloneVersions?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    typeOfNewMediaProject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    financiers?: Array<{ __typename?: 'Financier'; contribution: number; id: string; name: string }> | null;
    forumOpenTalent?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumCoprodPotential?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    forumBiography?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    contactPerson?: { __typename?: 'ContactPerson'; email?: string | null; fullName?: string | null } | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
};

export type DefaultApiImageFragment = {
    __typename?: 'Image';
    blurHash?: string | null;
    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
};

export type PageHitFragment = {
    __typename: 'Page';
    id: string;
    title: string;
    pageType: string;
    introText?: string | null;
    url: string;
    breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    thumbnail?: {
        __typename?: 'Image';
        blurHash?: string | null;
        webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    } | null;
};

export type ProjectSpaceFilmDetailsFragment = {
    __typename?: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    translatedTitle?: string | null;
    lengthInMinutes?: number | null;
    lengths?: string | null;
    yearOfProduction?: number | null;
    logline?: string | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    otherEditions?: Array<{
        __typename?: 'Film';
        id: string;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
    }> | null;
    credits?: Array<{
        __typename?: 'Credit';
        fullName?: string | null;
        email?: string | null;
        role?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    }> | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            id?: string | null;
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
        trailers?: Array<{ __typename?: 'Video'; id: string; title?: string | null; value: string }> | null;
        films?: Array<{ __typename?: 'Video'; id: string; value: string; title?: string | null }> | null;
        links?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    } | null;
    countriesOfProduction?: Array<{
        __typename?: 'LookupValue';
        key?: string | null;
        translation?: string | null;
    }> | null;
    countriesOfCoproduction?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    requestType?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    premiere?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    awards?: Array<{ __typename?: 'CustomFieldOption'; sortOrder?: number | null; translation?: string | null }> | null;
    sections?: Array<{ __typename?: 'EditionSection'; id: string; name?: string | null }> | null;
    subject?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    genre?: { __typename?: 'LookupValue'; key?: string | null; translation?: string | null } | null;
    spokenLanguages?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    tags?: Array<{ __typename?: 'LookupValue'; key?: string | null; translation?: string | null }> | null;
    projectSpaceProductionStatus?: Array<{
        __typename?: 'CustomFieldOption';
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
};

export type UserProfileFragment = {
    __typename?: 'MyIdfaUser';
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    roles?: Array<string> | null;
    person?: {
        __typename?: 'Person';
        mainAccreditation?: {
            __typename?: 'Accreditation';
            badges?: Array<{ __typename?: 'Badge'; id: string; guestbookBadge?: string | null } | null> | null;
        } | null;
    } | null;
    favoriteFilms?: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }> | null;
    calendar: {
        __typename: 'Calendar';
        iCalUrl?: string | null;
        items: Array<
            | { __typename?: 'Appointment' }
            | { __typename: 'Show'; id: string }
            | { __typename?: 'Ticket' }
            | { __typename?: 'VolunteerShift' }
        >;
    };
};

type UserProfileCalendarItem_Appointment_Fragment = { __typename?: 'Appointment' };

type UserProfileCalendarItem_Show_Fragment = { __typename: 'Show'; id: string };

type UserProfileCalendarItem_Ticket_Fragment = { __typename?: 'Ticket' };

type UserProfileCalendarItem_VolunteerShift_Fragment = { __typename?: 'VolunteerShift' };

export type UserProfileCalendarItemFragment =
    | UserProfileCalendarItem_Appointment_Fragment
    | UserProfileCalendarItem_Show_Fragment
    | UserProfileCalendarItem_Ticket_Fragment
    | UserProfileCalendarItem_VolunteerShift_Fragment;

export type ProfessionalsFilmCardQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ProfessionalsFilmCardQuery = {
    __typename?: 'InstituteQuery';
    film?: {
        __typename?: 'Film';
        id: string;
        yearOfProduction?: number | null;
        fullPreferredTitle?: string | null;
        credits?: Array<{
            __typename?: 'Credit';
            fullName?: string | null;
            role?: { __typename?: 'LookupValue'; key?: string | null } | null;
        }> | null;
        edition?: {
            __typename?: 'Edition';
            id: string;
            name: string;
            year: number;
            editionType: { __typename?: 'EditionType'; id: string; description: string };
        } | null;
        publications?: {
            __typename?: 'PublicationCollection';
            favoriteImage?: {
                __typename?: 'Image';
                blurHash?: string | null;
                webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
                jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            } | null;
        } | null;
    } | null;
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = {
    __typename?: 'InstituteQuery';
    viewer?: {
        __typename?: 'MyIdfaUser';
        firstName?: string | null;
        lastName?: string | null;
        email: string;
        roles?: Array<string> | null;
        person?: {
            __typename?: 'Person';
            mainAccreditation?: {
                __typename?: 'Accreditation';
                badges?: Array<{ __typename?: 'Badge'; id: string; guestbookBadge?: string | null } | null> | null;
            } | null;
        } | null;
        favoriteFilms?: Array<{ __typename?: 'Film'; id: string; fullPreferredTitle?: string | null }> | null;
        calendar: {
            __typename: 'Calendar';
            iCalUrl?: string | null;
            items: Array<
                | { __typename?: 'Appointment' }
                | { __typename: 'Show'; id: string }
                | { __typename?: 'Ticket' }
                | { __typename?: 'VolunteerShift' }
            >;
        };
    } | null;
};

export type TicketStatusQueryVariables = Exact<{
    fionaId: Scalars['ID'];
}>;

export type TicketStatusQuery = {
    __typename?: 'InstituteQuery';
    ticketAvailabilityStatus?: TicketAvailabilityStatusEnum | null;
};

export type UserActiveTicketsKeyQueryVariables = Exact<{ [key: string]: never }>;

export type UserActiveTicketsKeyQuery = {
    __typename?: 'InstituteQuery';
    viewer?: { __typename?: 'MyIdfaUser'; activeTicketsLoginKey?: string | null } | null;
};

export const DefaultEditionFragmentDoc = `
    fragment DefaultEdition on Edition {
  id
  name
  year
  editionType {
    id
    description
  }
}
    `;
export const DefaultApiImageFragmentDoc = `
    fragment DefaultApiImage on Image {
  webp: transforms(format: WEBP) {
    url
    width
    height
  }
  jpeg: transforms(format: JPEG) {
    url
    width
    height
  }
  blurHash
}
    `;
export const DefaultCreditFragmentDoc = `
    fragment DefaultCredit on Credit {
  fullName
  role {
    key
    translation
  }
  email
}
    `;
export const ArchiveFilmDetailsFragmentDoc = `
    fragment ArchiveFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const BerthaFundFilmDetailsFragmentDoc = `
    fragment BerthaFundFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    translation
  }
  fundingAmount
  requestType {
    sortOrder
    translation
  }
  lengthInMinutes
  logline
  yearOfProduction
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocLabFilmDetailsFragmentDoc = `
    fragment DocLabFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  description {
    key
    translation
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    experiences {
      id
      title
      value
    }
    docLabVideo {
      id
      title
      value
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  yearOfProduction
  sections {
    id
    name
  }
  newMediaType {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
  }
  subject {
    sortOrder
    translation
  }
  tags {
    key
    translation
  }
}
    `;
export const DocsForSaleFilmDetailsFragmentDoc = `
    fragment DocsForSaleFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  lengthInMinutes
  lengths
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  productionStatus {
    sortOrder
    translation
  }
  subject {
    sortOrder
    translation
  }
  logline
  docsForSaleSeries
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  docsForSaleSynopsisEntry {
    key
    translation
  }
  viewingReport {
    pdf
    csv
  }
  tags {
    key
    translation
  }
}
    `;
export const DocschoolFilmDetailsFragmentDoc = `
    fragment DocschoolFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  educationLevel {
    translation(language: $language)
  }
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  isAlternativeContent
  docschoolOneliner {
    key
    translation(language: $language)
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
    teacherMaterial {
      title
      url
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  yearOfProduction
  lengthInMinutes
  description {
    translation
  }
  kijkwijzer {
    sortOrder
    translation(language: $language)
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  tags {
    key
    translation
  }
}
    `;
export const FilmHitFragmentDoc = `
    fragment FilmHit on Film {
  __typename
  id
  fullPreferredTitle
  sortedTitle
  docschoolOneliner {
    translation
  }
  logline
  description {
    translation
  }
  intro {
    translation
  }
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const FestivalFilmDetailsFragmentDoc = `
    fragment FestivalFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  premiere {
    key
    translation
  }
  sections {
    id
    name
  }
  edition {
    ...DefaultEdition
  }
  isAlternativeContent
  isDocLab
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
  }
  credits {
    ...DefaultCredit
    person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  awards {
    sortOrder
    translation
  }
  countriesOfProduction {
    key
    translation(language: $language)
  }
  logline
  yearOfProduction
  lengthInMinutes
  colour {
    key
    translation
  }
  format {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  description {
    translation
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  sections {
    id
    name
    description {
      translation
    }
    items {
      ... on Composition {
        __typename
        id
        fullTitle
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
      ... on Film {
        __typename
        isDocLab
        ...FilmHit
        credits {
          fullName
          role {
            translation
            key
          }
        }
        yearOfProduction
        lengthInMinutes
      }
    }
  }
  subject {
    sortOrder
    translation(language: $language)
  }
  shows {
    fullTitle
    startOn
    endOn
    isOngoingProgram
    isVideoOnDemand
    intro {
      translation
    }
    location
    ticketAvailabilityStatus
    externalSaleLink
    id
    composition {
      fullTitle
    }
    introPaper {
      translation
    }
    audience {
      key
      translation
    }
    accessibility {
      translation
    }
    noSale
  }
}
    `;
export const FilmCardFilmFragmentDoc = `
    fragment FilmCardFilm on Film {
  id
  credits {
    fullName
    role {
      key
    }
  }
  yearOfProduction
  fullPreferredTitle
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const ForumFilmDetailsFragmentDoc = `
    fragment ForumFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    links {
      title
      value
    }
    films {
      id
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  requestType {
    sortOrder
    translation
  }
  productionStatus {
    sortOrder
    translation
  }
  credits {
    ...DefaultCredit
    forumPerson: person {
      id
      fullName
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
      mainAccreditation {
        publications {
          favoriteImage {
            ...DefaultApiImage
          }
        }
      }
    }
  }
  logline
  countriesOfCoproduction {
    sortOrder
    translation
  }
  forumSynopsisEntry {
    key
    translation
  }
  forumProjectDescriptionEntry {
    key
    translation
  }
  startProduction
  monthOfProduction
  yearOfProduction
  startRelease
  lengths
  standAloneVersions {
    sortOrder
    translation
  }
  typeOfNewMediaProject {
    sortOrder
    translation
  }
  totalBudget
  financingInPlace
  requestedFinancing
  financiers {
    contribution
    id
    name
  }
  forumOpenTalent {
    key
    translation
  }
  forumCoprodPotential {
    key
    translation
  }
  interestedInFollowingProducers
  forumBiography {
    key
    translation
  }
  contactPerson {
    email
    fullName
  }
  subject {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  tags {
    key
    translation
  }
}
    `;
export const PageHitFragmentDoc = `
    fragment PageHit on Page {
  id
  __typename
  title
  pageType
  introText
  url
  breadcrumbs {
    title
    value
  }
  thumbnail {
    ...DefaultApiImage
  }
}
    `;
export const ProjectSpaceFilmDetailsFragmentDoc = `
    fragment ProjectSpaceFilmDetails on Film {
  id
  fullPreferredTitle
  translatedTitle
  edition {
    ...DefaultEdition
  }
  otherEditions {
    id
    edition {
      ...DefaultEdition
    }
  }
  credits {
    ...DefaultCredit
  }
  publications {
    favoriteImage {
      id
      ...DefaultApiImage
    }
    stills {
      id
      ...DefaultApiImage
    }
    trailers {
      id
      title
      value
    }
    films {
      id
      value
      title
    }
    links {
      title
      value
    }
  }
  countriesOfProduction {
    key
    translation
  }
  countriesOfCoproduction {
    sortOrder
    translation
  }
  lengthInMinutes
  lengths
  requestType {
    sortOrder
    translation
  }
  yearOfProduction
  premiere {
    key
    translation
  }
  awards {
    sortOrder
    translation
  }
  sections {
    id
    name
  }
  subject {
    sortOrder
    translation
  }
  logline
  genre {
    key
    translation
  }
  spokenLanguages {
    key
    translation
  }
  tags {
    key
    translation
  }
  projectSpaceProductionStatus {
    sortOrder
    translation
  }
}
    `;
export const UserProfileCalendarItemFragmentDoc = `
    fragment UserProfileCalendarItem on CalendarItem {
  ... on Show {
    __typename
    id
  }
}
    `;
export const UserProfileFragmentDoc = `
    fragment UserProfile on MyIdfaUser {
  firstName
  lastName
  email
  roles
  person {
    mainAccreditation {
      badges {
        id
        guestbookBadge
      }
    }
  }
  favoriteFilms {
    id
    fullPreferredTitle
  }
  calendar {
    ... on Calendar {
      __typename
      iCalUrl
      items {
        ... on CalendarItem {
          ...UserProfileCalendarItem
        }
      }
    }
  }
}
    `;
export const CinemaItemCardDocument = `
    query cinemaItemCard($id: ID!) {
  film: cinemaFilm(id: $id) {
    ... on Film {
      id
      credits {
        fullName
        role {
          key
        }
      }
      yearOfProduction
      fullPreferredTitle
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
        stills {
          ...DefaultApiImage
        }
      }
    }
  }
  composition: cinemaComposition(id: $id) {
    ... on Composition {
      id
      fullTitle
      films {
        credits {
          fullName
          role {
            key
          }
        }
        yearOfProduction
        fullPreferredTitle
      }
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
        stills {
          ...DefaultApiImage
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useCinemaItemCardQuery = <TData = CinemaItemCardQuery, TError = unknown>(
    variables: CinemaItemCardQueryVariables,
    options?: UseQueryOptions<CinemaItemCardQuery, TError, TData>
) =>
    useQuery<CinemaItemCardQuery, TError, TData>(
        ['cinemaItemCard', variables],
        useApi<CinemaItemCardQuery, CinemaItemCardQueryVariables>(CinemaItemCardDocument).bind(null, variables),
        options
    );
useCinemaItemCardQuery.document = CinemaItemCardDocument;

useCinemaItemCardQuery.getKey = (variables: CinemaItemCardQueryVariables) => ['cinemaItemCard', variables];
export const DocschoolFilmCardDocument = `
    query docschoolFilmCard($id: ID!) {
  film(id: $id) {
    ... on Film {
      ...FilmCardFilm
      edition {
        id
        name
        year
        editionType {
          id
          description
        }
      }
    }
  }
}
    ${FilmCardFilmFragmentDoc}
${DefaultApiImageFragmentDoc}`;
export const useDocschoolFilmCardQuery = <TData = DocschoolFilmCardQuery, TError = unknown>(
    variables: DocschoolFilmCardQueryVariables,
    options?: UseQueryOptions<DocschoolFilmCardQuery, TError, TData>
) =>
    useQuery<DocschoolFilmCardQuery, TError, TData>(
        ['docschoolFilmCard', variables],
        useApi<DocschoolFilmCardQuery, DocschoolFilmCardQueryVariables>(DocschoolFilmCardDocument).bind(
            null,
            variables
        ),
        options
    );
useDocschoolFilmCardQuery.document = DocschoolFilmCardDocument;

useDocschoolFilmCardQuery.getKey = (variables: DocschoolFilmCardQueryVariables) => ['docschoolFilmCard', variables];
export const FestivalItemCardDocument = `
    query FestivalItemCard($id: ID!) {
  currentFestivalPart(id: $id) {
    __typename
    ... on Film {
      id
      credits {
        fullName
        role {
          key
        }
      }
      yearOfProduction
      fullPreferredTitle
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
    }
    ... on Composition {
      id
      fullTitle
      description {
        translation
      }
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
        stills {
          ...DefaultApiImage
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useFestivalItemCardQuery = <TData = FestivalItemCardQuery, TError = unknown>(
    variables: FestivalItemCardQueryVariables,
    options?: UseQueryOptions<FestivalItemCardQuery, TError, TData>
) =>
    useQuery<FestivalItemCardQuery, TError, TData>(
        ['FestivalItemCard', variables],
        useApi<FestivalItemCardQuery, FestivalItemCardQueryVariables>(FestivalItemCardDocument).bind(null, variables),
        options
    );
useFestivalItemCardQuery.document = FestivalItemCardDocument;

useFestivalItemCardQuery.getKey = (variables: FestivalItemCardQueryVariables) => ['FestivalItemCard', variables];
export const FilmCardDocument = `
    query filmCard($id: ID!) {
  film: archiveFilm(id: $id) {
    ... on Film {
      id
      credits {
        fullName
        role {
          key
        }
      }
      yearOfProduction
      fullPreferredTitle
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useFilmCardQuery = <TData = FilmCardQuery, TError = unknown>(
    variables: FilmCardQueryVariables,
    options?: UseQueryOptions<FilmCardQuery, TError, TData>
) =>
    useQuery<FilmCardQuery, TError, TData>(
        ['filmCard', variables],
        useApi<FilmCardQuery, FilmCardQueryVariables>(FilmCardDocument).bind(null, variables),
        options
    );
useFilmCardQuery.document = FilmCardDocument;

useFilmCardQuery.getKey = (variables: FilmCardQueryVariables) => ['filmCard', variables];
export const ProfessionalsFilmCardDocument = `
    query ProfessionalsFilmCard($id: ID!) {
  film(id: $id) {
    ... on Film {
      id
      credits {
        fullName
        role {
          key
        }
      }
      edition {
        id
        name
        year
        editionType {
          id
          description
        }
      }
      yearOfProduction
      fullPreferredTitle
      publications {
        favoriteImage {
          ...DefaultApiImage
        }
      }
    }
  }
}
    ${DefaultApiImageFragmentDoc}`;
export const useProfessionalsFilmCardQuery = <TData = ProfessionalsFilmCardQuery, TError = unknown>(
    variables: ProfessionalsFilmCardQueryVariables,
    options?: UseQueryOptions<ProfessionalsFilmCardQuery, TError, TData>
) =>
    useQuery<ProfessionalsFilmCardQuery, TError, TData>(
        ['ProfessionalsFilmCard', variables],
        useApi<ProfessionalsFilmCardQuery, ProfessionalsFilmCardQueryVariables>(ProfessionalsFilmCardDocument).bind(
            null,
            variables
        ),
        options
    );
useProfessionalsFilmCardQuery.document = ProfessionalsFilmCardDocument;

useProfessionalsFilmCardQuery.getKey = (variables: ProfessionalsFilmCardQueryVariables) => [
    'ProfessionalsFilmCard',
    variables,
];
export const ProfileDocument = `
    query Profile {
  viewer {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}
${UserProfileCalendarItemFragmentDoc}`;
export const useProfileQuery = <TData = ProfileQuery, TError = unknown>(
    variables?: ProfileQueryVariables,
    options?: UseQueryOptions<ProfileQuery, TError, TData>
) =>
    useQuery<ProfileQuery, TError, TData>(
        variables === undefined ? ['Profile'] : ['Profile', variables],
        useApi<ProfileQuery, ProfileQueryVariables>(ProfileDocument).bind(null, variables),
        options
    );
useProfileQuery.document = ProfileDocument;

useProfileQuery.getKey = (variables?: ProfileQueryVariables) =>
    variables === undefined ? ['Profile'] : ['Profile', variables];
export const TicketStatusDocument = `
    query ticketStatus($fionaId: ID!) {
  ticketAvailabilityStatus(fionaId: $fionaId)
}
    `;
export const useTicketStatusQuery = <TData = TicketStatusQuery, TError = unknown>(
    variables: TicketStatusQueryVariables,
    options?: UseQueryOptions<TicketStatusQuery, TError, TData>
) =>
    useQuery<TicketStatusQuery, TError, TData>(
        ['ticketStatus', variables],
        useApi<TicketStatusQuery, TicketStatusQueryVariables>(TicketStatusDocument).bind(null, variables),
        options
    );
useTicketStatusQuery.document = TicketStatusDocument;

useTicketStatusQuery.getKey = (variables: TicketStatusQueryVariables) => ['ticketStatus', variables];
export const UserActiveTicketsKeyDocument = `
    query UserActiveTicketsKey {
  viewer {
    activeTicketsLoginKey
  }
}
    `;
export const useUserActiveTicketsKeyQuery = <TData = UserActiveTicketsKeyQuery, TError = unknown>(
    variables?: UserActiveTicketsKeyQueryVariables,
    options?: UseQueryOptions<UserActiveTicketsKeyQuery, TError, TData>
) =>
    useQuery<UserActiveTicketsKeyQuery, TError, TData>(
        variables === undefined ? ['UserActiveTicketsKey'] : ['UserActiveTicketsKey', variables],
        useApi<UserActiveTicketsKeyQuery, UserActiveTicketsKeyQueryVariables>(UserActiveTicketsKeyDocument).bind(
            null,
            variables
        ),
        options
    );
useUserActiveTicketsKeyQuery.document = UserActiveTicketsKeyDocument;

useUserActiveTicketsKeyQuery.getKey = (variables?: UserActiveTicketsKeyQueryVariables) =>
    variables === undefined ? ['UserActiveTicketsKey'] : ['UserActiveTicketsKey', variables];
