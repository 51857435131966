import React, { Ref, useEffect, useRef } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import IconSearch from '../trinket/icon/IconSearch';

interface Props {
    handleSearchSubmit?: (searchTerm: string) => void;
    toggleSearch?: () => void;
    placeholder?: string;
    isSearchActive: boolean;
}

const NavigationSearchBar: React.FC<Props> = ({
    placeholder,
    isSearchActive,
    handleSearchSubmit,
    toggleSearch,
    ...rest
}) => {
    const theme = useTheme();
    const inputRef: Ref<HTMLInputElement> = useRef();
    const handleSearchIconClick = async () => {
        toggleSearch();
    };

    useEffect(() => {
        if (isSearchActive) {
            inputRef.current?.focus();
        }
    }, [isSearchActive]);

    return isSearchActive ? (
        <HStack
            as="form"
            color={theme.tokens.SyntaxTextColorOnDark}
            bg={theme.tokens.SyntaxBackgroundPrimaryDarker}
            gap={4}
            pl={6}
            pr={[0, null, 6]}
            minH={11}
            onSubmit={e => {
                e.preventDefault();
                toggleSearch();
                handleSearchSubmit(inputRef.current.value);
            }}
            flexGrow={[1, null, 0]}
            {...rest}
        >
            <Box
                ref={inputRef}
                as="input"
                placeholder={placeholder}
                bg="transparent"
                color="currentColor"
                fontFamily="inherit"
                fontSize={theme.tokens.SyntaxBodyTextLRegularFontSize}
                fontWeight={theme.tokens.FontWeightXl}
                h="100%"
                w={['100%', null, '30vw']}
                _focus={{
                    outline: 'none',
                }}
                sx={{
                    WebkitFontSmoothing: 'antialiased',
                }}
                _placeholder={{
                    color: 'inherit',
                    opacity: 0.3,
                }}
            />
            <Box w={6} h={6} as="button" type="submit" order={[-1, null, 0]}>
                <IconSearch />
            </Box>
        </HStack>
    ) : (
        <Flex
            color={theme.tokens.SyntaxTextColorOnDark}
            bg={theme.tokens.SyntaxBackgroundPrimaryDarker}
            px={6}
            alignItems="center"
            {...rest}
        >
            <Box
                w={6}
                h={6}
                as="button"
                type="button"
                onClick={() => handleSearchIconClick().then(() => inputRef.current?.focus())}
            >
                <IconSearch />
            </Box>
        </Flex>
    );
};

export default NavigationSearchBar;
