import Link from 'next/link';
import { AnchorHTMLAttributes, FC, PropsWithChildren } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import IconCaret from '../trinket/icon/IconCaret';
import Body from '../typography/Body';

export interface TagProps extends AnchorHTMLAttributes<HTMLAnchorElement>, PropsWithChildren {
    size?: 's' | 'm' | 'l';
}

const Tag: FC<TagProps> = ({ size = 'm', href, children, className, ...rest }) => {
    const inner = (
        <StyledTag size={size} hasLink={!!href} as={href ? 'a' : 'span'} className={className}>
            {children}
            {href && <IconCaret direction="right" />}
        </StyledTag>
    );

    return href ? (
        <Link href={href ?? ''} passHref={!!href} {...rest}>
            {inner}
        </Link>
    ) : (
        inner
    );
};

const StyledTag = styled(Body)<
    Required<Omit<TagProps, 'href' | keyof AnchorHTMLAttributes<HTMLAnchorElement>>> & { hasLink: boolean }
>(
    ({ size, hasLink, theme }) => css`
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: ${size === 'm' ? undefined : theme.tokens.FontSize1};
        height: ${size === 'm' ? theme.tokens.Sizing6 : theme.tokens.Sizing4};
        line-height: ${theme.tokens.LineHeightXxs};
        padding: ${setPadding(size, hasLink)};
        text-align: center;
        color: currentColor;
        text-decoration: none;
        border-radius: ${theme.tokens.BorderRadiusL};
        white-space: nowrap;

        svg {
            fill: currentColor;
            flex-shrink: 1;
            width: 18px;
            height: 18px;
        }

        span {
            font-family: ${theme.tokens.FontFamilySansSerif};
            padding-bottom: 2px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: ${theme.tokens.BorderWidthM} solid currentColor;
            opacity: ${hasLink ? 1 : 0.3};
            border-radius: inherit;
        }

        ${size === 's' &&
        css`
            font-size: ${theme.tokens.FontSize1};
            height: ${theme.tokens.Sizing4};

            svg {
                margin-left: ${theme.tokens.Sizing1};
            }
        `}

        ${size === 'm' &&
        css`
            height: ${theme.tokens.Sizing6};

            svg {
                margin-left: ${theme.tokens.Sizing2};
            }
        `}

        ${size === 'l' &&
        css`
            font-size: ${theme.tokens.FontSize5};
            height: ${theme.tokens.Sizing7};

            svg {
                margin-left: ${theme.tokens.Sizing2};
            }
        `}
    `
);
StyledTag.defaultProps = {
    clamp: 1,
};

function setPadding(size: 's' | 'm' | 'l', hasLink: boolean): string {
    if (size === 'l') {
        return hasLink ? '0 4px 0 12px' : '0 12px';
    }
    if (size === 'm') {
        return hasLink ? '0 4px 0 12px' : '0 12px';
    }
    return hasLink ? '0 4px 0 8px' : '0 8px';
}

export default Tag;
