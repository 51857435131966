import React, { useMemo } from 'react';

import { AspectRatio, Box, Flex, Stack, StackProps, Text } from '@chakra-ui/react';

import { useTheme } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';
import styled from '@emotion/styled';

interface TImage extends Omit<ImgProps, 'fill'> {
    width: number;
    height: number;
}

interface Props extends StackProps {
    caption?: string;
    images: [TImage, TImage?];
    large?: boolean;
}

const ContentBlockImage: React.FC<Props> = ({ caption, images, large = false, ...rest }) => {
    const theme = useTheme();
    const ratio = useMemo(() => {
        const ratios = images.map(i => i.width / i.height);
        const r = Math.max(...ratios);
        return r > 0.5 ? r : 0.5;
    }, [images]);
    return (
        <Stack
            mt={large ? 6 : null}
            mb={large ? 8 : 6}
            spacing={4}
            color={theme.tokens.SyntaxTextColorNeutralMuted}
            data-content-video
            {...rest}
        >
            <Flex gap={6}>
                {images.map((image, index) => (
                    <Box key={index === 0 ? 'left' : 'right'} bg={theme.tokens.SyntaxBackgroundNeutralDark} flex={1}>
                        {image && (
                            <AspectRatio ratio={ratio}>
                                <StyledImg {...image} />
                            </AspectRatio>
                        )}
                    </Box>
                ))}
            </Flex>
            {caption && <Text fontSize={2}>{caption}</Text>}
        </Stack>
    );
};

const StyledImg = styled(Img)`
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

export default ContentBlockImage;
