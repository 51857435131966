import React, { ReactNode } from 'react';
import { Button, ButtonProps, Flex, Menu, MenuButton, MenuItem, MenuList, MenuProps } from '@chakra-ui/react';
import { IconCaret } from '../../index';

interface Props extends MenuProps {
    children: ReactNode[];
    title: ReactNode;
    buttonProps?: ButtonProps;
}

const Dropdown: React.FC<Props> = ({ children, title, buttonProps, ...menuProps }) => (
    <Menu variant="outline" closeOnSelect={false} {...menuProps}>
        <MenuButton
            as={Button}
            sx={{
                svg: {
                    w: 5,
                    h: 5,
                    transition: 'transform 0.2s',
                },
                '&[aria-expanded="true"] svg': {
                    transform: 'scaleY(-1)',
                },
            }}
            textAlign="left"
            variant="outline"
            rightIcon={<IconCaret direction="down" />}
            {...buttonProps}
        >
            {title}
        </MenuButton>
        <MenuList p={0} overflow="hidden">
            <Flex direction="column" p={6} maxH="392px" overflowY="auto">
                {children.map((item, index) => (
                    <MenuItem p={0} cursor="default" key={index}>
                        {item}
                    </MenuItem>
                ))}
            </Flex>
        </MenuList>
    </Menu>
);
export default Dropdown;
