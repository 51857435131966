import { useTheme } from '@emotion/react';
import { useDisclosure } from 'designsystem';
import { StaticImageData } from 'next/image';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ArchiveFilmDetailsFragment,
    BerthaFundFilmDetailsFragment,
    DocLabFilmDetailsFragment,
    DocschoolFilmDetailsFragment,
    DocsForSaleFilmDetailsFragment,
    ForumFilmDetailsFragment,
    ProjectSpaceFilmDetailsFragment,
} from '../gql/api';
import useGetApiImageProps from './useGetApiImageProps';

type Publications = Omit<ArchiveFilmDetailsFragment['publications'], '__typename'> &
    Omit<BerthaFundFilmDetailsFragment['publications'], '__typename'> &
    Omit<DocLabFilmDetailsFragment['publications'], '__typename'> &
    Omit<DocschoolFilmDetailsFragment['publications'], '__typename'> &
    Omit<DocsForSaleFilmDetailsFragment['publications'], '__typename'> &
    Omit<ForumFilmDetailsFragment['publications'], '__typename'> &
    Omit<ProjectSpaceFilmDetailsFragment['publications'], '__typename'>;

const useMedia = (publications: Publications | null) => {
    const getImageProps = useGetApiImageProps();
    const disclosureProps = useDisclosure();
    const [index, setIndex] = useState(0);
    const { tokens } = useTheme();

    const media = useMemo(
        () => ({
            images: [...(publications?.stills || [])].filter(Boolean).map((image, i) => ({
                ...getImageProps(
                    image,
                    i.toString(),
                    `(min-width: ${tokens.MediaQueryXl}) 50vw, (min-width: ${tokens.MediaQueryL}) 50vw, (min-width: ${tokens.MediaQueryM}) 447px, (min-width: ${tokens.MediaQueryS}) 50vw, 100vw`
                ),
                type: 'image' as const,
            })),
            video: [publications?.docLabVideo, ...(publications?.trailers ?? [])].filter(Boolean).map(video => ({
                id: video.id,
                src: video.value,
                type: 'video' as const,
            })),
        }),
        [getImageProps, publications, tokens]
    );

    const flatMedia = useMemo(() => [...media.video, ...media.images], [media.images, media.video]);

    const setCurrentMedia = useCallback(
        ({ src }: { src: string | StaticImageData }) => setIndex(flatMedia.findIndex(m => m.src === src)),
        [flatMedia]
    );

    const mediaImages = useMemo(
        () =>
            media.images.map(image => ({
                ...image,
                onClick: () => {
                    setCurrentMedia(image);
                    disclosureProps.onOpen();
                },
            })),
        [disclosureProps, media.images, setCurrentMedia]
    );
    const mediaVideos = useMemo(
        () =>
            media.video.map(video => ({
                ...video,
                onClick: () => {
                    setCurrentMedia(video);
                    disclosureProps.onOpen();
                },
            })),
        [disclosureProps, media.video, setCurrentMedia]
    );

    const onPrev = useCallback(() => {
        setIndex(index - 1 < 0 ? flatMedia.length - 1 : index - 1);
    }, [flatMedia.length, index]);

    const onNext = useCallback(() => {
        setIndex((index + 1) % flatMedia.length);
    }, [flatMedia.length, index]);

    const onKey = useCallback<Parameters<typeof document.addEventListener<'keydown'>>['1']>(
        event => {
            if (event.key === 'ArrowLeft') {
                onPrev();
            } else if (event.key === 'ArrowRight') {
                onNext();
            }
        },
        [onNext, onPrev]
    );

    useEffect(() => {
        document.addEventListener('keydown', onKey);
        return () => document.removeEventListener('keydown', onKey);
    }, [onKey]);

    return {
        mediaImages,
        mediaVideos,
        disclosureProps,
        setCurrentMedia,
        mediaModalProps: {
            ...disclosureProps,
            media: flatMedia[index],
            index,
            total: flatMedia.length,
            onPrev,
            onNext,
        },
    };
};

export default useMedia;
