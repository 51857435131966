import { Button, chakra, Flex, HTMLChakraProps, Spinner, VStack } from 'designsystem';
import { useSession } from 'next-auth/react';
import Error from 'next/error';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useUserActiveTicketsKeyQuery } from '../../gql/api';
import isLoggedIn from '../../lib/isLoggedIn';
import useSignIn from '../../hooks/useSignIn';

interface ATMessage {
    vendor: 'IDFA';
    messageType: 'continue';
}

interface Props extends HTMLChakraProps<'iframe'> {
    onContinue: () => void;
}

const ATIFrame: React.FC<Props> = ({ src, className, onContinue, ...rest }) => {
    const { status } = useSession();
    const { data, isLoading } = useUserActiveTicketsKeyQuery(
        {},
        {
            enabled: !!isLoggedIn(status),
        }
    );
    const activeTicketsLoginKey = data?.viewer?.activeTicketsLoginKey;
    const { signIn, isLoading: isLoggingIn } = useSignIn();

    const onMessage = useCallback(
        (e: MessageEvent) => {
            const message: ATMessage = e.data;
            if (typeof message !== 'object') {
                return;
            }
            if ('vendor' in message && message.vendor === 'IDFA') {
                switch (message.messageType) {
                    case 'continue':
                        onContinue();
                        break;
                    default:
                        break;
                }
            }
        },
        [onContinue]
    );

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    });

    if (!isLoading && isLoggedIn(status) && !activeTicketsLoginKey) {
        return <Error statusCode={500} title="Couldn't retreive Active Tickets login for user" />;
    }

    return isLoggedIn(status) ? (
        // todo: loading state in case of no activeTicketsLoginKey
        !isLoading ? (
            <chakra.iframe
                src={`${src}?visitorLoginKey=${activeTicketsLoginKey}`}
                title="Active Tickets"
                sandbox="allow-downloads allow-downloads-without-user-activation allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation allow-top-navigation-by-user-activation"
                allow="geolocation camera idle-detection payment"
                {...rest}
            />
        ) : (
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center" className={className}>
                <Spinner className={className} />
            </Flex>
        )
    ) : (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center" className={className}>
            <VStack justifyContent="center" alignItems="center" spacing="4" w="100%">
                <FormattedMessage defaultMessage="Please log in first" />
                <Button onClick={() => signIn()} isLoading={isLoggingIn}>
                    Login
                </Button>
            </VStack>
        </Flex>
    );
};

export default ATIFrame;
