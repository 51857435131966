import React, { PropsWithChildren } from 'react';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import { Box, BoxProps } from '@chakra-ui/react';

import Grid from '../layout/Grid';
import { useTheme } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';

interface Props extends PropsWithChildren, BoxProps {
    image?: ImgProps;
}

const PersonHero: React.FC<Props> = ({ image, children, ...rest }) => {
    const theme = useTheme();
    return (
        <Grid gap={[0, 0, undefined]} {...rest}>
            <Box
                gridColumn={['1/-1', '1/3  ', '1/-5', null, '2 / -5']}
                bg={theme.tokens.SyntaxBackgroundNeutralDark}
                p={[5, null, 7, 9]}
                display="flex"
                alignItems="flex-end"
                ml={[0, null, 'calc(var(--gap) * -1)']}
            >
                {children}
            </Box>
            {image && (
                <Box gridColumn={['1/-1', '3/-1', '-5 / -1']} order={[-1, 0]}>
                    <AspectRatio.Root ratio={3 / 4}>
                        <Img fill {...image} style={{ objectFit: 'cover' }} />
                    </AspectRatio.Root>
                </Box>
            )}
        </Grid>
    );
};

export default PersonHero;
