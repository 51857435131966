import React, { PropsWithChildren, ReactElement } from 'react';

import { AccordionProps, ListItemProps } from '@chakra-ui/react';
import Body from '../typography/Body';
import Accordion, { ItemProps as AccordionItemProps } from './Accordion';

interface Props extends PropsWithChildren {
    items: ReactElement<AccordionItemProps & { key: ListItemProps['key'] }>[];
    accordionProps?: AccordionProps;
}

const ContentBlockAccordion: React.FC<Props> = ({ items, accordionProps, ...rest }) => (
    <Body mb={6} {...rest}>
        <Accordion {...accordionProps}>{items.map(item => item)}</Accordion>
    </Body>
);
export default ContentBlockAccordion;
