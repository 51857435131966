import { useTheme } from '@emotion/react';
import { Box, ChakraGrid, Flex, FormLabel, GridItem, Heading, NumberInput } from 'designsystem';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormErrorMessage from './FormErrorMessage';

interface ContributionProps {
    contributionOptions: number[];
}

const Contribution: FC<ContributionProps> = ({ contributionOptions }) => {
    const { setValue, register, watch } = useFormContext();
    const theme = useTheme();
    const [customContributionMode, setCustomContributionMode] = useState(false);
    const { contributionInEuros } = watch();
    const { formatMessage } = useIntl();

    return (
        <ChakraGrid gridTemplateColumns={['1fr', null, '1fr 1fr 1fr']} gridRowGap={[6, null, 9]} gridColumnGap={10}>
            {contributionOptions.map(contributionOption => (
                <Flex
                    key={contributionOption}
                    onClick={() => {
                        if (customContributionMode) {
                            setCustomContributionMode(false);
                        }
                        setValue('contributionInEuros', contributionOption);
                    }}
                    justifyContent="center"
                    py={11}
                    backgroundColor={
                        !customContributionMode && contributionInEuros === contributionOption
                            ? 'red.60'
                            : 'neutralwhite'
                    }
                    border="1px solid"
                    borderColor="neutral.30"
                    cursor="pointer"
                    position="relative"
                >
                    <Box
                        position="absolute"
                        h={5}
                        w={5}
                        borderRadius="100%"
                        right={4}
                        top={4}
                        borderStyle="solid"
                        borderWidth={['2px', null, '1.5px']}
                        borderColor={
                            !customContributionMode && contributionInEuros === contributionOption
                                ? theme.tokens.ColorNeutralWhite
                                : theme.tokens.ColorNeutral30
                        }
                    />
                    <Heading
                        variant={4}
                        color={
                            !customContributionMode && contributionInEuros === contributionOption
                                ? theme.tokens.ColorNeutralWhite
                                : theme.tokens.ColorNeutralBlack
                        }
                    >
                        €{contributionOption}
                    </Heading>
                </Flex>
            ))}
            <GridItem colSpan={[1, null, 3]}>
                {!customContributionMode && (
                    <Flex
                        justifyContent="center"
                        py={6}
                        border="1px solid"
                        borderColor="neutral.30"
                        cursor="pointer"
                        onClick={() => {
                            setCustomContributionMode(true);
                        }}
                        position="relative"
                    >
                        <Box
                            position="absolute"
                            h={5}
                            w={5}
                            borderRadius="100%"
                            right={4}
                            top={4}
                            borderStyle="solid"
                            borderWidth={['2px', null, '1.5px']}
                            borderColor={theme.tokens.ColorNeutral30}
                        />
                        <Heading variant={4} color={theme.tokens.ColorNeutralBlack}>
                            <FormattedMessage defaultMessage="Ander bedrag" />
                        </Heading>
                    </Flex>
                )}
                {customContributionMode && (
                    <>
                        <FormLabel htmlFor="contributionInEuros">
                            <FormattedMessage defaultMessage="Je bijdrage is" />
                        </FormLabel>
                        <NumberInput
                            {...register('contributionInEuros', {
                                setValueAs: (value: string) => parseFloat(value),
                                pattern: {
                                    value: /^\d+$/,
                                    message: formatMessage({
                                        defaultMessage: 'Vul een getal in',
                                        description: 'form.number',
                                    }),
                                },
                                min: {
                                    value: 5,
                                    message: formatMessage(
                                        {
                                            defaultMessage: 'Het minimale bedrag is {minAmount} euro',
                                            description: 'form.minContribution',
                                        },
                                        {
                                            minAmount: contributionOptions[0],
                                        }
                                    ),
                                },
                                max: {
                                    value: 150000,
                                    message: formatMessage(
                                        {
                                            defaultMessage: 'Het maximale bedrag is {maxAmount} euro',
                                            description: 'form.maxContribution',
                                        },
                                        {
                                            maxAmount: '150.000',
                                        }
                                    ),
                                },
                            })}
                            size="lg"
                            numberFormat="€"
                            placeholder={formatMessage({ defaultMessage: 'Ander bedrag' })}
                        />
                        <FormErrorMessage fieldName="contributionInEuros" />
                    </>
                )}
            </GridItem>
        </ChakraGrid>
    );
};

export default Contribution;
