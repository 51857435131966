import Image from 'next/image';
import React, { ReactElement, ReactNode, useMemo } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import useBrowserSize, { browserSizes } from '../../hooks/useBrowserSize';
import ColorSchemeProvider from '../ColorSchemeProvider';
import Wrapper from '../layout/Wrapper';
import Carousel from '../navigation/Carousel';
import Body from '../typography/Body';

interface Props {
    logos: { title: string; logo: ReactElement<React.ComponentProps<typeof Image>>; href: string }[];
    partnerTitle: ReactNode;
    partners: { name: ReactNode; href: string }[];
}

const Partners: React.FC<Props> = ({ logos, partnerTitle, partners, ...rest }) => {
    const theme = useTheme();
    const size = useBrowserSize();
    const visibleLogos = useMemo(() => {
        if (browserSizes.indexOf(size) <= browserSizes.indexOf('s')) {
            return 2;
        }
        if (browserSizes.indexOf(size) <= browserSizes.indexOf('m')) {
            return 3;
        }
        return 4;
    }, [size]);
    return (logos && logos.length > 0) || (partners && partners.length > 0) ? (
        <ColorSchemeProvider colorScheme="neutral" {...rest}>
            <Box>
                <Box
                    bg={theme.tokens.PartnersBackground}
                    color={theme.tokens.PartnersForeground}
                    py={[10, null, 15]}
                    mb={[-8, -10, null, -18]}
                >
                    <Wrapper>
                        <Stack spacing={[10, null, 15]}>
                            {logos && logos.length > 0 && (
                                <Box>
                                    <LogoCarousel
                                        visibleSlides={visibleLogos}
                                        naturalSlideWidth={192}
                                        naturalSlideHeight={64}
                                        slides={logos.map(l => ({
                                            slide: (
                                                <Box w={['80%', null, null, '60%']} h="100%" position="relative">
                                                    <a href={l.href ?? ''} target="_blank" rel="noreferrer">
                                                        <LogoImage fill {...l.logo.props} />
                                                    </a>
                                                </Box>
                                            ),
                                        }))}
                                    />
                                </Box>
                            )}
                            {partners && partners.length > 0 && (
                                <Box>
                                    <Stack spacing={7}>
                                        <Body fontWeight="l">{partnerTitle}</Body>
                                        <PartnerNames>
                                            {partners.map((p, index) => (
                                                <>
                                                    {p.href ? (
                                                        <a key={p.href} href={p.href} target="_blank" rel="noreferrer">
                                                            {p.name}
                                                        </a>
                                                    ) : (
                                                        p.name
                                                    )}
                                                    {index !== partners.length - 1 ? ', ' : '.'}
                                                </>
                                            ))}
                                        </PartnerNames>
                                    </Stack>
                                </Box>
                            )}
                        </Stack>
                    </Wrapper>
                </Box>
            </Box>
        </ColorSchemeProvider>
    ) : null;
};

const LogoCarousel = styled(Carousel)(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.tokens.Sizing3};
        min-height: 100% !important;
    `
);

const LogoImage = styled(Image)`
    filter: contrast(0) brightness(10);
    opacity: 0.8;
`;

const PartnerNames = styled(Body)(
    ({ theme }) => css`
        font-size: ${theme.fontSizes[9]};
        line-height: ${theme.lineHeights.xs};
        font-weight: ${theme.fontWeights.m};

        & > a,
        & > a:visited {
            text-decoration: none;
            text-decoration-thickness: from-font;
            color: inherit;

            &:hover {
                color: currentColor;
                text-decoration-line: underline;
                text-underline-offset: 0.135em;
            }
        }

        @media (max-width: ${theme.tokens.MediaQueryS}) {
            font-size: ${theme.fontSizes[6]};
            line-height: ${theme.lineHeights.s};
        }
    `
);

export default Partners;
