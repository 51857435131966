import React, { useMemo } from 'react';

import { Box, Flex, Skeleton, SkeletonText, Stack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import useBrowserSize, { browserSizes } from '../../hooks/useBrowserSize';
import Grid from '../layout/Grid';

const SkeletonListItem: React.FC = () => {
    const browserSize = useBrowserSize();
    const aspectRatio = useMemo(
        () => (browserSizes.indexOf(browserSize) <= browserSizes.indexOf('s') ? 16 / 9 : 3 / 2),
        [browserSize]
    );

    return (
        <Container as="article">
            <Grid>
                <Box gridColumn={['1/-1', null, '1/6', '1/5', '1/4']}>
                    <AspectRatio.Root ratio={aspectRatio}>
                        <Skeleton height="100%" width="100%" />
                    </AspectRatio.Root>
                </Box>
                <Flex
                    gridColumn={['1/-1', null, '6/-2', '5/-2', '4/-2']}
                    alignItems="center"
                    p={[5, null, 0]}
                    py={[5, null, 3]}
                >
                    <Stack spacing={3} width="100%">
                        <SkeletonText noOfLines={1} width="66%" skeletonHeight="30px" />

                        <SkeletonText noOfLines={1} width="50%" skeletonHeight="10px" />
                    </Stack>
                </Flex>
            </Grid>
        </Container>
    );
};

const Container = styled(Box)(
    ({ theme }) => css`
        outline: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
    `
);
Container.defaultProps = {
    as: 'article',
};
export default SkeletonListItem;
