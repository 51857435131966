import { radioAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type RadioTheme = Omit<MultiStyleConfig<typeof radioAnatomy>, 'parts'>;

/**
 * Theme for a Chakra Radio. Include this into your theme.
 */
const RadioTheming: RadioTheme = {
    baseStyle: ({ theme: T }) => ({
        container: {},
        control: {
            boxSizing: 'border-box',
            w: 6,
            h: 6,
            borderWidth: T.tokens.BorderWidthL,
            borderStyle: 'solid',
            borderColor: T.tokens.SyntaxColorInputEnabledBorder,
            bg: T.tokens.SyntaxBackgroundNeutralDefault,
            color: T.tokens.SyntaxColorActionEnabledBackground,
            transition: `all 0.2s`,
            borderRadius: '50%',
            '&:before': {
                content: `''`,
                w: 3,
                h: 3,
                borderRadius: '50%',
                backgroundColor: 'currentColor',
                opacity: 0,
            },
            _hover: {
                borderColor: T.tokens.SyntaxColorInputHoverBorder,
            },
            _focus: {
                borderColor: T.tokens.SyntaxColorInputFocusBorder,
                boxShadow: `0 0 0 ${T.tokens.Sizing1} ${T.tokens.BorderColorPrimaryLight}`,
                outline: 'none',
            },
            _checked: {
                borderColor: T.tokens.SyntaxColorInputActiveBorder,
                '&:before': {
                    opacity: 1,
                },
            },
            _disabled: {
                borderColor: T.tokens.SyntaxDisabledColorDefault,
                color: T.tokens.SyntaxDisabledColorDefault,
            },
        },
        label: {
            fontFamily: 'inherit',
            fontSize: T.tokens.SyntaxBodyTextLRegularFontSize,
            lineHeight: 'xxs',
            ml: 4,
            _disabled: {
                color: T.tokens.SyntaxDisabledColorDark,
            },
        },
    }),
    sizes: {
        md: {},
    },
    defaultProps: {
        size: 'md',
    },
};

export default RadioTheming;
