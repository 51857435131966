import { FC, HTMLAttributes } from 'react';

const IconViolence: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    <svg viewBox="0 0 184 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="91.8213" cy="91.5" r="91.5" fill="black" />
        <path
            d="M118.367 76.8978C119.54 74.5507 121.709 72.8574 124.271 72.2882L135.071 69.8881C139.769 68.844 142.731 64.189 141.687 59.4909L139.904 51.4653C139.018 47.4782 135.482 44.6414 131.397 44.6414H129.021C126.71 44.6414 124.494 43.7233 122.859 42.0891L118.999 38.2286C116.583 35.8122 112.977 35.0312 109.777 36.2311L102.43 38.9865C97.9233 40.6764 95.6401 45.6994 97.3299 50.2057L100.721 59.2493C101.572 61.5182 101.44 64.0389 100.356 66.2062L91.9539 83.0108C91.2296 84.4593 92.2829 86.1636 93.9024 86.1636H102.007C102.832 86.1636 103.586 86.6298 103.955 87.3679L106.595 92.647C107.398 94.2527 109.689 94.2527 110.492 92.647L118.367 76.8978Z"
            fill="white"
        />
        <path
            d="M89.4478 96.5442C91.0673 96.5442 92.1206 98.2486 91.3964 99.6971L82.9941 116.502C81.9104 118.669 81.7781 121.19 82.629 123.459L86.0203 132.502C87.7102 137.008 85.427 142.032 80.9206 143.721L73.573 146.477C70.3733 147.677 66.7676 146.896 64.3512 144.479L60.4908 140.619C58.8565 138.985 56.64 138.066 54.3289 138.066H51.9531C47.8687 138.066 44.3323 135.23 43.4463 131.243L41.6629 123.217C40.6188 118.519 43.5811 113.864 48.2792 112.82L59.0797 110.42C61.6414 109.85 63.8101 108.157 64.9836 105.81L72.8583 90.0608C73.6611 88.4551 75.9525 88.4551 76.7554 90.0608L79.395 95.34C79.764 96.078 80.5184 96.5442 81.3435 96.5442H89.4478Z"
            fill="white"
        />
        <path
            d="M82.7712 64.3103L80.0845 54.2834"
            stroke="white"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M70.8498 73.7969L61.1606 70.0632"
            stroke="white"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M102.041 119.844L104.728 129.87"
            stroke="white"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M113.963 110.357L123.652 114.091"
            stroke="white"
            strokeWidth="6.53571"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconViolence;
