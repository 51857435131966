import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { OptionsType } from 'cookies-next/src/types';

/* eslint-disable no-console */

export const fetchGlobalData = async <TData, TVariables>({
    query,
    locale,
    variables,
    options,
}: {
    query: string;
    locale: string;
    variables?: TVariables;
    options?: Record<string, string>;
    res?: OptionsType['res'];
    req?: OptionsType['req'];
}): Promise<TData> => {
    const headers: Record<string, string> = { 'Content-Type': 'application/json', ...options };
    if (locale) {
        headers['X-Default-Language'] = locale;
    }

    const opts: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify({ query, variables }),
    };
    const apiResponse = await fetch(process.env.NEXT_PUBLIC_GLOBAL_CORE_ENDPOINT, opts);

    if (!apiResponse.ok) {
        console.error('------------ ERROR DEBUG -------\n', { query, variables }, '\n--------------------------------');
        throw new Error(`${apiResponse.status}: ${apiResponse.statusText}, ${await apiResponse.text()}`);
    }

    const json = await apiResponse.json();

    if (json.errors) {
        console.error('------------ ERROR DEBUG -------\n', { query, variables }, '\n--------------------------------');
        const { message } = json.errors[0];

        throw new Error(message);
    }

    return json.data;
};

const useGlobal = <TData, TVariables>(query: string, variables?: TVariables): (() => Promise<TData>) => {
    const { locale } = useRouter();
    return useCallback(
        (callbackVariables?: TVariables) =>
            fetchGlobalData<TData, TVariables>({
                locale,
                query,
                variables: variables ?? callbackVariables,
            }),
        [locale, query, variables]
    );
};

useGlobal.fetch = fetchGlobalData;

export default useGlobal;
