import { Box, Flex, FlexProps } from '@chakra-ui/react';
import IconHeart from '../trinket/icon/IconHeart';
import React, { FC } from 'react';

interface Props extends FlexProps {
    isFavorite: boolean;
    iconColor: string;
    size?: 'm' | 'l';
    isLoading: boolean;
}

const FavoriteButton: FC<Props> = ({ isFavorite, isLoading, iconColor, size = 'm', ...rest }) => (
    <Flex
        as="button"
        alignItems="center"
        justifyContent="center"
        bgColor={isFavorite ? '#C9252D' : 'transparent'}
        borderRadius="9999px"
        border={isFavorite ? 'none' : '1px solid'}
        h={size === 'm' ? 9 : 10}
        w={size === 'm' ? 9 : 10}
        opacity={!isLoading ? 1 : 0.5}
        pointerEvents={!isLoading ? 'auto' : 'none'}
        transition="opacity .10s"
        {...rest}
    >
        <Box w={6} h={6}>
            <IconHeart color={iconColor} />
        </Box>
    </Flex>
);
export default FavoriteButton;
