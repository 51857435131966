import { useCallback } from 'react';
import { DecodedValueMap, QueryParamConfig, useQueryParams } from 'use-query-params';

type QueryParamConfigMap = Record<string, QueryParamConfig<string[], string[]>>;

const useSetQueryFilter = <QPCMap extends QueryParamConfigMap, K = keyof QPCMap>(queryParams: QPCMap) => {
    const [, setQueryFilters] = useQueryParams(queryParams);
    return useCallback(
        (filter: K, amountKey: string, value: boolean, inputType: 'checkbox' | 'radio' = 'checkbox') =>
            setQueryFilters((latestValues: DecodedValueMap<QPCMap>) => {
                const enabledFilters: string[] = latestValues[filter as string] ?? [];
                return {
                    [filter as string]: value
                        ? inputType === 'radio'
                            ? [amountKey]
                            : [...enabledFilters, amountKey]
                        : enabledFilters.filter(f => f !== amountKey),
                } as Partial<DecodedValueMap<QPCMap>>;
            }),
        [setQueryFilters]
    );
};
export default useSetQueryFilter;
