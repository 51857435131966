import { Box, Flex, Stack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import Img, { ImgProps } from '../media/Img';
import Heading from '../typography/Heading';

export interface CardProps extends PropsWithChildren {
    image?: ImgProps;
    title: ReactNode;
    titleVariant?: ComponentProps<typeof Heading>['variant'];
    variant?: 'primary' | 'neutral' | 'white';
}

const ImageBackgroundCard: React.FC<CardProps> = ({ variant, image, title, titleVariant, children, ...rest }) => (
    <Container
        variant={variant}
        pos="relative"
        _before={{
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bg: 'linear-gradient(rgba(0, 0, 0, 0.2), #000000)',
            zIndex: 1,
            opacity: 0.9,
        }}
        {...rest}
    >
        {image && <Img fill {...image} />}

        <Flex py={[4, null, 7, 9]} px={[4, '8%', null, 9]} flex={1} pos="absolute" bottom={0} zIndex="1" w="100%">
            <Stack flex={1} spacing={0}>
                <Heading variant={titleVariant} clamp={3} mb={5} as="h3" fontSize={[null, null, null, null, null]}>
                    {title}
                </Heading>
                {children}
            </Stack>
        </Flex>
    </Container>
);

const Container = styled(Box)<Pick<CardProps, 'variant'>>(
    ({ theme, variant }) => css`
        display: flex;
        flex-direction: column;
        flex: 1;
        text-decoration: none;
        color: ${theme.tokens.SyntaxTextColorDefault};
        ${variant === 'primary' &&
        css`
            background: ${theme.tokens.CardPrimaryBackground};
            color: ${theme.tokens.CardPrimaryForeground};
        `}
        ${variant === 'neutral' &&
        css`
            background: ${theme.tokens.CardNeutralBackground};
            color: ${theme.tokens.CardNeutralForeground};
        `};
        ${variant === 'white' &&
        css`
            background: ${theme.tokens.CardWhiteBackground};
            color: ${theme.tokens.CardWhiteBackground};
        `};
    `
);

export default ImageBackgroundCard;
