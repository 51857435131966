import DateSelectorItem from './DateSelectorItem';
import React, { FC } from 'react';
import { eachDayOfInterval, eachWeekOfInterval, endOfWeek, isSameDay } from 'date-fns';
import { Box, Grid } from '@chakra-ui/react';

interface Props {
    dates: Date[];
    onDateClick: (date: Date) => void;
}

const weekStartsOn = 1;

const DateSelector: FC<Props> = ({ dates, onDateClick }) => {
    const [start] = dates;
    const [end] = dates.slice(-1);
    const weeks = eachWeekOfInterval({ start, end }, { weekStartsOn });

    return (
        <Grid gap={2} templateColumns="repeat(7, minmax(0, 1fr))">
            {weeks.map(week => {
                const days = eachDayOfInterval({ start: week, end: endOfWeek(week, { weekStartsOn }) });
                return days.map(date => {
                    const isDateAvailable = dates.some(d => isSameDay(d, date));
                    return isDateAvailable ? (
                        <DateSelectorItem key={date.toISOString()} date={date} onClick={() => onDateClick(date)} />
                    ) : (
                        <Box />
                    );
                });
            })}
        </Grid>
    );
};

export default DateSelector;
