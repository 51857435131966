import { Tabs, FestivalProgrammeHero, Heading, Body, HtmlContent, Wrapper, TabPanels } from 'designsystem';
import { ComponentProps, FC, ReactNode } from 'react';

interface Props {
    tabIndex: number;
    onChange: (index: number) => void;
    tabs: string[];
    topChildren: ReactNode;
    pageTitle: string;
    introText: string;
    tabPanels: ReactNode[];
    override?: ComponentProps<typeof HtmlContent>['override'];
}

const ProgrammePageTemplate: FC<Props> = ({
    tabIndex,
    introText,
    onChange,
    pageTitle,
    tabPanels,
    tabs,
    topChildren,
    override,
}) => (
    <Tabs isFitted variant="line" index={tabIndex} onChange={onChange}>
        <FestivalProgrammeHero topChildren={topChildren} tabs={tabs}>
            <Heading mt={['72px', null, '112px']} clamp={1} variant={1}>
                {pageTitle}
            </Heading>
            <Body mt={[4, null, 7]} pb={13}>
                <HtmlContent html={introText} override={override} />
            </Body>
        </FestivalProgrammeHero>
        <Wrapper>
            <TabPanels>{tabPanels}</TabPanels>
        </Wrapper>
    </Tabs>
);

export default ProgrammePageTemplate;
