import { useIsMutating, useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { signIn as nextAuthSignIn } from 'next-auth/react';

const key = ['signIn'];

// KISS: this hook is just a wrapper around next-auth's signIn function
const useSignIn = () => {
    const { locale } = useRouter();
    const { mutate: signIn, ...rest } = useMutation(key, () => nextAuthSignIn('idfa-website', undefined, { locale }));

    return {
        signIn,
        ...rest,
    };
};

// use this if you just want to know if any sign in mutation is happening
export const useIsSigningIn = () => useIsMutating(key) > 0;

export default useSignIn;
