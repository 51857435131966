import React from 'react';

import { AspectRatio, Box, BoxProps, Flex, Stack } from '@chakra-ui/react';

import Grid from '../layout/Grid';
import Meta, { Props as MetaProps } from '../meta/Meta';
import Heading from '../typography/Heading';
import Img, { ImgProps } from '../media/Img';

export interface Props extends BoxProps {
    image?: ImgProps;
    title: string;
    meta: Omit<MetaProps, 'size'>;
}

const NewsListItem: React.FC<Props> = ({ title, image, meta, ...rest }) => (
    <Box
        as="article"
        textDecoration="none"
        color="currentColor"
        sx={{
            '&:hover picture': { transform: 'scale(1.05)' },
            '& picture': { transition: 'transform 1400ms cubic-bezier(0.16, 1, 0.3, 1)' },
        }}
        {...rest}
    >
        <Grid>
            <Flex gridColumn={['1 / -1', null, '1 / -5', '1 / -4']} alignItems="flex-start" pt={[6, null, 0]}>
                <Stack spacing={6} maxWidth={[null, null, '480px']}>
                    <Heading as="h2" variant={3} clamp={2}>
                        {title}
                    </Heading>
                    <Meta size="m" {...meta} />
                </Stack>
            </Flex>
            {image && (
                <Box gridColumn={['1 / -1', null, '-5 / span 4', '-4 / span 3']} order={0} gridRow={[1, null, 'auto']}>
                    <AspectRatio ratio={16 / 10}>
                        <Img fill style={{ objectFit: 'cover' }} {...image} />
                    </AspectRatio>
                </Box>
            )}
        </Grid>
    </Box>
);

export default NewsListItem;
