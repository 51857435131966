import { AspectRatio, AspectRatioProps, Box, BoxProps } from '@chakra-ui/react';
import React, { ComponentPropsWithRef } from 'react';
import dynamic from 'next/dynamic';
import { ReactPlayerProps } from 'react-player';
// importing react-player directly causes hydration error in newest version of NextJS: https://github.com/cookpete/react-player/issues/1428
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export interface Props extends BoxProps {
    ratio?: AspectRatioProps['ratio'];
    url: ReactPlayerProps['url'];
    videoProps?: Omit<ComponentPropsWithRef<typeof ReactPlayer>, 'title'>;
}

const Video: React.FC<Props> = ({ ratio = 16 / 9, url, videoProps, ...rest }) => (
    <Box {...rest}>
        <AspectRatio ratio={ratio}>
            <ReactPlayer width="100%" height="100%" url={url} controls {...videoProps} />
        </AspectRatio>
    </Box>
);

export default Video;
