import React, { ComponentProps } from 'react';
import SmallCard, { CardProps } from './SmallCard';
import IconMail from '../trinket/icon/IconMail';
import Avatar from '../trinket/Avatar';

interface Props extends CardProps {
    name: string;
    photo?: ComponentProps<typeof Avatar>;
}

const ContactCard: React.FC<Props> = ({ name, photo, ...rest }) => (
    <SmallCard variant="white" size="s" leftElement={photo && <Avatar {...photo} />} icon={<IconMail />} {...rest}>
        {name}
    </SmallCard>
);

export default ContactCard;
