import React, { ComponentProps, FC, ReactNode } from 'react';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import IconCheck from '../trinket/icon/IconCheck';
import Tile from './Tile';

interface Props extends BoxProps, Partial<ComponentProps<typeof Tile>> {
    icon?: ReactNode;
}

const ConfirmationTile: FC<Props> = ({ children, icon = <IconCheck />, ...rest }) => {
    const { tokens } = useTheme();
    return (
        <Tile primary={false} colorScheme="neutral" {...rest}>
            <Stack spacing={8} textAlign="center" alignItems="center">
                <Box
                    w={13}
                    h={13}
                    p={5}
                    boxSizing="border-box"
                    bg={tokens.ColorNeutralWhite}
                    color={tokens.SyntaxPrimaryColorDefault}
                    borderRadius="100%"
                >
                    {icon}
                </Box>
                <Stack spacing={3}>{children}</Stack>
            </Stack>
        </Tile>
    );
};

export default ConfirmationTile;
