import { ContentBlockFilm, Meta } from 'designsystem';
import { getMetaSimplifiedForFilmOrProject, useGetApiImageProps } from '../../index';
import { useProfessionalsFilmCardQuery } from '../../gql/api';
import Link from 'next/link';
import slugify from 'slugify';
import getCollectionFromEditionType from '../../lib/getCollectionFromEditionType';
import { EditionType } from '../../constants/editionTypes';

const ProfessionalFilmContentBlock: React.FC<{ id: string }> = ({ id, ...rest }) => {
    const getApiImageProps = useGetApiImageProps();
    const { data } = useProfessionalsFilmCardQuery({
        id,
    });
    return data?.film ? (
        <Link
            href={`${process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS}${getCollectionFromEditionType(
                data.film.edition?.editionType?.description as EditionType,
                'professionals'
            )}/${id}/${slugify(data.film.fullPreferredTitle, {
                lower: true,
            })}`}
        >
            <ContentBlockFilm
                image={getApiImageProps(data.film?.publications.favoriteImage, data.film.fullPreferredTitle)}
                title={data.film?.fullPreferredTitle}
                meta={<Meta size="s" {...getMetaSimplifiedForFilmOrProject(data.film)} />}
                {...rest}
            />
        </Link>
    ) : null;
};

export default ProfessionalFilmContentBlock;
