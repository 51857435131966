import { FilterSections } from 'designsystem';
import { PropsWithChildren } from 'react';
import { ArchiveFilterEnum } from '../../gql/api';
import SearchFilterSections from './SearchFilterSections';
import SelectedFilterSections from './SelectedFilterSections';

const ArchiveFilters = ({ children }: PropsWithChildren) => (
    <FilterSections defaultValue={ArchiveFilterEnum.ArchiveType}>{children}</FilterSections>
);

ArchiveFilters.Sections = SearchFilterSections;
ArchiveFilters.SelectedFilters = SelectedFilterSections;

export default ArchiveFilters;
