import React, { AnchorHTMLAttributes, ReactNode } from 'react';

import { Box, BoxProps, Flex, Spinner } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import IconUser from '../trinket/icon/IconUser';
import { NavPillar } from './Navigation';

interface Props extends BoxProps {
    pillars: NavPillar[];
    topPages: ReactNode[];
    loggedIn?: boolean;
    isLoginLoading?: boolean;
    userName?: string;
    handleMyIdfaClick?: () => void;
    handleLoginClick?: (e: React.MouseEvent) => void;
}

const NavigationTopBar: React.FC<Props> = ({
    pillars,
    topPages,
    loggedIn,
    isLoginLoading,
    userName,
    handleLoginClick,
    handleMyIdfaClick,
    ...rest
}) => {
    const theme = useTheme();

    return (
        <Box
            bg={theme.tokens.SyntaxBackgroundNeutralDarkest}
            color={theme.tokens.SyntaxTextColorOnDark}
            justifyContent="space-between"
            {...rest}
        >
            <Flex>
                {pillars.map(p => (
                    <NavigationTopItem href={p.href} key={p.key} selected={p.selected} pillarColor={p.color}>
                        {p.title}
                    </NavigationTopItem>
                ))}
            </Flex>
            <Flex display={['none', 'none', 'none', 'flex', 'flex']}>
                {topPages}
                {isLoginLoading && (
                    <NavigationTopItem disabled>
                        <Spinner w={4} h={4} mr={1} /> MyIDFA
                    </NavigationTopItem>
                )}
                {!isLoginLoading &&
                    (loggedIn ? (
                        <NavigationTopItem as="button" onClick={handleMyIdfaClick}>
                            <IconUser w={4} h={4} mr={1} /> {userName ?? 'MyIDFA'}
                        </NavigationTopItem>
                    ) : (
                        <NavigationTopItem as="button" onClick={handleLoginClick}>
                            <IconUser w={4} h={4} mr={1} /> Login
                        </NavigationTopItem>
                    ))}
            </Flex>
        </Box>
    );
};

export const NavigationTopItem = styled.a<{ selected?: boolean; pillarColor?: string; disabled?: boolean }>(
    ({ theme, pillarColor, selected = false, disabled = false }) => css`
        display: flex;
        height: ${theme.tokens.Sizing8};
        align-items: center;
        padding: 0 ${theme.tokens.Sizing5};
        font-size: ${theme.tokens.SyntaxBodyTextLRegularFontSize};
        line-height: ${theme.tokens.SyntaxBodyTextLRegularLineHeight};
        font-family: ${theme.tokens.SyntaxBodyTextLRegularFontFamily};
        font-weight: ${theme.tokens.FontWeightL};
        transition: all 0.2s;
        color: inherit;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background-color: ${pillarColor || theme.tokens.ColorNeutralWhite};
            color: ${theme.tokens.SyntaxTextColorDefault};
        }
        &:focus {
            background-color: ${pillarColor || theme.tokens.ColorNeutralWhite};
            color: ${theme.tokens.SyntaxTextColorDefault};
            outline: none;
        }
        &:active {
            background-color: ${pillarColor};
            color: ${theme.tokens.SyntaxTextColorDefault};
        }

        ${selected &&
        css`
            background-color: ${pillarColor};
            color: ${theme.tokens.SyntaxTextColorDefault};
        `}
        ${disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}

        @media (max-width: ${theme.tokens.MediaQueryS}) {
            font-size: ${theme.tokens.FontSize2};
            padding: 0 ${theme.tokens.Sizing3};
        }
    `
);

export const NavigationBottomItem = styled(Box)<AnchorHTMLAttributes<HTMLAnchorElement>>(
    ({ theme }) => css`
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 ${theme.tokens.Sizing4};
        font-size: ${theme.tokens.SyntaxBodyTextLRegularFontSize};
        line-height: ${theme.tokens.SyntaxBodyTextLRegularLineHeight};
        font-family: ${theme.tokens.SyntaxBodyTextLRegularFontFamily};
        font-weight: ${theme.tokens.FontWeightL};
        color: inherit;
        text-decoration: none;
        transition: all 0.2s;
        white-space: nowrap;

        &:hover {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryLight};
        }
        &:focus {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryLight};
            outline: none;
        }
        &:active {
            background-color: ${theme.tokens.SyntaxBackgroundPrimaryDefault};
        }

        & > svg {
            height: ${theme.tokens.Sizing5};
            margin: 0 -${theme.tokens.Sizing1};
        }

        & > * + * {
            margin-left: ${theme.tokens.Sizing2} !important;
        }

        @media (min-width: ${theme.tokens.MediaQueryXs}) {
            padding: 0 ${theme.tokens.Sizing6};
        }
    `
);

export default NavigationTopBar;
