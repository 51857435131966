import {
    Body,
    Box,
    Button,
    ConfirmationTile,
    Flex,
    Heading,
    Hero,
    HStack,
    HtmlContent,
    IconButton,
    IconFilters,
    IconWarn,
    Intro,
    Pagination,
    SearchInput,
    Skeleton,
    useDisclosure,
    Wrapper,
} from 'designsystem';
import { NextSeo } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import { ComponentProps, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import striptags from 'striptags';

import styled from '@emotion/styled';

import useSearchState from '../../hooks/useSearchState';
import Filters, { Filter } from '../collections/Filters';
import SearchResults, { SEARCH_RESULTS_AMOUNT } from '../SearchResults';
import { QueryParamConfig } from 'use-query-params';
import SearchHit, { Hit } from '../collections/SearchHit';
import FiltersDrawer from '../collections/FiltersDrawer';
import FilterModal from '../modals/FilterModal';
import { Collection } from '../../constants/collection';
import gtm from '../../lib/gtm';
import { useDebounce } from 'use-debounce';

interface Props {
    collectionPath: Collection;
    pageTitle: string;
    pageIntroText?: string;
    pageOpenGraphImages?: OpenGraphMedia[];
    queryParams?: Parameters<typeof useSearchState<Record<string, QueryParamConfig<string[], string[]>>>>[0];
    isError: boolean;
    isFetching: boolean;
    totalPages?: number;
    hits: Hit[];
    totalHits: number;
    filters?: Filter[];
    filterMessages?: ComponentProps<typeof FiltersDrawer>['collectionFilterMessages'];
    offset?: number;
    sortFilterItems?: ComponentProps<typeof FiltersDrawer>['sortFilterItems'];
    breadcrumbs: ReactNode;
    toggle?: ReactNode;
    meetingRequestLink?: string;
    heroBackgroundColor?: string;
    hideSearch?: boolean;
}

const getPageTitle = (collectionPath: Collection, pageTitle: string): string => {
    switch (collectionPath) {
        case 'doclab':
            return 'IDFA Doclab';
        case 'bertha-fund':
            return 'IDFA Bertha Fund';
        case 'forum':
            return 'IDFA Forum';
        case 'project-space':
            return 'IDFA Project Space';
        case 'docs-for-sale':
            return 'IDFA Docs for Sale';
        case 'docschool':
            return 'IDFA Docschool Online';
        default:
            return pageTitle;
    }
};

const CollectionOverviewTemplate: FC<Props> = ({
    collectionPath,
    pageTitle,
    pageIntroText,
    pageOpenGraphImages,
    queryParams,
    isError,
    isFetching,
    totalPages,
    hits,
    totalHits,
    filters,
    offset,
    breadcrumbs,
    sortFilterItems,
    filterMessages,
    toggle,
    meetingRequestLink,
    heroBackgroundColor,
    hideSearch,
    ...rest
}) => {
    const searchState = useSearchState(queryParams);
    const { query, setQuery, page, setPage, queryFilters, hasQueryFilters, inputRef } = searchState;
    const { formatMessage } = useIntl();
    const filtersDrawerProps = useDisclosure();
    const filterModalProps = useDisclosure();
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

    const onModal = useCallback(
        (filter: Filter) => {
            setSelectedFilter(filter);
            filterModalProps.onOpen();
        },
        [filterModalProps]
    );
    const [debouncedQuery] = useDebounce(query, 400);

    useEffect(() => {
        if (debouncedQuery !== '') {
            gtm.event('search_collection', {
                search_term: debouncedQuery,
                collection: collectionPath,
            });
        }
    }, [debouncedQuery, collectionPath]);

    return (
        <>
            <Box bgColor={heroBackgroundColor}>
                <Hero topChildren={breadcrumbs} w={null} m={0} mb={[0, null, -2]} maxW="unset">
                    <Heading mt={['72px', null, '112px']} variant={1} as="h1">
                        {pageTitle}
                    </Heading>
                    <Box mt={[4, null, 7]}>
                        <HtmlContent
                            html={pageIntroText}
                            override={{
                                p: ({ children }) => <Intro>{children}</Intro>,
                            }}
                        />
                    </Box>
                </Hero>
            </Box>
            <Wrapper {...rest}>
                <NextSeo
                    title={getPageTitle(collectionPath, pageTitle)}
                    description={striptags(pageIntroText)}
                    openGraph={{
                        images: pageOpenGraphImages,
                    }}
                    titleTemplate={collectionPath !== 'docschool' ? '%s | Explore the Collection' : ''}
                />

                <HStack mb={[0, null, 11]} spacing={6} alignItems="start">
                    {!hideSearch && (
                        <Box flexGrow={1}>
                            <SearchInput
                                placeholder={formatMessage({
                                    defaultMessage: 'Zoek door de collectie',
                                })}
                                value={query}
                                onChange={e => {
                                    inputRef.current.value = e.currentTarget.value;
                                    setQuery(e.currentTarget.value);
                                }}
                                onClear={() => setQuery('')}
                                ref={inputRef}
                            />
                        </Box>
                    )}

                    {filters?.length > 0 && (
                        <>
                            <IconButton
                                display={['flex', null, 'none']}
                                aria-label={formatMessage({
                                    id: 'AR/YrY',
                                    defaultMessage: 'Show filters',
                                })}
                                onClick={filtersDrawerProps.onOpen}
                                icon={<StyledIconFilters />}
                            />
                            <Button
                                display={['none', null, 'flex']}
                                onClick={filtersDrawerProps.onOpen}
                                rightIcon={<StyledIconFilters />}
                            >
                                {formatMessage({
                                    id: 'AR/YrY',
                                    defaultMessage: 'Show filters',
                                })}
                            </Button>
                        </>
                    )}
                </HStack>
                <Filters.Selected queryParams={queryParams} collectionPath={collectionPath} />

                {!isError ? (
                    <>
                        <Flex
                            direction={['column-reverse', null, 'row']}
                            gap={[5]}
                            my={[5, null, 4]}
                            justifyContent="space-between"
                            alignItems={['stretch', null, 'center']}
                        >
                            <Body>
                                {`${(page - 1) * SEARCH_RESULTS_AMOUNT + 1}-${
                                    page * SEARCH_RESULTS_AMOUNT > totalHits ? totalHits : page * SEARCH_RESULTS_AMOUNT
                                }`}{' '}
                                <FormattedMessage defaultMessage="of" id="of" />{' '}
                                <Skeleton as="span" isLoaded={!isFetching}>
                                    <span>{totalHits}</span>
                                </Skeleton>{' '}
                                <FormattedMessage
                                    defaultMessage="{count, plural, =0 {resultaten} one {resultaat} other {resultaten}}"
                                    id="results"
                                    values={{ count: totalHits }}
                                />
                            </Body>
                            {toggle}
                        </Flex>
                        <SearchResults
                            queryParams={queryParams}
                            isLoading={isFetching}
                            HitComponent={SearchHit}
                            getId={hit => hit.id}
                            hits={hits}
                            hasFilters={hasQueryFilters}
                            offset={offset}
                            collectionPath={collectionPath}
                        />
                    </>
                ) : (
                    <ConfirmationTile maxWidth="100%" icon={<IconWarn />}>
                        <Heading variant={4}>
                            <FormattedMessage defaultMessage="Er is iets mis gegaan" />
                        </Heading>
                        <Body>
                            <FormattedMessage defaultMessage="Probeer het later opnieuw" />
                        </Body>
                    </ConfirmationTile>
                )}
                {totalPages > 1 && (
                    <Flex justifyContent="center" my={[5, null, 9]}>
                        <Pagination
                            currentPage={page - 1}
                            setCurrentPage={(p: number) => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                });
                                setPage(p + 1);
                            }}
                            totalPages={totalPages}
                        />
                    </Flex>
                )}

                <FiltersDrawer
                    {...filtersDrawerProps}
                    queryParams={queryParams}
                    collectionFilterMessages={filterMessages}
                    filters={filters}
                    onModal={onModal}
                    totalHits={totalHits}
                    sortFilterItems={sortFilterItems}
                    onApplyFiltersEvent={() => {
                        for (const queryFilter of Object.keys(queryFilters)) {
                            if (queryFilters[queryFilter].length > 0) {
                                gtm.event('apply_filter', {
                                    filter_name: queryFilter,
                                    filter_value: queryFilters[queryFilter].join(', '),
                                    collection: 'archive',
                                });
                            }
                        }
                    }}
                    closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                    modal={
                        <FilterModal
                            filter={selectedFilter}
                            filterMessages={filterMessages}
                            queryParams={queryParams}
                            sortFilterItems={sortFilterItems}
                            collection={collectionPath}
                            {...filterModalProps}
                            closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                        />
                    }
                />
            </Wrapper>
        </>
    );
};

const StyledIconFilters = styled(IconFilters)`
    // chakra overrides the width/height which is very cool and not annoying - so that's why this is needed.
    width: ${({ theme }) => theme.sizes[5]} !important;
    height: ${({ theme }) => theme.sizes[5]} !important;
`;

export default CollectionOverviewTemplate;
