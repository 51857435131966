import { EditionType } from '../constants/editionTypes';

const mapEditionTypeToTitle = new Map<EditionType, string>([
    ['IDFA Fund', 'IDFA Bertha Fund'],
    ['IDFAcademy', 'IDFA Project Space'],
]);

const getTitleFromEditionType = (type: EditionType, hasEditionYear = false) => {
    if (hasEditionYear) {
        const editionTitleTextArray = type.split(' ');
        const editionArrayLength = editionTitleTextArray.length;
        const editionYear = Number(editionTitleTextArray[editionArrayLength - 1]);

        let foundType = '';
        const [firstWord] = editionTitleTextArray;

        if (editionArrayLength > 2) {
            foundType = editionTitleTextArray.slice(0, -1).join(' '); // remove year and join back to string
        } else if (editionArrayLength === 2) {
            foundType = firstWord;
        } else {
            foundType = type;
        }

        const translatedEdtion = mapEditionTypeToTitle.get(foundType as EditionType);
        return translatedEdtion ? `${translatedEdtion} ${editionYear}` : type;
    }
    return mapEditionTypeToTitle.get(type) ?? type;
};

export default getTitleFromEditionType;
