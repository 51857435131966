import { FC } from 'react';
import { Body, Box, chakra, ChakraGrid, Heading, Img, TextButton } from 'designsystem';
import { useGetCmsImageProps } from 'shared';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useStrandsQuery } from '../../gql/cms';
import { FormattedMessage } from 'react-intl';
import siteHandleByLocale from '../../utils/siteHandleByLocale';
import { useRouter } from 'next/router';

const CinemaStrands: FC = () => {
    const { locale } = useRouter();
    const { data } = useStrandsQuery({ site: siteHandleByLocale[locale] });
    const strands = data?.instituteStrandsEntries;
    const getImgProps = useGetCmsImageProps();

    return (
        data && (
            <ChakraGrid gridTemplateColumns={['1fr', null, 'repeat(3, 1fr)']} gridGap={[6, null, 9]}>
                {strands.map(strand => (
                    <ChakraLink
                        href={`/${strand.uri}`}
                        key={strand.id}
                        aspectRatio="3 / 2"
                        display="flex"
                        alignItems="flex-end"
                        p={6}
                        position="relative"
                        textDecoration="none"
                    >
                        <Background pos="absolute" top={0} right={0} bottom={0} left={0} zIndex={0}>
                            <Img
                                style={{ objectFit: 'cover' }}
                                fill
                                {...getImgProps(strand.headerImage[0], strand.title)}
                            />
                        </Background>
                        <Box zIndex={10}>
                            <Heading variant={4} color="neutralwhite." mb={2}>
                                {strand.title}
                            </Heading>
                            <Box color="neutralwhite.">
                                <TextButton as="button">
                                    <Body fontSize={4}>
                                        <FormattedMessage defaultMessage="Bekijk strand" id="strand.cta" />
                                    </Body>
                                </TextButton>
                            </Box>
                        </Box>
                    </ChakraLink>
                ))}
            </ChakraGrid>
        )
    );
};

export default CinemaStrands;

const ChakraLink = chakra(Link);

const Background = styled(Box)(
    ({ theme }) => `
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(transparent, ${theme.tokens.ColorNeutralBlack});
        z-index: 1;
        opacity: 0.3333;
    }
`
);
