import React, { FC } from 'react';
import { Body, Box, Button, CookieDrawer, Heading, HStack, Stack, useDisclosure } from 'designsystem';
import { FormattedMessage } from 'react-intl';

interface Props extends ReturnType<typeof useDisclosure> {
    onConsent?: () => void;
    onReject?: () => void;
}

const CookieConsent: FC<Props> = ({ onConsent, onReject, ...rest }) => (
    <CookieDrawer {...rest}>
        <Stack direction={['column', null, null, 'row']} spacing={[6]}>
            <Box>
                <Heading variant={3} mb={4}>
                    <FormattedMessage defaultMessage="This website uses cookies" />
                </Heading>
                <Body>
                    <FormattedMessage defaultMessage="We use cookies to see how people use our website and make it better for them." />
                    <br />
                    <FormattedMessage
                        defaultMessage="Cookies also help us show personalized ads. You can <a>read</a>. our policy on cookies. If you say no, we only use cookies that help the website work and analyze how it's used."
                        values={{
                            a: children => (
                                <a tabIndex={-1} href="/organisation/contact/privacy/">
                                    {children}
                                </a>
                            ),
                        }}
                    />
                </Body>
            </Box>
            <Box>
                <HStack spacing={4}>
                    {/* id attr is needed to track btn clicks in GA */}
                    <Button variant="outline" tabIndex={-1} onClick={onReject} id="cookies-decline">
                        <FormattedMessage defaultMessage="Decline" />
                    </Button>
                    <Button variant="solidOnDark" onClick={onConsent} id="cookies-accept">
                        <FormattedMessage defaultMessage="Accept cookies" />
                    </Button>
                </HStack>
            </Box>
        </Stack>
    </CookieDrawer>
);

export default CookieConsent;
