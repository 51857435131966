interface PageViewParams {
    page_pillar: 'institute' | 'professionals' | 'festival' | 'collection';
    page_navigation?: string;
    page_language?: string;
    film_name?: string;
    film_id?: string;
    film_edition?: string;
    film_section?: string;
    film_subject?: string;
}

export const pageView = (url: string, params: PageViewParams) => {
    window.dataLayer.push({
        event: 'page_loaded',
        page_url: url,
        ...params,
    });
};

export const event = (name: string, params: Record<string, string | number>) => {
    window.dataLayer.push({
        event: name,
        ...params,
    });
};

export default {
    pageView,
    event,
};
