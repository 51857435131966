import { Drawer as ChakraDrawer, DrawerBody, DrawerContent, DrawerProps as ChakraDrawerProps } from '@chakra-ui/react';
import { FC } from 'react';
import { Container } from '../../index';

type DrawerProps = ChakraDrawerProps;

const CookieDrawer: FC<DrawerProps> = ({ children, ...rest }) => (
    <ChakraDrawer {...rest} variant="cookie" placement="bottom" blockScrollOnMount={false}>
        <DrawerContent>
            <DrawerBody
                sx={{
                    a: {
                        color: 'white',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        _hover: {
                            color: 'white',
                            textDecoration: 'underline',
                        },
                    },
                }}
            >
                <Container p={0}>{children}</Container>
            </DrawerBody>
        </DrawerContent>
    </ChakraDrawer>
);

export default CookieDrawer;
