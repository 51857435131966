import React, { HTMLAttributes } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const IconUser: React.FC<HTMLAttributes<HTMLOrSVGElement> & BoxProps> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box as="svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.8 6.9091C16.8 9.62032 14.651 11.8182 12 11.8182C9.34903 11.8182 7.2 9.62032 7.2 6.9091C7.2 4.19788 9.34903 2 12 2C14.651 2 16.8 4.19788 16.8 6.9091Z"
            fill="currentColor"
        />
        <path
            d="M12 11.8182C7.58172 11.8182 4 15.4813 4 20H20C20 15.4813 16.4183 11.8182 12 11.8182Z"
            fill="currentColor"
        />
    </Box>
);

export default IconUser;
