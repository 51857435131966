import { useEffect, useMemo, useRef } from 'react';
import {
    ArchiveFilterEnum,
    useSearchArchiveCountsQuery,
    useSearchArchiveFiltersQuery,
    useSearchArchiveHitsQuery,
} from '../gql/api';
import { ArchiveFilterQueryParams, SEARCH_RESULTS_AMOUNT } from '../components/pages/ArchivePage';
import { SearchState } from 'shared';
import { useRouter } from 'next/router';
import { useQueryParam } from 'use-query-params';

// this hook attempts to normalize all search related data (which consists of various separate backend calls), into a single result.
const useArchiveSearch = (searchState: SearchState<typeof ArchiveFilterQueryParams>) => {
    const { debouncedQuery, isSearching, queryFilters, page } = searchState;
    const { locale } = useRouter();
    const prevLocale = useRef(locale);
    const countsResult = useSearchArchiveCountsQuery();
    const [archiveTypeFilter, setArchiveTypeFilter] = useQueryParam('ARCHIVE_TYPE');
    const offset = (page - 1) * SEARCH_RESULTS_AMOUNT;
    const hitsResult = useSearchArchiveHitsQuery(
        {
            query: debouncedQuery,
            filters: (Object.entries(queryFilters) as [ArchiveFilterEnum, string[]][])
                .filter(([, value]) => value.length > 0) // remove empty filters
                .map(([key, value]) => ({
                    key,
                    value,
                })),
            offset,
            limit: SEARCH_RESULTS_AMOUNT,
        },
        { enabled: isSearching, keepPreviousData: true }
    );
    const filtersResult = useSearchArchiveFiltersQuery(
        {
            filters:
                queryFilters[ArchiveFilterEnum.ArchiveType].length > 0
                    ? [
                          {
                              key: ArchiveFilterEnum.ArchiveType,
                              value: queryFilters[ArchiveFilterEnum.ArchiveType],
                          },
                      ]
                    : [],
        },
        { enabled: isSearching, keepPreviousData: true }
    );
    const { refetch } = filtersResult;
    useEffect(() => {
        // sync archive type person filter to locale
        if (archiveTypeFilter === 'Person' && locale === 'nl') {
            setArchiveTypeFilter('Persoon');
        } else if (archiveTypeFilter === 'Persoon' && locale === 'en') {
            setArchiveTypeFilter('Person');
        }
    }, [locale, archiveTypeFilter, setArchiveTypeFilter]);

    useEffect(() => {
        if (prevLocale.current !== locale && refetch) {
            // refetch filters on lang switch
            refetch();
            prevLocale.current = locale;
        }
    }, [locale, prevLocale, refetch]);

    const totalPages = useMemo(
        () => Math.ceil((hitsResult.data?.searchArchive?.totalHits ?? 0) / SEARCH_RESULTS_AMOUNT),
        [hitsResult.data?.searchArchive?.totalHits]
    );

    const totalHits = useMemo(() => {
        if (!isSearching) {
            return countsResult.data?.searchArchive?.totalHits;
        }
        return hitsResult.data?.searchArchive?.totalHits ?? countsResult.data?.searchArchive?.totalHits;
    }, [countsResult.data?.searchArchive?.totalHits, hitsResult.data?.searchArchive?.totalHits, isSearching]);

    const filters = useMemo(() => {
        if (!isSearching) {
            return countsResult.data?.searchArchive?.filters;
        }
        return filtersResult.data?.searchArchive?.filters ?? countsResult.data?.searchArchive?.filters;
    }, [countsResult.data?.searchArchive?.filters, filtersResult.data?.searchArchive?.filters, isSearching]);

    return {
        totalPages,
        totalHits,
        filters,
        offset,
        hits: hitsResult.data?.searchArchive?.hits,
        isError: isSearching ? hitsResult.isError || filtersResult.isError : countsResult.isError,
        isFetching: isSearching ? hitsResult.isFetching || filtersResult.isFetching : countsResult.isFetching,
    };
};

export default useArchiveSearch;
