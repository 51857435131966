/* eslint-disable */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useGlobal } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export enum GroupTypeEnum {
    Festival = 'FESTIVAL',
    Institute = 'INSTITUTE',
    Professional = 'PROFESSIONAL',
}

export enum ImageFormatEnum {
    Jpeg = 'JPEG',
    Webp = 'WEBP',
}

export type SearchGlobalFilterType = {
    group?: InputMaybe<GroupTypeEnum>;
    pageTypes?: InputMaybe<Array<InputMaybe<SearchGlobalPageTypeEnum>>>;
};

export enum SearchGlobalPageTypeEnum {
    Film = 'FILM',
    Information = 'INFORMATION',
    News = 'NEWS',
    Program = 'PROGRAM',
}

export enum TicketAvailabilityStatusEnum {
    HighAvailability = 'HIGH_AVAILABILITY',
    LowAvailability = 'LOW_AVAILABILITY',
    SoldOut = 'SOLD_OUT',
}

export type SearchGlobalQueryVariables = Exact<{
    query?: InputMaybe<Scalars['String']>;
    limit: Scalars['Int'];
    offset: Scalars['Int'];
    filters: SearchGlobalFilterType;
}>;

export type SearchGlobalQuery = {
    __typename?: 'SearchGlobalQuery';
    searchGlobal: {
        __typename?: 'SearchGlobalResult';
        totalHits: number;
        hits: Array<
            | {
                  __typename: 'Composition';
                  id: string;
                  fullTitle?: string | null;
                  group: string;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                      stills?: Array<{
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      }> | null;
                  } | null;
              }
            | {
                  __typename: 'EditionSection';
                  id: string;
                  name?: string | null;
                  group: string;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                      stills?: Array<{
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      }> | null;
                  } | null;
              }
            | {
                  __typename: 'Film';
                  id: string;
                  fullPreferredTitle?: string | null;
                  group: string;
                  sortedTitle?: string | null;
                  logline?: string | null;
                  docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
                  edition?: {
                      __typename?: 'Edition';
                      id: string;
                      name: string;
                      year: number;
                      editionType: { __typename?: 'EditionType'; id: string; description: string };
                  } | null;
                  publications?: {
                      __typename?: 'PublicationCollection';
                      favoriteImage?: {
                          __typename?: 'Image';
                          blurHash?: string | null;
                          webp?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                          jpeg?: Array<{
                              __typename?: 'ImageTransform';
                              url: string;
                              width: number;
                              height: number;
                          }> | null;
                      } | null;
                  } | null;
              }
            | {
                  __typename: 'Page';
                  id: string;
                  title: string;
                  pageType: string;
                  introText?: string | null;
                  group: string;
                  url: string;
                  breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
                  thumbnail?: {
                      __typename?: 'Image';
                      blurHash?: string | null;
                      webp?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                      jpeg?: Array<{
                          __typename?: 'ImageTransform';
                          url: string;
                          width: number;
                          height: number;
                      }> | null;
                  } | null;
              }
        >;
    };
};

export type PageHitFragment = {
    __typename: 'Page';
    id: string;
    title: string;
    pageType: string;
    introText?: string | null;
    group: string;
    url: string;
    breadcrumbs?: Array<{ __typename?: 'Link'; title: string; value: string }> | null;
    thumbnail?: {
        __typename?: 'Image';
        blurHash?: string | null;
        webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    } | null;
};

export type FilmHitFragment = {
    __typename: 'Film';
    id: string;
    fullPreferredTitle?: string | null;
    group: string;
    sortedTitle?: string | null;
    logline?: string | null;
    docschoolOneliner?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    edition?: {
        __typename?: 'Edition';
        id: string;
        name: string;
        year: number;
        editionType: { __typename?: 'EditionType'; id: string; description: string };
    } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
    } | null;
};

export type DefaultEditionFragment = {
    __typename?: 'Edition';
    id: string;
    name: string;
    year: number;
    editionType: { __typename?: 'EditionType'; id: string; description: string };
};

export type DefaultApiImageFragment = {
    __typename?: 'Image';
    blurHash?: string | null;
    webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
    jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
};

export type CompositionHitFragment = {
    __typename: 'Composition';
    id: string;
    fullTitle?: string | null;
    group: string;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    intro?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
    } | null;
};

export type EditionSectionHitFragment = {
    __typename: 'EditionSection';
    id: string;
    name?: string | null;
    group: string;
    description?: { __typename?: 'LookupValue'; translation?: string | null } | null;
    publications?: {
        __typename?: 'PublicationCollection';
        favoriteImage?: {
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        } | null;
        stills?: Array<{
            __typename?: 'Image';
            blurHash?: string | null;
            webp?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
            jpeg?: Array<{ __typename?: 'ImageTransform'; url: string; width: number; height: number }> | null;
        }> | null;
    } | null;
};

export const DefaultApiImageFragmentDoc = `
    fragment DefaultApiImage on Image {
  webp: transforms(format: WEBP) {
    url
    width
    height
  }
  jpeg: transforms(format: JPEG) {
    url
    width
    height
  }
  blurHash
}
    `;
export const PageHitFragmentDoc = `
    fragment PageHit on Page {
  id
  __typename
  title
  pageType
  introText
  group
  url
  breadcrumbs {
    title
    value
  }
  thumbnail {
    ...DefaultApiImage
  }
}
    `;
export const DefaultEditionFragmentDoc = `
    fragment DefaultEdition on Edition {
  id
  name
  year
  editionType {
    id
    description
  }
}
    `;
export const FilmHitFragmentDoc = `
    fragment FilmHit on Film {
  __typename
  id
  fullPreferredTitle
  group
  sortedTitle
  docschoolOneliner {
    translation
  }
  logline
  description {
    translation
  }
  intro {
    translation
  }
  edition {
    ...DefaultEdition
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
  }
}
    `;
export const CompositionHitFragmentDoc = `
    fragment CompositionHit on Composition {
  __typename
  id
  fullTitle
  group
  description {
    translation
  }
  intro {
    translation
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
  }
}
    `;
export const EditionSectionHitFragmentDoc = `
    fragment EditionSectionHit on EditionSection {
  __typename
  id
  name
  group
  description {
    translation
  }
  publications {
    favoriteImage {
      ...DefaultApiImage
    }
    stills {
      ...DefaultApiImage
    }
  }
}
    `;
export const SearchGlobalDocument = `
    query searchGlobal($query: String, $limit: Int!, $offset: Int!, $filters: SearchGlobalFilterType!) {
  searchGlobal(query: $query, offset: $offset, filters: $filters, limit: $limit) {
    totalHits
    hits {
      ...PageHit
      ...FilmHit
      ...CompositionHit
      ...EditionSectionHit
    }
  }
}
    ${PageHitFragmentDoc}
${DefaultApiImageFragmentDoc}
${FilmHitFragmentDoc}
${DefaultEditionFragmentDoc}
${CompositionHitFragmentDoc}
${EditionSectionHitFragmentDoc}`;
export const useSearchGlobalQuery = <TData = SearchGlobalQuery, TError = unknown>(
    variables: SearchGlobalQueryVariables,
    options?: UseQueryOptions<SearchGlobalQuery, TError, TData>
) =>
    useQuery<SearchGlobalQuery, TError, TData>(
        ['searchGlobal', variables],
        useGlobal<SearchGlobalQuery, SearchGlobalQueryVariables>(SearchGlobalDocument).bind(null, variables),
        options
    );
useSearchGlobalQuery.document = SearchGlobalDocument;

useSearchGlobalQuery.getKey = (variables: SearchGlobalQueryVariables) => ['searchGlobal', variables];
