/**
 * Do not edit directly
 * Generated on Fri, 20 Oct 2023 14:33:20 GMT
 */

export const ButtonPrimaryEnabledBackground = 'var(--button-primary-enabled-background)';
export const ButtonPrimaryEnabledForeground = 'var(--button-primary-enabled-foreground)';
export const ButtonPrimaryHoverBackground = 'var(--button-primary-hover-background)';
export const ButtonPrimaryHoverForeground = 'var(--button-primary-hover-foreground)';
export const ButtonPrimaryFocusBackground = 'var(--button-primary-focus-background)';
export const ButtonPrimaryFocusForeground = 'var(--button-primary-focus-foreground)';
export const ButtonPrimaryActiveBackground = 'var(--button-primary-active-background)';
export const ButtonPrimaryActiveForeground = 'var(--button-primary-active-foreground)';
export const ButtonPrimaryDisabledBackground = 'var(--button-primary-disabled-background)';
export const ButtonPrimaryDisabledForeground = 'var(--button-primary-disabled-foreground)';
export const CardPrimaryBackground = 'var(--card-primary-background)';
export const CardPrimaryForeground = 'var(--card-primary-foreground)';
export const CardNeutralBackground = 'var(--card-neutral-background)';
export const CardNeutralForeground = 'var(--card-neutral-foreground)';
export const CardWhiteBackground = 'var(--card-white-background)';
export const CardWhiteForeground = 'var(--card-white-foreground)';
export const LinkEnabledForeground = 'var(--link-enabled-foreground)';
export const LinkHoverForeground = 'var(--link-hover-foreground)';
export const PartnersBackground = 'var(--partners-background)';
export const PartnersForeground = 'var(--partners-foreground)';
export const TextButtonForeground = 'var(--text-button-foreground)';
export const TextButtonLFontSize = 'var(--text-button-l-font-size)';
export const TextButtonMFontSize = 'var(--text-button-m-font-size)';
export const TextButtonSFontSize = 'var(--text-button-s-font-size)';
export const TilePrimaryBackground = 'var(--tile-primary-background)';
export const TilePrimaryForeground = 'var(--tile-primary-foreground)';
export const TileNeutralBackground = 'var(--tile-neutral-background)';
export const TileNeutralForeground = 'var(--tile-neutral-foreground)';
export const BorderColorPrimaryDefault = 'var(--border-color-primary-default)';
export const BorderColorPrimaryLighter = 'var(--border-color-primary-lighter)';
export const BorderColorPrimaryLight = 'var(--border-color-primary-light)';
export const BorderColorNeutralDefault = 'var(--border-color-neutral-default)';
export const BorderColorNeutralDarkest = 'var(--border-color-neutral-darkest)';
export const BorderRadiusL = 'var(--border-radius-l)';
export const BorderWidthS = 'var(--border-width-s)';
export const BorderWidthM = 'var(--border-width-m)';
export const BorderWidthL = 'var(--border-width-l)';
export const BorderWidthXl = 'var(--border-width-xl)';
export const BorderWidthXxl = 'var(--border-width-xxl)';
export const ColorYellow40 = 'var(--color-yellow-40)';
export const ColorYellow50 = 'var(--color-yellow-50)';
export const ColorYellow60 = 'var(--color-yellow-60)';
export const ColorYellow70 = 'var(--color-yellow-70)';
export const ColorYellow80 = 'var(--color-yellow-80)';
export const ColorYellow90 = 'var(--color-yellow-90)';
export const ColorLightPurple40 = 'var(--color-light-purple-40)';
export const ColorLightPurple50 = 'var(--color-light-purple-50)';
export const ColorLightPurple60 = 'var(--color-light-purple-60)';
export const ColorLightPurple70 = 'var(--color-light-purple-70)';
export const ColorLightPurple80 = 'var(--color-light-purple-80)';
export const ColorLightPurple90 = 'var(--color-light-purple-90)';
export const ColorLimeGreen40 = 'var(--color-lime-green-40)';
export const ColorLimeGreen50 = 'var(--color-lime-green-50)';
export const ColorLimeGreen60 = 'var(--color-lime-green-60)';
export const ColorLimeGreen70 = 'var(--color-lime-green-70)';
export const ColorLimeGreen80 = 'var(--color-lime-green-80)';
export const ColorLimeGreen90 = 'var(--color-lime-green-90)';
export const ColorRed40 = 'var(--color-red-40)';
export const ColorRed50 = 'var(--color-red-50)';
export const ColorRed60 = 'var(--color-red-60)';
export const ColorRed70 = 'var(--color-red-70)';
export const ColorRed80 = 'var(--color-red-80)';
export const ColorRed90 = 'var(--color-red-90)';
export const ColorGrey40 = 'var(--color-grey-40)';
export const ColorGrey50 = 'var(--color-grey-50)';
export const ColorGrey60 = 'var(--color-grey-60)';
export const ColorGrey70 = 'var(--color-grey-70)';
export const ColorGrey80 = 'var(--color-grey-80)';
export const ColorGrey90 = 'var(--color-grey-90)';
export const ColorDarkBlue40 = 'var(--color-dark-blue-40)';
export const ColorDarkBlue50 = 'var(--color-dark-blue-50)';
export const ColorDarkBlue60 = 'var(--color-dark-blue-60)';
export const ColorDarkBlue70 = 'var(--color-dark-blue-70)';
export const ColorDarkBlue80 = 'var(--color-dark-blue-80)';
export const ColorDarkBlue90 = 'var(--color-dark-blue-90)';
export const ColorNeutral10 = 'var(--color-neutral-10)';
export const ColorNeutral20 = 'var(--color-neutral-20)';
export const ColorNeutral30 = 'var(--color-neutral-30)';
export const ColorNeutral40 = 'var(--color-neutral-40)';
export const ColorNeutral50 = 'var(--color-neutral-50)';
export const ColorNeutral60 = 'var(--color-neutral-60)';
export const ColorNeutral70 = 'var(--color-neutral-70)';
export const ColorNeutral80 = 'var(--color-neutral-80)';
export const ColorNeutral90 = 'var(--color-neutral-90)';
export const ColorNeutral100 = 'var(--color-neutral-100)';
export const ColorNeutralWhite = 'var(--color-neutral-white)';
export const ColorNeutralBlack = 'var(--color-neutral-black)';
export const ColorPositive40 = 'var(--color-positive-40)';
export const ColorPositive50 = 'var(--color-positive-50)';
export const ColorPositive60 = 'var(--color-positive-60)';
export const ColorPositive70 = 'var(--color-positive-70)';
export const ColorPositive80 = 'var(--color-positive-80)';
export const ColorInformative40 = 'var(--color-informative-40)';
export const ColorInformative50 = 'var(--color-informative-50)';
export const ColorInformative60 = 'var(--color-informative-60)';
export const ColorInformative70 = 'var(--color-informative-70)';
export const ColorInformative80 = 'var(--color-informative-80)';
export const ColorNotice40 = 'var(--color-notice-40)';
export const ColorNotice50 = 'var(--color-notice-50)';
export const ColorNotice60 = 'var(--color-notice-60)';
export const ColorNotice70 = 'var(--color-notice-70)';
export const ColorNotice80 = 'var(--color-notice-80)';
export const ColorNegative40 = 'var(--color-negative-40)';
export const ColorNegative50 = 'var(--color-negative-50)';
export const ColorNegative60 = 'var(--color-negative-60)';
export const ColorNegative70 = 'var(--color-negative-70)';
export const ColorNegative80 = 'var(--color-negative-80)';
export const FontFamilySansSerif = 'var(--font-family-sans-serif)';
export const FontSize1 = 'var(--font-size-1)';
export const FontSize2 = 'var(--font-size-2)';
export const FontSize3 = 'var(--font-size-3)';
export const FontSize4 = 'var(--font-size-4)';
export const FontSize5 = 'var(--font-size-5)';
export const FontSize6 = 'var(--font-size-6)';
export const FontSize7 = 'var(--font-size-7)';
export const FontSize8 = 'var(--font-size-8)';
export const FontSize9 = 'var(--font-size-9)';
export const FontSize10 = 'var(--font-size-10)';
export const FontWeightXxl = 'var(--font-weight-xxl)';
export const FontWeightXl = 'var(--font-weight-xl)';
export const FontWeightL = 'var(--font-weight-l)';
export const FontWeightM = 'var(--font-weight-m)';
export const FontWeightS = 'var(--font-weight-s)';
export const LineHeightM = 'var(--line-height-m)';
export const LineHeightS = 'var(--line-height-s)';
export const LineHeightXs = 'var(--line-height-xs)';
export const LineHeightXxs = 'var(--line-height-xxs)';
export const MediaQueryXl = '1440px';
export const MediaQueryL = '1280px';
export const MediaQueryM = '1024px';
export const MediaQueryS = '768px';
export const MediaQueryXs = '480px';
export const Sizing1 = '4px';
export const Sizing2 = '8px';
export const Sizing3 = '12px';
export const Sizing4 = '16px';
export const Sizing5 = '20px';
export const Sizing6 = '24px';
export const Sizing7 = '32px';
export const Sizing8 = '40px';
export const Sizing9 = '48px';
export const Sizing10 = '56px';
export const Sizing11 = '64px';
export const Sizing12 = '72px';
export const Sizing13 = '80px';
export const Sizing14 = '96px';
export const Sizing15 = '104px';
export const Sizing16 = '128px';
export const Sizing17 = '160px';
export const Sizing18 = '192px';
export const Sizing19 = '224px';
export const Sizing20 = '256px';
export const SyntaxBackgroundNeutralDefault = 'var(--syntax-background-neutral-default)';
export const SyntaxBackgroundNeutralDark = 'var(--syntax-background-neutral-dark)';
export const SyntaxBackgroundNeutralDarkest = 'var(--syntax-background-neutral-darkest)';
export const SyntaxBackgroundPrimaryLightest = 'var(--syntax-background-primary-lightest)';
export const SyntaxBackgroundPrimaryLight = 'var(--syntax-background-primary-light)';
export const SyntaxBackgroundPrimaryDefault = 'var(--syntax-background-primary-default)';
export const SyntaxBackgroundPrimaryDark = 'var(--syntax-background-primary-dark)';
export const SyntaxBackgroundPrimaryDarker = 'var(--syntax-background-primary-darker)';
export const SyntaxBackgroundPrimaryDarkest = 'var(--syntax-background-primary-darkest)';
export const SyntaxBodyTextLRegularFontSize = 'var(--syntax-body-text-l-regular-font-size)';
export const SyntaxBodyTextLRegularLineHeight = 'var(--syntax-body-text-l-regular-line-height)';
export const SyntaxBodyTextLRegularFontFamily = 'var(--syntax-body-text-l-regular-font-family)';
export const SyntaxBodyTextLRegularFontWeight = 'var(--syntax-body-text-l-regular-font-weight)';
export const SyntaxBodyTextSRegularFontSize = 'var(--syntax-body-text-s-regular-font-size)';
export const SyntaxBodyTextSRegularLineHeight = 'var(--syntax-body-text-s-regular-line-height)';
export const SyntaxBodyTextSRegularFontFamily = 'var(--syntax-body-text-s-regular-font-family)';
export const SyntaxBodyTextSRegularFontWeight = 'var(--syntax-body-text-s-regular-font-weight)';
export const SyntaxColorActionEnabledBackground = 'var(--syntax-color-action-enabled-background)';
export const SyntaxColorActionEnabledForeground = 'var(--syntax-color-action-enabled-foreground)';
export const SyntaxColorActionFocusBackground = 'var(--syntax-color-action-focus-background)';
export const SyntaxColorActionFocusForeground = 'var(--syntax-color-action-focus-foreground)';
export const SyntaxColorActionHoverBackground = 'var(--syntax-color-action-hover-background)';
export const SyntaxColorActionHoverForeground = 'var(--syntax-color-action-hover-foreground)';
export const SyntaxColorActionActiveBackground = 'var(--syntax-color-action-active-background)';
export const SyntaxColorActionActiveForeground = 'var(--syntax-color-action-active-foreground)';
export const SyntaxColorInputEnabledBackground = 'var(--syntax-color-input-enabled-background)';
export const SyntaxColorInputEnabledBorder = 'var(--syntax-color-input-enabled-border)';
export const SyntaxColorInputFocusBackground = 'var(--syntax-color-input-focus-background)';
export const SyntaxColorInputFocusBorder = 'var(--syntax-color-input-focus-border)';
export const SyntaxColorInputHoverBackground = 'var(--syntax-color-input-hover-background)';
export const SyntaxColorInputHoverBorder = 'var(--syntax-color-input-hover-border)';
export const SyntaxColorInputActiveBackground = 'var(--syntax-color-input-active-background)';
export const SyntaxColorInputActiveBorder = 'var(--syntax-color-input-active-border)';
export const SyntaxColorInputInvalidBackground = 'var(--syntax-color-input-invalid-background)';
export const SyntaxColorInputInvalidBorder = 'var(--syntax-color-input-invalid-border)';
export const SyntaxColorInputDisabledBackground = 'var(--syntax-color-input-disabled-background)';
export const SyntaxColorInputDisabledBorder = 'var(--syntax-color-input-disabled-border)';
export const SyntaxColorDisabledBackground = 'var(--syntax-color-disabled-background)';
export const SyntaxColorDisabledForeground = 'var(--syntax-color-disabled-foreground)';
export const SyntaxColorErrorForeground = 'var(--syntax-color-error-foreground)';
export const SyntaxDisabledColorDefault = 'var(--syntax-disabled-color-default)';
export const SyntaxDisabledColorDark = 'var(--syntax-disabled-color-dark)';
export const SyntaxHeadingTextL1FontSize = 'var(--syntax-heading-text-l-1-font-size)';
export const SyntaxHeadingTextL1LineHeight = 'var(--syntax-heading-text-l-1-line-height)';
export const SyntaxHeadingTextL1FontFamily = 'var(--syntax-heading-text-l-1-font-family)';
export const SyntaxHeadingTextL1FontWeight = 'var(--syntax-heading-text-l-1-font-weight)';
export const SyntaxHeadingTextL2FontSize = 'var(--syntax-heading-text-l-2-font-size)';
export const SyntaxHeadingTextL2LineHeight = 'var(--syntax-heading-text-l-2-line-height)';
export const SyntaxHeadingTextL2FontFamily = 'var(--syntax-heading-text-l-2-font-family)';
export const SyntaxHeadingTextL2FontWeight = 'var(--syntax-heading-text-l-2-font-weight)';
export const SyntaxHeadingTextL3FontSize = 'var(--syntax-heading-text-l-3-font-size)';
export const SyntaxHeadingTextL3LineHeight = 'var(--syntax-heading-text-l-3-line-height)';
export const SyntaxHeadingTextL3FontFamily = 'var(--syntax-heading-text-l-3-font-family)';
export const SyntaxHeadingTextL3FontWeight = 'var(--syntax-heading-text-l-3-font-weight)';
export const SyntaxHeadingTextL4FontSize = 'var(--syntax-heading-text-l-4-font-size)';
export const SyntaxHeadingTextL4LineHeight = 'var(--syntax-heading-text-l-4-line-height)';
export const SyntaxHeadingTextL4FontFamily = 'var(--syntax-heading-text-l-4-font-family)';
export const SyntaxHeadingTextL4FontWeight = 'var(--syntax-heading-text-l-4-font-weight)';
export const SyntaxHeadingTextL5FontSize = 'var(--syntax-heading-text-l-5-font-size)';
export const SyntaxHeadingTextL5LineHeight = 'var(--syntax-heading-text-l-5-line-height)';
export const SyntaxHeadingTextL5FontFamily = 'var(--syntax-heading-text-l-5-font-family)';
export const SyntaxHeadingTextL5FontWeight = 'var(--syntax-heading-text-l-5-font-weight)';
export const SyntaxHeadingTextS1FontSize = 'var(--syntax-heading-text-s-1-font-size)';
export const SyntaxHeadingTextS1LineHeight = 'var(--syntax-heading-text-s-1-line-height)';
export const SyntaxHeadingTextS1FontFamily = 'var(--syntax-heading-text-s-1-font-family)';
export const SyntaxHeadingTextS1FontWeight = 'var(--syntax-heading-text-s-1-font-weight)';
export const SyntaxHeadingTextS2FontSize = 'var(--syntax-heading-text-s-2-font-size)';
export const SyntaxHeadingTextS2LineHeight = 'var(--syntax-heading-text-s-2-line-height)';
export const SyntaxHeadingTextS2FontFamily = 'var(--syntax-heading-text-s-2-font-family)';
export const SyntaxHeadingTextS2FontWeight = 'var(--syntax-heading-text-s-2-font-weight)';
export const SyntaxHeadingTextS3FontSize = 'var(--syntax-heading-text-s-3-font-size)';
export const SyntaxHeadingTextS3LineHeight = 'var(--syntax-heading-text-s-3-line-height)';
export const SyntaxHeadingTextS3FontFamily = 'var(--syntax-heading-text-s-3-font-family)';
export const SyntaxHeadingTextS3FontWeight = 'var(--syntax-heading-text-s-3-font-weight)';
export const SyntaxHeadingTextS4FontSize = 'var(--syntax-heading-text-s-4-font-size)';
export const SyntaxHeadingTextS4LineHeight = 'var(--syntax-heading-text-s-4-line-height)';
export const SyntaxHeadingTextS4FontFamily = 'var(--syntax-heading-text-s-4-font-family)';
export const SyntaxHeadingTextS4FontWeight = 'var(--syntax-heading-text-s-4-font-weight)';
export const SyntaxHeadingTextS5FontSize = 'var(--syntax-heading-text-s-5-font-size)';
export const SyntaxHeadingTextS5LineHeight = 'var(--syntax-heading-text-s-5-line-height)';
export const SyntaxHeadingTextS5FontFamily = 'var(--syntax-heading-text-s-5-font-family)';
export const SyntaxHeadingTextS5FontWeight = 'var(--syntax-heading-text-s-5-font-weight)';
export const SyntaxOverlayColorDefault = 'var(--syntax-overlay-color-default)';
export const SyntaxOverlayColorDarkest = 'var(--syntax-overlay-color-darkest)';
export const SyntaxPillarColorCollectionDefault = 'var(--syntax-pillar-color-collection-default)';
export const SyntaxPillarColorCollectionLighter = 'var(--syntax-pillar-color-collection-lighter)';
export const SyntaxPillarColorFestivalDefault = 'var(--syntax-pillar-color-festival-default)';
export const SyntaxPillarColorFestivalLighter = 'var(--syntax-pillar-color-festival-lighter)';
export const SyntaxPillarColorInstituteDefault = 'var(--syntax-pillar-color-institute-default)';
export const SyntaxPillarColorInstituteLighter = 'var(--syntax-pillar-color-institute-lighter)';
export const SyntaxPillarColorProfessionalsDefault = 'var(--syntax-pillar-color-professionals-default)';
export const SyntaxPillarColorProfessionalsLighter = 'var(--syntax-pillar-color-professionals-lighter)';
export const SyntaxPrimaryColorLight = 'var(--syntax-primary-color-light)';
export const SyntaxPrimaryColorLighter = 'var(--syntax-primary-color-lighter)';
export const SyntaxPrimaryColorDefault = 'var(--syntax-primary-color-default)';
export const SyntaxPrimaryColorDarker = 'var(--syntax-primary-color-darker)';
export const SyntaxPrimaryColorDark = 'var(--syntax-primary-color-dark)';
export const SyntaxPrimaryColorDarkest = 'var(--syntax-primary-color-darkest)';
export const SyntaxTextColorNeutralMuted = 'var(--syntax-text-color-neutral-muted)';
export const SyntaxTextColorNeutral = 'var(--syntax-text-color-neutral)';
export const SyntaxTextColorDefault = 'var(--syntax-text-color-default)';
export const SyntaxTextColorMuted = 'var(--syntax-text-color-muted)';
export const SyntaxTextColorOnDark = 'var(--syntax-text-color-on-dark)';
export const SyntaxTextColorOnPrimary = 'var(--syntax-text-color-on-primary)';
