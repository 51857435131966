import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type CheckboxTheme = Omit<MultiStyleConfig<typeof checkboxAnatomy>, 'parts'>;

/**
 * Theme for a Chakra Checkbox. Include this into your theme.
 */
const CheckboxTheming: CheckboxTheme = {
    baseStyle: ({ theme: T }) => ({
        container: {},
        control: {
            boxSizing: 'border-box',
            w: 6,
            h: 6,
            borderWidth: T.tokens.BorderWidthL,
            borderStyle: 'solid',
            borderColor: T.tokens.SyntaxColorInputEnabledBorder,
            bg: T.tokens.SyntaxColorInputEnabledBackground,
            color: T.tokens.SyntaxColorActionEnabledForeground,
            transition: `all 0.2s`,
            svg: {
                width: '100%',
                height: '100%',
            },
            _hover: {
                borderColor: T.tokens.SyntaxColorInputHoverBorder,
            },
            _focus: {
                borderColor: T.tokens.SyntaxColorInputFocusBorder,
                boxShadow: `0 0 0 ${T.tokens.Sizing1} ${T.tokens.BorderColorPrimaryLight}`,
                outline: 'none',
            },
            _checked: {
                borderColor: T.tokens.SyntaxColorInputActiveBorder,
                bg: T.tokens.SyntaxColorActionEnabledBackground,
            },
            _disabled: {
                bg: T.tokens.SyntaxBackgroundNeutralDefault,
                borderColor: T.tokens.SyntaxDisabledColorDefault,
                color: T.tokens.SyntaxDisabledColorDefault,
            },
        },
        icon: {
            w: 6,
            h: 6,
        },
        label: {
            fontFamily: 'inherit',
            fontSize: T.tokens.SyntaxBodyTextLRegularFontSize,
            lineHeight: 'xxs',
            ml: 4,
            _disabled: {
                color: T.tokens.SyntaxDisabledColorDark,
            },
        },
    }),
    sizes: {
        md: {},
    },
    defaultProps: {
        size: 'md',
    },
};

export default CheckboxTheming;
