import { useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { useCart } from '../components/contexts/cartContext';

export type PurchaseStatus = 'success' | 'failed';

export const usePurchaseStatus = () => {
    const [purchaseStatus, setPurchaseStatus] = useQueryParam<PurchaseStatus>('purchaseStatus');
    const { onOpenPurchaseSuccess, onOpenPurchaseFailed } = useCart();

    useEffect(() => {
        if (!purchaseStatus) {
            return;
        }
        if (purchaseStatus === 'success') {
            onOpenPurchaseSuccess();
        }
        if (purchaseStatus === 'failed') {
            onOpenPurchaseFailed();
        }
        setPurchaseStatus(undefined);
    }, [onOpenPurchaseFailed, onOpenPurchaseSuccess, purchaseStatus, setPurchaseStatus]);
};

export default usePurchaseStatus;
