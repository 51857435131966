import React, { ChangeEvent, HTMLProps, useMemo } from 'react';

import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import IconSearch from '../trinket/icon/IconSearch';
import Input, { Props as InputProps } from './Input';
import IconClose from '../trinket/icon/IconClose';
import { Required } from 'utility-types';
import Select from './Select';
import { Box } from '@chakra-ui/react';
import IconFilter from '../trinket/icon/IconFilter';

interface SearchInputProps extends Omit<InputProps, 'rightElement'> {
    onClear?: () => void;
    onFilterChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
    filterValue?: string;
    options?: Required<HTMLProps<HTMLOptionElement>, 'value'>[];
}
const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
    ({ value, onClear, onFilterChange, filterValue, options, defaultValue, ...rest }, ref) => {
        const filled = !!value || !!defaultValue;
        const theme = useTheme();
        const showSelect = useMemo(() => options && options.length > 0, [options]);
        return (
            <>
                <StyledInput
                    type="search"
                    leftElement={
                        filled ? (
                            <IconClose
                                tabIndex={0}
                                style={{ cursor: 'pointer' }}
                                onClick={onClear}
                                onKeyDown={e => e.key === 'Enter' || (e.key === 'Space' && onClear())}
                            />
                        ) : (
                            <IconSearch />
                        )
                    }
                    leftElementProps={!filled ? { style: { pointerEvents: 'none' } } : null}
                    rightAddon={
                        showSelect ? (
                            <Select
                                display={['none', null, 'block']}
                                borderRadius={`0 ${theme.tokens.BorderRadiusL} ${theme.tokens.BorderRadiusL} 0`}
                                onChange={onFilterChange}
                                defaultValue={filterValue}
                            >
                                {options.map(option => (
                                    <option key={option.value.toString()} {...option} />
                                ))}
                            </Select>
                        ) : undefined
                    }
                    value={value}
                    defaultValue={defaultValue}
                    borderRadius={
                        showSelect
                            ? [
                                  `${theme.tokens.BorderRadiusL} !important`,
                                  null,
                                  `${theme.tokens.BorderRadiusL} 0 0 ${theme.tokens.BorderRadiusL} !important`,
                              ]
                            : theme.tokens.BorderRadiusL
                    }
                    ref={ref}
                    {...rest}
                />
                {showSelect && (
                    <Box pos="relative" display={['block', null, 'none']}>
                        <Box pos="absolute" w={6} h={6} zIndex={1} top="50%" left={5} transform="translateY(-50%)">
                            <IconFilter />
                        </Box>
                        <Select
                            mt={2}
                            h={9}
                            borderRadius={theme.tokens.BorderRadiusL}
                            sx={{
                                pl: 10,
                            }}
                            onChange={onFilterChange}
                        >
                            {options.map(option => (
                                <option key={option.value.toString()} {...option} />
                            ))}
                        </Select>
                    </Box>
                )}
            </>
        );
    }
);

SearchInput.displayName = 'SearchInput';

const StyledInput = styled(Input)(
    ({ theme }) => css`
        height: ${theme.tokens.Sizing9};
        align-items: stretch;

        .chakra-input__left-element {
            left: ${theme.tokens.Sizing3};
        }

        input {
            height: 100%;
            padding-left: ${theme.tokens.Sizing10};
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
            }
        }

        .chakra-select__wrapper {
            height: 100%;
        }
        .chakra-select {
            height: 100%;
        }
    `
);

export default SearchInput;
