import { EditionType } from '../constants/editionTypes';
import { FilmOrProjectType } from '../constants/filmOrProjectTypes';

const mapEditionTypeTofilmOrProjectTypes = new Map<EditionType, FilmOrProjectType>([
    ['IDFA', 'idfa'],
    ['IDFA Fund', 'berthaFund'],
    ['IDFA Forum', 'forum'],
    ['IDFAcademy', 'projectSpace'],
]);

const getFilmOrProjectTypesFromEditionType = (type: string) =>
    mapEditionTypeTofilmOrProjectTypes.get(type as EditionType);

export default getFilmOrProjectTypesFromEditionType;
