import { Modal, ModalProps } from 'designsystem';
import { FormattedMessage } from 'react-intl';
import { Optional } from 'utility-types';

type Props = Optional<ModalProps, 'title' | 'closeLabel'>;

const TicketModal: React.FC<Props> = ({ children, ...rest }) => (
    <Modal
        title={<FormattedMessage defaultMessage="Tickets" description="Ticket modal title" />}
        closeLabel={<FormattedMessage defaultMessage="Sluiten" description="Close modal" />}
        variant="iframe"
        {...rest}
    >
        {rest.isOpen && children}
    </Modal>
);

export default TicketModal;
