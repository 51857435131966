import { useState, useCallback, useEffect } from 'react';

const useScrolled = (offset = 20) => {
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = useCallback(
        (e: Event) => {
            const window = e.currentTarget as Window;
            setScrolled(window.scrollY > offset);
        },
        [offset]
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return scrolled;
};

export default useScrolled;
