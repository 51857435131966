import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalProps } from 'designsystem';
import React, { FC } from 'react';
import useArchiveSearch from '../../hooks/useArchiveSearch';
import ArchiveFilters from './ArchiveFilters';
import { ArchiveFilterQueryParams } from '../pages/ArchivePage';
import { useRemoveQueryFilters } from 'shared';

type Filters = ReturnType<typeof useArchiveSearch>['filters'];
interface Props extends Omit<ModalProps, 'title' | 'children'> {
    filters: Filters;
    hasQueryFilters: boolean;
    totalHits: number;
    onModal: (selectedFilter: Filters[number]) => void;
}

const ArchiveFiltersModal: FC<Props> = ({ filters, hasQueryFilters, totalHits, onModal, ...rest }) => {
    const removeQueryFilters = useRemoveQueryFilters(ArchiveFilterQueryParams);

    return (
        <Modal
            title={<FormattedMessage defaultMessage="Filteren" />}
            actions={
                <>
                    <Button variant="outline" disabled={!hasQueryFilters} onClick={() => removeQueryFilters()}>
                        <FormattedMessage defaultMessage="Wis alles" />
                    </Button>
                    <Button onClick={rest.onClose}>
                        <FormattedMessage defaultMessage="Bekijk {amount}" values={{ amount: totalHits }} />
                    </Button>
                </>
            }
            {...rest}
            closeLabel={<FormattedMessage defaultMessage="Sluiten" description="Close modal" />}
        >
            <ArchiveFilters>
                <ArchiveFilters.SelectedFilters filters={filters} />
                <ArchiveFilters.Sections onModal={onModal} filters={filters} />
            </ArchiveFilters>
        </Modal>
    );
};

export default ArchiveFiltersModal;
