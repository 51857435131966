import { FC, HTMLAttributes } from 'react';

const IconLogout: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15 7V2H2V22H15V17" stroke="currentColor" strokeWidth="1.5" />
        <path d="M22 12H8" stroke="currentColor" strokeWidth="1.5" />
        <path d="M18 8L22 12L18 16" stroke="currentColor" strokeWidth="1.5" />
    </svg>
);

export default IconLogout;
