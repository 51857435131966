import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import { Stack, Text } from '@chakra-ui/react';

import Tile, { Props as TileProps } from './Tile';
import Body from '../typography/Body';
import { useTheme } from '@emotion/react';

interface Props extends PropsWithChildren<Omit<TileProps, 'title'>> {
    title: string;
    description?: ReactNode;
    buttons?: ReactNode;
}

const CtaWidget: FC<Props> = ({ title, description, buttons, children, ...rest }) => {
    const theme = useTheme();
    const longWord = useMemo(() => title?.split(' ').some(word => word.length >= 12), [title]);

    return (
        <Tile
            p={[8, null, '12.5%', null, null, '15%']}
            py={[9, 10]}
            justifyContent="center"
            sx={{ aspectRatio: [null, null, null, '1'] }}
            {...rest}
        >
            <Stack spacing={[7, null, null, 9]}>
                <Stack spacing={[5, null, null, 9]}>
                    <Text
                        as="h2"
                        fontWeight={theme.tokens.SyntaxHeadingTextL1FontWeight}
                        lineHeight={theme.tokens.SyntaxHeadingTextL1LineHeight}
                        fontSize={[8, null, 9, longWord ? 9 : theme.tokens.SyntaxHeadingTextL1FontSize]}
                    >
                        {title}
                    </Text>
                    {description && <Body>{description}</Body>}
                    {children}
                </Stack>
                {buttons && <Stack spacing={2}>{buttons}</Stack>}
            </Stack>
        </Tile>
    );
};

export default CtaWidget;
