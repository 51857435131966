import * as React from 'react';
import { SVGProps } from 'react';

const IconClock = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <g stroke="currentColor" strokeWidth={1.5}>
            <path d="M1.667 10a8.333 8.333 0 1 0 16.667 0 8.333 8.333 0 0 0-16.667 0Z" />
            <path d="M10 5v5h3.333" />
        </g>
    </svg>
);
export default IconClock;
