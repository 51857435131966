import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import Body from './Body';

const Intro: FC<PropsWithChildren & ComponentProps<typeof Body>> = ({ children, ...rest }) => (
    <Body as="p" fontSize={5} lineHeight="m" {...rest}>
        {children}
    </Body>
);

export default Intro;
