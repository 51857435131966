import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Image, { ImageProps } from 'next/image';
import React, { AnchorHTMLAttributes, useMemo } from 'react';
import IconCaret from '../trinket/icon/IconCaret';
import Body from '../typography/Body';
import Heading from '../typography/Heading';

type BaseProps = Omit<BoxProps, 'backgroundImage'> & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick'>;

interface TileWithoutBackground {
    variant: 'neutral' | 'pillar';
    backgroundImage?: never;
}

interface TileWithBackground {
    variant: 'background';
    backgroundImage: Omit<ImageProps, 'fill' | 'width' | 'height' | 'blurDataURL'>;
}

type Props = BaseProps &
    (TileWithBackground | TileWithoutBackground) & {
        title: string;
        description: string;
    };

const NavigationTile: React.FC<Props> = ({ title, description, variant = 'neutral', backgroundImage, ...rest }) => {
    const theme = useTheme();
    const [tileBackground, tileForeground] = useMemo(() => {
        switch (variant) {
            case 'pillar':
                return [theme.tokens.TilePrimaryBackground, theme.tokens.TilePrimaryForeground];
            case 'background':
                return [theme.tokens.SyntaxBackgroundNeutralDarkest, theme.tokens.SyntaxTextColorOnDark];
            case 'neutral':
            default:
                return [theme.tokens.TileNeutralBackground, theme.tokens.TileNeutralForeground];
        }
    }, [
        theme.tokens.SyntaxBackgroundNeutralDarkest,
        theme.tokens.SyntaxTextColorOnDark,
        theme.tokens.TileNeutralBackground,
        theme.tokens.TileNeutralForeground,
        theme.tokens.TilePrimaryBackground,
        theme.tokens.TilePrimaryForeground,
        variant,
    ]);

    return (
        <Tile
            display="flex"
            overflow="hidden"
            pos="relative"
            p={8}
            bg={tileBackground}
            color={tileForeground}
            textDecoration="none"
            alignItems="flex-end"
            width="100%"
            height="100%"
            boxSizing="border-box"
            sx={{
                '&:hover picture': { transform: 'scale(1.05)' },
                '& picture': { transition: 'transform 1400ms cubic-bezier(0.16, 1, 0.3, 1)' },
            }}
            {...rest}
        >
            {backgroundImage && (
                <Background pos="absolute" top={0} right={0} bottom={0} left={0} zIndex={0}>
                    <Image style={{ objectFit: 'cover' }} fill {...backgroundImage} />
                </Background>
            )}
            <Flex flexDir="row" alignItems="center" w="100%" zIndex={1}>
                <Stack spacing={4} flexGrow={1}>
                    <Heading variant={2}>{title}</Heading>
                    <Body fontWeight="xl">{description}</Body>
                </Stack>
                <Box boxSize={8}>
                    <IconCaret direction="right" />
                </Box>
            </Flex>
        </Tile>
    );
};

const Tile = styled.a<BaseProps>``.withComponent(Box);
Tile.defaultProps = {
    as: 'a',
};

const Background = styled(Box)(
    ({ theme }) => `
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(transparent, ${theme.tokens.ColorNeutralBlack});
        z-index: 1;
        opacity: 0.3333;
    }
`
);

export default NavigationTile;
