import { Box, Flex, Text } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import React from 'react';

interface Props {
    number: number;
}

const IconRank: React.FC<Props> = ({ number, ...rest }) => {
    const theme = useTheme();
    return (
        <Box {...rest}>
            <Flex
                w={6}
                h={6}
                borderRadius="100%"
                borderWidth={theme.tokens.BorderWidthXl}
                borderStyle="solid"
                justifyContent="center"
                alignItems="center"
            >
                <Text fontWeight="xl" lineHeight="xxs" textAlign="center">
                    {number}
                </Text>
            </Flex>
        </Box>
    );
};

export default IconRank;
