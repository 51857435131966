import {
    Body,
    Box,
    Button,
    Checkbox,
    FilterSectionExpandable,
    FilterSections,
    HStack,
    Modal,
    ModalProps,
    SearchInput,
} from 'designsystem';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { QueryParamConfig, useQueryParams } from 'use-query-params';
import groupBy from 'lodash.groupby';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import Fuse from 'fuse.js';
import orderBy from 'lodash.orderby';
import { Filter } from '../collections/Filters';
import gtm from '../../lib/gtm';

type QueryParamConfigMap = Record<string, QueryParamConfig<string[], string[]>>;
interface Props extends Omit<ModalProps, 'title' | 'children'> {
    filter?: {
        filter?: string;
        amounts?: Array<{
            key: string;
            amount: number;
        }>;
    };
    queryParams: QueryParamConfigMap;
    filterMessages: Record<string, MessageDescriptor>;
    sortFilterItems?: (filter: string, amounts: Filter['amounts']) => Filter['amounts'];
    collection?: string;
}

const FilterModal: FC<Props> = ({
    filter,
    queryParams,
    filterMessages,
    sortFilterItems = (filterKey, amounts) => {
        switch (filterKey) {
            default:
                return orderBy(amounts, amount => amount.key.toLowerCase());
        }
    },
    collection,
    ...rest
}) => {
    const [queryFilters, setQueryFilters] = useQueryParams(queryParams);
    const { formatMessage } = useIntl();

    // we keep a local copy of the queryFilters that we apply after the modal has been confirmed
    const [queryFiltersState, setQueryFiltersState] =
        useState<Partial<Record<keyof typeof queryParams, string[]>>>(queryFilters);

    const setQueryFilter = useCallback(
        (amountKey: string, value: boolean) =>
            setQueryFiltersState(latestValues => {
                const enabledFilters = latestValues[filter.filter] ?? [];
                return {
                    [filter.filter]: value
                        ? [...enabledFilters, amountKey]
                        : enabledFilters.filter(f => f !== amountKey),
                };
            }),
        [filter?.filter]
    );

    const fuse = useMemo(() => new Fuse(filter?.amounts, { keys: ['key'], threshold: 0.4 }), [filter?.amounts]);
    const [searchQuery, setSearchQuery] = useState('');
    const filteredAmounts = useMemo(() => {
        if (searchQuery) {
            return fuse.search(searchQuery).map(result => result.item);
        }
        return filter ? sortFilterItems(filter.filter, filter.amounts) : [];
    }, [filter, fuse, searchQuery, sortFilterItems]);
    const shouldGroupAmounts = useMemo(
        () => filteredAmounts.some(amount => amount.key.includes('|')),
        [filteredAmounts]
    );
    const groupedAmounts = useMemo(
        () => (shouldGroupAmounts ? groupBy(filteredAmounts, amount => amount.key.split('|')?.[0]) : null),
        [filteredAmounts, shouldGroupAmounts]
    );
    const enabledAmounts = queryFiltersState[filter?.filter] ?? [];
    const [sectionsValue, setSectionsValue] = useState<string | null>(null);

    useEffect(() => {
        // reset state if modal gets (re-)opened
        if (rest.isOpen) {
            setQueryFiltersState(queryFilters);
            setSearchQuery('');
            setSectionsValue(null);
        }
    }, [queryFilters, rest.isOpen]);

    useEffect(() => {
        // whilst searching, open the first section by default
        if (shouldGroupAmounts && searchQuery) {
            setSectionsValue(Object.keys(groupedAmounts)?.[0]);
        }
    }, [groupedAmounts, searchQuery, shouldGroupAmounts]);

    return (
        <Modal
            {...rest}
            title={filter?.filter ? formatMessage(filterMessages[filter.filter]) : ''}
            actions={
                <>
                    <Button
                        variant="outline"
                        onClick={() => {
                            setQueryFiltersState({ [filter.filter]: [] });
                        }}
                    >
                        <FormattedMessage defaultMessage="Wis alles" />
                    </Button>
                    <Button
                        onClick={() => {
                            for (const queryFilter of Object.keys(queryFiltersState)) {
                                if (queryFiltersState[queryFilter].length > 0) {
                                    gtm.event('apply_filter', {
                                        filter_name: queryFilter,
                                        filter_value: queryFiltersState[queryFilter].join(', '),
                                        collection,
                                    });
                                }
                            }
                            setQueryFilters(queryFiltersState);
                            rest.onClose();
                        }}
                    >
                        <FormattedMessage defaultMessage="Toepassen" />
                    </Button>
                </>
            }
        >
            <Box mb={6}>
                <SearchInput
                    onClear={() => setSearchQuery('')}
                    onChange={e => setSearchQuery(e.currentTarget.value)}
                    value={searchQuery}
                    placeholder={formatMessage(
                        {
                            defaultMessage: 'Zoek een {filterType}',
                        },
                        {
                            filterType: filter?.filter
                                ? formatMessage(filterMessages[filter?.filter]).toLowerCase()
                                : '',
                        }
                    )}
                />
            </Box>

            {filteredAmounts.length === 0 && (
                <Body mb={9} opacity={0.7}>
                    <FormattedMessage defaultMessage="Geen resultaten" />
                </Body>
            )}

            {!shouldGroupAmounts &&
                filteredAmounts.map(amount => {
                    const id = `${filter}-${amount.key}`;
                    return (
                        <HStack key={amount.key} spacing={4} as={Body}>
                            <Checkbox
                                isChecked={enabledAmounts.includes(amount.key)}
                                onChange={e => setQueryFilter(amount.key, e.currentTarget.checked)}
                                id={id}
                            />
                            <Box as="label" flexGrow={1} htmlFor={id}>
                                {amount.key}
                            </Box>
                        </HStack>
                    );
                })}

            {shouldGroupAmounts && (
                <FilterSections onValueChange={setSectionsValue} value={sectionsValue}>
                    {Object.entries(groupedAmounts).map(([category, amounts]) => {
                        const id = `${filter.filter}-${category}`;
                        const isChecked = amounts
                            .filter(amount => amount.key.startsWith(`${category}|`))
                            .every(amount => enabledAmounts.includes(amount.key));
                        const isIndeterminate = !isChecked
                            ? amounts.some(amount => enabledAmounts.includes(amount.key))
                            : false;
                        return (
                            <FilterSectionExpandable
                                key={category}
                                isFull
                                heading={
                                    <HStack spacing={4} as={Body}>
                                        <Checkbox
                                            isIndeterminate={isIndeterminate}
                                            isChecked={isIndeterminate || isChecked}
                                            onChange={e =>
                                                setQueryFiltersState({
                                                    [filter.filter]: e.currentTarget.checked
                                                        ? amounts.map(amount => amount.key)
                                                        : [],
                                                })
                                            }
                                            id={id}
                                        />
                                        <Box as="label" flexGrow={1} htmlFor={id}>
                                            {category}
                                        </Box>
                                    </HStack>
                                }
                                value={category}
                                filters={amounts.map(amount => {
                                    const amountId = `${filter.filter}-${amount.key}`;
                                    return (
                                        <HStack key={amount.key} spacing={4} as={Body}>
                                            <Checkbox
                                                isChecked={enabledAmounts.includes(amount.key)}
                                                onChange={e => setQueryFilter(amount.key, e.currentTarget.checked)}
                                                id={amountId}
                                            />
                                            <Box as="label" flexGrow={1} htmlFor={amountId}>
                                                {amount.key.split('|')?.[1] ?? category}
                                            </Box>
                                        </HStack>
                                    );
                                })}
                                translations={{
                                    chooseA: <FormattedMessage defaultMessage="Kies een" />,
                                    show: <FormattedMessage defaultMessage="Toon" />,
                                    more: <FormattedMessage defaultMessage="meer" />,
                                    less: <FormattedMessage defaultMessage="minder" />,
                                }}
                            />
                        );
                    })}
                </FilterSections>
            )}
        </Modal>
    );
};

export default FilterModal;
