import { chakra } from '@chakra-ui/react';
import { FC, HTMLAttributes } from 'react';

const IconExternalLink: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.6451 5.66666H26.334V13.3555H24.334V9.08089L16.6673 16.7475L15.2531 15.3333L22.9198 7.66666H18.6451V5.66666ZM8.33399 8.33333L14.6673 8.33333V10.3333H10.334V21.6667H21.4895V17.3333H23.4895V23.6667H8.33398L8.33399 8.33333Z"
            fill="currentColor"
        />
    </svg>
);

export default chakra(IconExternalLink);
