import { ComponentSingleStyleConfig, defineStyleConfig, StyleFunctionProps } from '@chakra-ui/react';

const solidButtonStyles = ({ theme: T }: StyleFunctionProps) => ({
    bg: T.tokens.ButtonPrimaryEnabledBackground,
    color: T.tokens.ButtonPrimaryEnabledForeground,
    _hover: {
        bg: T.tokens.ButtonPrimaryHoverBackground,
        color: T.tokens.ButtonPrimaryHoverForeground,
    },
    _focusVisible: {
        bg: T.tokens.ButtonPrimaryFocusBackground,
        color: T.tokens.ButtonPrimaryFocusForeground,
        outlineColor: T.tokens.ButtonPrimaryFocusBackground,
    },
    _active: {
        bg: T.tokens.ButtonPrimaryActiveBackground,
        color: T.tokens.ButtonPrimaryActiveForeground,
    },
    _disabled: {
        bg: T.tokens.ButtonPrimaryDisabledBackground,
        color: T.tokens.ButtonPrimaryDisabledForeground,
    },
});

/**
 * Theme for a Chakra Button. Include this into your theme.
 */
const ButtonTheme: ComponentSingleStyleConfig = defineStyleConfig({
    baseStyle: ({ theme: T }) => ({
        fontFamily: 'inherit',
        fontSize: T.tokens.SyntaxBodyTextLRegularFontSize,
        lineHeight: 'xxs',
        fontWeight: 'l',
        borderRadius: 'lg',
        gap: 3,
        outlineWidth: T.tokens.BorderWidthM,
        outlineColor: 'transparent',
        outlineOffset: '3px',
        borderStyle: 'solid',
        borderWidth: 0,
    }),
    sizes: {
        md: {
            minW: 9,
            px: 6,
            height: 9,
            svg: {
                w: 6,
                h: 6,
            },
            '.chakra-button__icon': {
                bg: 'transparent',
                m: 0,
            },
        },
    },
    variants: {
        solid: solidButtonStyles,
        solidOnDark: props => ({
            bg: props.theme.tokens.ColorNeutralWhite,
            color: props.theme.tokens.ColorNeutralBlack,
            _hover: {
                bg: props.theme.tokens.ColorNeutral20,
            },
            _focusVisible: {
                bg: props.theme.tokens.ColorNeutral20,
                outlineColor: props.theme.tokens.ColorNeutral20,
            },
            _active: {
                bg: props.theme.tokens.ColorNeutral30,
            },
            _disabled: {
                bg: props.theme.tokens.ColorNeutral30,
                color: props.theme.tokens.ColorNeutral60,
            },
        }),
        outline: props => ({
            ...solidButtonStyles(props),
            bg: 'none',
            color: 'currentColor',
            borderColor: 'currentColor',
            borderWidth: props.theme.tokens.BorderWidthM,
        }),
        ghost: {
            bg: 'none',
            color: 'currentColor',
            border: 'none',
            _hover: {
                outlineColor: 'currentColor',
                opacity: 0.9,
            },
            _focusVisible: {
                outlineColor: 'currentColor',
                opacity: 0.9,
            },
            _active: {
                opacity: 0.7,
            },
            _disabled: {
                opacity: 0.5,
            },
            svg: {
                w: 7,
                h: 7,
            },
        },
    },
    defaultProps: {
        variant: 'solid',
        size: 'md',
    },
});

export default ButtonTheme;
