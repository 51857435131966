import { useRouter } from 'next/router';
import { useMemo } from 'react';

const useParentPage = () => {
    const { asPath } = useRouter();
    const getParentPage = (path: string) => {
        const splitPath = path.split('/').slice(1);
        if (splitPath.length > 1) {
            return splitPath[splitPath.length - 2];
        }
        return path.replace('/', '');
    };

    const parentPagePath = useMemo(() => getParentPage(asPath), [asPath]);

    return {
        parentPagePath,
        getParentPage,
    };
};

export default useParentPage;
