import { useTheme } from '@emotion/react';
import { ErrorMessage } from '@hookform/error-message';
import { Body } from 'designsystem';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface FormErrorMessageProps {
    fieldName: string;
}

const FormErrorMessage: FC<FormErrorMessageProps> = ({ fieldName }) => {
    const theme = useTheme();
    const {
        formState: { errors },
    } = useFormContext();

    return (
        <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({ message }) => (
                <Body
                    mt={1}
                    color={theme.tokens.ColorNegative40}
                    fontSize={theme.tokens.FontSize1}
                    lineHeight={theme.tokens.LineHeightXs}
                >
                    {message}
                </Body>
            )}
        />
    );
};

export default FormErrorMessage;
