import React, { PropsWithChildren, ReactElement } from 'react';

import { BoxProps, List, ListIcon, ListItem, ListItemProps } from '@chakra-ui/react';
import Body from '../typography/Body';
import IconRank from '../trinket/icon/IconRank';

interface Props extends PropsWithChildren, BoxProps {
    items: ReactElement<ListItemProps & { key: ListItemProps['key'] }>[];
}

const ContentBlockRanking: React.FC<Props> = ({ items, ...rest }) => (
    <Body mb={6} {...rest}>
        <List variant="ranking">
            {items.map((item, i) => {
                const { key, children, ...r } = item.props;
                return (
                    <ListItem key={key} fontWeight="xl" {...r}>
                        <ListIcon as={IconRank} number={i + 1} />
                        {children}
                    </ListItem>
                );
            })}
        </List>
    </Body>
);
export default ContentBlockRanking;
