import { Box, BoxProps } from '@chakra-ui/react';
import Img, { ImgProps } from '../media/Img';

const Avatar: React.FC<ImgProps & BoxProps> = ({ className, ...rest }) => (
    <Box className={className} w={9} h={9} borderRadius="lg" overflow="hidden">
        <Img {...rest} />
    </Box>
);

export default Avatar;
