import { Box, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactElement, ReactNode } from 'react';
import Tag, { TagProps } from '../navigation/Tag';
import Body from '../typography/Body';

export interface MetaItem {
    key: string;
    value: ReactNode;
}

export interface Props extends BoxProps {
    tag?: ReactElement<TagProps>;
    items: MetaItem[];
    size: 's' | 'm';
}

const Meta: React.FC<Props> = ({ tag, items, size, ...rest }) => (
    <Box {...rest}>
        {tag && (
            <Box display="inline-block" mr={3}>
                <Tag {...tag.props} size={size} />
            </Box>
        )}
        {items?.map(({ key, value }) => (
            <MetaValue key={key} data-meta={key} size={size}>
                {value}
            </MetaValue>
        ))}
    </Box>
);

const MetaValue = styled(Body)<Pick<Props, 'size'>>(
    ({ theme, size }) =>
        css`
            display: inline;
            font-size: ${size === 's' && theme.tokens.FontSize1};
            line-height: ${size === 's' && theme.tokens.LineHeightXs};
            &:not(:last-child):after {
                content: '•';
                margin: 0 ${theme.tokens.Sizing2};
            }
        `
);

export default Meta;
