import { festivalTheme, instituteTheme, professionalsTheme } from 'designsystem';
import addTrailingSlash from './addTrailingSlash';

interface PillarNavItem {
    key: string;
    href: string;
    title: string;
    color: string;
}

const pillarNavItems: Array<PillarNavItem> = [
    {
        key: 'institute',
        href: process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE || '/404',
        title: 'Institute',
        color: instituteTheme.tokens.SyntaxPillarColorInstituteLighter,
    },
    {
        key: 'festival',
        href: process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL || '/404',
        title: 'Festival',
        color: festivalTheme.tokens.SyntaxPillarColorFestivalLighter,
    },
    {
        key: 'professionals',
        href: process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS || '/404',
        title: 'Professionals',
        color: professionalsTheme.tokens.SyntaxPillarColorProfessionalsLighter,
    },
];

export const getLocalPillarNavItems = (locale: string) =>
    pillarNavItems.map(item => ({
        ...item,
        href: `${addTrailingSlash(item.href)}${item.key !== 'professionals' ? locale : ''}`,
    }));

export default pillarNavItems;
