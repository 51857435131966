import { FC, ReactNode } from 'react';
import {
    Drawer as ChakraDrawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps as ChakraDrawerProps,
    ModalCloseButton,
} from '@chakra-ui/react';

import IconClose from '../trinket/icon/IconClose';
import Heading from '../typography/Heading';
import { ModalTitleBlock } from './Modal';
import Actions from './Actions';

export interface DrawerProps extends ChakraDrawerProps {
    title: ReactNode;
    actions?: ReactNode;
    closeLabel: ReactNode;
}

const Drawer: FC<DrawerProps> = ({ children, title, actions, closeLabel = 'sluiten', ...rest }) => (
    <ChakraDrawer {...rest}>
        <DrawerOverlay />
        <DrawerContent>
            <DrawerHeader>
                <ModalTitleBlock>
                    <Heading variant={4} clamp={1} as="h2">
                        {title}
                    </Heading>
                </ModalTitleBlock>
                <ModalCloseButton onClick={rest.onClose}>
                    <span>{closeLabel} </span>
                    <IconClose />
                </ModalCloseButton>
            </DrawerHeader>
            <DrawerBody>
                {children}
                {actions && <Actions>{actions}</Actions>}
            </DrawerBody>
        </DrawerContent>
    </ChakraDrawer>
);

export default Drawer;
