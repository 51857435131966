import { Body, Box, Checkbox, FilterSectionExpandable, HStack, Radio } from 'designsystem';
import orderBy from 'lodash.orderby';
import { FC } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { QueryParamConfig, useQueryParams } from 'use-query-params';
import camelCase from 'lodash.camelcase';
import useSearchState from '../../hooks/useSearchState';
import useSetQueryFilter from '../../hooks/useSetQueryFilter';
import { Filter } from './Filters';

interface Props {
    filters: Filter[];
    queryParams: Parameters<typeof useSearchState<Record<string, QueryParamConfig<string[], string[]>>>>[0];
    collectionFilterMessages: Record<string, MessageDescriptor>;
    amountMessages?: Record<string, Record<string, MessageDescriptor>>;
    sortFilterItems?: (filter: string, amounts: Filter['amounts']) => Filter['amounts'];
    onModal: (selectedFilter: Filter) => void;
}

const SearchFilterSections: FC<Props> = ({
    filters,
    onModal,
    queryParams,
    collectionFilterMessages,
    amountMessages = {},
    sortFilterItems = (filter, amounts) => {
        if (filter in amountMessages) {
            return amounts.sort((a, b) => {
                // sort by how the order in which they are defined in showTypeFilterMessages
                const indexA = Object.keys(amountMessages[filter]).indexOf(camelCase(a.key));
                const indexB = Object.keys(amountMessages[filter]).indexOf(camelCase(b.key));
                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
            });
        }

        return orderBy(amounts, amount => amount.key.toLowerCase());
    },
}) => {
    const [queryFilters] = useQueryParams(queryParams);
    const setQueryFilter = useSetQueryFilter(queryParams);
    const { formatMessage } = useIntl();

    return (
        <>
            {filters
                .filter(({ amounts }) => !amounts.every(({ amount }) => amount === 0)) // hide filters without results
                .map(currentFilter => {
                    const { filter, amounts, inputType } = currentFilter;
                    return (
                        <FilterSectionExpandable
                            key={filter}
                            heading={formatMessage(collectionFilterMessages[filter])}
                            selectedFilters={queryFilters[filter]
                                .map(f => {
                                    const message = amountMessages[filter]?.[camelCase(f)];
                                    return message ? formatMessage(message) : f.split('|')?.[1] ?? f;
                                })
                                .join(', ')}
                            onModal={() => onModal(currentFilter)}
                            filters={sortFilterItems(filter, amounts).map(amount => {
                                const id = `${filter}-${amount.key}`;
                                const enabledFilters = queryFilters[filter] ?? [];
                                const message = amountMessages[filter]?.[camelCase(amount.key)];
                                return (
                                    <HStack key={amount.key} spacing={4} as={Body}>
                                        <Box as="label" flexGrow={1} htmlFor={id}>
                                            {!message
                                                ? amount.key.includes('|')
                                                    ? amount.key.split('|')[1]
                                                    : amount.key
                                                : formatMessage(amountMessages[filter][camelCase(amount.key)])}
                                        </Box>
                                        {inputType === 'radio' ? (
                                            <Radio
                                                isChecked={enabledFilters.includes(amount.key)}
                                                onChange={e =>
                                                    setQueryFilter(filter, amount.key, e.currentTarget.checked, 'radio')
                                                }
                                                id={id}
                                            />
                                        ) : (
                                            <Checkbox
                                                isChecked={enabledFilters.includes(amount.key)}
                                                onChange={e =>
                                                    setQueryFilter(filter, amount.key, e.currentTarget.checked)
                                                }
                                                id={id}
                                            />
                                        )}
                                    </HStack>
                                );
                            })}
                            value={filter}
                            translations={{
                                chooseA: <FormattedMessage defaultMessage="Kies een" />,
                                show: <FormattedMessage defaultMessage="Toon" />,
                                more: <FormattedMessage defaultMessage="meer" />,
                                less: <FormattedMessage defaultMessage="minder" />,
                            }}
                        />
                    );
                })}
        </>
    );
};

export default SearchFilterSections;
