import { Box, Modal, ModalCloseButton, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';
import IconClose from '../trinket/icon/IconClose';

interface Props extends Omit<ModalProps, 'children'> {
    url: string;
    allow?: HTMLIFrameElement['allow'];
    closeLabel: ReactNode;
}

const ExperienceModal: React.FC<Props> = ({ url, allow, closeLabel = 'Sluiten', ...rest }) => {
    const { tokens } = useTheme();
    return (
        <Modal variant="media" size="lg" {...rest}>
            <ModalOverlay />
            <Box pos="fixed" bg={tokens.SyntaxBackgroundNeutralDarkest} insetX={10} insetY={15} zIndex={1000}>
                <iframe title="experience" src={url} allow={allow} width="100%" height="100%" />
            </Box>
            <ModalCloseButton onClick={rest.onClose}>
                <span>{closeLabel} </span>
                <IconClose />
            </ModalCloseButton>
        </Modal>
    );
};

export default ExperienceModal;
