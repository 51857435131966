/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useCallback, useState } from 'react';

import { Box, BoxProps, chakra, Flex, IconButton, shouldForwardProp, Stack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import Wrapper from '../layout/Wrapper';
import Grid from '../layout/Grid';
import Heading from '../typography/Heading';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import IconCaret from '../trinket/icon/IconCaret';
import { ImgProps } from '../media/Img';
import Link from 'next/link';
import BackgroundCarousel, { CarouselDots } from '../content/BackgroundCarousel';

interface Slide {
    key: string;
    image: ImgProps;
    title: ReactNode;
    href: string;
    description: ReactNode;
}

interface Props extends BoxProps {
    slides: Slide[];
}

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Hero: FC<Props> = ({ slides, ...rest }) => {
    const theme = useTheme();
    const [slideIndex, setSlideIndex] = useState(0);
    const onPrev = useCallback(
        () => setSlideIndex(slideIndex === 0 ? slides.length - 1 : (slideIndex - 1) % slides.length),
        [slideIndex, slides.length]
    );
    const onNext = useCallback(() => setSlideIndex((slideIndex + 1) % slides.length), [slideIndex, slides.length]);

    return (
        <Box
            bg={theme.tokens.SyntaxBackgroundNeutralDarkest}
            color={theme.tokens.SyntaxTextColorOnDark}
            position="relative"
            h={[null, null, `calc((100vh - ${theme.sizes[15]}) + 280px)`]}
            minHeight={[null, null, '1024px']}
            mb={[-8, null, 'calc(-280px - 5%)']}
            {...rest}
        >
            <BackgroundCarousel
                slides={slides}
                slideIndex={slideIndex}
                h={['100%', null, `calc(100vh - ${theme.sizes[15]})`]}
                minHeight={[null, null, '800px']}
                _before={{
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bg: `linear-gradient(84.41deg, ${theme.tokens.SyntaxOverlayColorDarkest} 4.32%, rgba(0, 0, 0, 0.15) 119.35%)`,
                    zIndex: 1,
                    opacity: 0.7,
                }}
            />
            <Wrapper
                wide
                display="flex"
                position="relative"
                zIndex={1}
                h={['auto', null, 'calc(100vh - 5vw)']}
                minHeight={[null, null, '800px']}
            >
                <Wrapper minH="440px">
                    <Grid placeSelf="center" w="100%" h="100%">
                        <Flex
                            justify="center"
                            align="center"
                            gridColumn={['1 / span 2', null, null, '1 / span 1']}
                            display={['none', null, 'flex']}
                            position={['static', null, null, null, 'absolute']}
                            left={0}
                            top={0}
                            bottom={0}
                        >
                            <IconButton
                                aria-label="previous"
                                variant="ghost"
                                position="absolute"
                                icon={<IconCaret direction="left" />}
                                onClick={onPrev}
                            />
                        </Flex>
                        <Box gridColumn={['1 / -1', null, '3 / -3', null, '2 / span 5']} position="relative">
                            <AnimatePresence initial={false}>
                                {slides.map(
                                    (slide, i) =>
                                        i === slideIndex && (
                                            <AnimationBox
                                                key={slide.key}
                                                as={motion.div}
                                                display="flex"
                                                alignItems="center"
                                                position="absolute"
                                                top={0}
                                                left={0}
                                                right={0}
                                                bottom={0}
                                                py={16}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.2 } as any}
                                                style={{ touchAction: 'none' }}
                                                onPanEnd={(e, pointInfo) => {
                                                    if (pointInfo.delta.x > 0) {
                                                        onNext();
                                                    } else {
                                                        onPrev();
                                                    }
                                                }}
                                            >
                                                <Stack spacing={[6, null, null, 8]}>
                                                    <Link href={slide.href}>
                                                        <Stack spacing={[6, null, null, 8]}>
                                                            <Heading as="h1" variant={1}>
                                                                {slide.title}
                                                            </Heading>
                                                            <Heading as="p" variant={4}>
                                                                {slide.description}
                                                            </Heading>
                                                        </Stack>
                                                    </Link>
                                                    <CarouselDots
                                                        slides={slides.length}
                                                        currentSlide={slideIndex}
                                                        onClick={setSlideIndex}
                                                        display={['flex', null, 'none']}
                                                    />
                                                </Stack>
                                            </AnimationBox>
                                        )
                                )}
                            </AnimatePresence>
                        </Box>
                        <Flex
                            justify="center"
                            align="center"
                            gridColumn={['-1 / span 2', '-2 / span 2', null, null, '8 / span 1']}
                            display={['none', null, 'flex']}
                        >
                            <IconButton
                                aria-label="next"
                                variant="ghost"
                                icon={<IconCaret direction="right" />}
                                position={['absolute', null, null, null, 'static']}
                                right={0}
                                onClick={onNext}
                            />
                        </Flex>
                    </Grid>
                </Wrapper>
            </Wrapper>
        </Box>
    );
};

export default Hero;
