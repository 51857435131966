import { ArchiveFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const archiveFilterMessages = defineMessages<ArchiveFilterEnum>({
    [ArchiveFilterEnum.ArchiveType]: { defaultMessage: 'In content type' },
    [ArchiveFilterEnum.CountriesOfProduction]: { defaultMessage: 'Productieland' },
    [ArchiveFilterEnum.EditionYear]: { defaultMessage: 'Editie' },
    [ArchiveFilterEnum.Tags]: { defaultMessage: 'Onderwerp' },
    [ArchiveFilterEnum.IdfaAwards]: { defaultMessage: 'IDFA award' },
    [ArchiveFilterEnum.LengthInMinutes]: { defaultMessage: 'Lengte' },
    [ArchiveFilterEnum.Premiere]: { defaultMessage: 'Première' },
    [ArchiveFilterEnum.ReportType]: { defaultMessage: 'Type' },
    [ArchiveFilterEnum.Section]: { defaultMessage: 'Sectie' },
    [ArchiveFilterEnum.Professions]: { defaultMessage: 'Rol' },
    [ArchiveFilterEnum.TvPrice]: { defaultMessage: 'TV prijs' }, // what
    [ArchiveFilterEnum.Nationality]: { defaultMessage: 'Nationaliteit' },
});

export default archiveFilterMessages;
