import { cssVar, StyleConfig } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const SkeletonTheming: StyleConfig = {
    baseStyle: ({ theme: T }) => ({
        [$startColor.variable]: T.tokens.ColorGrey40,
        [$endColor.variable]: T.tokens.ColorGrey60,
        opacity: 0.2,
    }),
};

export default SkeletonTheming;
