import * as React from 'react';
import { SVGProps } from 'react';

const IconLocation = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <path stroke="currentColor" strokeWidth={1.5} d="M11.667 10H8.334V6.665h3.333v3.333Z" />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M10 18.333s7.084-5.307 7.084-10c0-3.64-2.698-6.667-7.084-6.667-4.386 0-7.083 3.027-7.083 6.667 0 4.693 7.083 10 7.083 10Z"
        />
    </svg>
);
export default IconLocation;
