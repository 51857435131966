import { cssVar, StyleConfig } from '@chakra-ui/react';

const $popperBg = cssVar('popper-bg');
const $popperArrowWidth = cssVar('popper-arrow-size');
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

/**
 * Theme for a Chakra Popover. Include this into your theme.
 */
const PopoverTheme: StyleConfig = {
    baseStyle: {
        body: {
            p: 4,
        },
        content: {
            outline: 'none !important',
            [$popperBg.variable]: 'black',
            [$popperArrowWidth.variable]: 'sizes.5',
            bg: $popperBg.reference,
            [$arrowBg.variable]: $popperBg.reference,
            [$arrowShadowColor.variable]: `colors.gray.200`,
            maxWidth: '347px',
            boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
            background: 'black',
            color: 'white',
            zIndex: 'inherit',
            fontWeight: 400,
        },
    },
    defaultProps: {},
};

export default PopoverTheme;
