import { Box, BoxProps, chakra, HStack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { isValidMotionProp, motion } from 'framer-motion';
import { ReactNode } from 'react';
import Body from '../typography/Body';

interface Option {
    id: string;
    children: ReactNode;
    onClick: () => void;
    selected: boolean;
}

interface Props extends BoxProps {
    options: [Option, Option];
}

const ChakraBox = chakra(motion.div, {
    shouldForwardProp: isValidMotionProp,
});

const Toggle: React.FC<Props> = ({ options, ...rest }) => {
    const { tokens } = useTheme();
    return (
        <HStack
            borderRadius={tokens.BorderRadiusL}
            borderStyle="solid"
            borderWidth={tokens.BorderWidthM}
            borderColor={tokens.BorderColorNeutralDefault}
            p={1}
            spacing={0}
            {...rest}
        >
            {options.map(option => (
                <Box
                    key={option.id}
                    onClick={option.onClick}
                    borderRadius={tokens.BorderRadiusL}
                    pos="relative"
                    py={2}
                    px={[4, 5]}
                    flexGrow={[1, null, 0]}
                    textAlign="center"
                >
                    <Body fontWeight="xl">{option.children}</Body>
                    {option.selected && (
                        <ChakraBox
                            layoutId="toggle"
                            backdropFilter="invert(1)"
                            borderRadius="inherit"
                            pos="absolute"
                            inset="0"
                        />
                    )}
                </Box>
            ))}
        </HStack>
    );
};

export default Toggle;
