import { IntlShape } from 'react-intl';

const parseFullName = (fullName: string, intl: IntlShape) =>
    fullName.replace(
        ' / ',
        ` ${intl.formatMessage({
            description: "credits 'namens'",
            defaultMessage: 'namens',
            id: 'credits-on-behalf-of',
        })} `
    );

export default parseFullName;
