import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { Session } from 'next-auth';
import { deleteCookie, getCookies } from 'cookies-next';
import { OptionsType } from 'cookies-next/src/types';

/* eslint-disable no-console */

const removeSession = (res?: OptionsType['res'], req?: OptionsType['req']) => {
    const cookies = getCookies({ req, res });
    res?.removeHeader('Set-Cookie');
    for (const name of Object.keys(cookies)) {
        if (name.includes('next-auth')) {
            deleteCookie(name, { req, res, secure: true });
        }
    }
};

export const fetchApiData = async <TData, TVariables>({
    query,
    locale,
    session,
    variables,
    options,
    res,
    req,
}: {
    query: string;
    locale: string;
    session?: Session;
    variables?: TVariables;
    options?: Record<string, string>;
    res?: OptionsType['res'];
    req?: OptionsType['req'];
}): Promise<TData> => {
    const headers: Record<string, string> = { 'Content-Type': 'application/json', ...options };
    if (locale) {
        headers['X-Default-Language'] = locale;
    }
    if (session?.accessToken) {
        headers.Authorization = `Bearer ${session.accessToken}`;
    }

    const opts: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify({ query, variables }),
    };
    let apiResponse = await fetch(process.env.NEXT_PUBLIC_CORE_ENDPOINT, opts);

    if (apiResponse.status === 401 && session) {
        // eslint-disable-next-line no-console
        console.error('Backend returned 401, destroying session and retrying...', res, req);

        // declare session invalid - delete session cookies
        removeSession(res, req);
        if (typeof window !== 'undefined') {
            await signOut();
        }

        // retry without auth header
        delete headers.Authorization;
        apiResponse = await fetch(process.env.NEXT_PUBLIC_CORE_ENDPOINT, { ...opts, headers });
    }

    if (!apiResponse.ok) {
        console.error('------------ ERROR DEBUG -------\n', { query, variables }, '\n--------------------------------');
        throw new Error(`${apiResponse.status}: ${apiResponse.statusText}, ${await apiResponse.text()}`);
    }

    const json = await apiResponse.json();

    if (json.errors) {
        console.error('------------ ERROR DEBUG -------\n', { query, variables }, '\n--------------------------------');
        const { message } = json.errors[0];

        throw new Error(message);
    }

    return json.data;
};

const useApi = <TData, TVariables>(query: string, variables?: TVariables): (() => Promise<TData>) => {
    const { locale } = useRouter();
    const { data: sessionData } = useSession();
    // make a duplicate of session as it's mutated somewhere
    const session = useMemo(() => ({ ...sessionData }), [sessionData]);
    return useCallback(
        (callbackVariables?: TVariables) =>
            fetchApiData<TData, TVariables>({
                locale,
                query,
                variables: variables ?? callbackVariables,
                session,
            }),
        [locale, query, session, variables]
    );
};

useApi.fetch = fetchApiData;

export default useApi;
