import { BoxProps, HStack, IconButton, SkeletonText, Stack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import IconCaret from '../trinket/icon/IconCaret';
import Body from '../typography/Body';

interface Props extends Omit<BoxProps, 'title'> {
    title: ReactNode;
    current: number;
    total: number;
    isLoading?: boolean;
    body?: ReactNode;
    onNext?: () => void;
    onPrev?: () => void;
    prevDisabled?: boolean;
    nextDisabled?: boolean;
}

const BrowseStepper: React.FC<Props> = ({
    title = 'Blader door de collection',
    current,
    total,
    onPrev,
    onNext,
    body = `${current}/${total} results`,
    prevDisabled,
    nextDisabled,
    isLoading,
    ...rest
}) => (
    <HStack alignItems="center" spacing={[4, null, null, 6]} {...rest}>
        <IconButton
            variant="outline"
            aria-label="browse previous"
            icon={<IconCaret direction="left" />}
            onClick={() => onPrev?.()}
            isDisabled={isLoading || prevDisabled}
        />
        {isLoading ? (
            <Stack spacing={4}>
                <Body fontWeight="xl" mt={1}>
                    <SkeletonText noOfLines={1} skeletonHeight={4}>
                        {title}
                    </SkeletonText>
                </Body>
                <Body mb={1}>
                    <SkeletonText noOfLines={1} skeletonHeight={4}>
                        {body}
                    </SkeletonText>
                </Body>
            </Stack>
        ) : (
            <Stack spacing={0}>
                <Body fontWeight="xl">{title}</Body>
                <Body>{body}</Body>
            </Stack>
        )}

        <IconButton
            variant="outline"
            aria-label="browse next"
            icon={<IconCaret direction="right" />}
            onClick={() => onNext?.()}
            isDisabled={isLoading || nextDisabled}
            ml="auto"
        />
    </HStack>
);

export default BrowseStepper;
