import { useCallback } from 'react';

export const fetchCraftData = async <TData = never, TVariables = never>({
    variables,
    query,
    options,
    previewData,
}: {
    query: string;
    variables?: TVariables;
    options?: RequestInit['headers'];
    previewData?: { token: string };
}): Promise<TData> => {
    const url = process.env.NEXT_PUBLIC_CMS_ENDPOINT;
    const headers: Record<string, unknown> = { ...options };

    if (previewData) {
        headers['X-Craft-Token'] = previewData.token;
    }

    const res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify({ query, variables }),
    });

    if (!res.ok) {
        throw new Error(await res.text());
    }

    const json = await res.json();

    if (json.errors) {
        const { message } = json.errors[0];

        throw new Error(message);
    }

    return json.data;
};

const useCraft = <TData, TVariables>(
    query: string,
    variables?: TVariables
): ((callbackVariables?: TVariables) => Promise<TData>) =>
    useCallback(
        (callbackVariables?: TVariables) =>
            fetchCraftData<TData, TVariables>({ query, variables: variables ?? callbackVariables }),
        [query, variables]
    );

useCraft.fetch = fetchCraftData;

export default useCraft;
