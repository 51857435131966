import React from 'react';
import IconExternalLink from '../trinket/icon/IconExternalLink';
import SmallCard, { CardProps } from './SmallCard';

const LinkCard: React.FC<Omit<CardProps, 'icon'>> = ({ children, ...rest }) => (
    <SmallCard variant="white" size="s" icon={<IconExternalLink />} {...rest}>
        {children}
    </SmallCard>
);

export default LinkCard;
