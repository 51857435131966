import { FC, ReactNode } from 'react';
import {
    Modal as ChakraModal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalProps,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconClose from '../trinket/icon/IconClose';
import Heading from '../typography/Heading';
import Actions from './Actions';

export interface Props extends ModalProps {
    title?: ReactNode;
    actions?: ReactNode;
    closeLabel: ReactNode;
}

const Modal: FC<Props> = ({ children, title, actions, closeLabel, ...rest }) => (
    <ChakraModal {...rest}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader bg={title && 'transparent'}>
                <ModalTitleBlock>
                    {title && (
                        <Heading variant={4} clamp={1} as="h2">
                            {title}
                        </Heading>
                    )}
                </ModalTitleBlock>
                <ModalCloseButton onClick={rest.onClose}>
                    <span>{closeLabel} </span>
                    <IconClose />
                </ModalCloseButton>
            </ModalHeader>
            <ModalBody>
                {children}
                {actions && <Actions>{actions}</Actions>}
            </ModalBody>
        </ModalContent>
    </ChakraModal>
);

export const ModalTitleBlock = styled.div(
    ({ theme }) => css`
        margin: 0;
        flex-grow: 1;
        background-color: ${theme.tokens.SyntaxBackgroundNeutralDark};
        padding: ${theme.tokens.Sizing5} ${theme.tokens.Sizing6};
    `
);

export default Modal;
