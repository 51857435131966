import React, { HTMLAttributes } from 'react';

const IconDownload: React.FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 2V12.5858L17 8.58579L18.4142 10L12 16.4142L5.58579 10L7 8.58579L11 12.5858V2H13ZM4 14V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V14H22V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V14H4Z"
            fill="currentColor"
        />
    </svg>
);

export default IconDownload;
