interface ATTicketOrPassArgs {
    id: string;
    locale: string;
}

const getATLocale = (locale: string) => (locale === 'en' ? 'en-US' : locale === 'nl' ? 'nl-NL' : locale);

/**
 * @param atInfo Information about the Active Tickets show.
 * @param atInfo.id Active Tickets show id.
 * @param atInfo.locale Current locale
 */
export const getATTicketUrl = ({ id, locale }: ATTicketOrPassArgs) =>
    `${process.env.NEXT_PUBLIC_ACTIVE_TICKETS}/${getATLocale(locale)}/Show/ByKey/${id}`;

/**
 * @param atInfo Information about the Active Tickets show.
 * @param atInfo.id Active Tickets pass id.
 * @param atInfo.locale Current locale
 */
export const getATPassUrl = ({ id, locale }: ATTicketOrPassArgs) =>
    `${process.env.NEXT_PUBLIC_ACTIVE_TICKETS}/${getATLocale(locale)}/Pass/Details/${id}`;

/**
 * @param atInfo Information about the Active Tickets show.
 * @param atInfo.ticketType Type of ticket to get the url for.
 * @param atInfo.id Active Tickets show or pass id.
 * @param atInfo.locale Current locale
 */
export const getATUrlForTicketType = (ticketType: string, atInfo: ATTicketOrPassArgs) =>
    ticketType === 'pass' ? getATPassUrl(atInfo) : getATTicketUrl(atInfo);

export const getATCartUrl = ({ locale }: { locale: string }) =>
    `${process.env.NEXT_PUBLIC_ACTIVE_TICKETS}/${getATLocale(locale)}/Cart`;

export const getATOrderHistoryUrl = ({ locale }: { locale: string }) =>
    `${process.env.NEXT_PUBLIC_ACTIVE_TICKETS}/${getATLocale(locale)}/OrderHistory`;
