import { useCallback } from 'react';
import { ImgProps } from 'designsystem';
// eslint-disable-next-line no-restricted-imports
import placeholder from 'designsystem/src/assets/images/placeholder.svg';
import { useTheme } from '@emotion/react';
import blurHashToDataURL from '../lib/blurHashToDataURL';
import { OpenGraphMedia } from 'next-seo/lib/types';

export interface CmsImage {
    webp_100w?: string;
    webp_400w?: string;
    webp_860w?: string;
    webp_1280w?: string;
    webp_1920w?: string;
    webp_2560w?: string;
    jpeg_100w?: string;
    jpeg_400w?: string;
    jpeg_860w?: string;
    jpeg_1280w?: string;
    jpeg_1920w?: string;
    jpeg_2560w?: string;
    alt?: string;
    url?: string;
    title?: string;
    blurHash?: string;
    width?: number;
    height?: number;
}

/**
 * TODO: Move to shared package
 */
const useGetImageProps = () => {
    const { breakpoints } = useTheme();
    return useCallback(
        (
            image: CmsImage,
            alt?: string,
            sizes = `(min-width: ${breakpoints.xl}) 20vw, (min-width: ${breakpoints.l}) 30vw, (min-width: ${breakpoints.m}) 447px, (min-width: ${breakpoints.s}) 50vw, 100vw`
        ): ImgProps => {
            const mainTransform = image?.url;

            if (!image || !mainTransform) {
                return {
                    ...placeholder,
                    alt: alt || image?.alt,
                };
            }

            const props: ImgProps = {
                sources: [
                    {
                        type: 'image/webp',
                        sizes,
                        srcSet: `
                            ${image.webp_100w} 100w,
                            ${image.webp_400w} 400w,
                            ${image.webp_860w} 860w,
                            ${image.webp_1280w} 1280w,
                            ${image.webp_1920w} 1920w,
                            ${image.webp_2560w} 2560w
                        `,
                    },
                    {
                        type: 'image/jpeg',
                        sizes,
                        srcSet: `
                            ${image.jpeg_100w} 100w,
                            ${image.jpeg_400w} 400w,
                            ${image.jpeg_860w} 860w,
                            ${image.jpeg_1280w} 1280w,
                            ${image.jpeg_1920w} 1920w,
                            ${image.jpeg_2560w} 2560w
                        `,
                    },
                ],
                alt: alt || image.alt,
                src: mainTransform,
                width: image.width,
                height: image.height,
            };

            if (image?.blurHash) {
                props.placeholder = blurHashToDataURL(image?.blurHash);
            }

            return props;
        },
        [breakpoints.l, breakpoints.m, breakpoints.s, breakpoints.xl]
    );
};

// helper which gets the best image for opengraph
export const cmsImageToOpenGraph = (image: CmsImage[]): OpenGraphMedia[] => {
    const transform = image?.[0]?.jpeg_2560w;
    if (!transform) {
        return [];
    }
    return [
        {
            url: transform,
            width: 2560,
        },
    ];
};

export default useGetImageProps;
