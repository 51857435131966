import React from 'react';
import { MetaProps, useTheme } from '../..';
import Meta from '../meta/Meta';
import ImageBackgroundCard, { CardProps } from './ImageBackgroundCard';
import { AspectRatio, useMediaQuery } from '@chakra-ui/react';

export interface Props extends CardProps {
    meta?: Omit<MetaProps, 'size'>;
}

const ProgrammeCard: React.FC<Props> = ({ meta, variant = 'white', ...rest }) => {
    const { tokens } = useTheme();
    const small = useMediaQuery(`(max-width: ${tokens.MediaQueryS})`)[0];

    return (
        <AspectRatio ratio={4 / 3}>
            <ImageBackgroundCard {...rest} variant={variant} titleVariant={small ? 4 : 2}>
                {meta && <Meta size={small ? 's' : 'm'} {...meta} />}
            </ImageBackgroundCard>
        </AspectRatio>
    );
};

export default ProgrammeCard;
