import React, { ReactNode } from 'react';

import { AspectRatio, Box, Flex, Stack } from '@chakra-ui/react';

import { Props as ColorSchemeProps } from '../ColorSchemeProvider';
import Heading from '../typography/Heading';
import Tile from './Tile';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';
import NextImg, { ImageProps } from 'next/image';

interface NextImageProps extends ImageProps {
    type: 'next';
}

interface Props extends ColorSchemeProps {
    title: ReactNode;
    image: ImgProps | NextImageProps;
    inverted?: boolean;
}

const isNextImage = (image: ImgProps | NextImageProps): image is NextImageProps => image.type === 'next';

const Cta: React.FC<Props> = ({ colorScheme, image, title, inverted, children, ...rest }) => (
    <Flex w="100%" flexDir="row" flexWrap="wrap" gridTemplateColumns="repeat(6, 1fr)" {...rest}>
        <Box
            width={['100%', `${(5 / 6) * 100}%`, `${(4 / 6) * 100}%`, `${(3 / 6) * 100}%`]}
            mt={[0, null, null, 15]}
            order={[0, null, null, inverted ? 2 : 1]}
            marginLeft={inverted ? 'auto' : undefined}
        >
            <AspectRatio w="100%" ratio={1}>
                {isNextImage(image) ? (
                    <NextImg fill {...image} />
                ) : (
                    <Img style={{ objectFit: 'cover' }} fill {...image} />
                )}
            </AspectRatio>
        </Box>
        <Tile
            p={[9, null, 11, 10, 13]}
            width={['100%', `${(5 / 6) * 100}%`, `${(4 / 6) * 100}%`, `${(3 / 6) * 100}%`]}
            mb={[0, null, null, 15]}
            mt={[0, '-50%', null, 0]}
            zIndex={1}
            order={[1, null, null, inverted ? 1 : 2]}
            colorScheme={colorScheme}
            marginLeft={inverted ? undefined : 'auto'}
            justifyContent="center"
        >
            <ResponsiveAspectRatio w="100%">
                <Flex w="100%">
                    <Stack spacing={7}>
                        <CtaHeading as="h2" variant={2}>
                            {title}
                        </CtaHeading>
                        {children}
                    </Stack>
                </Flex>
            </ResponsiveAspectRatio>
        </Tile>
    </Flex>
);

const CtaHeading = styled(Heading)(
    ({ theme }) => css`
        @media (max-width: ${theme.breakpoints.l}) {
            font-size: ${theme.tokens.SyntaxHeadingTextL3FontSize};
        }
        @media (max-width: ${theme.breakpoints.m}) {
            font-size: ${theme.tokens.SyntaxHeadingTextL2FontSize};
        }
        @media (max-width: ${theme.breakpoints.s}) {
            font-size: ${theme.tokens.SyntaxHeadingTextL3FontSize};
        }
    `
);

const ResponsiveAspectRatio = styled(Box)(
    ({ theme }) => css`
        aspect-ratio: 1;

        @media (max-width: ${theme.breakpoints.xs}) {
            aspect-ratio: unset;
        }
    `
);

export default Cta;
