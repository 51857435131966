import { Box, Heading, Hero, Meta, SkeletonText, Stack, Tag } from 'designsystem';
import React from 'react';
import { useTheme } from '@emotion/react';

const emptyImage =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1'%3E%3Crect width='1' height='1' fill='transparent' /%3E%3C/svg%3E";

const LoadingFilmPage = () => {
    const theme = useTheme();
    return (
        <Hero
            image={{ src: emptyImage, width: 1, height: 1 }}
            topChildren={
                <Box py={2}>
                    <SkeletonText
                        noOfLines={1}
                        width="66%"
                        skeletonHeight={[
                            theme.tokens.SyntaxBodyTextSRegularFontSize,
                            null,
                            theme.tokens.SyntaxBodyTextLRegularFontSize,
                        ]}
                    />
                </Box>
            }
        >
            <Stack spacing={7}>
                <Stack spacing={4}>
                    <Tag color="neutralwhite.">
                        <SkeletonText display="inline-block" noOfLines={1} width="50px" skeletonHeight={3} />
                    </Tag>
                    <Stack mt={2}>
                        <Heading as="h1" variant={1} clamp={3}>
                            <SkeletonText
                                noOfLines={2}
                                width="100%"
                                skeletonHeight={[
                                    theme.tokens.SyntaxHeadingTextS1FontSize,
                                    null,
                                    theme.tokens.SyntaxHeadingTextL1FontSize,
                                ]}
                            />
                        </Heading>
                        <SkeletonText
                            noOfLines={1}
                            width="66%"
                            skeletonHeight={[
                                theme.tokens.SyntaxHeadingTextS5FontSize,
                                null,
                                theme.tokens.SyntaxHeadingTextL5FontSize,
                            ]}
                        />
                    </Stack>
                    <Box>
                        <Meta
                            items={[
                                {
                                    key: '1',
                                    value: (
                                        <SkeletonText
                                            display="inline-block"
                                            noOfLines={1}
                                            width="80px"
                                            skeletonHeight={[
                                                theme.tokens.SyntaxBodyTextSRegularFontSize,
                                                null,
                                                theme.tokens.SyntaxBodyTextLRegularFontSize,
                                            ]}
                                        />
                                    ),
                                },
                                {
                                    key: '2',
                                    value: (
                                        <SkeletonText
                                            display="inline-block"
                                            noOfLines={1}
                                            width="120px"
                                            skeletonHeight={[
                                                theme.tokens.SyntaxBodyTextSRegularFontSize,
                                                null,
                                                theme.tokens.SyntaxBodyTextLRegularFontSize,
                                            ]}
                                        />
                                    ),
                                },
                                {
                                    key: '3',
                                    value: (
                                        <SkeletonText
                                            display="inline-block"
                                            noOfLines={1}
                                            width="180px"
                                            skeletonHeight={[
                                                theme.tokens.SyntaxBodyTextSRegularFontSize,
                                                null,
                                                theme.tokens.SyntaxBodyTextLRegularFontSize,
                                            ]}
                                        />
                                    ),
                                },
                                {
                                    key: '4',
                                    value: (
                                        <SkeletonText
                                            display="inline-block"
                                            noOfLines={1}
                                            width="60px"
                                            skeletonHeight={[
                                                theme.tokens.SyntaxBodyTextSRegularFontSize,
                                                null,
                                                theme.tokens.SyntaxBodyTextLRegularFontSize,
                                            ]}
                                        />
                                    ),
                                },
                            ]}
                            size="m"
                        />
                    </Box>
                </Stack>
            </Stack>
        </Hero>
    );
};

export default LoadingFilmPage;
