import styled from '@emotion/styled';
import {
    Box,
    Grid,
    Heading,
    Hero,
    HtmlContent,
    IconExternalLink,
    ImgProps,
    Intro,
    PageCard,
    Partners,
    Stack,
    Wrapper,
} from 'designsystem';
import { NextSeo } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import Image from 'next/image';
import Link from 'next/link';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import striptags from 'striptags';

interface SubPage {
    title: string;
    uri: string;
    navCtaText: string;
    navIsFeatured: boolean;
    navThumbnail: ImgProps[];
    relatedLinks: Array<{
        label: string;
        linkObject?: {
            target: string;
            uri: string;
            customText: string;
            element?: {
                uri: string;
            };
        };
    }>;
}

interface RelatedLink {
    label?: string;
    linkObject?: {
        target?: string;
        url?: string;
        element?: {
            uri?: string;
        };
    };
}

interface PartnerLogo {
    title?: string;
    logoImage?: {
        url?: string;
    }[];
    linkObject?: string;
}

interface PartnerListing {
    partnerName?: string;
    linkUrl?: string;
}

interface Props {
    pageTitle: string;
    pageIntroText?: string;
    pageOpenGraphImages?: OpenGraphMedia[];
    subPages: SubPage[];
    relatedLinkHeading?: ReactNode;
    relatedLinks?: RelatedLink[];
    partners?: {
        partnerLogos?: PartnerLogo[];
        partnerListingsText?: string;
        partnerListings?: PartnerListing[];
    };
    breadcrumbs: ReactNode;
}

const renderSubPage = (subPage: SubPage) => {
    const thumb = subPage.navThumbnail?.[0];
    return (
        <Box key={subPage.uri} gridColumn={['1/-1', 'span 6', null, 'span 4']} display="flex" flexDirection="column">
            <Box as={Link} href={subPage.uri} display="flex" flexDirection="column" flex={1}>
                <PageCard
                    variant={subPage.navIsFeatured ? 'primary' : 'neutral'}
                    size="l"
                    title={subPage.title}
                    image={thumb}
                    textButtonProps={
                        subPage.navCtaText && {
                            children: subPage.navCtaText || <FormattedMessage defaultMessage="Learn more" />,
                            size: 's',
                        }
                    }
                />
            </Box>
        </Box>
    );
};
const NavigationTemplate: FC<Props> = ({
    pageTitle,
    pageIntroText,
    pageOpenGraphImages,
    subPages,
    relatedLinkHeading,
    relatedLinks,
    partners,
    breadcrumbs,
    ...rest
}) => (
    <Stack spacing={18} {...rest}>
        <NextSeo
            title={pageTitle}
            description={striptags(pageIntroText)}
            openGraph={{
                images: pageOpenGraphImages,
            }}
        />

        <Wrapper>
            <Grid>
                <Box gridColumnStart={[1, null, null, 2]} gridColumnEnd={[-1, null, null, -2]}>
                    <Hero topChildren={breadcrumbs} w={null} m={0} maxW="unset">
                        <Box maxWidth={[null, null, '580px']}>
                            <Heading mt={['72px', null, '112px']} variant={1} as="h1">
                                {pageTitle}
                            </Heading>
                            <Box mt={[4, null, 7]}>
                                <HtmlContent
                                    html={pageIntroText}
                                    override={{
                                        p: ({ children }) => <Intro>{children}</Intro>,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Hero>
                </Box>
            </Grid>
            <CardsGrid mb={[14, null, 17]}>{subPages.map(subPage => renderSubPage(subPage))}</CardsGrid>

            {relatedLinks?.length > 0 && (
                <>
                    <Heading variant={2} mb={[4, null, 7]}>
                        {relatedLinkHeading}
                    </Heading>
                    <RelatedGrid>
                        {relatedLinks?.map((link, i) => {
                            // eslint-disable-next-line prefer-destructuring
                            const element = link.linkObject.element;
                            const uri = element?.uri ? `/${element.uri}` : null;
                            return (
                                <Box key={i} gridColumn={['1/-1', 'span 6', null, 'span 4']}>
                                    <Link href={uri ?? link.linkObject.url} target={link.linkObject.target}>
                                        <PageCard
                                            size="s"
                                            title={link.label}
                                            textButtonProps={{
                                                iconRight:
                                                    link.linkObject.target === '_blank' ? (
                                                        <IconExternalLink />
                                                    ) : undefined,
                                            }}
                                        />
                                    </Link>
                                </Box>
                            );
                        })}
                    </RelatedGrid>
                </>
            )}
        </Wrapper>
        <Partners
            logos={partners?.partnerLogos.map(logo => ({
                href: logo.linkObject,
                logo: <Image src={logo.logoImage?.[0].url} alt={logo.title} />,
                title: logo.title,
            }))}
            partnerTitle={partners?.partnerListingsText}
            partners={partners?.partnerListings.map(partner => ({
                name: partner.partnerName,
                href: partner.linkUrl,
            }))}
        />
    </Stack>
);

const CardsGrid = styled(Grid)`
    row-gap: var(--gap);

    @media (max-width: ${({ theme }) => theme.tokens.MediaQueryS}) {
        row-gap: 24px;
    }
`;

const RelatedGrid = styled(Grid)`
    row-gap: 24px;
`;

export default NavigationTemplate;
