import { ComponentType, createContext, ReactNode, useContext, useMemo } from 'react';
import { Body, Note, SkeletonListItem, Stack } from 'designsystem';
import styled from '@emotion/styled';
import useRemoveQueryFilters from '../hooks/useRemoveQueryFilters';
import { QueryParamConfigMap } from 'use-query-params';
import { FormattedMessage } from 'react-intl';

export const SEARCH_RESULTS_AMOUNT = 10;

interface Props<Hit> {
    isLoading: boolean;
    hits?: Hit[];
    HitComponent: ComponentType<{ hit: Hit; offset: number }>;
    queryParams: QueryParamConfigMap;
    getId: (hit: Hit) => string;
    hasFilters?: boolean;
    offset: number;
    collectionPath: string;
    noResultsMessage?: ReactNode;
    noResultsWithFilterMessage?: ReactNode;
}

const SearchContext = createContext<{ queryParams?: QueryParamConfigMap; collectionPath?: string }>({});

export const useSearchContext = () => useContext(SearchContext);

const NoResultsWithFilterMessage = (
    <FormattedMessage
        defaultMessage="No results were found matching the filter."
        description="No search results: filters"
        id="search-no-filters"
    />
);
const NoResultsMessage = (
    <FormattedMessage
        defaultMessage="No results were found matching your search terms, try another term."
        description="No search results: term"
        id="search-no-term"
    />
);

const SearchResults = <Hit,>({
    isLoading,
    hits,
    hasFilters,
    HitComponent,
    getId,
    queryParams,
    offset,
    collectionPath,
    noResultsMessage = NoResultsMessage,
    noResultsWithFilterMessage = NoResultsWithFilterMessage,
}: Props<Hit>) => {
    const removeFilters = useRemoveQueryFilters(queryParams);
    const contextValues = useMemo(
        () => ({
            queryParams,
            collectionPath,
        }),
        [collectionPath, queryParams]
    );

    return (
        <SearchContext.Provider value={contextValues}>
            <Stack spacing={9}>
                {isLoading &&
                    [...new Array(SEARCH_RESULTS_AMOUNT).fill(null).keys()].map(index => (
                        <SkeletonListItem key={index} />
                    ))}

                {!isLoading &&
                    hits.length > 0 &&
                    hits?.map((hit, i) => <HitComponent offset={offset + i} key={getId(hit)} hit={hit} />)}

                {!isLoading && hits.length === 0 && (
                    <Note>
                        {!hasFilters ? (
                            noResultsMessage
                        ) : (
                            <Body>
                                {noResultsWithFilterMessage}{' '}
                                <Action onClick={() => removeFilters()}>
                                    <FormattedMessage defaultMessage="Clear all filters" id="clear-filters" />
                                </Action>
                            </Body>
                        )}
                    </Note>
                )}
            </Stack>
        </SearchContext.Provider>
    );
};

const Action = styled.a`
    cursor: pointer;
    font-weight: 600;
`;

export default SearchResults;
