import { useTheme } from '@emotion/react';
import { Body, Box, Checkbox, Heading, IconCheck, IconClose, Input, Select, Stack, FormLabel } from 'designsystem';
import { isValid } from 'iban';
import { FC, ReactNode } from 'react';
import { useFormContext, ValidationRule } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSection, getCountryNamesByLocale } from '../../utils/donationFormSection';
import Contribution from './Contribution';
import FormErrorMessage from './FormErrorMessage';

const DonationFormSection: FC<{ section: FormSection; contributionOptions?: number[] }> = ({
    section,
    contributionOptions,
}) => {
    const { register, getFieldState, watch, formState } = useFormContext();
    const { locale, formatMessage } = useIntl();
    const { country } = watch();
    const theme = useTheme();

    const renderValidationIcon = (fieldName: string): ReactNode | undefined => {
        const { error, isTouched } = getFieldState(fieldName, formState);
        if (!isTouched) {
            return undefined;
        }

        if (error) {
            return (
                <Box backgroundColor={theme.tokens.ColorNegative40} w={4} h={4} borderRadius="100%">
                    <IconClose color={theme.tokens.ColorNeutralWhite} />
                </Box>
            );
        }
        return (
            <Box backgroundColor={theme.tokens.ColorPositive70} w={4} h={4} borderRadius="100%" padding="2px">
                <IconCheck color={theme.tokens.ColorNeutralWhite} />
            </Box>
        );
    };

    const getPattern = (fieldName: string, selectedCountry: string): ValidationRule<RegExp> | undefined => {
        if (fieldName === 'phone') {
            return {
                value: /^\d+$/,
                message: formatMessage({ defaultMessage: 'Gebruik alleen cijfers' }),
            };
        }
        if (fieldName === 'zipCode') {
            // we only validate dutch zipcodes
            if (selectedCountry === 'NL') {
                return {
                    value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                    message: formatMessage({ defaultMessage: 'Ongeldige postcode' }),
                };
            }
            return {
                value: /^.{4,}$/,
                message: formatMessage({ defaultMessage: 'Ongeldige postcode' }),
            };
        }
        return undefined;
    };

    return (
        <Stack spacing={4}>
            {section.title && <Heading variant={4}>{section.title}</Heading>}
            {section.fields.map(formField => (
                <Box key={formField.name}>
                    {formField.name !== 'permission' && (
                        <FormLabel htmlFor={formField.name}>{formField.label}</FormLabel>
                    )}
                    <Box w={formField.width ? ['100%', null, formField.width] : ['100%', null, '67%']}>
                        {formField.type === 'text' && (
                            <>
                                {formField.name !== 'iban' && (
                                    <Input
                                        type={formField.type}
                                        {...register(formField.name, {
                                            pattern: getPattern(formField.name, country),
                                            maxLength: formField.name === 'phone' && {
                                                value: 15,
                                                message: formatMessage({
                                                    defaultMessage: 'Telefoonnummer kan maximaal 15 cijfers bevatten',
                                                }),
                                            },
                                            required:
                                                formField.name !== 'lastNamePrefix' &&
                                                formatMessage({
                                                    defaultMessage: 'Dit veld is verplicht',
                                                }),
                                        })}
                                        rightElement={renderValidationIcon(formField.name)}
                                    />
                                )}
                                {formField.name === 'iban' && (
                                    <Input
                                        type={formField.type}
                                        {...register(formField.name, {
                                            validate: value =>
                                                isValid(value) ||
                                                formatMessage({ defaultMessage: 'Ongeldig IBAN nummer' }),
                                        })}
                                        rightElement={renderValidationIcon(formField.name)}
                                    />
                                )}
                                <FormErrorMessage fieldName={formField.name} />
                            </>
                        )}
                        {formField.type === 'email' && (
                            <>
                                <Input
                                    type={formField.type}
                                    {...register(formField.name, {
                                        required: formatMessage({
                                            defaultMessage: 'Dit veld is verplicht',
                                        }),
                                        pattern: {
                                            // eslint-disable-next-line no-useless-escape
                                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: formatMessage({
                                                defaultMessage: 'Vul een geldig emailadres in',
                                            }),
                                        },
                                    })}
                                />
                                <FormErrorMessage fieldName={formField.name} />
                            </>
                        )}
                        {formField.type === 'select' && (
                            <>
                                <Select
                                    placeholder={formField.label as string}
                                    {...register(formField.name, {
                                        required: formatMessage({
                                            defaultMessage: 'Dit veld is verplicht',
                                            description: 'form.required',
                                        }),
                                    })}
                                >
                                    {getCountryNamesByLocale(locale).map(countryName => (
                                        <option key={countryName.value} value={countryName.value}>
                                            {countryName.label}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage fieldName={formField.name} />
                            </>
                        )}

                        {formField.type === 'checkbox' && (
                            <>
                                {formField.name === 'permission' && (
                                    <Body
                                        color={theme.tokens.ColorGrey60}
                                        fontSize={theme.tokens.FontSize1}
                                        lineHeight={theme.tokens.LineHeightXs}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Ik machtig hierbij de Stichting International Documentary Film Festival Amsterdam om tot
                                wederopzegging de bijdrage als Vriend van IDFA, zoals hierboven gekozen, maandelijks af
                                te schrijven van mijn rekening."
                                            description="form.permission"
                                        />
                                    </Body>
                                )}
                                <Checkbox
                                    mt={4}
                                    {...register(formField.name, {
                                        required: formatMessage({
                                            defaultMessage: 'Dit veld is verplicht',
                                        }),
                                    })}
                                >
                                    {formField.label}
                                </Checkbox>
                                <FormErrorMessage fieldName={formField.name} />
                            </>
                        )}
                    </Box>

                    {formField.type === 'contribution' && contributionOptions && (
                        <Contribution contributionOptions={contributionOptions} />
                    )}
                </Box>
            ))}
        </Stack>
    );
};

export default DonationFormSection;
