import { DocschoolFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const docschoolFilterMessages = defineMessages<DocschoolFilterEnum>({
    [DocschoolFilterEnum.EducationLevel]: { defaultMessage: 'Onderwijsniveau' },
    [DocschoolFilterEnum.Theme]: { defaultMessage: 'Thema' },
    [DocschoolFilterEnum.LengthInMinutes]: { defaultMessage: 'Lengte' },
    [DocschoolFilterEnum.YearOfProduction]: { defaultMessage: 'Jaar' },
    [DocschoolFilterEnum.CountriesOfProduction]: { defaultMessage: 'Land' },
    [DocschoolFilterEnum.FullyDutchSpoken]: { defaultMessage: 'Volledig in het Nederlands' },
});

export default docschoolFilterMessages;
