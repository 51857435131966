import { Box, BoxProps, Flex, HStack, Stack } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { AnchorHTMLAttributes, ReactNode, useCallback, useEffect, useState } from 'react';
import ImageCarousel, { CarouselDots } from '../content/BackgroundCarousel';
import { ImgProps } from '../media/Img';
import IconCaret from '../trinket/icon/IconCaret';
import Body from '../typography/Body';
import Heading from '../typography/Heading';

type BaseProps = Omit<BoxProps, 'backgroundImage'> & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick'>;

interface Slide {
    key: string;
    image: ImgProps;
    title: ReactNode;
}

interface Props extends BaseProps {
    title: string;
    description: string;
    slides: Slide[];
    interval?: number;
}

const CarouselTile: React.FC<Props> = ({ title, description, slides, interval = 4000, ...rest }) => {
    const theme = useTheme();
    const [slideIndex, setSlideIndex] = useState(0);
    const onNext = useCallback(() => setSlideIndex((slideIndex + 1) % slides.length), [slideIndex, slides.length]);

    useEffect(() => {
        const timer = setInterval(() => onNext(), interval);
        return () => clearInterval(timer);
    }, [interval, onNext]);

    return (
        <Tile
            display="flex"
            overflow="hidden"
            pos="relative"
            p={8}
            bg="black"
            color={theme.tokens.SyntaxTextColorOnDark}
            textDecoration="none"
            alignItems="flex-end"
            width="100%"
            height="100%"
            boxSizing="border-box"
            {...rest}
        >
            <Box
                pos="absolute"
                top="0"
                right="0"
                bottom="0"
                left="0"
                _before={{
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bg: 'linear-gradient(rgba(0, 0, 0, 0.2), #000000)',
                    zIndex: 1,
                    opacity: 0.9,
                }}
            >
                <ImageCarousel slides={slides} slideIndex={slideIndex} />
            </Box>
            <Flex flexDir="row" alignItems="center" w="100%" zIndex={1}>
                <HStack spacing={6} position="absolute" top={8} left={8} zIndex={3}>
                    <CarouselDots slides={slides.length} currentSlide={slideIndex} onClick={setSlideIndex} />
                    <Body>{slides[slideIndex]?.title}</Body>
                </HStack>
                <Stack spacing={4} flexGrow={1}>
                    <Heading variant={2}>{title}</Heading>
                    <Body fontWeight="xl">{description}</Body>
                </Stack>
                <Box boxSize={8}>
                    <IconCaret direction="right" />
                </Box>
            </Flex>
        </Tile>
    );
};

const Tile = styled.a<BaseProps>``.withComponent(Box);
Tile.defaultProps = {
    as: 'a',
};

export default CarouselTile;
