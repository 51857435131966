import React, { PropsWithChildren, ReactNode } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Body from '../typography/Body';

interface Props extends PropsWithChildren {
    heading: ReactNode;
    action?: ReactNode;
}

const FilterSection: React.FC<Props> = ({ heading, action, children, ...args }) => (
    <Box {...args}>
        <Header>
            <Flex alignItems="center" py={4}>
                <GroupTitle flexGrow={1}>{heading}</GroupTitle>
                {action}
            </Flex>
        </Header>
        <Flex flexWrap="wrap" pb={6} gap={2}>
            {children}
        </Flex>
    </Box>
);

const Header = styled.div(
    ({ theme }) => css`
        -webkit-appearance: none;
        border: none;
        background: transparent;
        padding: 0;
        display: block;
        width: 100%;
        text-align: left;

        &:focus {
            border-color: ${theme.tokens.SyntaxColorInputFocusBorder};
            box-shadow: 0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight};
            outline: none;
        }
    `
);

const GroupTitle = styled(Body)(
    ({ theme }) => css`
        font-weight: ${theme.tokens.FontWeightXl};
    `
);

export default FilterSection;
