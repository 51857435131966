import { FC, ReactNode } from 'react';
import Body from '../typography/Body';

interface LabelProps {
    htmlFor: string;
    children: ReactNode;
}

const FormLabel: FC<LabelProps> = ({ htmlFor, children }) => (
    <Body as="label" htmlFor={htmlFor} mb={2}>
        {children}
    </Body>
);

export default FormLabel;
