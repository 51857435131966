import {
    Box,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput as NumberInputComponent,
    NumberInputField,
    NumberInputStepper
} from '@chakra-ui/react';
import React from 'react';
import IconCaret from '../trinket/icon/IconCaret';
import Body from '../typography/Body';
import Heading from '../typography/Heading';

interface Props {
    numberFormat?: string;
    size: string;
    placeholder: string;
}
const NumberInput = React.forwardRef<HTMLInputElement, Props>(({ numberFormat, size, ...rest }, ref) => (
    <NumberInputComponent defaultValue={0} size={size} ref={ref}>
        {numberFormat && (
            <Box pos="absolute" top="50%" left={size === 'lg' ? 7 : 4} transform="translateY(-50%)">
                {size === 'lg' ? <Heading variant={4}>{numberFormat}</Heading> : <Body>{numberFormat}</Body>}
            </Box>
        )}
        <NumberInputField {...rest} />
        <NumberInputStepper>
            <NumberIncrementStepper>
                <IconCaret direction="up" />
            </NumberIncrementStepper>
            <NumberDecrementStepper>
                <IconCaret direction="down" />
            </NumberDecrementStepper>
        </NumberInputStepper>
    </NumberInputComponent>
));

NumberInput.displayName = 'NumberInput';

export default NumberInput;
