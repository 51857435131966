import React, { useMemo } from 'react';

import { AspectRatio, Box, Stack, StackProps, Text } from '@chakra-ui/react';

import { useTheme } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';
import getAspectRatio from '../../utils/getAspectRatio';
import styled from '@emotion/styled';

interface Props extends StackProps {
    caption?: string;
    image: Omit<ImgProps, 'fill'>;
    large?: boolean;
}

const ContentBlockImage: React.FC<Props> = ({ caption, image, large = false, ...rest }) => {
    const theme = useTheme();
    const ratio = useMemo(
        () => (getAspectRatio(image.width, image.height) >= 1 ? getAspectRatio(image.width, image.height) : 1),
        [image.width, image.height]
    );
    return (
        <Stack
            mt={large ? 6 : null}
            mb={large ? 8 : 6}
            spacing={4}
            color={theme.tokens.SyntaxTextColorNeutralMuted}
            data-content-video
            {...rest}
        >
            <Box bg={theme.tokens.SyntaxBackgroundNeutralDark}>
                <AspectRatio ratio={ratio}>
                    <div>
                        <StyledImg fill {...image} />
                    </div>
                </AspectRatio>
            </Box>
            {caption && <Text fontSize={2}>{caption}</Text>}
        </Stack>
    );
};

const StyledImg = styled(Img)`
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

export default ContentBlockImage;
