import { Modal, ModalCloseButton, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import IconClose from '../trinket/icon/IconClose';
import { useTheme } from '@emotion/react';
// importing react-player directly causes hydration error in newest version of NextJS: https://github.com/cookpete/react-player/issues/1428

interface Props extends Omit<ModalProps, 'children'> {
    closeLabel: ReactNode;
    children?: ReactNode;
}

const ContainerModal: React.FC<Props> = ({ closeLabel = 'Sluiten', children, ...rest }) => {
    const { tokens } = useTheme();
    return (
        <Modal variant="media" size="lg" {...rest}>
            <ModalOverlay />
            <ModalContent as="div" bg="transparent" textColor={tokens.SyntaxTextColorOnDark}>
                {children}
            </ModalContent>
            <ModalCloseButton onClick={rest.onClose}>
                <span>{closeLabel} </span>
                <IconClose />
            </ModalCloseButton>
        </Modal>
    );
};

export default ContainerModal;
