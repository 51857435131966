import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconCaret from '../trinket/icon/IconCaret';
import Body, { Props as BodyProps } from '../typography/Body';
import { Box, BoxProps } from '@chakra-ui/react';

type BaseProps = AnchorHTMLAttributes<HTMLAnchorElement> & PropsWithChildren & BoxProps;
export interface Props extends BaseProps {
    size?: 's' | 'm' | 'l';
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    colored?: boolean;
    clamp?: BodyProps['clamp'];
}

const TextButton: React.FC<Props> = ({
    children,
    size = 'm',
    iconLeft,
    iconRight = <IconCaret />,
    colored = false,
    clamp = 1,
    ...restProps
}) => (
    <StyledAnchor size={size} colored={colored} fontWeight="xl" {...restProps}>
        {iconLeft}
        <Label size={size} clamp={clamp}>
            {children}
        </Label>
        {iconRight}
    </StyledAnchor>
);

const StyledAnchor = styled(Box)<Pick<Props, 'size' | 'colored'>>(
    ({ size, colored, theme }) =>
        css`
            display: inline-flex;
            align-items: center;
            color: ${colored ? theme.tokens.TextButtonForeground : 'currentColor'};
            text-decoration: none;
            gap: ${theme.tokens.Sizing1};
            cursor: pointer;

            & > svg {
                flex-shrink: 0;
            }

            ${size === 'l' &&
            css`
                & > svg {
                    width: ${theme.tokens.Sizing7};
                    height: ${theme.tokens.Sizing7};
                }
            `}
            ${size === 'm' &&
            css`
                & > svg {
                    width: ${theme.tokens.Sizing6};
                    height: ${theme.tokens.Sizing6};
                }
            `}
            ${size === 's' &&
            css`
                & > svg {
                    width: ${theme.tokens.Sizing5};
                    height: ${theme.tokens.Sizing5};
                }
            `}

            &:hover, &:focus {
                text-decoration-line: underline;
            }

            &:focus {
                border-color: ${theme.tokens.SyntaxColorInputFocusBorder};
                box-shadow: 0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight};
                outline: none;
            }
        `
);
StyledAnchor.defaultProps = {
    as: 'a',
};

const Label = styled(Body)<Pick<Props, 'size'>>(
    ({ size, theme }) =>
        css`
            line-height: ${theme.tokens.LineHeightXs};
            ${size === 'l' &&
            css`
                font-size: ${theme.tokens.TextButtonLFontSize};
                font-weight: ${theme.tokens.FontWeightXxl};
            `}
            ${size === 'm' &&
            css`
                font-size: ${theme.tokens.TextButtonMFontSize};
            `}
            ${size === 's' &&
            css`
                font-size: ${theme.tokens.TextButtonSFontSize};
            `}
        `
);

export default TextButton;
