import { useTheme } from '@emotion/react';
import { Body, Button, ConfirmationTile, Heading, IconCheck, IconClose, Stack } from 'designsystem';
import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SingleDonationInput, useSingleDonationMutation } from '../../gql/api';
import { contactFormSection, contributionFormSection, nameFormSection } from '../../utils/donationFormSection';
import DonationFormSection from './DonationFormSection';
import FormErrorMessage from './FormErrorMessage';
import { gtm } from 'shared';

interface SingleDonationFormProps {
    formConfirmationHeading: string;
    formConfirmationMessage: string;
}

const SingleDonationForm: FC<SingleDonationFormProps> = ({ formConfirmationHeading, formConfirmationMessage }) => {
    const { asPath, push, query } = useRouter();
    const theme = useTheme();

    const formMethods = useForm<SingleDonationInput & { serverError?: string }>({
        defaultValues: {
            contributionInEuros: 5,
        },
        mode: 'onBlur',
    });

    const { handleSubmit, clearErrors, setError } = formMethods;

    const { mutate, isLoading, isSuccess } = useSingleDonationMutation({
        onSuccess(data) {
            if (data.initiateSingleDonation.redirectUrl) {
                push(data.initiateSingleDonation.redirectUrl);
            }
        },
    });

    useEffect(() => {
        if (isSuccess) {
            window.scrollTo(0, 0);
        }
    }, [isSuccess]);

    return (
        <>
            {!query.paymentStatus && (
                <FormProvider {...formMethods}>
                    <form
                        onSubmit={handleSubmit((values: SingleDonationInput) => {
                            if (isLoading) {
                                return;
                            }
                            clearErrors('serverError');
                            try {
                                mutate({
                                    input: {
                                        ...values,
                                        successUrl: `${process.env.NEXT_PUBLIC_BASE_URL}${asPath}?paymentStatus=success`,
                                        failureUrl: `${process.env.NEXT_PUBLIC_BASE_URL}${asPath}?paymentStatus=fail`,
                                    },
                                });
                                gtm.event('form_submit', {
                                    form_name: 'eenmalige-donatie',
                                    value: values.contributionInEuros,
                                });
                            } catch (err) {
                                setError('serverError', err);
                            }
                        })}
                    >
                        <Stack spacing={[8, null, 10]} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            <DonationFormSection section={nameFormSection} />
                            <DonationFormSection
                                section={{
                                    ...contactFormSection,
                                    fields: contactFormSection.fields.filter(formField => formField.name === 'email'),
                                }}
                            />
                        </Stack>

                        <Stack spacing={10} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            <Heading variant={2}>
                                <FormattedMessage defaultMessage="Bijdrage" />
                            </Heading>
                            <DonationFormSection section={contributionFormSection} contributionOptions={[5, 10, 25]} />
                        </Stack>

                        <Button type="submit" isLoading={isLoading}>
                            <FormattedMessage defaultMessage="Verstuur" />
                        </Button>
                        <FormErrorMessage fieldName="serverError" />
                    </form>
                </FormProvider>
            )}
            {query.paymentStatus === 'success' && (
                <ConfirmationTile
                    id="confirmation"
                    maxWidth={['100%', null, '50%']}
                    icon={<IconCheck color={theme.tokens.ColorRed60} />}
                >
                    <Heading variant={4}>{formConfirmationHeading}</Heading>
                    <Body>{formConfirmationMessage}</Body>
                </ConfirmationTile>
            )}
            {query.paymentStatus === 'fail' && (
                <ConfirmationTile
                    id="confirmation"
                    maxWidth={['100%', null, '50%']}
                    icon={<IconClose color={theme.tokens.ColorRed60} />}
                >
                    <Heading variant={4}>
                        <FormattedMessage defaultMessage="Betaling mislukt" />
                    </Heading>
                    <Body>
                        <FormattedMessage defaultMessage="Er is iets misgegaan bij het verwerken van de betaling" />
                    </Body>
                </ConfirmationTile>
            )}
        </>
    );
};

export default SingleDonationForm;
