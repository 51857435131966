import type { ErrorProps } from 'next/error';
import { Body, Box, Heading, IconExternalLink, Stack, TextButton, Tile, WideGrid, Wrapper } from 'designsystem';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { FC } from 'react';
import { captureException } from '@sentry/nextjs';

interface AppErrorProps extends ErrorProps {
    err?: Error;
    hasGetInitialPropsRun?: boolean;
}

const ErrorTemplate: FC<AppErrorProps> = ({ err, hasGetInitialPropsRun, statusCode }) => {
    if (!hasGetInitialPropsRun && err && statusCode !== 404) {
        captureException(err);
    }
    const { tokens } = useTheme();
    return (
        <Wrapper>
            <WideGrid py={8}>
                <Stack
                    gridColumn={['1 / -1', undefined, undefined, '3 / -3']}
                    gridRow={1}
                    py={12}
                    spacing={7}
                    textAlign="center"
                >
                    <Heading variant={1}>
                        {statusCode === 404 ? (
                            <FormattedMessage
                                defaultMessage="Helaas, deze pagina kunnen we niet vinden."
                                id="error.404.title"
                            />
                        ) : (
                            <FormattedMessage defaultMessage="Er is helaas iets misgegaan." id="error.misc.title" />
                        )}
                    </Heading>
                    <Body maxW="480px" mx="auto">
                        <FormattedMessage
                            defaultMessage={`Maar... we helpen je graag verder op weg! Blijft een link het niet doen? Laat het ons weten, zodat we het snel kunnen herstellen.

Neem ook een kijkje op een van onze nieuwe sites:`}
                            id="error.misc.body"
                        />
                    </Body>
                </Stack>
                <Box
                    display="grid"
                    gap={[2, null, 4, null, 7]}
                    gridRow={2}
                    gridColumn="1 /-1"
                    gridTemplateColumns={['1fr', null, null, null, '1fr 1fr 1fr']}
                >
                    <Tile colorScheme="institute">
                        <Stack spacing={3}>
                            <Heading variant={4}>
                                <FormattedMessage defaultMessage="IDFA Institute " />
                            </Heading>
                            <Body>
                                <FormattedMessage defaultMessage="Stap in de wereld van IDFA en ontdek de kracht van creatieve documentaires. Verken wat er te zien is en hoe je kunt deelnemen." />
                            </Body>
                            <TextButton
                                href={process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE}
                                size="s"
                                iconRight={<IconExternalLink />}
                            >
                                <FormattedMessage defaultMessage="Naar de institute website" />
                            </TextButton>
                        </Stack>
                    </Tile>
                    <Tile colorScheme="festival">
                        <Stack spacing={3}>
                            <Heading variant={4}>
                                <FormattedMessage defaultMessage="IDFA Festival " />
                            </Heading>
                            <Body>
                                <FormattedMessage defaultMessage="Ontdek het beste van creatieve documentaires op het International Documentary Film Festival Amsterdam." />
                            </Body>
                            <TextButton
                                href={process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}
                                size="s"
                                iconRight={<IconExternalLink />}
                            >
                                <FormattedMessage defaultMessage="Naar de festival website" />
                            </TextButton>
                        </Stack>
                    </Tile>
                    <Tile colorScheme="professionals">
                        <Stack spacing={3} color={`${tokens.SyntaxTextColorDefault} !important`}>
                            <Heading variant={4}>
                                <FormattedMessage defaultMessage="IDFA Professionals " />
                            </Heading>
                            <Body>
                                <FormattedMessage defaultMessage="IDFA Professionals verbindt filmmakers en professionals uit de internationale industrie om de creatieve documentaires van morgen te creëren en te delen." />
                            </Body>
                            <TextButton
                                href={process.env.NEXT_PUBLIC_PILLAR_URL_PROFESSIONALS}
                                size="s"
                                iconRight={<IconExternalLink />}
                            >
                                <FormattedMessage defaultMessage="Naar de professionals website" />
                            </TextButton>
                        </Stack>
                    </Tile>
                </Box>
            </WideGrid>
        </Wrapper>
    );
};

export default ErrorTemplate;
