import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

const useTheaterModal = () => {
    const [videoSrc, setVideoSrc] = useState<string>();
    const disclosure = useDisclosure();
    const handleVideoClick = useCallback(
        (src: string) => {
            setVideoSrc(src);
            disclosure.onOpen();
        },
        [disclosure]
    );

    return {
        ...disclosure,
        videoSrc,
        handleVideoClick,
    };
};

export default useTheaterModal;
