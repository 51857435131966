import { BerthaFundFilmDetailsFragment } from '../gql/api';

/** editionsTypes where 'IDFA', 'IDFA Forum', 'IDFA Fund', 'IDFAcademy' they have been changed to IDFA based on
 * IDFA24-16  as only films that have been shown at the festival should have an archive box */

const editionsTypes = ['IDFA'];

interface Film {
    otherEditions?: BerthaFundFilmDetailsFragment['otherEditions'];
}

const filmInArchive = (film: Film) =>
    !!film.otherEditions?.find(
        edition => editionsTypes.findIndex(editionType => editionType === edition.edition.editionType.description) >= 0
    );

export default filmInArchive;
