import { Body, Box, Wrap } from 'designsystem';
import Link from 'next/link';
import { FC } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import slugify from 'slugify';
import parseFullName from '../lib/parseFullName';

interface Props {
    credits: Array<{
        fullName?: string;
        email?: string;
        person?: {
            fullName?: string;
            id?: string;
        };
    }>;
    shouldRenderLinks?: boolean;
    shouldRenderEmail?: boolean;
}

const renderLine = (
    { email, person, ...credit }: Props['credits'][number],
    index: number,
    credits: Props['credits'],
    intl: IntlShape,
    shouldRenderLinks = true,
    shouldRenderEmail = true
) => {
    const { fullName } = credit;
    if ((person && shouldRenderLinks) || (email && shouldRenderEmail)) {
        if (shouldRenderLinks && !fullName && shouldRenderEmail && !email) {
            return null;
        }
        if (shouldRenderEmail && email) {
            return (
                <Link key={person?.id ?? index} href={`mailto:${email}`}>
                    <Body color="darkblue.60" textDecoration="underline" key={email} minWidth="fit-content">
                        {fullName ? parseFullName(fullName, intl) : email}
                        {index !== credits.length - 1 && <>, </>}
                    </Body>
                </Link>
            );
        }
        // credited person exists in DB -> link to person page
        return (
            <Link key={person?.id ?? index} href={`/person/${person?.id}/${slugify(fullName, { lower: true })}`}>
                <Body color="darkblue.60" textDecoration="underline" minWidth="fit-content">
                    {fullName && parseFullName(fullName, intl)}
                    {index !== credits.length - 1 && <>, </>}
                </Body>
            </Link>
        );
    }
    if (!fullName) {
        return null;
    }
    return (
        // credited person doesn't exist in DB -> show name only
        <Body key={fullName} minWidth="fit-content">
            {fullName && parseFullName(fullName, intl)}
            {index !== credits.length - 1 && <>, </>}
        </Body>
    );
};

const CreditLine: FC<Props> = ({ credits, shouldRenderLinks, shouldRenderEmail }) => {
    const intl = useIntl();
    return (
        <>
            <Box display={['none', null, 'block']}>
                <Wrap spacing={1}>
                    {credits.map((credit, index) =>
                        renderLine(credit, index, credits, intl, shouldRenderLinks, shouldRenderEmail)
                    )}
                </Wrap>
            </Box>
            <Box display={['block', null, 'none']}>
                <Wrap>
                    {credits.map((credit, index) =>
                        renderLine(credit, index, credits, intl, shouldRenderLinks, shouldRenderEmail)
                    )}
                </Wrap>
            </Box>
        </>
    );
};

export default CreditLine;
