import { Body, Button, Heading, Input, NewsletterModal, Stack } from 'designsystem';
import React, { ComponentProps, FC, FormHTMLAttributes } from 'react';
import { useTheme } from '@emotion/react';
import { FormattedMessage, useIntl } from 'react-intl';
import gtm from '../lib/gtm';

interface Props extends Omit<ComponentProps<typeof NewsletterModal>, 'children'> {
    formProps: FormHTMLAttributes<unknown>;
}

const NewsletterSignup: FC<Props> = ({ formProps, ...rest }) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    return (
        <NewsletterModal closeOnOverlayClick={false} closeOnEsc={false} {...rest}>
            <Stack
                {...formProps}
                as="form"
                onSubmit={() => {
                    gtm.event('form_submit', {
                        form_name: 'newsletter',
                    });
                    rest.onClose();
                }}
            >
                <Heading variant={3} mb={4} pr={8}>
                    <FormattedMessage defaultMessage="Blijf op de hoogte" />
                </Heading>
                <Stack spacing={6}>
                    <Body>
                        <FormattedMessage defaultMessage="Het laatste nieuws van IDFA in je mail" />{' '}
                    </Body>
                    <Input
                        height={9}
                        px={7}
                        tabIndex={0}
                        borderRadius={theme.tokens.BorderRadiusL}
                        placeholder={formatMessage({ defaultMessage: 'Vul je e-mailadres in' })}
                        _placeholder={{ color: theme.tokens.ColorGrey50 }}
                        type="email"
                        isRequired
                        name="EMAIL"
                        id="mce-EMAIL"
                    />
                    <Button variant="solidOnDark" type="submit">
                        <FormattedMessage id="zBGjlz" defaultMessage="Aanmelden" />
                    </Button>
                </Stack>
            </Stack>
        </NewsletterModal>
    );
};
export default NewsletterSignup;
