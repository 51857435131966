import { useDisclosure } from 'designsystem';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { getATCartUrl } from '../../lib/getActiveTicketsUrl';
import isLoggedIn from '../../lib/isLoggedIn';
import TicketModal from '../modals/TicketModal';
import { FormattedMessage } from 'react-intl';
import ATIFrame from '../activetickets/ATIFrame';
import PurchaseSuccessModal from '../modals/PurchaseSuccessModal';
import PurchaseFailedModal from '../modals/PurchaseFailedModal';
import useSignIn from '../../hooks/useSignIn';

export interface CartContextValues {
    onOpenCart: () => void;
    onOpenPurchaseSuccess: () => void;
    onOpenPurchaseFailed: () => void;
}

const CartContext = createContext<CartContextValues>({
    onOpenCart: () => {},
    onOpenPurchaseSuccess: () => {},
    onOpenPurchaseFailed: () => {},
});

export const CartProvider: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
    const { locale } = useRouter();
    const { status } = useSession();
    const loggedIn = useMemo(() => isLoggedIn(status), [status]);

    const cartDisclosureProps = useDisclosure();
    const { onOpen: onCartOpen } = cartDisclosureProps;
    const { signIn } = useSignIn();
    const onOpenCart: CartContextValues['onOpenCart'] = useCallback(() => {
        if (loggedIn) {
            onCartOpen();
        } else {
            signIn();
        }
    }, [loggedIn, onCartOpen, signIn]);

    const purchaseSuccessDisclosureProps = useDisclosure();
    const purchaseFailedDisclosureProps = useDisclosure();
    const values: CartContextValues = useMemo(
        () => ({
            onOpenCart,
            onOpenPurchaseSuccess: purchaseSuccessDisclosureProps.onOpen,
            onOpenPurchaseFailed: purchaseFailedDisclosureProps.onOpen,
        }),
        [onOpenCart, purchaseFailedDisclosureProps.onOpen, purchaseSuccessDisclosureProps.onOpen]
    );

    return (
        <CartContext.Provider value={values} {...rest}>
            {children}
            <TicketModal title={<FormattedMessage defaultMessage="Winkelmandje" id="cart" />} {...cartDisclosureProps}>
                <ATIFrame src={getATCartUrl({ locale })} w="100%" h="100%" onContinue={cartDisclosureProps.onClose} />
            </TicketModal>
            <PurchaseSuccessModal {...purchaseSuccessDisclosureProps} />
            <PurchaseFailedModal {...purchaseFailedDisclosureProps} />
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);

export default CartProvider;
