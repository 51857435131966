import { useTheme } from '@emotion/react';
import {
    Body,
    Box,
    Button,
    ConfirmationTile,
    Divider,
    Heading,
    IconClose,
    Modal,
    ModalProps,
    VStack,
} from 'designsystem';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { Optional } from 'utility-types';

type Props = Omit<Optional<ModalProps, 'title' | 'closeLabel'>, 'children'>;

const PurchaseFailedModal: React.FC<Props> = ({ ...rest }) => {
    const { tokens } = useTheme();
    const { locale } = useRouter();
    return (
        <Modal
            closeLabel={<FormattedMessage defaultMessage="Sluiten" description="Close modal" />}
            variant="iframe"
            {...rest}
        >
            <ConfirmationTile h="100%" icon={<IconClose color={tokens.SyntaxColorErrorForeground} />}>
                <VStack spacing={8} maxW="640px" alignItems="flex-start" textAlign="left">
                    <VStack spacing={4}>
                        <Heading variant={2} as="h2">
                            <FormattedMessage
                                defaultMessage="Oops! Something went wrong..."
                                id="purchase-confirmation.failure.title"
                            />
                        </Heading>
                        <Body>
                            <FormattedMessage
                                defaultMessage="We're sorry, but it seems there was an issue processing your payment. Your tickets have not been purchased, and no funds have been deducted from your account."
                                id="purchase-confirmation.failure.description"
                            />
                        </Body>
                    </VStack>
                    <Divider />
                    <VStack spacing={4} alignItems="flex-start">
                        {locale === 'nl' ? (
                            <>
                                <Heading variant={5}>Wat nu te doen?</Heading>
                                <Body as="ol" listStyleType="decimal">
                                    <li>
                                        Controleer je betaalinformatie en zorg ervoor dat alle gegevens correct zijn
                                        ingevoerd.
                                    </li>
                                    <li>Probeer het opnieuw na een paar minuten.</li>
                                    <li>Overweeg een andere betaalmethode te gebruiken.</li>
                                    <li>Neem contact op met je bank of betaalservice als het probleem aanhoudt.</li>
                                    <li>
                                        Als je verdere hulp nodig hebt, kun je ons bereiken via info@idfa.nl of bel ons
                                        op{' '}
                                        <Box as="a" whiteSpace="nowrap" href="tel:+31206273329">
                                            020 627 3329
                                        </Box>
                                        .
                                    </li>
                                </Body>
                            </>
                        ) : (
                            <>
                                <Heading variant={5}>What can you do now?</Heading>
                                <Body as="ol" listStyleType="decimal">
                                    <li>
                                        Double-check your payment details and ensure all information is correctly
                                        entered.
                                    </li>
                                    <li>Try again in a few minutes.</li>
                                    <li>Consider using a different payment method.</li>
                                    <li>Contact your bank or payment service if the issue persist.</li>
                                    <li>
                                        If you need further assistance, you can reach us at info@idfa.nl or call us at{' '}
                                        <Box as="a" whiteSpace="nowrap" href="tel:+31206273329">
                                            020 627 3329
                                        </Box>
                                    </li>
                                </Body>
                            </>
                        )}
                    </VStack>
                    <Box mx="auto">
                        <Button variant="solid" onClick={rest.onClose}>
                            <FormattedMessage defaultMessage="Close" description="Close modal" />
                        </Button>
                    </Box>
                </VStack>
            </ConfirmationTile>
        </Modal>
    );
};

export default PurchaseFailedModal;
