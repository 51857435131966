import { menuAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type MenuTheme = Omit<MultiStyleConfig<typeof menuAnatomy>, 'parts'>;

/**
 * Theme for a Chakra Menu. Include this into your theme.
 */
const MenuTheming: MenuTheme = {
    baseStyle: ({ theme: { tokens } }) => ({
        button: {
            bg: tokens.ButtonPrimaryEnabledBackground,
            color: tokens.ButtonPrimaryEnabledForeground,
            _hover: {
                bg: tokens.ButtonPrimaryHoverBackground,
                color: tokens.ButtonPrimaryHoverForeground,
            },
            _focusVisible: {
                bg: tokens.ButtonPrimaryFocusBackground,
                color: tokens.ButtonPrimaryFocusForeground,
                outlineColor: tokens.ButtonPrimaryFocusBackground,
            },
            _active: {
                bg: tokens.ButtonPrimaryActiveBackground,
                color: tokens.ButtonPrimaryActiveForeground,
            },
            _disabled: {
                bg: tokens.ButtonPrimaryDisabledBackground,
                color: tokens.ButtonPrimaryDisabledForeground,
            },
            fontFamily: 'inherit',
            fontSize: tokens.SyntaxBodyTextLRegularFontSize,
            lineHeight: 'xxs',
            fontWeight: 'l',
            borderRadius: 'lg',
            gap: 3,
            outlineWidth: tokens.BorderWidthM,
            outlineColor: 'transparent',
            outlineOffset: '3px',
            borderStyle: 'solid',
            borderWidth: 0,
        },
        list: {
            bg: tokens.SyntaxBackgroundNeutralDefault,
            display: 'flex',
            flexDir: 'column',
            p: 4,
            gap: 2,
            color: tokens.SyntaxTextColorDefault,
            borderRadius: 24,
            boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
            zIndex: 100,
        },
        divider: {
            display: 'none',
        },
        item: {
            fontFamily: 'inherit',
            fontSize: tokens.SyntaxBodyTextLRegularFontSize,
            lineHeight: 'xxs',
            h: 7,
            p: 2,
        },
    }),
    sizes: {
        md: {
            button: {
                minW: 9,
                px: 6,
                height: 9,
                svg: {
                    w: 6,
                    h: 6,
                },
                '.chakra-button__icon': {
                    bg: 'transparent',
                    m: 0,
                },
            },
        },
    },
    variants: {
        outline: ({ theme: { tokens } }) => ({
            list: {
                boxShadow: 'none',
                color: 'currentColor',
                borderColor: 'currentColor',
                borderWidth: tokens.BorderWidthM,
                borderStyle: 'solid',
            },
        }),
        solid: {},
        solidOnDark: ({ theme: { tokens } }) => ({
            // todo: tokenize this properly
            button: {
                bg: tokens.ColorNeutralWhite,
                color: tokens.ColorNeutralBlack,
                _hover: {
                    bg: tokens.ColorNeutral20,
                },
                _focusVisible: {
                    bg: tokens.ColorNeutral20,
                    outlineColor: tokens.ColorNeutral20,
                },
                _active: {
                    bg: tokens.ColorNeutral30,
                },
                _disabled: {
                    bg: tokens.ColorNeutral30,
                    color: tokens.ColorNeutral60,
                },
            },
        }),
    },
    defaultProps: {
        size: 'md',
        variant: 'solid',
    },
};

export default MenuTheming;
