import { Box, BoxProps, ButtonProps, Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import Button from '../action/Button';
import Wrapper from '../layout/Wrapper';
import Heading from '../typography/Heading';

interface EditionNavProps extends BoxProps {
    editions: ReactElement<ButtonProps>[];
    translatedTitle?: ReactElement;
}

const EditionNavigation: FC<EditionNavProps> = ({ editions, translatedTitle, ...rest }) => (
    <EditionNavContainer w="100%" pt={[9, 8]} pb={[9]} {...rest}>
        <Wrapper>
            <Heading variant={3} mb={5}>
                {translatedTitle ?? 'Editions'}
            </Heading>
            <Flex gap={3} flexWrap="wrap">
                {editions.map(edition => edition)}
            </Flex>
        </Wrapper>
    </EditionNavContainer>
);

const EditionNavContainer = styled(Box)(
    ({ theme }) => css`
        background-color: ${theme.tokens.ColorNeutral10};
    `
);

export const EditionNavItem = styled(Button)(
    ({ theme }) => css`
        @media screen and (max-width: ${theme.tokens.MediaQueryS}) {
            padding: 0 ${theme.tokens.Sizing5};
            max-height: ${theme.tokens.Sizing8};
        }
    `
);
EditionNavItem.defaultProps = {
    variant: 'outline',
};

export default EditionNavigation;
