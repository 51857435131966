import React, { ReactElement, ReactNode, useMemo } from 'react';

import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tr,
} from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import useBrowserSize, { browserSizes } from '../../hooks/useBrowserSize';
import Grid from '../layout/Grid';
import Img, { ImgProps } from '../media/Img';
import { Props as MetaProps } from '../meta/Meta';
import IconAdd from '../trinket/icon/IconAdd';
import IconRemove from '../trinket/icon/IconRemove';
import Heading from '../typography/Heading';
import TextButton from '../action/TextButton';

interface Props {
    name: ReactNode;
    meta: ReactElement<MetaProps>;
    image: ImgProps;
    tableData?: { key: string; value: ReactNode }[];
    lessInfoLabel?: ReactNode;
    moreInfoLabel?: ReactNode;
}

const ExtendedPersonCard: React.FC<Props> = ({ name, image, meta, tableData, lessInfoLabel, moreInfoLabel }) => {
    const theme = useTheme();
    const browserSize = useBrowserSize();
    const aspectRatio = useMemo(
        () => (browserSizes.indexOf(browserSize) <= browserSizes.indexOf('s') ? 16 / 9 : 3 / 2),
        [browserSize]
    );

    return (
        <Container as="article">
            <Grid gap={[0]}>
                <Box gridColumn={['1/-1', null, '1/6', '1/5', '1/4']}>
                    <AspectRatio.Root ratio={aspectRatio}>
                        <Img fill {...image} />
                    </AspectRatio.Root>
                </Box>
                <Flex
                    gridColumn={['1/-1', null, '6/-2', '5/-2', '4/-2']}
                    alignItems={['baseline']}
                    p={[theme.tokens.Sizing4, null, theme.tokens.Sizing5, null, 0]}
                    py={[0, null, theme.tokens.Sizing3, null, 0]}
                    flexDirection="column"
                    justifyContent={['flex-start']}
                >
                    <Stack spacing={theme.tokens.Sizing3}>
                        <Heading as="h2" variant={4} clamp={2} mt={[theme.tokens.Sizing4, null, 8]}>
                            {name}
                        </Heading>
                        <Stack>{meta}</Stack>
                    </Stack>
                    {tableData && tableData.length > 0 && (
                        <Accordion allowToggle width="100%">
                            <AccordionItem border={0} p={0}>
                                {({ isExpanded }) => (
                                    <>
                                        <AccordionPanel pb={4} mt={7}>
                                            <TableContainer
                                                borderTop="solid"
                                                borderBottom="solid"
                                                borderColor={theme.tokens.BorderColorNeutralDefault}
                                                borderWidth={theme.tokens.BorderWidthM}
                                                whiteSpace="unset"
                                            >
                                                <Table>
                                                    <TableBody>
                                                        {tableData.map(({ key, value }) => (
                                                            <Tr
                                                                key={key}
                                                                display="flex"
                                                                flexDirection={['column', null, null, 'row']}
                                                            >
                                                                <Td
                                                                    fontWeight={theme.fontWeights.xl}
                                                                    minW={20}
                                                                    py={[theme.tokens.Sizing2, null, null, 4]}
                                                                    pt={[6]}
                                                                    pl={0}
                                                                >
                                                                    {key}
                                                                </Td>
                                                                <Td
                                                                    display="flex"
                                                                    flexDir="row"
                                                                    w="100%"
                                                                    justifyContent={[null, null, null, 'flex-end']}
                                                                    py={[null, null, null, 4]}
                                                                    pr={0}
                                                                    pl={[0]}
                                                                    pb={[6]}
                                                                >
                                                                    {value}
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionPanel>
                                        <AccordionButton p={0} m={0}>
                                            {isExpanded ? (
                                                <ToggleButton
                                                    iconLeft={<IconRemove />}
                                                    iconRight={null}
                                                    size="s"
                                                    mt={theme.tokens.Sizing4}
                                                    mb={theme.tokens.Sizing8}
                                                >
                                                    {lessInfoLabel}
                                                </ToggleButton>
                                            ) : (
                                                <ToggleButton
                                                    iconLeft={<IconAdd />}
                                                    iconRight={null}
                                                    size="s"
                                                    mt={theme.tokens.Sizing4}
                                                    mb={theme.tokens.Sizing8}
                                                >
                                                    {moreInfoLabel}
                                                </ToggleButton>
                                            )}
                                        </AccordionButton>
                                    </>
                                )}
                            </AccordionItem>
                        </Accordion>
                    )}
                </Flex>
            </Grid>
        </Container>
    );
};

const TableBody = styled(Tbody)(
    ({ theme }) => css`
        a {
            color: ${theme.tokens.ColorDarkBlue60};
        }
    `
);

const ToggleButton = styled(TextButton)(
    ({ theme }) => css`
        color: ${theme.tokens.ColorDarkBlue60};
    `
);

const Container = styled(Box)(
    ({ theme }) => css`
        outline: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        color: ${theme.tokens.SyntaxTextColorDefault};
        text-decoration: none;

        &:hover picture {
            transform: scale(1.05);
        }

        picture {
            transition: transform 1400ms cubic-bezier(0.16, 1, 0.3, 1);
        }

        img {
            object-fit: cover;
        }

        @media (min-width: ${theme.tokens.MediaQueryS}) {
            [data-mouse-on] {
                display: none;
            }
            [data-mouse-off] {
                display: block;
            }

            &:hover {
                [data-mouse-on] {
                    display: block;
                }
                [data-mouse-off] {
                    display: none;
                }
            }
        }
    `
);

export default ExtendedPersonCard;
