import React, { useMemo } from 'react';

import { Box, Flex, Stack, StackProps } from '@chakra-ui/react';

import { useTheme } from '@emotion/react';
import Img, { ImgProps } from '../media/Img';
import styled from '@emotion/styled';
import Link from 'next/link';
import ConditionalWrap from 'conditional-wrap';

interface TSponsor extends Omit<ImgProps, 'fill'> {
    width: number;
    height: number;
    href?: string;
}

interface Props extends StackProps {
    sponsors: TSponsor[];
}

const ContentBlockSponsors: React.FC<Props> = ({ sponsors, ...rest }) => {
    const theme = useTheme();
    const ratio = useMemo(() => {
        const ratios = sponsors.map(i => i.width / i.height);
        const r = Math.max(...ratios);
        return r > 0.5 ? r : 0.5;
    }, [sponsors]);
    return (
        <Stack
            mt={null}
            mb={6}
            spacing={4}
            color={theme.tokens.SyntaxTextColorNeutralMuted}
            data-content-video
            {...rest}
        >
            <Flex gap={6}>
                {sponsors.filter(Boolean).map(sponsor => (
                    <Box key={sponsor.id} flex={1} aspectRatio={ratio}>
                        <ConditionalWrap
                            condition={!!sponsor.href}
                            wrap={children => (
                                <Link href={sponsor.href} target="_blank">
                                    {children}
                                </Link>
                            )}
                        >
                            <StyledImg {...sponsor} />
                        </ConditionalWrap>
                    </Box>
                ))}
            </Flex>
        </Stack>
    );
};

const StyledImg = styled(Img)`
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

export default ContentBlockSponsors;
