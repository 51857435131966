import { Box, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export interface Props extends BoxProps {
    variant: 1 | 2 | 3 | 4 | 5;
    clamp?: number;
}

const Heading = styled(Box)<Props>(
    ({ theme, clamp, variant }) => css`
        display: block;
        white-space: pre-line;

        [data-font-loaded='false'] & {
            font-family: arial black !important;
            letter-spacing: -0.065em;
            word-spacing: -0.06em;
            transform: translateY(-0.05em);
        }

        ${clamp &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: ${clamp};
            -webkit-box-orient: vertical;
            overflow: hidden;
        `}

        ${variant === 1 &&
        css`
            font-size: ${theme.tokens.SyntaxHeadingTextL1FontSize};
            line-height: ${theme.tokens.SyntaxHeadingTextL1LineHeight};
            font-family: ${theme.tokens.SyntaxHeadingTextL1FontFamily};
            font-weight: ${theme.tokens.SyntaxHeadingTextL1FontWeight};

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                font-size: ${theme.tokens.SyntaxHeadingTextS1FontSize};
                line-height: ${theme.tokens.SyntaxHeadingTextS1LineHeight};
                font-family: ${theme.tokens.SyntaxHeadingTextS1FontFamily};
                font-weight: ${theme.tokens.SyntaxHeadingTextS1FontWeight};
            }
        `}
        ${variant === 2 &&
        css`
            font-size: ${theme.tokens.SyntaxHeadingTextL2FontSize};
            line-height: ${theme.tokens.SyntaxHeadingTextL2LineHeight};
            font-family: ${theme.tokens.SyntaxHeadingTextL2FontFamily};
            font-weight: ${theme.tokens.SyntaxHeadingTextL2FontWeight};

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                font-size: ${theme.tokens.SyntaxHeadingTextS2FontSize};
                line-height: ${theme.tokens.SyntaxHeadingTextS2LineHeight};
                font-family: ${theme.tokens.SyntaxHeadingTextS2FontFamily};
                font-weight: ${theme.tokens.SyntaxHeadingTextS2FontWeight};
            }
        `}
        ${variant === 3 &&
        css`
            font-size: ${theme.tokens.SyntaxHeadingTextL3FontSize};
            line-height: ${theme.tokens.SyntaxHeadingTextL3LineHeight};
            font-family: ${theme.tokens.SyntaxHeadingTextL3FontFamily};
            font-weight: ${theme.tokens.SyntaxHeadingTextL3FontWeight};

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                font-size: ${theme.tokens.SyntaxHeadingTextS3FontSize};
                line-height: ${theme.tokens.SyntaxHeadingTextS3LineHeight};
                font-family: ${theme.tokens.SyntaxHeadingTextS3FontFamily};
                font-weight: ${theme.tokens.SyntaxHeadingTextS3FontWeight};
            }
        `}
        ${variant === 4 &&
        css`
            font-size: ${theme.tokens.SyntaxHeadingTextL4FontSize};
            line-height: ${theme.tokens.SyntaxHeadingTextL4LineHeight};
            font-family: ${theme.tokens.SyntaxHeadingTextL4FontFamily};
            font-weight: ${theme.tokens.SyntaxHeadingTextL4FontWeight};

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                font-size: ${theme.tokens.SyntaxHeadingTextS4FontSize};
                line-height: ${theme.tokens.SyntaxHeadingTextS4LineHeight};
                font-family: ${theme.tokens.SyntaxHeadingTextS4FontFamily};
                font-weight: ${theme.tokens.SyntaxHeadingTextS4FontWeight};
            }
        `}
        ${variant === 5 &&
        css`
            font-size: ${theme.tokens.SyntaxHeadingTextL5FontSize};
            line-height: ${theme.tokens.SyntaxHeadingTextL5LineHeight};
            font-family: ${theme.tokens.SyntaxHeadingTextL5FontFamily};
            font-weight: ${theme.tokens.SyntaxHeadingTextL5FontWeight};

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                font-size: ${theme.tokens.SyntaxHeadingTextS5FontSize};
                line-height: ${theme.tokens.SyntaxHeadingTextS5LineHeight};
                font-family: ${theme.tokens.SyntaxHeadingTextS5FontFamily};
                font-weight: ${theme.tokens.SyntaxHeadingTextS5FontWeight};
            }
        `}
    `
);

export default Heading;
