import { Box, BoxProps } from '@chakra-ui/react';
import Body from '../typography/Body';
import { FormattedDate, useIntl } from 'react-intl';
import { FC } from 'react';

interface Props extends BoxProps {
    date: Date;
}

const DateSelectorItem: FC<Props> = ({ date, ...props }) => {
    const { locale } = useIntl();
    return (
        <Box
            as="button"
            cursor="pointer"
            borderRadius="lg"
            border="1px solid currentColor"
            px={[1, null, null, null, null, 3]}
            py={3}
            display="inline-block"
            textAlign="center"
            {...props}
        >
            <Body fontWeight={600}>
                <FormattedDate
                    value={date}
                    // the weekday is too long in english (3 chars), so we use the narrow version there
                    weekday={locale === 'en' ? 'narrow' : 'short'}
                    timeZone="Europe/Amsterdam"
                />
            </Body>
            <Body fontWeight={600}>
                <FormattedDate value={date} day="numeric" timeZone="Europe/Amsterdam" />
            </Body>
        </Box>
    );
};

export default DateSelectorItem;
