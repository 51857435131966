import { NumberParam, StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params';
import { useDebounce } from 'use-debounce';
import { Ref, useEffect, useMemo, useRef } from 'react';
import { QueryParamConfigMapWithInherit } from 'use-query-params/src/types';
import isEqual from 'lodash.isequal';

export type SearchState<QP extends QueryParamConfigMapWithInherit> = ReturnType<typeof useSearchState<QP>>;

const useSearchState = <QP extends QueryParamConfigMapWithInherit>(filterQueryParams: QP) => {
    const [query, setQuery] = useQueryParam('query', withDefault(StringParam, ''));
    const [debouncedQuery] = useDebounce(query, 400);
    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
    const [queryFilters] = useQueryParams(filterQueryParams);
    const inputRef: Ref<HTMLInputElement> = useRef();
    const hasQueryFilters = useMemo(
        () => Object.values(queryFilters).some(filter => filter.length > 0),
        [queryFilters]
    );
    const isSearching = !!debouncedQuery || hasQueryFilters;
    const prevQuery = useRef(query);
    const prevQueryFilters = useRef(queryFilters);

    useEffect(() => {
        const shouldResetPagination =
            page > 1 && (prevQuery.current !== query || !isEqual(queryFilters, prevQueryFilters.current));
        if (shouldResetPagination) {
            setPage(null);
        }
        prevQuery.current = query;
        prevQueryFilters.current = queryFilters;
    }, [page, query, queryFilters, setPage]);

    return {
        query,
        setQuery,
        page,
        setPage,
        hasQueryFilters,
        isSearching,
        queryFilters,
        debouncedQuery,
        inputRef,
    };
};

export default useSearchState;
