import { ContentPage } from '../../pages/[...uri]';
import React, { ComponentProps, FC, useMemo } from 'react';
import {
    DocschoolFilterEnum,
    SearchDocschoolFiltersQueryVariables,
    SearchDocschoolHitsQueryVariables,
    useSearchDocschoolFiltersQuery,
    useSearchDocschoolHitsQuery,
} from '../../gql/api';
import { ArrayParam, QueryParamConfig, withDefault } from 'use-query-params';
import useDocschoolSearch from '../../hooks/useDocschoolSearch';
import { SEARCH_RESULTS_AMOUNT } from './ArchivePage';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    getMetaForFilmOrProjectHit,
    useSearchState,
} from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import docschoolFilterMessages from '../../constants/docschoolFilterMessages';
import InstituteBreadcrumbs from '../InstituteBreadcrumbs';
import orderBy from 'lodash.orderby';

type DocschoolCollectionContentPage = Extract<
    ContentPage,
    { __typename: 'instituteContentPages_docschoolCollectionPage_Entry' }
>;

export const DocschoolFilterQueryParams: Record<DocschoolFilterEnum, QueryParamConfig<string[]>> = Object.assign(
    {},
    ...Object.values(DocschoolFilterEnum).map(value => ({ [value]: withDefault(ArrayParam, []) }))
);

const educationLevelSorting = [
    'brugklas',
    'vmbo onder',
    'vmbo boven',
    'havo onder',
    'havo boven',
    'vwo onder',
    'vwo boven',
    'mbo',
];

const DocschoolCollectionPage: FC<{ data: DocschoolCollectionContentPage }> = ({ data }) => {
    const searchState = useSearchState(DocschoolFilterQueryParams);
    const { hits, ...docschoolSearch } = useDocschoolSearch(searchState);
    const processedHits: ComponentProps<typeof CollectionOverviewTemplate>['hits'] = useMemo(
        () =>
            (hits || []).map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.docschoolOneliner?.translation,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit, filmOrProjectType: 'docschool' }),
                awards: hit.awards,
            })),
        [hits]
    );

    return (
        <CollectionOverviewTemplate
            collectionPath="docschool"
            breadcrumbs={<InstituteBreadcrumbs />}
            pageTitle={data.title}
            pageIntroText={data.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            queryParams={DocschoolFilterQueryParams}
            filterMessages={docschoolFilterMessages}
            sortFilterItems={(filter, amounts) => {
                switch (filter) {
                    case DocschoolFilterEnum.EducationLevel:
                        return amounts.sort(
                            (a, b) =>
                                educationLevelSorting.findIndex(search => a.key.includes(search)) -
                                educationLevelSorting.findIndex(search => b.key.includes(search))
                        );
                    default:
                        return orderBy(amounts, amount => amount.key.toLowerCase());
                }
            }}
            hits={processedHits}
            {...docschoolSearch}
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const hitVars: SearchDocschoolHitsQueryVariables = {
        query: '',
        filters: [],
        offset: 0,
        limit: SEARCH_RESULTS_AMOUNT,
    };
    await queryClient.prefetchQuery(useSearchDocschoolHitsQuery.getKey(hitVars), () =>
        fetchApiData({
            locale,
            query: useSearchDocschoolHitsQuery.document,
            variables: hitVars,
        })
    );
    const filterVars: SearchDocschoolFiltersQueryVariables = { filters: [] };
    await queryClient.prefetchQuery(useSearchDocschoolFiltersQuery.getKey(filterVars), () =>
        fetchApiData({
            locale,
            query: useSearchDocschoolFiltersQuery.document,
            variables: filterVars,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default DocschoolCollectionPage;
