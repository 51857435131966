import { FC, HTMLAttributes } from 'react';

const IconPlayModal: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.57153 18.7145C6.83977 18.7145 3.28582 18.7145 3.28582 18.7145C3.28582 18.7145 3.27005 15.1668 3.28582 14.4288M18.7144 14.4288C18.7144 15.8402 18.7144 18.7145 18.7144 18.7145C18.7144 18.7145 15.8756 18.7145 14.4287 18.7145M14.4287 3.28589H18.7144L18.7144 7.57161M3.2858 7.57161V3.28589H7.57151"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5713 2.42871H19.5713L19.5713 8.42872L17.857 8.42872L17.857 4.143H13.5713V2.42871ZM2.42838 2.42872H8.42838V4.143H4.14267V8.42872H2.42838V2.42872ZM4.16079 13.5901L4.14249 14.447C4.13488 14.8033 4.13479 15.8619 4.13677 16.8466C4.1375 17.2097 4.1385 17.5601 4.13947 17.8573L8.4284 17.8573L8.42839 19.5716L3.28619 19.5716L3.28554 18.7144L2.42841 18.7182L2.42619 18.1516C2.42495 17.8072 2.42347 17.3402 2.42249 16.85C2.42053 15.8764 2.42044 14.7921 2.42859 14.4104L2.4469 13.5535L4.16079 13.5901ZM3.28554 18.7144L2.42841 18.7182L2.4322 19.5716H3.28619L3.28554 18.7144ZM19.5713 13.5716V18.7139L18.7141 18.7144L18.7141 19.5716L13.5713 19.5716V17.8573L17.857 17.8573V13.5716H19.5713ZM18.7141 18.7144L18.7141 19.5716L19.5713 19.5716V18.7139L18.7141 18.7144Z"
            fill="currentColor"
        />
        <path d="M15.2144 11L8.96436 14.75L8.96436 7.25L15.2144 11Z" fill="currentColor" />
    </svg>
);

export default IconPlayModal;
