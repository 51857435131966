import React from 'react';

import { Stack, StackProps } from '@chakra-ui/react';

import Heading from '../typography/Heading';

import TextButton, { Props as TextButtonProps } from '../action/TextButton';

interface Props extends StackProps {
    title: string;
    textButtonProps: TextButtonProps;
}

const DestinationListItem: React.FC<Props> = ({ title, textButtonProps, ...rest }) => (
    <Stack as="article" py={8} spacing={6} {...rest}>
        <Heading variant={3}>{title}</Heading>
        <TextButton as="span" size="s" {...textButtonProps} />
    </Stack>
);

export default DestinationListItem;
