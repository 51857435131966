import { inputAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type InputTheme = Omit<MultiStyleConfig<typeof inputAnatomy>, 'parts'>;

/**
 * Theme for a Chakra Input. Include this into your theme.
 */
const InputTheming: InputTheme = {
    baseStyle: ({ theme }) => ({
        element: {
            w: 8,
            h: '100%',
            gap: 2,
            '& > svg': {
                w: 6,
                h: 6,
            },
        },
        field: {
            font: 'inherit',
            fontSize: theme.tokens.SyntaxBodyTextLRegularFontSize,
            lineHeight: theme.tokens.SyntaxBodyTextSRegularLineHeight,
            boxSizing: 'border-box',
            py: 2,
            px: 2,
            h: 8,
            border: `${theme.tokens.BorderWidthM} solid ${theme.tokens.SyntaxColorInputEnabledBorder}`,
            w: '100%',
            transition: 'all 0.2s',
            '&:placeholder': {
                fontFamily: 'inherit',
            },
            _focus: {
                borderColor: theme.tokens.SyntaxColorInputFocusBorder,
                boxShadow: `0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight}`,
                outline: 'none',
            },
            _hover: {
                borderColor: theme.tokens.SyntaxColorInputHoverBorder,
            },
            _invalid: {
                borderColor: theme.tokens.SyntaxColorInputInvalidBorder,
                _focus: {
                    borderColor: theme.tokens.SyntaxColorInputInvalidBorder,
                    boxShadow: `0 0 0 ${theme.tokens.Sizing1} ${theme.tokens.BorderColorPrimaryLight}`,
                },
            },
            _disabled: {
                bg: theme.tokens.SyntaxColorInputDisabledBackground,
                color: theme.tokens.SyntaxColorInputDisabledBorder,
                borderColor: theme.tokens.SyntaxColorInputDisabledBorder,
                _hover: {
                    borderColor: theme.tokens.SyntaxColorInputDisabledBorder,
                },
            },
        },
    }),
    sizes: {},
};

export default InputTheming;
