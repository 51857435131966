import React from 'react';

import { Box, BoxProps, RadioProps, useRadio } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Heading from '../typography/Heading';

type Props = RadioProps & BoxProps;

const RadioBox: React.FC<Props> = ({ children, ...rest }) => {
    const { getInputProps, getCheckboxProps } = useRadio(rest);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label" {...rest}>
            <input {...input} />
            <CheckboxBox {...checkbox} cursor="pointer">
                <Heading variant={4}>{children}</Heading>
            </CheckboxBox>
        </Box>
    );
};

const CheckboxBox = styled(Box)(
    ({ theme }) => css`
        box-sizing: border-box;
        border: ${theme.tokens.BorderWidthL} solid ${theme.tokens.BorderColorNeutralDefault};
        padding: ${theme.sizes[6]};
        position: relative;
        text-align: center;

        &[data-checked] {
            background: ${theme.tokens.SyntaxBackgroundPrimaryDefault};
            border-color: ${theme.tokens.SyntaxBackgroundPrimaryDefault};
            color: ${theme.tokens.SyntaxTextColorOnPrimary};
        }

        &:before {
            box-sizing: border-box;
            position: absolute;
            content: '';
            width: ${theme.sizes[5]};
            height: ${theme.sizes[5]};
            top: ${theme.sizes[4]};
            right: ${theme.sizes[4]};
            border: ${theme.tokens.BorderWidthL} solid ${theme.tokens.BorderColorNeutralDefault};
            border-radius: 100%;
        }
        &[data-checked]:before {
            border-width: ${theme.tokens.BorderWidthXxl};
            border-color: currentColor;
        }
    `
);

export default RadioBox;
