import { useTheme } from '@emotion/react';
import { FC, ReactNode } from 'react';
import IconClose from '../trinket/icon/IconClose';
import { NavigationBottomItem } from './NavigationTopBar';
import { Show } from '@chakra-ui/react';

interface NavigationCloseButtonProps {
    closeLabel: ReactNode;
    handleClose: () => void;
}

const NavigationCloseButton: FC<NavigationCloseButtonProps> = ({ closeLabel, handleClose }) => {
    const theme = useTheme();
    return (
        <NavigationBottomItem
            as="a"
            onClick={handleClose}
            bg={[null, null, theme.tokens.SyntaxColorActionEnabledBackground]}
            minW={['auto', null, '160px']}
            justifyContent="center"
        >
            <Show above="m">
                <span>{closeLabel}</span>
            </Show>
            <Show below="m">
                <IconClose color="white" />
            </Show>
            <Show above="m">
                <IconClose />
            </Show>
        </NavigationBottomItem>
    );
};

export default NavigationCloseButton;
