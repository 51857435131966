import styled from '@emotion/styled';
import * as Accordion from '@radix-ui/react-accordion';
import { AccordionSingleProps } from '@radix-ui/react-accordion';
import { FC } from 'react';
import { css } from '@emotion/react';

const FilterSections: FC<Omit<AccordionSingleProps, 'type'>> = props => (
    <StyledAccordionRoot type="single" collapsible {...props} />
);

const StyledAccordionRoot = styled(Accordion.Root)(
    ({ theme }) => css`
        > * + * {
            border-top: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        }
    `
);

export default FilterSections;
