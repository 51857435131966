import { FC, HTMLAttributes } from 'react';

const IconTicket: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5 5.5H1.5V7.54647C1.94898 7.69266 2.41258 7.91319 2.84566 8.23193C3.88335 8.99567 4.63043 10.2449 4.63043 12C4.63043 13.7551 3.88335 15.0043 2.84566 15.7681C2.41258 16.0868 1.94898 16.3073 1.5 16.4535V18.5H22.5V16.3122C22.0419 16.123 21.5622 15.8619 21.1182 15.514C20.1933 14.7891 19.3696 13.6311 19.3696 12C19.3696 10.3689 20.1933 9.21089 21.1182 8.48604C21.5622 8.13809 22.0419 7.87702 22.5 7.68779V5.5ZM24 8.8C22.9565 8.97778 20.8696 9.86667 20.8696 12C20.8696 14.1333 22.9565 15.0222 24 15.2V18.5V20H22.5H1.5H0V18.5V16.7V15.2C1.04348 15.2 3.13043 14.56 3.13043 12C3.13043 9.44 1.04348 8.8 0 8.8V7.3V5.5V4H1.5H22.5H24V5.5V8.8ZM15 6H16.4674V7.5H15V6ZM16.4674 9.5H15V11H16.4674V9.5ZM15 13H16.4674V14.5H15V13ZM16.4674 16.5H15V18H16.4674V16.5Z"
            fill="currentColor"
        />
    </svg>
);

export default IconTicket;
