import { NextAuthOptions, Profile } from 'next-auth';
import { fetchApiData } from '../hooks/useApi';
import { ProfileDocument, ProfileQuery, ProfileQueryVariables } from '../gql/api';
import { JWT } from 'next-auth/jwt';
import { FormData } from 'formdata-node';

async function refreshAccessToken(token: JWT): Promise<JWT> {
    try {
        const data = new FormData();
        data.append('client_id', process.env.CLIENT_ID);
        data.append('client_secret', process.env.CLIENT_SECRET);
        data.append('grant_type', 'refresh_token');
        data.append('refresh_token', token.refreshToken);
        data.append('scope', 'user_info active_tickets');

        const response = await fetch(process.env.MYIDFA_TOKEN_URL, {
            method: 'POST',
            body: data as BodyInit,
        });

        const refreshedTokens = await response.json();
        if (response.status !== 200) {
            throw refreshedTokens;
        }
        return {
            ...token,
            accessToken: refreshedTokens.access_token,
            accessTokenExpires: refreshedTokens.expires_at * 1000,
            refreshToken: refreshedTokens.refresh_token ?? token.refreshToken, // Fall back to old refresh token
        };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return {
            ...token,
            error: 'RefreshAccessTokenError',
        };
    }
}

// For more information on each option (and a full list of options) go to
// https://next-auth.js.org/configuration/options
const authOptions: NextAuthOptions = {
    providers: [
        {
            id: process.env.CLIENT_ID,
            name: 'idfa-website',
            type: 'oauth',
            version: '2.0',
            authorization: {
                url: process.env.MYIDFA_AUTH_URL,
                params: {
                    scope: 'user_info active_tickets',
                },
            },
            userinfo: {
                request: async ({ tokens: { access_token: accessToken } }) => {
                    const profile = await fetchApiData<ProfileQuery, ProfileQueryVariables>({
                        query: ProfileDocument,
                        locale: 'nl',
                        options: accessToken
                            ? {
                                  Authorization: `Bearer ${accessToken}`,
                              }
                            : {},
                    });
                    delete profile.viewer.calendar;
                    delete profile.viewer.favoriteFilms;
                    return profile as Profile;
                },
            },
            token: process.env.MYIDFA_TOKEN_URL,
            clientSecret: process.env.CLIENT_SECRET,
            clientId: process.env.CLIENT_ID,
            checks: ['state', 'pkce'],
            profile(profile: ProfileQuery) {
                return {
                    ...profile?.viewer,
                    id: profile?.viewer?.email,
                    name: `${profile?.viewer?.firstName ?? ''} ${profile?.viewer?.lastName ?? ''}`.trim(),
                };
            },
        },
    ],
    secret: process.env.NEXTAUTH_SECRET,
    debug: false,
    callbacks: {
        async jwt({ token, user, account }): Promise<JWT> {
            if (account && user) {
                // Initial sign in
                return {
                    accessToken: account.access_token,
                    accessTokenExpires: account.expires_at * 1000,
                    refreshToken: account.refresh_token,
                    user,
                };
            }

            if (Date.now() < token.accessTokenExpires) {
                return token;
            }

            return refreshAccessToken(token);
        },
        async session({ session, token }) {
            return {
                ...session,
                user: token.user,
                accessToken: token.accessToken,
                accesTokenExpires: token.accessTokenExpires,
                error: token.error ?? null,
                roles: token.user.roles,
            };
        },
    },
};

export default authOptions;
