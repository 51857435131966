import { ArchiveFilterEnum } from '../../gql/api';
import { FC, useMemo } from 'react';
import { Body, Box, Checkbox, FilterSectionExpandable, HStack, Radio } from 'designsystem';
import { useQueryParams } from 'use-query-params';
import useArchiveSearch from '../../hooks/useArchiveSearch';
import archiveFilterMessages from '../../constants/archiveFilterMessages';
import { FormattedMessage, useIntl } from 'react-intl';

import { ArchiveFilterQueryParams } from '../pages/ArchivePage';
import orderBy from 'lodash.orderby';
import { gtm, useSetQueryFilter } from 'shared';
import getFiltersForArchiveType from '../../utils/filtersForArchiveType';

type Filters = ReturnType<typeof useArchiveSearch>['filters'];
interface Props {
    filters: Filters;
    onModal: (selectedFilter: Filters[number]) => void;
}

const SearchFilterSections: FC<Props> = ({ filters, onModal }) => {
    const [queryFilters, setQueryFilters] = useQueryParams(ArchiveFilterQueryParams);
    const setQueryFilter = useSetQueryFilter(ArchiveFilterQueryParams);
    const currentArchiveType = useMemo(() => queryFilters.ARCHIVE_TYPE[0], [queryFilters.ARCHIVE_TYPE]);
    const filteredFiltersForArchiveType = useMemo(
        () =>
            getFiltersForArchiveType(currentArchiveType, filters)
                .filter(({ filter }) => filter !== ArchiveFilterEnum.ArchiveType)
                .filter(({ amounts }) => !amounts.every(({ amount }) => amount === 0)),

        [currentArchiveType, filters]
    );
    const archiveTypeFilter = useMemo(
        () => filters.find(({ filter }) => filter === ArchiveFilterEnum.ArchiveType),
        [filters]
    );
    const { formatMessage } = useIntl();

    return (
        <>
            {archiveTypeFilter &&
                // hide if an archive type was chosen
                queryFilters[ArchiveFilterEnum.ArchiveType].length === 0 && (
                    <FilterSectionExpandable
                        heading={formatMessage(archiveFilterMessages[ArchiveFilterEnum.ArchiveType])}
                        filters={[
                            <HStack key="all" spacing={4} as={Body}>
                                <Radio
                                    isChecked={queryFilters[ArchiveFilterEnum.ArchiveType].length === 0}
                                    onChange={() => setQueryFilters({ [ArchiveFilterEnum.ArchiveType]: [] })}
                                    id={`${ArchiveFilterEnum.ArchiveType}-all`}
                                />
                                <Box as="label" flexGrow={1} htmlFor={`${ArchiveFilterEnum.ArchiveType}-all`}>
                                    <FormattedMessage defaultMessage="Alles" />
                                </Box>
                            </HStack>,
                            ...orderBy(archiveTypeFilter.amounts, [filter => filter.key.toLowerCase()]).map(amount => {
                                const id = `${ArchiveFilterEnum.ArchiveType}-${amount.key}`;
                                const enabledFilters = queryFilters[ArchiveFilterEnum.ArchiveType] ?? [];
                                return (
                                    <HStack key={amount.key} spacing={4} as={Body}>
                                        <Radio
                                            isChecked={enabledFilters.includes(amount.key)}
                                            onChange={() => {
                                                setQueryFilters({ [ArchiveFilterEnum.ArchiveType]: [amount.key] });
                                            }}
                                            id={id}
                                        />
                                        <Box as="label" flexGrow={1} htmlFor={id}>
                                            {amount.key}
                                        </Box>
                                    </HStack>
                                );
                            }),
                        ]}
                        value={ArchiveFilterEnum.ArchiveType}
                        translations={{
                            chooseA: <FormattedMessage defaultMessage="Kies een" />,
                            show: <FormattedMessage defaultMessage="Toon" />,
                            more: <FormattedMessage defaultMessage="meer" />,
                            less: <FormattedMessage defaultMessage="minder" />,
                        }}
                    />
                )}

            {filteredFiltersForArchiveType.map(currentFilter => {
                const { filter, amounts } = currentFilter;
                return (
                    <FilterSectionExpandable
                        key={filter}
                        heading={formatMessage(archiveFilterMessages[filter])}
                        selectedFilters={queryFilters[filter].map(f => f.split('|')?.[1] ?? f).join(', ')}
                        onModal={() => onModal(currentFilter)}
                        filters={orderBy(amounts, [amount => amount.key.toLowerCase()]).map(amount => {
                            const id = `${filter}-${amount.key}`;
                            const enabledFilters = queryFilters[filter] ?? [];
                            return (
                                <HStack key={amount.key} spacing={4} as={Body}>
                                    <Checkbox
                                        isChecked={enabledFilters.includes(amount.key)}
                                        onChange={e => {
                                            if (e.currentTarget.checked) {
                                                gtm.event('apply_filter', {
                                                    filter_name: filter,
                                                    filter_value: amount.key,
                                                    collection: 'archive',
                                                });
                                            }
                                            setQueryFilter(filter, amount.key, e.currentTarget.checked);
                                        }}
                                        id={id}
                                    />
                                    <Box as="label" flexGrow={1} htmlFor={id}>
                                        {amount.key}
                                    </Box>
                                </HStack>
                            );
                        })}
                        value={filter}
                        translations={{
                            chooseA: <FormattedMessage defaultMessage="Kies een" />,
                            show: <FormattedMessage defaultMessage="Toon" />,
                            more: <FormattedMessage defaultMessage="meer" />,
                            less: <FormattedMessage defaultMessage="minder" />,
                        }}
                    />
                );
            })}
        </>
    );
};

export default SearchFilterSections;
