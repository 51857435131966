import { useCallback } from 'react';
import { ImgProps } from 'designsystem';
// eslint-disable-next-line no-restricted-imports
import placeholder from 'designsystem/src/assets/images/placeholder.svg';
import { useTheme } from '@emotion/react';
import { OpenGraphMedia } from 'next-seo/lib/types';
import blurHashToDataURL from '../lib/blurHashToDataURL';

interface Image {
    webp?: Array<{
        url: string;
        width: number;
        height: number;
    }>;
    jpeg?: Array<{
        url: string;
        width: number;
        height: number;
    }>;
    blurHash?: string;
}

const useGetApiImageProps = () => {
    const { breakpoints } = useTheme();
    return useCallback(
        (
            image: Image,
            alt: string,
            sizes = `(min-width: ${breakpoints.xl}) 20vw, (min-width: ${breakpoints.l}) 30vw, (min-width: ${breakpoints.m}) 447px, (min-width: ${breakpoints.s}) 50vw, 100vw`
        ): ImgProps => {
            const mainTransform = image?.jpeg?.[0];

            if (!image || !mainTransform) {
                return {
                    ...placeholder,
                    alt,
                };
            }

            const props: ImgProps = {
                sources: [
                    {
                        type: 'image/webp',
                        sizes,
                        srcSet: image.webp.map(t => `${t.url} ${t.width}w`).join(', '),
                    },
                    {
                        type: 'image/jpeg',
                        sizes,
                        srcSet: image.webp.map(t => `${t.url} ${t.width}w`).join(', '),
                    },
                ],
                alt,
                src: mainTransform.url,
            };

            if (image?.blurHash) {
                props.placeholder = blurHashToDataURL(image?.blurHash);
            }

            return props;
        },
        [breakpoints.l, breakpoints.m, breakpoints.s, breakpoints.xl]
    );
};

// helper which gets the best image for opengraph
export const apiImageToOpenGraph = (image: Image): OpenGraphMedia[] => {
    const maxWidth = Math.max(...(image?.jpeg.map(({ width }) => width) ?? []));
    const transform = image?.jpeg.find(({ width }) => width === maxWidth);
    if (!transform) {
        return [];
    }
    const { url, width, height } = transform;
    return [
        {
            url,
            width,
            height,
        },
    ];
};

export default useGetApiImageProps;
