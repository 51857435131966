import { Box, chakra } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Grid = chakra(
    styled(Box)(
        ({ theme }) => css`
            display: grid;
            grid-template-columns: repeat(4, 1fr); // column: 56px | gap: 48px
            --gap: ${Math.floor((24 / 376) * 10000) / 100}vw;
            gap: var(--gap);

            @media screen and (min-width: ${theme.tokens.MediaQueryS}) {
                --test: ${(20 / parseFloat(theme.tokens.MediaQueryS)) * 11};
                grid-template-columns: repeat(
                    auto-fill,
                    ${((0.9 - (20 / parseFloat(theme.tokens.MediaQueryS)) * 11) / 12) * 100}vw
                ); // column: 39px | gap: 20px
                /* grid-template-columns: repeat(12, 1fr); */
                --gap: ${Math.floor((20 / parseFloat(theme.tokens.MediaQueryS)) * 10000) / 100}vw;
                gap: var(--gap);
            }

            @media screen and (min-width: ${theme.tokens.MediaQueryL}) {
                grid-template-columns: repeat(
                    auto-fill,
                    ${(56 / parseFloat(theme.tokens.MediaQueryXl)) * 100}vw
                ); // column: 56px | gap: 48px
                --gap: ${Math.floor((48 / parseFloat(theme.tokens.MediaQueryXl)) * 10000) / 100}vw;
                gap: var(--gap);
            }

            @media screen and (min-width: ${theme.tokens.MediaQueryXl}) {
                grid-template-columns: repeat(auto-fill, 56px);
                --gap: 48px;
                gap: var(--gap);
            }
        `
    )
);

export default Grid;
