import { FC, HTMLAttributes } from 'react';

const IconTwitter: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            d="M1 19.0007C16.6171 25.0622 21.0381 13.9748 20.9048 8.50064C22.1733 8.15458 23 7.29115 23 6.4054C21.9524 6.95588 21.4048 6.79943 20.881 6.4054C22.009 5.63716 21.9524 4.54194 21.9524 3.78636C21.425 4.06282 20.4488 4.74626 19.3333 4.83399C18.5875 3.66779 16.7143 2.73876 14.0952 3.78637C11.4762 4.83398 10.9524 7.62764 11.4762 9.02447C8.12381 9.02447 4.14286 5.5324 2.57143 3.78637C0.688138 6.47364 3.07073 9.13917 4.14286 10.0721C3.42812 10.2858 2.57143 10.0721 2.04762 9.54828C2.04762 12.5393 4.66667 13.7387 5.71429 14.2626H3.61905C3.61905 16.3578 6.06349 17.2308 7.28571 17.4054C6.44762 18.2435 3.07104 19.0007 1 19.0007Z"
        />
    </svg>
);

export default IconTwitter;
