import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { isLoggedIn } from '../../index';

const Login: React.FC = () => {
    const { status } = useSession();
    const { locale, push } = useRouter();
    const loggedIn = useMemo(() => isLoggedIn(status), [status]);

    useEffect(() => {
        if (loggedIn) {
            push('/myidfa');
        } else if (loggedIn === false) {
            signIn('idfa-website', undefined, { locale });
        }
    }, [loggedIn, locale, push]);

    return <> </>;
};

export default Login;
