import { Switch as ChakraSwitch } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const StyledSwitch = styled(ChakraSwitch)(
    ({ theme }) => css`
        border-radius: ${theme.tokens.BorderRadiusL};
        background-color: ${theme.tokens.ColorNeutral30};
        padding: 4px;
        .chakra-switch__track {
            border-radius: ${theme.tokens.BorderRadiusL};
            transition-duration: 200ms;
            .chakra-switch__thumb {
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
                margin-bottom: 2px;
            }
        }
    `
);

export default StyledSwitch;
