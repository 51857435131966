import React, { FC, ReactElement, ReactNode, useMemo } from 'react';

import { Box, Flex, HStack, Stack, StackProps } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import useBrowserSize, { browserSizes } from '../../hooks/useBrowserSize';
import Grid from '../layout/Grid';
import { Props as MetaProps } from '../meta/Meta';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
import Img, { ImgProps } from '../media/Img';
import Tag from '../navigation/Tag';
import Link from 'next/link';
import ConditionalWrap from 'conditional-wrap';
import FavoriteButton from '../action/FavoriteButton';
import CalendarButton from '../action/CalendarButton';
import TicketButton from '../action/TicketButton';

interface Props {
    name: ReactNode;
    meta?: ReactElement<MetaProps>;
    description?: ReactNode;
    tags?: { key: string; value: string }[];
    image: ImgProps;
    showCalendarIcon?: boolean;
    showFavoriteIcon?: boolean;
    showTicketIcon?: boolean;
    isFavorited?: boolean;
    addedToCalendar?: boolean;
    soldOut?: boolean;
    href?: string;
    toggleFavorite?: () => void;
    isLoadingFavorite?: boolean;
    toggleCalendar?: () => void;
    isLoadingCalendar?: boolean;
    onTicketClick?: () => void;
    externalTicketLink?: string;
    isLoadingTicket?: boolean;
    children?: ReactNode;
}

const ProgrammeListItemButtons: FC<Partial<Props> & StackProps> = ({
    showCalendarIcon,
    showFavoriteIcon,
    showTicketIcon,
    addedToCalendar,
    isFavorited,
    toggleCalendar,
    isLoadingCalendar,
    toggleFavorite,
    isLoadingFavorite,
    onTicketClick,
    externalTicketLink,
    isLoadingTicket,
    ...rest
}) => {
    const browserSize = useBrowserSize();
    const smallScreen = browserSizes.indexOf(browserSize) <= browserSizes.indexOf('s');
    const theme = useTheme();

    return (
        <HStack alignItems="start" spacing={2} zIndex={[10, null, 0]} p={4} {...rest}>
            {(onTicketClick || externalTicketLink) && showTicketIcon && (
                <TicketButton
                    onClick={onTicketClick}
                    externalTicketLink={externalTicketLink}
                    isLoading={isLoadingTicket}
                    iconColor={!smallScreen ? theme.tokens.ColorNeutralBlack : theme.tokens.ColorNeutralWhite}
                />
            )}
            {showCalendarIcon && (
                <CalendarButton
                    addedToCalendar={addedToCalendar}
                    isLoading={isLoadingCalendar}
                    iconColor={
                        addedToCalendar || !smallScreen
                            ? theme.tokens.ColorNeutralBlack
                            : theme.tokens.ColorNeutralWhite
                    }
                    borderColor={['neutralwhite.', null, 'neutralblack.']}
                    onClick={toggleCalendar}
                    w={9}
                    h={9}
                />
            )}
            {showFavoriteIcon && (
                <FavoriteButton
                    isFavorite={isFavorited}
                    isLoading={isLoadingFavorite}
                    iconColor={
                        isFavorited || smallScreen ? theme.tokens.ColorNeutralWhite : theme.tokens.ColorNeutralBlack
                    }
                    onClick={toggleFavorite}
                    borderColor={['neutralwhite.', null, 'neutralblack.']}
                />
            )}
        </HStack>
    );
};

const ProgrammeListItem: React.FC<Props> = ({
    name,
    image,
    meta,
    description,
    tags,
    showTicketIcon = true,
    showCalendarIcon = true,
    showFavoriteIcon = true,
    isFavorited,
    addedToCalendar,
    soldOut,
    href,
    toggleCalendar,
    toggleFavorite,
    onTicketClick,
    externalTicketLink,
    isLoadingFavorite,
    isLoadingCalendar,
    children,
}) => {
    const theme = useTheme();
    const browserSize = useBrowserSize();
    const smallScreen = browserSizes.indexOf(browserSize) <= browserSizes.indexOf('s');
    const aspectRatio = useMemo(() => (smallScreen ? 16 / 9 : 3 / 2), [smallScreen]);
    const hasHover = description ? true : undefined;
    const opacity = soldOut ? 0.6 : 1;
    return (
        <Container
            as="article"
            sx={{
                '&:hover picture': { transform: 'scale(1.05)' },
                '& picture': {
                    transition: 'transform 1400ms cubic-bezier(0.16, 1, 0.3, 1)',
                    opacity,
                },
            }}
            opacity={opacity}
            position="relative"
        >
            <ConditionalWrap
                wrap={child => (
                    <Link href={href} prefetch={false}>
                        {child}
                    </Link>
                )}
                condition={!!href}
            >
                <Grid pointerEvents={soldOut ? 'none' : 'auto'}>
                    <Box gridColumn={['1/-1', null, '1/6', '1/5', '1/4']}>
                        <AspectRatio.Root ratio={aspectRatio}>
                            <Flex alignItems="center" justifyContent="center" h="100%">
                                <Img fill {...image} />
                                {soldOut && (
                                    <Body color="neutralwhite." zIndex={10} fontWeight={600}>
                                        Sold out
                                    </Body>
                                )}
                            </Flex>
                        </AspectRatio.Root>
                    </Box>
                    <Flex
                        gridColumn={['1/-1', null, '6/-3', '5/-3', '4/-3']}
                        alignItems="center"
                        p={[theme.tokens.Sizing5, null, 0]}
                        py={[theme.tokens.Sizing5, null, theme.tokens.Sizing3]}
                        pt={[0, null, theme.tokens.Sizing3]}
                    >
                        <Stack spacing={theme.tokens.Sizing3}>
                            <Heading as="h2" variant={4} clamp={2} opacity={opacity}>
                                {name}
                            </Heading>
                            {meta?.props?.items?.length > 0 && (
                                <Stack data-meta data-mouse-off={hasHover}>
                                    {meta}
                                </Stack>
                            )}
                            {description && (
                                <Stack data-desc data-mouse-on={hasHover}>
                                    <Description clamp={browserSize === 'm' ? 2 : 3}>{description}</Description>
                                </Stack>
                            )}
                            {tags?.length > 0 && (
                                <Flex
                                    data-tags
                                    alignItems="center"
                                    alignContent="center"
                                    gap={1}
                                    alignSelf="stretch"
                                    flexWrap="wrap"
                                >
                                    {tags.map(tag => (
                                        <Tag key={tag.key} size="s">
                                            {tag.value}
                                        </Tag>
                                    ))}
                                </Flex>
                            )}
                            {children && (
                                <Flex data-mouse-off={hasHover}>
                                    <Body>{children}</Body>
                                </Flex>
                            )}
                        </Stack>
                    </Flex>
                </Grid>
            </ConditionalWrap>
            {!soldOut && (
                <ProgrammeListItemButtons
                    isFavorited={isFavorited}
                    addedToCalendar={addedToCalendar}
                    showTicketIcon={showTicketIcon}
                    showCalendarIcon={showCalendarIcon}
                    showFavoriteIcon={showFavoriteIcon}
                    toggleCalendar={toggleCalendar}
                    toggleFavorite={toggleFavorite}
                    onTicketClick={onTicketClick}
                    externalTicketLink={externalTicketLink}
                    isLoadingCalendar={isLoadingCalendar}
                    isLoadingFavorite={isLoadingFavorite}
                    top={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    width={['100%', null, 'auto']}
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    aspectRatio={[aspectRatio, null, 'auto']}
                />
            )}
        </Container>
    );
};

const Container = styled(Box)(
    ({ theme }) => css`
        outline: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        color: ${theme.tokens.SyntaxTextColorDefault};
        text-decoration: none;

        img {
            object-fit: cover;
        }

        @media (min-width: ${theme.tokens.MediaQueryS}) {
            [data-mouse-on] {
                display: none;
            }
            [data-mouse-off] {
                display: block;
            }

            &:hover {
                [data-mouse-on] {
                    display: block;
                }
                [data-mouse-off] {
                    display: none;
                }
            }
        }
    `
);

Container.defaultProps = {
    as: 'article',
};

const Description = styled(Body)(
    ({ theme }) => css`
        font-size: ${theme.tokens.FontSize2};
        line-height: ${theme.tokens.LineHeightS};
    `
);

export default ProgrammeListItem;
