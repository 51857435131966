import { css } from '@emotion/react';
import GraphikBold from '../assets/fonts/GraphikBold.otf';
import GraphikBoldItalic from '../assets/fonts/GraphikBoldItalic.otf';
import GraphikSemiBold from '../assets/fonts/GraphikSemibold.otf';
import GraphikSemiBoldItalic from '../assets/fonts/GraphikSemiboldItalic.otf';
import GraphikMedium from '../assets/fonts/GraphikMedium.otf';
import GraphikMediumItalic from '../assets/fonts/GraphikMediumItalic.otf';
import GraphikRegular from '../assets/fonts/GraphikRegular.otf';
import GraphikRegularItalic from '../assets/fonts/GraphikRegularItalic.otf';
import GraphikLight from '../assets/fonts/GraphikLight.otf';
import GraphikLightItalic from '../assets/fonts/GraphikLightItalic.otf';

const graphikFontFaceCss = css`
    /* bold */
    @font-face {
        src: local('GraphikBold'), url('${GraphikBold}');
        font-family: 'Graphik';
        font-weight: 700;
    }
    @font-face {
        src: local('GraphikBoldItalic'), url('${GraphikBoldItalic}');
        font-family: 'Graphik';
        font-weight: 700;
        font-style: italic;
    }

    /* semi bold */
    @font-face {
        src: local('GraphikSemiBold'), url('${GraphikSemiBold}');
        font-family: 'Graphik';
        font-weight: 600;
    }
    @font-face {
        src: local('GraphikSemiBoldItalic'), url('${GraphikSemiBoldItalic}');
        font-family: 'Graphik';
        font-weight: 600;
        font-style: italic;
    }

    /* medium */
    @font-face {
        src: local('GraphikMedium'), url('${GraphikMedium}');
        font-family: 'Graphik';
        font-weight: 500;
    }
    @font-face {
        src: local('GraphikMediumItalic'), url('${GraphikMediumItalic}');
        font-family: 'Graphik';
        font-weight: 500;
        font-style: italic;
    }

    /* regular */
    @font-face {
        src: local('GraphikRegular'), url('${GraphikRegular}');
        font-family: 'Graphik';
        font-weight: 400;
    }
    @font-face {
        src: local('GraphikRegularItalic'), url('${GraphikRegularItalic}');
        font-family: 'Graphik';
        font-weight: 400;
        font-style: italic;
    }

    /* light */
    @font-face {
        src: local('GraphikLight'), url('${GraphikLight}');
        font-family: 'Graphik';
        font-weight: 300;
    }
    @font-face {
        src: local('GraphikLightItalic'), url('${GraphikLightItalic}');
        font-family: 'Graphik';
        font-weight: 300;
        font-style: italic;
    }
`;

export default graphikFontFaceCss;
