import {
    Input as InputComponent,
    InputAddonProps,
    InputElementProps,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputProps,
    InputRightAddon,
    InputRightElement,
} from '@chakra-ui/react';
import React from 'react';

export interface Props extends InputProps {
    leftElement?: React.ReactNode;
    leftElementProps?: InputElementProps;
    rightElement?: React.ReactNode;
    rightElementProps?: InputElementProps;
    leftAddon?: React.ReactNode;
    leftAddonProps?: InputAddonProps;
    rightAddon?: React.ReactNode;
    rightAddonProps?: InputAddonProps;
}

// ref from forwardRef is needed to be able to register input fields w/ react hook form in custom input component: https://react-hook-form.com/api/useform/register
const Input = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            leftAddon,
            leftAddonProps,
            leftElement,
            leftElementProps,
            rightElement,
            rightElementProps,
            rightAddon,
            rightAddonProps,
            className,
            ...rest
        },
        ref
    ) => (
        <InputGroup className={className}>
            {leftAddon && <InputLeftAddon {...leftAddonProps}>{leftAddon}</InputLeftAddon>}
            {leftElement && <InputLeftElement {...leftElementProps}>{leftElement}</InputLeftElement>}
            <InputComponent ref={ref} {...rest} />
            {rightElement && <InputRightElement {...rightElementProps}>{rightElement}</InputRightElement>}
            {rightAddon && <InputRightAddon {...rightAddonProps}>{rightAddon}</InputRightAddon>}
        </InputGroup>
    )
);

Input.displayName = 'Input';

export default Input;
