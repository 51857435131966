import { Body, Note, SkeletonListItem, Stack } from 'designsystem';
import { ComponentType } from 'react';
import { useRemoveQueryFilters } from 'shared';
import { QueryParamConfigMap } from 'use-query-params';

import styled from '@emotion/styled';

import { SEARCH_RESULTS_AMOUNT } from './pages/ArchivePage';
import { FormattedMessage } from 'react-intl';

interface Props<Hit> {
    isLoading: boolean;
    hits?: Hit[];
    HitComponent: ComponentType<{ hit: Hit; offset: number }>;
    queryParams: QueryParamConfigMap;
    getId: (hit: Hit) => string;
    hasFilters?: boolean;
    offset: number;
}

const SearchResults = <Hit,>({ isLoading, hits, hasFilters, HitComponent, getId, queryParams, offset }: Props<Hit>) => {
    const removeFilters = useRemoveQueryFilters(queryParams);

    return (
        <Stack spacing={9}>
            {isLoading &&
                [...new Array(SEARCH_RESULTS_AMOUNT).fill(null).keys()].map(index => <SkeletonListItem key={index} />)}

            {!isLoading &&
                hits.length > 0 &&
                hits?.map((hit, i) => <HitComponent offset={offset + i} key={getId(hit)} hit={hit} />)}

            {!isLoading && hits.length === 0 && (
                <Note>
                    {!hasFilters ? (
                        <FormattedMessage
                            defaultMessage="Er zijn geen resultaten gevonden die aan je zoektermen voldoen, probeer een andere term."
                            description="No search results: term"
                            id="search-no-term"
                        />
                    ) : (
                        <>
                            <FormattedMessage
                                defaultMessage="Er zijn geen resultaten gevonden die aan het filter voldoen."
                                description="No search results: filters"
                                id="search-no-filters"
                            />{' '}
                            <Action as="span" onClick={() => removeFilters()}>
                                <FormattedMessage defaultMessage="Wis alle filters" id="clear-filters" />
                            </Action>
                        </>
                    )}
                </Note>
            )}
        </Stack>
    );
};

const Action = styled(Body)`
    color: ${({ theme }) => theme.tokens.SyntaxPrimaryColorDefault};
    font-weight: ${({ theme }) => theme.tokens.FontWeightXl};
    cursor: pointer;
`;

export default SearchResults;
