import React, { FC, ReactNode } from 'react';

import { Box, BoxProps, chakra, Flex, Grid as ChakraGrid, shouldForwardProp } from '@chakra-ui/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Wrapper from '../layout/Wrapper';
import Grid from '../layout/Grid';
import Img, { ImgProps } from '../media/Img';
import { isValidMotionProp, motion } from 'framer-motion';

interface Props extends BoxProps {
    variant?: 'default' | 'dark';
    // displayed on desktop only!
    rightChildren?: ReactNode;

    topChildren?: ReactNode;
    children: ReactNode;
    image?: ImgProps;
}

const Hero: FC<Props> = ({ variant = 'default', children, topChildren, rightChildren, image, ...rest }) => {
    const theme = useTheme();
    return (
        <>
            <InnerHero hasImage={!!image} {...rest}>
                <Wrapper display="flex" pt={7} pb={rest.pb ?? [5, 8, 10]}>
                    {image && (
                        <AnimationBox layoutId="hero" position="absolute" w="100%" h="100%" sx={{ inset: 0 }}>
                            <StyledImg {...image} fill />
                            <Overlay
                                bgGradient={`linear(to-b, rgba(0,0,0,0), ${
                                    variant === 'default'
                                        ? theme.tokens.SyntaxOverlayColorDefault
                                        : theme.tokens.SyntaxOverlayColorDarkest
                                }) 83%`}
                            />
                        </AnimationBox>
                    )}
                    <Flex flexDir="row" grow={1} zIndex="base">
                        <Grid w="100%">
                            <Flex
                                flexDir={['column']}
                                justifyContent={['space-between']}
                                flexGrow={1}
                                gridColumn={['1 / -1', null, '1 / span 10']}
                            >
                                <Box>{topChildren}</Box>
                                <Box>{children}</Box>
                            </Flex>
                        </Grid>
                    </Flex>
                </Wrapper>
                <ChakraGrid
                    gridTemplate="1fr 1fr / 1fr"
                    pos="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    display={['none', null, null, 'grid']}
                    maxW="480px"
                >
                    {rightChildren}
                </ChakraGrid>
            </InnerHero>
            <Box display={['block', null, null, 'none']}>{rightChildren}</Box>
        </>
    );
};

export default Hero;

const Overlay = styled(Box)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const InnerHero = styled(({ hasImage, ...props }: { hasImage: boolean } & Props) => <Flex {...props} />)(
    ({ theme, hasImage, bgColor }) => css`
        background: ${hasImage ? theme.tokens.SyntaxBackgroundNeutralDarkest : bgColor ?? 'transparent'};
        position: relative;
        min-height: ${hasImage ? '480px' : 'unset'};
        color: ${hasImage ? theme.tokens.ColorNeutralWhite : theme.tokens.ColorNeutralBlack};
        z-index: 1;
        @media (min-width: ${theme.breakpoints.m}) {
            height: ${hasImage ? '768px' : 'unset'};
        }
    `
);

const StyledImg = styled(Img)`
    object-fit: cover;
`;

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});
