import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from '@chakra-ui/react';

export interface Props {
    clamp?: number;
    nowrap?: boolean;
}

const Body = styled(Box)<Props>(
    ({ theme, clamp, nowrap, fontSize, lineHeight }) => css`
        white-space: ${nowrap ? 'nowrap' : 'pre-line'};
        font-size: ${fontSize || theme.tokens.SyntaxBodyTextLRegularFontSize};
        line-height: ${lineHeight || theme.tokens.SyntaxBodyTextLRegularLineHeight};
        font-family: ${theme.tokens.SyntaxBodyTextLRegularFontFamily};
        font-variant-numeric: tabular-nums;

        [data-font-loaded='false'] & {
            letter-spacing: 0.014em;
            word-spacing: 0.065em;
        }

        ${clamp &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: ${clamp};
            -webkit-box-orient: vertical;
            overflow: hidden;
        `}

        strong {
            font-weight: ${theme.fontWeights.xl};
        }

        a {
            color: ${theme.tokens.LinkEnabledForeground};
            text-decoration: underline;

            &:hover {
                color: ${theme.tokens.LinkEnabledForeground};
            }
        }
    `
);

export default Body;
