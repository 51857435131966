import { FC, HTMLAttributes } from 'react';

const IconCalendarAdd: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Calendar Add">
            <g id="calendar-add--add-calendar-date-day-month">
                <path id="Rectangle 11" d="M2 4H22V22H2V4Z" stroke="currentColor" strokeWidth="1.5" />
                <g id="Vector 39">
                    <path d="M7 1V4V1Z" fill="currentColor" />
                    <path d="M7 1V4" stroke="currentColor" strokeWidth="1.5" />
                </g>
                <g id="Vector 40">
                    <path d="M17 1V4V1Z" fill="currentColor" />
                    <path d="M17 1V4" stroke="currentColor" strokeWidth="1.5" />
                </g>
                <g id="Vector 1435">
                    <path d="M8 15H16H8ZM12 11V19V11Z" fill="currentColor" />
                    <path d="M8 15H16M12 11V19" stroke="currentColor" strokeWidth="1.5" />
                </g>
                <g id="Vector 38">
                    <path d="M2 8H22H2Z" fill="currentColor" />
                    <path d="M2 8H22" stroke="currentColor" strokeWidth="1.5" />
                </g>
            </g>
        </g>
    </svg>
);

export default IconCalendarAdd;
