import { ContentBlockFilm, Meta } from 'designsystem';
import { getMetaSimplifiedForFilmOrProject, useGetApiImageProps } from '../../index';
import { useFestivalItemCardQuery } from '../../gql/api';
import Link from 'next/link';
import slugify from 'slugify';

const FestivalFilmContentBlock: React.FC<{ id: string }> = ({ id, ...rest }) => {
    const getApiImageProps = useGetApiImageProps();
    const { data } = useFestivalItemCardQuery({
        id,
    });
    const part = data?.currentFestivalPart;

    if (part?.__typename === 'Film') {
        return (
            <Link
                href={`${process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}film/${id}/${slugify(part.fullPreferredTitle, {
                    lower: true,
                })}`}
            >
                <ContentBlockFilm
                    image={getApiImageProps(part?.publications.favoriteImage, part.fullPreferredTitle)}
                    title={part?.fullPreferredTitle}
                    meta={<Meta size="s" {...getMetaSimplifiedForFilmOrProject(part)} />}
                    {...rest}
                />
            </Link>
        );
    }

    if (part?.__typename === 'Composition') {
        return (
            <Link
                href={`${process.env.NEXT_PUBLIC_PILLAR_URL_FESTIVAL}composition/${id}/${slugify(part.fullTitle, {
                    lower: true,
                })}`}
            >
                <ContentBlockFilm
                    image={getApiImageProps(
                        part.publications?.favoriteImage ?? part.publications?.stills[0],
                        part.fullTitle
                    )}
                    title={part?.fullTitle}
                    meta={null}
                    {...rest}
                />
            </Link>
        );
    }

    return null;
};

export default FestivalFilmContentBlock;
