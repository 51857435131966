import { QueryClient } from '@tanstack/react-query';
import { NewsPathQuery, NewsPathQueryVariables, useNewsPathQuery } from '../gql/cms';
import { fetchCraftData } from '../hooks/useCraft';

const fetchNewsUri = async (queryClient: QueryClient, site: string) => {
    const variables: NewsPathQueryVariables = { site };
    const data = await queryClient.fetchQuery(useNewsPathQuery.getKey(variables), () =>
        fetchCraftData<NewsPathQuery, NewsPathQueryVariables>({
            query: useNewsPathQuery.document,
            variables,
        })
    );
    return data.entry.uri;
};

export default fetchNewsUri;
