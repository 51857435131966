import { AspectRatio, Modal, ModalCloseButton, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import IconClose from '../trinket/icon/IconClose';
import Img, { ImgProps } from './Img';
import dynamic from 'next/dynamic';
import { ReactPlayerProps } from 'react-player';
import Body from '../typography/Body';
import { useTheme } from '@emotion/react';
// importing react-player directly causes hydration error in newest version of NextJS: https://github.com/cookpete/react-player/issues/1428
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface MediaImg extends ImgProps {
    type: 'image';
}

interface MediaVideo extends Omit<ReactPlayerProps, 'url'> {
    type: 'video';
    src: ReactPlayerProps['url'];
}

interface Props extends Omit<ModalProps, 'children'> {
    index: number;
    total: number;
    media?: MediaImg | MediaVideo;
    onPrev?: () => void;
    onNext?: () => void;
    closeLabel: ReactNode;
    children?: ReactNode;
}

const isVideoSrc = (media: Props['media']): media is MediaVideo => media.type === 'video';

const MediaModal: React.FC<Props> = ({
    media,
    index,
    total,
    onNext,
    onPrev,
    closeLabel = 'Sluiten',
    children,
    ...rest
}) => {
    const { tokens } = useTheme();
    return (
        <Modal variant="media" size="lg" {...rest}>
            <ModalOverlay />
            <AnimatePresence initial={false}>
                <ModalContent bg="transparent" key={index} textColor={tokens.SyntaxTextColorOnDark}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.4 } }}
                        exit={{ opacity: 0, transition: { duration: 0.4 } }}
                    >
                        {media &&
                            (isVideoSrc(media) ? (
                                <AspectRatio ratio={16 / 9}>
                                    <ReactPlayer width="100%" height="100%" url={media.src} {...media} controls />
                                </AspectRatio>
                            ) : (
                                <Img {...media} />
                            ))}
                        {children}
                    </motion.div>
                    {total > 1 && (
                        <Body mx="auto" my={3}>
                            {index + 1} / {total}
                        </Body>
                    )}
                </ModalContent>
            </AnimatePresence>
            <ModalCloseButton onClick={rest.onClose}>
                <span>{closeLabel} </span>
                <IconClose />
            </ModalCloseButton>
        </Modal>
    );
};

export default MediaModal;
