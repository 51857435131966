/* eslint-disable react/destructuring-assignment */
import React, { HTMLProps, SourceHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StaticImageData } from 'next/image';
import { motion } from 'framer-motion';

type SourceProps = SourceHTMLAttributes<HTMLSourceElement>;
export interface ImgProps extends Omit<HTMLProps<HTMLPictureElement>, 'src'> {
    /** Default is 1.2 */
    scaleAmount?: number;
    /**  Preferably some kind of low quality, low size image of the same kind as the src image */
    placeholder?: string;
    /**  In miliseconds. Default is 750 miliseconds */
    fadeInDuration?: number;
    /**  Amount of blur that is applied to the placeholder. Default is 20 pixels */
    blurAmount?: string;
    src: string | StaticImageData;
    sources?: SourceProps[];
    loading?: 'eager' | 'lazy';
    className?: string;
    alt?: string;
    title?: string;
    width?: number | string;
    height?: number | string;
    addNoscript?: boolean;
    fill?: boolean;
    motionProps?: React.ComponentProps<typeof motion.img>;
    borderRadius?: number | string;
}

const imageCache: Record<string, boolean> = {};

const addToImageCache = (src: string) => {
    if (typeof document === 'undefined') {
        return;
    }

    imageCache[src] = true;
};

const isImageCached = (src: string | undefined): boolean => {
    if (typeof document === 'undefined') {
        return false;
    }
    if (!src) {
        return false;
    }
    return imageCache[src] || false;
};

const generateNoscriptSource = (props: SourceProps) => {
    const type = props.type ? `type=${props.type}` : '';
    const srcSet = props.srcSet ? `srcset=${props.srcSet}` : '';
    return `<source ${type}${srcSet}/>`;
};

const generateNoscriptSources = (...sources: SourceProps[]) => sources.map(generateNoscriptSource).join(``);

const noscriptImg = (props: ImgProps) => {
    // Check if prop exists before adding each attribute to the string output below to prevent
    // HTML validation issues caused by empty values like width="" and height=""
    const src = props.src ? `src="${props.src}" ` : `src="" `; // required attribute
    const srcSet = props.srcSet ? `srcset="${props.srcSet}" ` : ``;
    const title = props.title ? `title="${props.title}" ` : ``;
    const alt = props.alt ? `alt="${props.alt}" ` : `alt="" `; // required attribute
    const width = props.width ? `width="${props.width}" ` : ``;
    const height = props.height ? `height="${props.height}" ` : ``;
    const loading = props.loading ? `loading="${props.loading}" ` : ``;
    const sources = generateNoscriptSources(...(props.sources ?? []));
    return `<picture>${sources}<img ${loading}${width}${height}${srcSet}${src}${alt}${title}style="position:absolute;top:0;left:0;opacity:1;width:100%;height:100%;object-fit:cover;object-position:center"/></picture>`;
};

/**
 * There are basically three ways to use the `Img` component.
 *
 * 1. Default, where width and height are required and an image with static dimensions will be displayed
 * 2. `layout: fill` will fill its parent container
 * 3. `layout: ratio` will size the image according to the ratio that's given as a prop
 */
const Img = (props: ImgProps) => {
    const {
        fadeInDuration = 500,
        blurAmount = '10px',
        scaleAmount = 1.2,
        sources,
        loading = 'lazy',
        alt,
        title,
        className,
        addNoscript = false,
        fill,
        width,
        height,
        as,
        motionProps,
        borderRadius,
        ...rest
    } = props;
    let { placeholder } = props;

    let src: string;
    if (typeof props.src !== 'string') {
        // support for next.js static image imports
        const staticImage = props.src as StaticImageData;

        placeholder = staticImage?.blurDataURL;
        src = staticImage?.src;
    } else {
        src = props.src as string;
    }

    const showNoscript = addNoscript || loading === 'eager';
    const ref = useRef<HTMLImageElement>(null);
    const [inCache] = useState(isImageCached(src));
    const [loaded, setLoaded] = useState(false);

    const onLoad = useCallback(() => {
        setLoaded(true);
        if (src) {
            addToImageCache(src);
        }
    }, [src]);

    useEffect(() => {
        // if the img has already been downloaded onLoad won't trigger so we do it manually
        if (ref.current?.complete) {
            onLoad();
        }
    }, [onLoad]);

    const srcRef = useRef(src);

    useEffect(() => {
        const resetImgState = () => {
            setLoaded(false);
        };

        if (srcRef.current !== src) {
            resetImgState();
        }

        srcRef.current = src;
    }, [src]);

    return (
        <Container isFill={!!fill} width={width} height={height} borderRadius={borderRadius}>
            {placeholder && (
                <Placeholder
                    title={title}
                    alt={alt}
                    width={width}
                    height={height}
                    blurAmount={blurAmount}
                    scaleAmount={scaleAmount}
                    src={placeholder}
                    loading={loading}
                    aria-hidden
                    style={{
                        opacity: loaded ? 0 : 1,
                        transition: `opacity 0ms`,
                        transitionDelay: inCache ? '0ms' : `${fadeInDuration}ms`,
                    }}
                />
            )}
            <Picture isFill={!!fill} className={className} {...rest}>
                {sources?.map((source, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <source key={i} {...source} />
                ))}
                <motion.img
                    title={title}
                    alt={alt}
                    ref={ref}
                    width={width}
                    height={height}
                    loading={loading}
                    onLoad={onLoad}
                    src={src}
                    style={{
                        opacity: loaded ? 1 : 0,
                        transition: 'opacity ease',
                        transitionDuration: !placeholder || inCache ? '0ms' : `${fadeInDuration}ms`,
                    }}
                    {...motionProps}
                />
                {/* Show the original image during server-side rendering */}
                {/*  eslint-disable-next-line react/no-danger */}
                {showNoscript && <noscript dangerouslySetInnerHTML={{ __html: noscriptImg(props) }} />}
            </Picture>
        </Container>
    );
};

export default Img;

const Placeholder = styled.img<{ blurAmount: string; scaleAmount: number }>`
    filter: blur(${({ blurAmount }) => blurAmount});
    transform: scale(${({ scaleAmount }) => scaleAmount});
    object-fit: cover;
    pointer-events: none;
    background: ${({ theme }) => theme.colors.neutral['10']};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-position: center;
`;

interface ContainerProps {
    width: number | string;
    height: number | string;
    isFill: boolean;
    borderRadius?: number | string;
}

const Container = styled.div<ContainerProps>`
    overflow: hidden;
    position: relative;

    ${({ isFill, width, height, borderRadius }) =>
        !isFill
            ? css`
                  aspect-ratio: ${typeof width === 'string' ? parseFloat(width) : width} /
                      ${typeof height === 'string' ? parseFloat(height) : height};
              `
            : css`
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: ${borderRadius || 'unset'};
              `}
`;

const Picture = styled.picture<Pick<ContainerProps, 'isFill'>>`
    ${({ isFill }) =>
        isFill &&
        css`
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        `}

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
`;
