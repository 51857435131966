import { ReactNode, useCallback, useState } from 'react';

import { AspectRatio, BoxProps, Flex, GridItem, Stack, useInterval } from '@chakra-ui/react';

import Wrapper from '../layout/Wrapper';
import { ImgProps } from './Img';
import BackgroundCarousel from '../content/BackgroundCarousel';
import Body from '../typography/Body';
import BrowseStepper from '../navigation/BrowseStepper';
import Heading from '../typography/Heading';
import Grid from '../layout/Grid';

interface Slide {
    key: string;
    image: ImgProps;
}

interface Props extends Omit<BoxProps, 'title'> {
    slides: Slide[];
    title: ReactNode;
    description?: ReactNode;
    buttons?: ReactNode;
    navigationTitle: ReactNode;
}

const ImpressionCarousel: React.FC<Props> = ({ slides, title, description, buttons, navigationTitle, ...props }) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const onPrev = useCallback(
        () => setSlideIndex(slideIndex === 0 ? slides.length - 1 : (slideIndex - 1) % slides.length),
        [slideIndex, slides.length]
    );
    const onNext = useCallback(() => setSlideIndex((slideIndex + 1) % slides.length), [slideIndex, slides.length]);

    useInterval(() => {
        onNext();
    }, 5000);

    return (
        <Wrapper {...props}>
            <Flex flexDir="column">
                <AspectRatio ratio={16 / 9} order={[2, null, 1]} mb={[6, null, 11]}>
                    <BackgroundCarousel slides={slides} slideIndex={slideIndex} />
                </AspectRatio>
                <Grid order={[1, null, 3]}>
                    <GridItem colStart={[1, null, null, 2]} colSpan={6} mb={[11, null, 0]}>
                        <Stack spacing={4}>
                            <Heading variant={3}>{title}</Heading>
                            {description && <Body>{description}</Body>}
                            {buttons}
                        </Stack>
                    </GridItem>
                </Grid>
                <Grid order={[3, null, 2]}>
                    <GridItem display="flex" colStart={[1, null, -6, -5]} colEnd={[-1]} pos="relative">
                        <BrowseStepper
                            pos="absolute"
                            left={0}
                            right={0}
                            onNext={onNext}
                            onPrev={onPrev}
                            title={navigationTitle}
                            current={slideIndex + 1}
                            total={slides.length}
                            body={`${slideIndex + 1}/${slides.length}`}
                        />
                    </GridItem>
                </Grid>
            </Flex>
        </Wrapper>
    );
};

export default ImpressionCarousel;
