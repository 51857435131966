import { ArchiveFilterEnum } from '../gql/api';

// Union type for enum values. I know...
type ArchiveFilterValues = `${ArchiveFilterEnum}`;

const shownFiltersForArchiveTypes: Record<string, ArchiveFilterValues[]> = {
    film: Object.values(ArchiveFilterEnum),
    report: ['ARCHIVE_TYPE', 'REPORT_TYPE', 'LENGTH_IN_MINUTES'],
    person: Object.values(ArchiveFilterEnum),
    persoon: Object.values(ArchiveFilterEnum),
};

// eslint-disable-next-line import/prefer-default-export
export default shownFiltersForArchiveTypes;
