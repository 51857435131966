import { FC, HTMLAttributes } from 'react';

const IconSettings: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.03125 7.36875L12 2.91937L19.9688 7.37062V16.6312L12 21.0816L4.03125 16.6303V7.36969V7.36875Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M8.23828 12C8.23828 12.9973 8.63446 13.9537 9.33965 14.6589C10.0448 15.3641 11.0013 15.7603 11.9986 15.7603C12.9959 15.7603 13.9523 15.3641 14.6575 14.6589C15.3627 13.9537 15.7589 12.9973 15.7589 12C15.7589 11.5062 15.6616 11.0172 15.4727 10.561C15.2837 10.1048 15.0067 9.69023 14.6575 9.34106C14.3084 8.99188 13.8938 8.7149 13.4376 8.52592C12.9814 8.33695 12.4924 8.23969 11.9986 8.23969C11.5048 8.23969 11.0158 8.33695 10.5596 8.52592C10.1034 8.7149 9.68883 8.99188 9.33965 9.34106C8.99047 9.69023 8.71349 10.1048 8.52452 10.561C8.33554 11.0172 8.23828 11.5062 8.23828 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
        />
    </svg>
);

export default IconSettings;
