import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { dateFormat, timeFormat } from '../constants/dateFormats';

type DateVariants = 'DATE-SHORT' | 'DATE-MEDIUM' | 'DATE-LONG';
type TimeVariants = 'TIME-SHORT' | 'TIME-LONG';

export const useDateFormatter = () => {
    const { locale } = useRouter();
    const { formatDate } = useIntl();

    const replaceColonWithDot = (str: string) => str.replaceAll(':', locale === 'nl' ? '.' : ':');
    const removeCurrentYear = (str: string) => {
        const currentYear = new Date().getFullYear().toString();
        return str.replace(`, ${currentYear}`, '').replace(currentYear, '').trim();
    };
    const replaceNonBreakingSpace = (str: string) => str.replaceAll(' ', ' ');

    const dateFormatter = (date: Date, variant: DateVariants) => {
        switch (variant) {
            case 'DATE-SHORT':
                return replaceNonBreakingSpace(replaceColonWithDot(formatDate(date, dateFormat.short)));
            case 'DATE-MEDIUM':
                return replaceNonBreakingSpace(replaceColonWithDot(formatDate(date, dateFormat.medium)));
            case 'DATE-LONG':
                return replaceNonBreakingSpace(
                    removeCurrentYear(replaceColonWithDot(formatDate(date, dateFormat.long)))
                );
            default:
                throw new Error(`Invalid date variant: ${variant}`);
        }
    };

    const timeFormatter = (date: Date, variant: TimeVariants) => {
        switch (variant) {
            case 'TIME-SHORT':
                return replaceColonWithDot(formatDate(date, timeFormat.short));
            case 'TIME-LONG':
                return replaceColonWithDot(formatDate(date, timeFormat.long));
            default:
                throw new Error(`Invalid time variant: ${variant}`);
        }
    };

    const dateRangeFormatter = (from: Date, to: Date, variant: DateVariants) =>
        `${dateFormatter(from, variant)}⁠–${dateFormatter(to, variant)}`;

    const timeRangeFormatter = (from: Date, to: Date, variant: TimeVariants) =>
        `${timeFormatter(from, variant)}⁠–${timeFormatter(to, variant)}`;

    const dateTimeRangeFormatter = (from: Date, to: Date, dateVariant: DateVariants, timeVariant: TimeVariants) =>
        `${replaceNonBreakingSpace(
            `${dateFormatter(from, dateVariant)} ${timeFormatter(from, timeVariant)}`
        )} - ${replaceNonBreakingSpace(`${dateFormatter(to, dateVariant)} ${timeFormatter(to, timeVariant)}`)}`;

    return {
        dateFormatter,
        timeFormatter,
        dateRangeFormatter,
        timeRangeFormatter,
        dateTimeRangeFormatter,
    };
};

export const FormattedDate: React.FC<{ date: Date; variant: DateVariants }> = ({ date, variant }) => {
    const { dateFormatter } = useDateFormatter();

    return <span suppressHydrationWarning>{dateFormatter(date, variant)}</span>;
};

export const FormattedTime: React.FC<{ date: Date; variant: TimeVariants }> = ({ date, variant }) => {
    const { timeFormatter } = useDateFormatter();

    return <span suppressHydrationWarning>{timeFormatter(date, variant)}</span>;
};

export const FormattedDateRange: React.FC<{ from: Date; to: Date; variant: DateVariants }> = ({
    from,
    to,
    variant,
}) => {
    const { dateRangeFormatter } = useDateFormatter();

    return <span suppressHydrationWarning>{dateRangeFormatter(from, to, variant)}</span>;
};

export const FormattedTimeRange: React.FC<{ from: Date; to: Date; variant: TimeVariants }> = ({
    from,
    to,
    variant,
}) => {
    const { timeRangeFormatter } = useDateFormatter();

    return <span suppressHydrationWarning>{timeRangeFormatter(from, to, variant)}</span>;
};

export const FormattedDateTimeRange: React.FC<{
    from: Date;
    to: Date;
    dateVariant: DateVariants;
    timeVariant: TimeVariants;
}> = ({ from, to, dateVariant, timeVariant }) => {
    const { dateTimeRangeFormatter } = useDateFormatter();

    return <span suppressHydrationWarning>{dateTimeRangeFormatter(from, to, dateVariant, timeVariant)}</span>;
};
