import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import React, { FC, useMemo } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useIntl } from 'react-intl';
import { ArticlesOverviewQuery, ArticlesOverviewQueryVariables, useArticlesOverviewQuery } from '../../gql/cms';
import { ArticlesOverviewTemplate, cmsImageToOpenGraph, fetchCraftData, getMetaForArticle, PreviewData } from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import siteHandleByLocale from '../../utils/siteHandleByLocale';

import { ContentPage } from '../../pages/[...uri]';
import InstituteBreadcrumbs from '../InstituteBreadcrumbs';

type NewsArticleOverviewPage = Extract<ContentPage, { __typename: 'instituteContentPages_newsOverview_Entry' }>;

const ITEMS_PER_PAGE = 10;

const NewsArticlesOverview: FC<{ data: NewsArticleOverviewPage }> = ({ data: contentPageData }) => {
    const [page] = useQueryParam('page', withDefault(NumberParam, 1));
    const intl = useIntl();
    const { locale } = intl;
    const { data: overviewData, isFetching } = useArticlesOverviewQuery({
        site: siteHandleByLocale[locale],
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
    });
    const newsArticles = overviewData?.instituteNewsArticlesEntries;
    const newsArticlesWithMeta = useMemo(
        () =>
            newsArticles?.map(article => ({
                ...article,
                meta: getMetaForArticle(article, intl),
                href: `/news/${article.slug}`,
            })),
        [newsArticles, intl]
    );

    return (
        <ArticlesOverviewTemplate
            pageTitle={contentPageData.title}
            pageIntro={contentPageData.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(contentPageData.navThumbnail)}
            loading={isFetching}
            articles={newsArticlesWithMeta}
            articleCount={overviewData?.articleCount}
            breadcrumbs={<InstituteBreadcrumbs />}
        />
    );
};

export const getStaticProps: GetStaticProps = async ctx => {
    const { locale, previewData } = ctx;
    const queryClient = new QueryClient();
    const vars = {
        site: siteHandleByLocale[locale],
        limit: ITEMS_PER_PAGE,
        offset: 0,
    };

    await queryClient.prefetchQuery(useArticlesOverviewQuery.getKey(vars), () =>
        fetchCraftData<ArticlesOverviewQuery, ArticlesOverviewQueryVariables>({
            query: useArticlesOverviewQuery.document,
            variables: vars,
            previewData: previewData as PreviewData,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default NewsArticlesOverview;
