import { StyleConfig } from '@chakra-ui/react';

/**
 * Theme for a Chakra Accordion. Include this into your theme.
 */
const AccordionTheme: StyleConfig = {
    baseStyle: {
        container: {
            borderBottomWidth: 'm',
            borderBottomStyle: 'solid',
            borderBottomColor: 'currentColor',
            pb: 3,
            '& + &': {
                pt: 6,
            } as never, // <-- needed because typings are wrong
        },
        button: {
            textAlign: 'left',
            mt: -6,
            mb: -3,
            py: 6,
        },
        panel: {
            pb: 4,
        },
        icon: {},
    },
    defaultProps: {
        variant: 'bla',
        size: 'md',
    },
};

export default AccordionTheme;
