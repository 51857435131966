import { MetaProps, Tag } from 'designsystem';
import { IntlShape } from 'react-intl';
import { FilmOrProjectType } from '../constants/filmOrProjectTypes';
import { FormattedDate } from '../hooks/useDateFormatter';
import getFilmOrProjectTypesFromEditionType from './getFilmOrProjectTypeFromEditionType';

const filmMetaFields = [
    'director',
    'countries-of-production',
    'year',
    'length',
    'ed-lvl',
    'premiere',
    'new-media-type',
    'request-type',
    'edition-year',
    'sections',
    'agent',
] as const;

interface MetaFilmOrProject {
    yearOfProduction?: number;
    lengthInMinutes?: number;
    countriesOfProduction?: Array<{
        key?: string;
        translation?: string;
    }>;
    credits?: Array<{
        fullName?: string;
        role?: {
            key?: string;
            translation?: string;
        };
    }>;
    educationLevel?: { translation?: string }[];
    premiere?: { key?: string; translation?: string };
    newMediaType?: Array<{
        sortOrder?: number;
        translation?: string;
    }>;
    sections?: {
        id?: string;
        name?: string;
    }[];
    edition?: {
        id: string;
        name: string;
        year: number;
        editionType: { description: string; id: string };
    };
    requestType?: Array<{
        sortOrder?: number | null;
        translation?: string | null;
    }>;
    docsForSaleSeries?: string;
}

interface MetaPerson {
    professions?: {
        translation?: string;
    };
    nationality?: {
        translation?: string;
    };
}

interface MetaViewingHistory {
    viewedAt: string;
}

interface MetaReport {
    lengthInMinutes?: number;
    alternativeContentType?: { translation?: string };
}

export interface MetaArticle {
    newsCategory?: { title?: string }[];
    postDate?: string;
    authorName?: string;
}

interface MetaStory {
    storyCategory?: { title?: string }[];
    postDate?: string;
    authorName?: string;
}

const stripCompanyName = (credit: string): string => {
    // assuming company name in credit is always behind the slash
    const slashIndex = credit.indexOf('/');
    if (slashIndex !== -1) {
        return credit.slice(0, slashIndex).trimEnd();
    }
    return credit;
};

const getFieldFromFilmOrProject = (
    filmOrProject: MetaFilmOrProject,
    field: (typeof filmMetaFields)[number]
): Omit<MetaProps, 'size'>['items'][number] | null => {
    switch (field) {
        case 'director': {
            const directors = filmOrProject.credits?.filter(credit => credit.role.key === 'director');
            return (
                directors?.length > 0 && {
                    key: field,
                    value: directors.map(director => stripCompanyName(director.fullName)).join(', '),
                }
            );
        }
        case 'countries-of-production': {
            return (
                filmOrProject.countriesOfProduction?.length > 0 && {
                    key: field,
                    value: filmOrProject.countriesOfProduction.map(({ translation }) => translation).join(', '),
                }
            );
        }
        case 'year': {
            return filmOrProject.yearOfProduction && { key: field, value: filmOrProject.yearOfProduction.toString() };
        }
        case 'edition-year': {
            return (
                filmOrProject.edition && {
                    key: field,
                    value: filmOrProject.edition.year,
                }
            );
        }
        case 'length': {
            return (
                (filmOrProject.lengthInMinutes || filmOrProject.docsForSaleSeries) && {
                    key: field,
                    value: filmOrProject.docsForSaleSeries ? 'Series' : `${filmOrProject.lengthInMinutes} min`,
                }
            );
        }
        case 'ed-lvl': {
            return (
                filmOrProject.educationLevel?.length > 0 && {
                    key: field,
                    value: filmOrProject.educationLevel.map(level => level.translation).join(', '),
                }
            );
        }
        case 'premiere': {
            return (
                filmOrProject.premiere &&
                filmOrProject.premiere.key !== 'not-set' &&
                filmOrProject.premiere.key !== 'n-a' && {
                    key: field,
                    value: filmOrProject.premiere.translation,
                }
            );
        }
        case 'agent': {
            const foundSalesAgents = filmOrProject.credits?.filter(credit => credit.role.key === 'sales-agent');
            return (
                foundSalesAgents?.length > 0 && {
                    key: field,
                    value: foundSalesAgents.map(agent => stripCompanyName(agent.fullName)).join(', '),
                }
            );
        }
        case 'sections': {
            return (
                filmOrProject.sections?.length > 0 && {
                    key: field,
                    value: filmOrProject.sections
                        .filter(section => section.name !== 'Not selected at IDFA')
                        .map(s => s.name)
                        .join(', '),
                }
            );
        }
        case 'new-media-type': {
            return (
                filmOrProject.newMediaType?.length > 0 && {
                    key: field,
                    value: filmOrProject.newMediaType
                        ?.sort((a, b) => b.sortOrder - a.sortOrder)
                        .map(type => type.translation)
                        .join(', '),
                }
            );
        }
        case 'request-type': {
            return (
                filmOrProject.requestType?.length > 0 && {
                    key: field,
                    value: filmOrProject.requestType
                        .sort((a, b) => b.sortOrder - a.sortOrder)
                        .map(type => type.translation)
                        .join(', '),
                }
            );
        }
        default:
            return null;
    }
};

/**
 * Gets meta information for a film or project. When a film or project type is passed through as a parameter it is used to select the fields to be returned. Otherwise the films editionType is used.
 *
 * @param film
 * @param filmOrProjectType force the use of fields for a specified film or project type
 * @returns
 */
export const getMetaForFilmOrProjectDetails = ({
    filmOrProject,
    filmOrProjectType,
}: {
    filmOrProject: MetaFilmOrProject | null;
    filmOrProjectType?: FilmOrProjectType;
}): Omit<MetaProps, 'size'> => {
    if (!filmOrProject) {
        return { items: [] };
    }

    const fieldsForFilmOrProjectDetails = new Map<FilmOrProjectType, (typeof filmMetaFields)[number][]>([
        ['idfa', ['director', 'countries-of-production', 'year', 'length', 'premiere', 'sections']],
        ['docschool', ['director', 'countries-of-production', 'year', 'length', 'ed-lvl']],
        ['berthaFund', ['director', 'countries-of-production']],
        ['forum', ['director', 'countries-of-production']],
        ['projectSpace', ['director', 'countries-of-production']],
        ['docLab', ['director', 'countries-of-production', 'edition-year', 'length', 'sections']],
        ['docsForSale', ['director', 'countries-of-production', 'year', 'length', 'sections']],
        ['schedule', ['director', 'countries-of-production', 'year', 'length', 'premiere', 'sections']],
        ['cinema', ['director', 'countries-of-production', 'year', 'length', 'sections']],
    ]);

    return {
        items: fieldsForFilmOrProjectDetails
            .get(
                filmOrProjectType ||
                    getFilmOrProjectTypesFromEditionType(filmOrProject.edition?.editionType?.description)
            )
            ?.map(field => getFieldFromFilmOrProject(filmOrProject, field))
            .filter(item => Boolean(item?.value)),
    };
};

/**
 * Gets meta information for a film or project. When a film or project type is passed through as a parameter it is used to select the fields to be returned. Otherwise the films editionType is used.
 *
 * @param film
 * @param filmOrProjecType force the use of fiels for a specified film or project type
 * @returns
 */
export const getMetaForFilmOrProjectHit = ({
    filmOrProject,
    filmOrProjectType,
}: {
    filmOrProject: MetaFilmOrProject;
    filmOrProjectType?: FilmOrProjectType;
}): Omit<MetaProps, 'size'> => {
    const fieldsForFilmOrProjectHit = new Map<FilmOrProjectType, (typeof filmMetaFields)[number][]>([
        ['idfa', ['director', 'countries-of-production', 'year', 'length']],
        ['docschool', ['director', 'countries-of-production', 'year', 'length']],
        ['berthaFund', ['director', 'countries-of-production', 'request-type', 'edition-year']],
        ['forum', ['director', 'countries-of-production', 'length']],
        ['projectSpace', ['director', 'countries-of-production', 'request-type', 'edition-year']],
        ['docLab', ['director', 'countries-of-production', 'year', 'length', 'premiere', 'new-media-type', 'sections']],
        ['docsForSale', ['director', 'countries-of-production', 'year', 'length', 'sections', 'agent']],
        ['schedule', ['director', 'countries-of-production', 'year', 'length']],
    ]);

    return {
        items: fieldsForFilmOrProjectHit
            .get(
                filmOrProjectType ||
                    getFilmOrProjectTypesFromEditionType(filmOrProject.edition?.editionType?.description)
            )
            ?.map(field => getFieldFromFilmOrProject(filmOrProject, field))
            .filter(item => Boolean(item?.value)),
    };
};

export const getMetaSimplifiedForFilmOrProject = (film: MetaFilmOrProject): Omit<MetaProps, 'size'> => ({
    items: (['director', 'countries-of-production'] as const)
        .map(field => getFieldFromFilmOrProject(film, field))
        .filter(item => Boolean(item?.value)),
});

export const getMetaForViewingHistory = (viewingHistory: MetaViewingHistory): Omit<MetaProps, 'size'> => ({
    items: [
        viewingHistory.viewedAt && {
            key: 'viewed-at',
            value: viewingHistory.viewedAt,
        },
    ].filter(Boolean),
});

export const getMetaForReport = (reportFilm: MetaReport): Omit<MetaProps, 'size'> => ({
    items: [
        reportFilm.alternativeContentType && {
            key: 'report-content-type',
            value: reportFilm.alternativeContentType.translation,
        },
        reportFilm.lengthInMinutes && { key: 'report-length', value: `${reportFilm.lengthInMinutes} min` },
    ].filter(Boolean),
});

export const getMetaForPerson = (person: MetaPerson): Omit<MetaProps, 'size'> => ({
    items: [
        person?.professions && {
            key: 'role',
            value: person.professions.translation,
        },
        person?.nationality && {
            key: 'nationality',
            value: person.nationality.translation,
        },
    ].filter(Boolean),
});

export const getMetaForArticle = (article: MetaArticle, intl: IntlShape): Omit<MetaProps, 'size'> => ({
    tag: article?.newsCategory?.length > 0 ? <Tag>{article.newsCategory[0].title}</Tag> : undefined,
    items: [
        article?.postDate && {
            key: 'postDate',
            value: <FormattedDate date={new Date(article.postDate)} variant="DATE-MEDIUM" />,
        },
        article?.authorName && {
            key: 'author',
            value: `${intl.formatMessage({ defaultMessage: 'Door', id: 'author-by' })} ${article.authorName}`,
        },
    ].filter(Boolean),
});

export const getMetaForStory = (story: MetaStory, intl: IntlShape): Omit<MetaProps, 'size'> => ({
    tag: story?.storyCategory?.length > 0 ? <Tag>{story.storyCategory[0].title}</Tag> : undefined,
    items: [
        story?.postDate && {
            key: 'postDate',
            value: <FormattedDate date={new Date(story.postDate)} variant="DATE-MEDIUM" />,
        },
        story?.authorName && {
            key: 'author',
            value: `${intl.formatMessage({ defaultMessage: 'Door', id: 'author-by' })} ${story.authorName}`,
        },
    ].filter(Boolean),
});
