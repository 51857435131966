import { BoxProps, Divider, Stack, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren, ReactNode } from 'react';
import Tile from '../tile/Tile';
import Heading from '../typography/Heading';

interface Props extends PropsWithChildren, Omit<BoxProps, 'title'> {
    title?: ReactNode;
    subtitle?: ReactNode;
}

const ContentBlockTickets: React.FC<Props> = ({ title = 'Tickets', subtitle = '', children, ...rest }) => (
    <Tile colorScheme="neutral" mb={6} {...rest}>
        <Stack spacing={2}>
            <Heading variant={3}>{title}</Heading>
            {subtitle}
            <VStack pt={6} spacing={8} divider={<Divider />} alignItems="stretch">
                {children}
            </VStack>
        </Stack>
    </Tile>
);

export default ContentBlockTickets;
