import { FC, ReactNode } from 'react';
import { Grid, GridItem, Stack, useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Body from '../typography/Body';

interface LightTableLine {
    key: string;
    description: ReactNode;
    value: ReactNode;
}

const LightTable: FC<{ lines: LightTableLine[]; spacing?: number }> = ({ lines, spacing }) => {
    const theme = useTheme();
    return (
        <Stack spacing={spacing || 3} flexDirection="column">
            {lines.map(line => (
                <Grid
                    key={line.key}
                    gridTemplateColumns={['1fr', null, `${theme.tokens.Sizing17} 1fr`]}
                    gap={['2px', null, 6]}
                >
                    <GridItem display="flex">
                        <Description>{line.description}</Description>
                    </GridItem>
                    <GridItem display="flex">
                        <Body>{line.value}</Body>
                    </GridItem>
                </Grid>
            ))}
        </Stack>
    );
};

const Description = styled(Body)`
    color: ${({ theme }) => theme.tokens.ColorGrey50};
`;

export default LightTable;
