import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, BoxProps, Flex, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Heading from '../typography/Heading';
import { css } from '@emotion/react';
import Body from '../typography/Body';

export interface CardProps extends BoxProps, PropsWithChildren {
    leftElement?: ReactNode;
    icon: ReactNode;
    variant?: 'primary' | 'neutral' | 'white';
    size?: 's' | 'm';
}

const SmallCard: React.FC<CardProps> = ({ variant = 'neutral', size = 'm', leftElement, children, icon, ...rest }) => (
    <Container variant={variant} {...rest}>
        <Box p={size === 'm' ? [5, null, 6] : 4}>
            <Flex alignItems="center">
                <HStack spacing={4} flexGrow={1} as="h3">
                    {leftElement}
                    {size === 'm' ? (
                        <Heading variant={4} clamp={1}>
                            {children}
                        </Heading>
                    ) : (
                        <Body fontWeight="xl">{children}</Body>
                    )}
                </HStack>
                <Box flexShrink={0} w={[6]} h={[6]}>
                    {icon}
                </Box>
            </Flex>
        </Box>
    </Container>
);

const Container = styled(Box)<Pick<CardProps, 'variant'>>(
    ({ theme, variant }) => css`
        text-decoration: none;
        color: ${theme.tokens.SyntaxTextColorDefault};
        ${variant === 'primary' &&
        css`
            background: ${theme.tokens.SyntaxBackgroundPrimaryDefault};
            color: ${theme.tokens.SyntaxTextColorOnPrimary};
        `}
        ${variant === 'neutral' &&
        css`
            background: ${theme.tokens.SyntaxBackgroundNeutralDark};
        `};
        ${variant === 'white' &&
        css`
            background: ${theme.tokens.SyntaxBackgroundNeutralDefault};
            border: ${theme.tokens.BorderWidthM} solid ${theme.tokens.BorderColorNeutralDefault};
        `};
    `
);

export default SmallCard;
