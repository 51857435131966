'use client';

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import cookies from 'js-cookie';
import { addHours } from 'date-fns';

const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '.idfa.nl';

const useLastPageCookie = () => {
    const { pathname } = useRouter();

    useEffect(() => {
        if (window.location.href.includes('login')) return;
        cookies.set('referer', window.location.href, {
            domain: cookieDomain,
            expires: addHours(new Date(), 1),
        });
    }, [pathname]);
};

export default useLastPageCookie;
