import { Box, Grid, Heading, Hero, HtmlContent, Intro, Partners, Stack, Wrapper } from 'designsystem';
import Image from 'next/image';
import React, { FC, ReactNode } from 'react';
import { NextSeo } from 'next-seo';
import striptags from 'striptags';
import { OpenGraphMedia } from 'next-seo/lib/types';
import ContentBlocks from '../ContentBlocks';
import TableOfContents from '../TableOfContents';

interface PartnerLogo {
    title?: string;
    logoImage?: {
        url?: string;
    }[];
    linkObject?: string;
}

interface PartnerListing {
    partnerName?: string;
    linkUrl?: string;
}

interface Props {
    pageTitle: string;
    pageIntroText?: string;
    pageOpenGraphImages?: OpenGraphMedia[];
    tableOfContentsTitle?: ReactNode;
    contentBlocks?: Omit<React.ComponentProps<typeof ContentBlocks>, 'stylesPerContentBlockType'>;
    sponsorBanner?: ReactNode;
    partners?: {
        partnerLogos?: PartnerLogo[];
        partnerListingsText?: string;
        partnerListings?: PartnerListing[];
    };
    breadcrumbs: ReactNode;
    children?: ReactNode;
}

const InformationTemplate: FC<Props> = ({
    pageTitle,
    pageIntroText,
    pageOpenGraphImages,
    tableOfContentsTitle,
    contentBlocks,
    sponsorBanner,
    partners,
    breadcrumbs,
    children,
    ...rest
}) => (
    <Stack spacing={18} {...rest}>
        <NextSeo
            title={pageTitle}
            description={striptags(pageIntroText)}
            openGraph={{
                images: pageOpenGraphImages,
            }}
        />

        <Wrapper>
            <Grid as="article" id="article" rowGap={[0, 0, 0, 0, 0]}>
                <Box gridColumn={['1 / -1', null, null, '1 / span 8', '2 / span 6']}>
                    <Hero topChildren={breadcrumbs} w={null} m={0} maxW="unset">
                        <Grid>
                            <Stack gridColumn={['1 / -1', null, null, null, '1 / span 6']} spacing={[4, null, 7]}>
                                <Heading mt={['72px', null, '112px']} variant={1} as="h1">
                                    {pageTitle}
                                </Heading>
                                {pageIntroText && (
                                    <HtmlContent
                                        html={pageIntroText}
                                        override={{
                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                            p: ({ children }) => <Intro>{children}</Intro>,
                                        }}
                                    />
                                )}
                                {tableOfContentsTitle && (
                                    <TableOfContents
                                        title={tableOfContentsTitle}
                                        parentNodeOfTitles="#article"
                                        key={`${pageTitle}-toc`}
                                    />
                                )}
                                {children}
                            </Stack>
                        </Grid>
                    </Hero>
                </Box>
                <ContentBlocks
                    {...contentBlocks}
                    stylesPerContentBlockType={{
                        default: {
                            gridColumn: ['1 / -1', null, '1 / span 10', '1 / span 8', '2 / span 6'],
                            gridAutoRows: 'auto',
                        },
                        contentBlocks_image_BlockType_large: {
                            gridColumn: '1/-1',
                        },
                    }}
                />
                <Box
                    gridRow={[null, null, null, 1]}
                    gridColumn={['1 / -1', null, null, '9 / -1']}
                    h={[null, null, null, '0']}
                    textAlign="center"
                >
                    {sponsorBanner}
                </Box>
            </Grid>
        </Wrapper>
        {partners && (
            <Partners
                logos={partners.partnerLogos
                    .filter(logo => logo.logoImage?.length)
                    .map(logo => ({
                        href: logo.linkObject,
                        logo: <Image src={logo.logoImage[0].url} alt={logo.title} />,
                        title: logo.title,
                    }))}
                partnerTitle={partners.partnerListingsText}
                partners={partners.partnerListings.map(partner => ({
                    name: partner.partnerName,
                    href: partner.linkUrl,
                }))}
            />
        )}
    </Stack>
);

export default InformationTemplate;
