import { Body, Box, FilmListItem, Meta } from 'designsystem';
import Link from 'next/link';
import queryString from 'query-string';
import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import slugify from 'slugify';
import { encodeQueryParams, StringParam, useQueryParam, useQueryParams } from 'use-query-params';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import useGetApiImageProps from '../../hooks/useGetApiImageProps';
import { CmsImage } from '../../hooks/useGetCmsImageProps';
import getHighestRankingAward from '../../lib/getHighestRankingAward';
import { useSearchContext } from '../SearchResults';
import stripHtml from '../../lib/stripHtml';

export interface Hit {
    id: string;
    title?: string | null;
    image?: CmsImage | null;
    description?: string;
    meta?: Omit<ComponentProps<typeof Meta>, 'size'>;
    awards?: Array<{
        sortOrder?: number | null;
        translation?: string | null;
    }> | null;
    note?: ReactNode;
}

interface Props {
    hit: Hit;
    offset: number;
}

const SearchHit: FC<Props> = ({ hit, offset }) => {
    const { queryParams, collectionPath } = useSearchContext();
    const getImageProps = useGetApiImageProps();
    const imageProps = useMemo(() => getImageProps(hit.image, hit.title), [getImageProps, hit]);
    const [params] = useQueryParams(queryParams);
    const [query] = useQueryParam('query', StringParam);
    const search = useMemo(
        () =>
            queryString.stringify({
                ...encodeQueryParams(queryParams, params),
                ...(query ? { query } : {}),
                offset,
            }),
        [offset, params, query, queryParams]
    );

    return (
        <Link
            href={{
                pathname: `/${collectionPath}/${hit.id}/${slugify(hit.title, { lower: true })}`,
                search,
            }}
        >
            <FilmListItem
                title={hit.title}
                meta={<Meta size="s" {...hit.meta} />}
                image={imageProps}
                description={hit.description && <DescWrapper>{stripHtml(hit.description)}</DescWrapper>}
                award={getHighestRankingAward(hit.awards)?.translation}
                note={hit.note}
            />
        </Link>
    );
};

const DescWrapper = styled(Box)(
    ({ theme }) => css`
        ${Body} {
            font-size: ${theme.tokens.FontSize2};
            line-height: ${theme.tokens.LineHeightS};
        }
    `
);

export default SearchHit;
