import { Breadcrumb } from 'designsystem';

const breadcrumbsFromAncestors = (
    splitPath: string[],
    currentPage: Partial<Breadcrumb>,
    ancestors: Partial<Breadcrumb>[]
): Breadcrumb[] =>
    splitPath
        .map((_, index) => {
            if (index === splitPath.length - 1) {
                return {
                    title: currentPage?.title || '',
                    uri: currentPage?.uri || '/',
                };
            }
            const ancestorDetails = ancestors?.find(
                page =>
                    page?.uri ===
                    splitPath
                        .slice(0, index + 1)
                        .join('/')
                        .split(/[?#]/)[0]
            );
            if (!ancestorDetails) {
                return null;
            }
            const uri = ancestorDetails?.uri
                ? ancestorDetails?.uri.charAt(0) === '/'
                    ? ancestorDetails.uri
                    : `/${ancestorDetails.uri}`
                : '/';
            return {
                title: ancestorDetails?.title || '',
                uri,
            };
        })
        .filter(Boolean) as Breadcrumb[];

export default breadcrumbsFromAncestors;
