import React, { ComponentProps } from 'react';

import { AspectRatio, Stack } from '@chakra-ui/react';

import Img from '../media/Img';
import Avatar from '../trinket/Avatar';
import Body from '../typography/Body';
import { CardProps } from './SmallCard';

interface Props extends Omit<CardProps, 'icon'> {
    photo: ComponentProps<typeof Avatar>;
    name: string;
    jobDescription: string;
}

const ContactCard: React.FC<Props> = ({ name, jobDescription, photo, ...rest }) => (
    <Stack maxW="160px" spacing={2} {...rest}>
        <AspectRatio ratio={3 / 4}>
            <Img fill {...photo} />
        </AspectRatio>
        <Stack spacing={0}>
            <Body fontWeight="xl">{name}</Body>
            <Body fontSize={1}>{jobDescription}</Body>
        </Stack>
    </Stack>
);

export default ContactCard;
