import React, { HTMLAttributes } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const IconAccessibility: React.FC<HTMLAttributes<HTMLOrSVGElement> & BoxProps> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box as="svg" width="20px" height="20px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_3941_15243)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.41406 9.9974C2.41406 5.80924 5.80924 2.41406 9.9974 2.41406C14.1856 2.41406 17.5807 5.80924 17.5807 9.9974C17.5807 14.1856 14.1856 17.5807 9.9974 17.5807C5.80924 17.5807 2.41406 14.1856 2.41406 9.9974ZM9.9974 0.914062C4.98081 0.914062 0.914062 4.98081 0.914062 9.9974C0.914062 15.014 4.98081 19.0807 9.9974 19.0807C15.014 19.0807 19.0807 15.014 19.0807 9.9974C19.0807 4.98081 15.014 0.914062 9.9974 0.914062ZM10.7767 6.03121C10.9818 5.82609 11.0971 5.54789 11.0971 5.25781C11.0971 4.96773 10.9818 4.68953 10.7767 4.48441C10.5716 4.2793 10.2934 4.16406 10.0033 4.16406C9.71322 4.16406 9.43502 4.2793 9.2299 4.48441C9.02478 4.68953 8.90955 4.96773 8.90955 5.25781C8.90955 5.54789 9.02478 5.82609 9.2299 6.03121C9.43502 6.23633 9.71322 6.35156 10.0033 6.35156C10.2934 6.35156 10.5716 6.23633 10.7767 6.03121ZM5.2382 7.85219L8.54497 8.17448V10.7266L8.21538 15.4078C8.20628 15.506 8.23472 15.6041 8.295 15.6822C8.35527 15.7603 8.44289 15.8126 8.54022 15.8287C8.63755 15.8448 8.73736 15.8234 8.81955 15.7688C8.90175 15.7143 8.96022 15.6306 8.9832 15.5347L9.82611 11.4339C9.87278 11.2501 10.1338 11.2501 10.1805 11.4339L11.0234 15.5347C11.0464 15.6306 11.1049 15.7143 11.187 15.7688C11.2692 15.8234 11.369 15.8448 11.4664 15.8287C11.5637 15.8126 11.6513 15.7603 11.7116 15.6822C11.7719 15.6041 11.8003 15.506 11.7912 15.4078L11.4616 10.7266V8.17448L14.7691 7.85219C14.867 7.84011 14.9566 7.79112 15.0196 7.71522C15.0826 7.63933 15.1143 7.54225 15.1081 7.44381C15.102 7.34537 15.0585 7.25298 14.9866 7.1855C14.9146 7.11803 14.8196 7.08055 14.721 7.08073H5.28632C5.18769 7.08055 5.0927 7.11803 5.02076 7.1855C4.94881 7.25298 4.90533 7.34537 4.89919 7.44381C4.89305 7.54225 4.92471 7.63933 4.98771 7.71522C5.05071 7.79112 5.1403 7.84011 5.2382 7.85219Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_3941_15243">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </Box>
);

export default IconAccessibility;
