import { FC, useMemo } from 'react';
import { ArchiveFilterEnum } from '../../gql/api';
import { useQueryParams } from 'use-query-params';
import { Body, FilterSection, FilterTag } from 'designsystem';
import styled from '@emotion/styled';
import useArchiveSearch from '../../hooks/useArchiveSearch';
import archiveFilterMessages from '../../constants/archiveFilterMessages';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArchiveFilterQueryParams } from '../pages/ArchivePage';
import { useRemoveQueryFilters } from 'shared';

interface Props {
    filters: ReturnType<typeof useArchiveSearch>['filters'];
}

const SelectedFilterSections: FC<Props> = ({ filters }) => {
    const archiveTypeFilter = useMemo(
        () => filters?.find(({ filter }) => filter === ArchiveFilterEnum.ArchiveType),
        [filters]
    );
    const [queryFilters, setQueryFilters] = useQueryParams(ArchiveFilterQueryParams);
    const removeQueryFilters = useRemoveQueryFilters(ArchiveFilterQueryParams);
    const activeArchiveTypeQueryFilter = queryFilters[ArchiveFilterEnum.ArchiveType]?.[0];
    const activeFilters = (Object.entries(queryFilters) as [ArchiveFilterEnum, string[]][])
        .filter(([key]) => key !== ArchiveFilterEnum.ArchiveType)
        .filter(([, values]) => values.length > 0);

    const { formatMessage } = useIntl();

    if (Object.values(queryFilters).every(values => values.length === 0)) {
        // don't render anything if there are no filters chosen
        return null;
    }

    return (
        <>
            {activeArchiveTypeQueryFilter && (
                <FilterSection heading={formatMessage(archiveFilterMessages[archiveTypeFilter.filter])}>
                    <FilterTag onClear={() => setQueryFilters({ [ArchiveFilterEnum.ArchiveType]: [] })}>
                        {activeArchiveTypeQueryFilter}
                    </FilterTag>
                </FilterSection>
            )}

            {activeFilters.length > 0 && (
                <FilterSection
                    heading={<FormattedMessage defaultMessage="Geselecteerde filters" />}
                    action={
                        <Action onClick={() => removeQueryFilters(true)}>
                            <FormattedMessage defaultMessage="Wis alles" />
                        </Action>
                    }
                >
                    {activeFilters.flatMap(([activeFilter, activeFilterValues]) =>
                        activeFilterValues.map(filterValue => (
                            <FilterTag
                                key={filterValue}
                                onClear={() =>
                                    setQueryFilters(latestValues => ({
                                        [activeFilter]: latestValues[activeFilter].filter(
                                            filter => filter !== filterValue
                                        ),
                                    }))
                                }
                            >
                                {
                                    // remove category from display label if present
                                    filterValue.split('|')[1] ?? filterValue
                                }
                            </FilterTag>
                        ))
                    )}
                </FilterSection>
            )}
        </>
    );
};

const Action = styled(Body)`
    color: ${({ theme }) => theme.tokens.SyntaxPrimaryColorDefault};
    font-weight: ${({ theme }) => theme.tokens.FontWeightXl};
    cursor: pointer;
`;

export default SelectedFilterSections;
