import { Body, Box, Button, ConfirmationTile, Heading, Modal, ModalProps, VStack } from 'designsystem';
import { FormattedMessage } from 'react-intl';
import { Optional } from 'utility-types';

type Props = Omit<Optional<ModalProps, 'title' | 'closeLabel'>, 'children'>;

const PurchaseSuccessModal: React.FC<Props> = ({ ...rest }) => (
    <Modal
        closeLabel={<FormattedMessage defaultMessage="Sluiten" description="Close modal" />}
        variant="iframe"
        {...rest}
    >
        <ConfirmationTile h="100%">
            <VStack spacing={8}>
                <VStack spacing={4} maxW="640px">
                    <Heading variant={2} as="h2">
                        <FormattedMessage
                            defaultMessage="Payment Successful!"
                            id="purchase-confirmation.success.title"
                        />
                    </Heading>
                    <Body>
                        <FormattedMessage
                            defaultMessage="Thank you for your purchase. We have successfully received your payment. The tickets for the performance will be sent to your email address."
                            id="purchase-confirmation.success.description"
                        />
                    </Body>
                </VStack>
                <Box>
                    <Button variant="solid" onClick={rest.onClose}>
                        <FormattedMessage defaultMessage="Close" description="Close modal" />
                    </Button>
                </Box>
            </VStack>
        </ConfirmationTile>
    </Modal>
);

export default PurchaseSuccessModal;
