import {
    Body,
    CollectionLogo,
    FestivalLogo,
    InstituteLogo,
    Navigation,
    NavigationTopItem,
    NavSiteSection,
    ProfessionalsLogo,
    useDisclosure,
} from 'designsystem';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import gtm from '../lib/gtm';
import isLoggedIn from '../lib/isLoggedIn';
import { getLocalPillarNavItems } from '../lib/pillarNavItems';
import LanguageSwitch from './LanguageSwitch';
import useSignIn from '../hooks/useSignIn';
import MyIdfaNavigation from './MyIdfaNavigations';
import getShortenedName from '../lib/getShortenedName';

interface Props extends Partial<ComponentProps<typeof Navigation>> {
    pillarKey: string;
    pages?: NavSiteSection[];
    overviewLabel?: string;
    searchBarPlaceholder?: string;
    langSwitchUri?: string | undefined;
    myIdfaPath?: string;
    showLanguageSwitch?: boolean;
    isLoading: boolean;
}

const getPillarLogo = (pillarKey: string): ReactNode | undefined => {
    switch (pillarKey) {
        case 'institute':
            return <InstituteLogo />;
        case 'professionals':
            return <ProfessionalsLogo />;
        case 'festival':
            return <FestivalLogo />;
        case 'collection':
            return <CollectionLogo />;
        default:
            return undefined;
    }
};

const PillarNavigation: FC<Props> = ({
    pillarKey,
    pages = [],
    overviewLabel,
    searchBarPlaceholder,
    langSwitchUri,
    myIdfaPath,
    showLanguageSwitch = true,
    ...rest
}) => {
    const { locale, push } = useRouter();
    const { status, data } = useSession();
    const { isLoading, signIn } = useSignIn();
    const myIdfaDisclosure = useDisclosure();
    const userName = useMemo(
        () => getShortenedName(data?.user?.firstName, data?.user?.lastName) || 'MyIDFA',
        [data?.user?.firstName, data?.user?.lastName]
    );

    return (
        <>
            <Navigation
                logo={getPillarLogo(pillarKey)}
                pillars={getLocalPillarNavItems(locale).map(pillar => ({
                    ...pillar,
                    selected: pillar.title.toLowerCase() === pillarKey,
                }))}
                pillarKey={pillarKey}
                pages={pages}
                topPages={[
                    showLanguageSwitch && <LanguageSwitch key="language-switch" uri={langSwitchUri} />,
                    <NavigationTopItem
                        key="donate"
                        href={
                            locale === 'nl'
                                ? process.env.NEXT_PUBLIC_DONATION_URL_NL ??
                                  'https://www.idfa.nl/steun/steun-als-particulier/word-vriend-van-idfa/'
                                : process.env.NEXT_PUBLIC_DONATION_URL_EN ??
                                  'https://www.idfa.nl/en/support/private-support/become-a-friend-of-idfa/'
                        }
                    >
                        <Body fontSize={4} fontWeight={['700', null, '500']}>
                            <FormattedMessage defaultMessage="Donate" />
                        </Body>
                    </NavigationTopItem>,
                ]}
                closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                handleLoginClick={e => {
                    e.preventDefault();
                    signIn();
                }}
                userName={userName}
                handleMyIdfaClick={myIdfaDisclosure.onOpen}
                handleSearchSubmit={searchTerm => {
                    gtm.event('search', {
                        search_term: searchTerm,
                    });

                    push(`/search?q=${searchTerm}`);
                }}
                loggedIn={isLoggedIn(status)}
                isLoginLoading={status === 'loading' || isLoading}
                overviewLabel={overviewLabel}
                searchBarPlaceholder={searchBarPlaceholder}
                {...rest}
            />
            <MyIdfaNavigation {...myIdfaDisclosure} />
        </>
    );
};

export default PillarNavigation;
