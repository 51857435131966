import { IconExternalLink, TabPanel } from 'designsystem';
import { FC } from 'react';
import { GetStaticProps } from 'next';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { ProgrammePageTemplate, fetchApiData, fetchCraftData } from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import { ContentPage, DEFAULT_REVALIDATION_TIME } from '../../pages/[...uri]';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';
import { useIntl } from 'react-intl';
import InstituteBreadcrumbs from '../InstituteBreadcrumbs';
import CinemaStrands from '../cinema/CinemaStrands';
import { StrandsQuery, StrandsQueryVariables, useStrandsQuery } from '../../gql/cms';
import CinemaSchedule from '../cinema/CinemaSchedule';
import {
    SearchCinemaScheduleQuery,
    SearchCinemaScheduleQueryVariables,
    useSearchCinemaScheduleQuery,
} from '../../gql/api';
import { addMonths, startOfMonth } from 'date-fns';
import { useTheme } from '@emotion/react';

const defaultTab = process.env.NEXT_PUBLIC_PROGRAMME_DEFAULT_TAB
    ? Number(process.env.NEXT_PUBLIC_PROGRAMME_DEFAULT_TAB)
    : 0;

const defaultMetadata: Record<string, NextSeoProps> = {
    nl: {
        description: 'Hier vind je strands',
    },
    en: {
        description: 'Here you find strands',
    },
};

type ProgrammeOverviewPageData = Extract<ContentPage, { __typename: 'instituteContentPages_cinemaProgramPage_Entry' }>;

const ProgrammeOverviewPage: FC<{ data: ProgrammeOverviewPageData }> = ({ data }) => {
    const { query, locale } = useRouter();
    const [tabIndex, setTabIndex] = useQueryParam(
        'tabIndex',
        withDefault(NumberParam, query.tabIndex ? Number(query.tabIndex) : defaultTab)
    );
    const [, setPage] = useQueryParam('page', NumberParam);
    const { formatMessage } = useIntl();
    const theme = useTheme();

    return (
        <>
            <NextSeo title={data?.title} {...defaultMetadata[locale]} />
            <ProgrammePageTemplate
                introText={data?.introText}
                onChange={index => {
                    setTabIndex(index);
                    setPage(1);
                }}
                pageTitle={data?.title}
                topChildren={<InstituteBreadcrumbs />}
                tabIndex={tabIndex}
                tabs={[formatMessage({ defaultMessage: 'Agenda' }), formatMessage({ defaultMessage: 'Programma' })]}
                tabPanels={[
                    <TabPanel key={0} py={[6, null, null, 9]} px={0}>
                        <CinemaSchedule />
                    </TabPanel>,
                    <TabPanel key={1} py={[6, null, null, 9]} px={0}>
                        <CinemaStrands />
                    </TabPanel>,
                ]}
                override={{
                    a: ({ children, href, style, ...rest }) => (
                        <a
                            href={href?.replace('$PRIMARY_SITE_URL', '') ?? '#'}
                            style={{ color: theme.tokens.SyntaxPrimaryColorDark }}
                            {...rest}
                        >
                            {children}
                            {rest.target === '_blank' && (
                                <IconExternalLink
                                    style={{
                                        width: '1.25em',
                                        height: '1.25em',
                                        display: 'inline-block',
                                        verticalAlign: '-14%',
                                    }}
                                />
                            )}
                        </a>
                    ),
                }}
            />
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const startOfCurrentMonth = startOfMonth(new Date());

    await queryClient.prefetchQuery(useSearchCinemaScheduleQuery.getKey(), () =>
        fetchApiData<SearchCinemaScheduleQuery, SearchCinemaScheduleQueryVariables>({
            query: useSearchCinemaScheduleQuery.document,
            locale,
            variables: {
                start: startOfCurrentMonth.toISOString(),
                end: addMonths(startOfCurrentMonth, 1).toISOString(),
            },
        })
    );

    await queryClient.prefetchQuery(useStrandsQuery.getKey(), () =>
        fetchCraftData<StrandsQuery, StrandsQueryVariables>({
            query: useStrandsQuery.document,
        })
    );

    return {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export default ProgrammeOverviewPage;
