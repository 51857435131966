import { Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import Body from '../typography/Body';

type ProgrammeFilterSelects = {
    key: string;
    label: ReactNode;
    select: ReactNode;
};

interface ProgrammeSearchInputProps {
    fields: ProgrammeFilterSelects[];
}

const ProgrammeSearchInput: FC<ProgrammeSearchInputProps> = ({ fields }) => (
    <Flex
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={6}
        border="1.5px solid"
        borderColor="neutralblack"
        borderRadius="1024px"
    >
        {fields.map(field => (
            <Flex alignItems="center" key={field.key} suppressHydrationWarning>
                <Body as="label" for={field.key} minW="fit-content">
                    {field.label}
                </Body>
                <span suppressHydrationWarning>{field.select}</span>
            </Flex>
        ))}
    </Flex>
);

export default ProgrammeSearchInput;
