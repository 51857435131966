import { FormHTMLAttributes } from 'react';

const mailchimpKeys: Record<string, string> = {
    nl: process.env.NEXT_PUBLIC_MAILCHIMP_KEY_NL,
    en: process.env.NEXT_PUBLIC_MAILCHIMP_KEY_EN,
};

const getNewsletterFormProps = (locale: string): FormHTMLAttributes<unknown> => {
    const key = mailchimpKeys[locale];

    return {
        action: `//idfa.us6.list-manage.com/subscribe/post?u=${key?.replace('_', '&id=')}`,
        method: 'post',
        id: 'mc-embedded-subscribe-form',
        name: 'mc-embedded-subscribe-form',
        target: '_blank',
    };
};

export default getNewsletterFormProps;
