import React, { HTMLAttributes } from 'react';

const IconClose: React.FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 10.3518L7.64817 6L6 7.64817L10.3518 12L6 16.3518L7.64817 18L12 13.6482L16.3518 18L18 16.3518L13.6482 12L18 7.64817L16.3518 6L12 10.3518Z"
            fill="currentColor"
        />
    </svg>
);

export default IconClose;
