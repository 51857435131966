import { Breadcrumb, Breadcrumbs } from 'designsystem';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import breadcrumbsFromAncestors from '../../lib/breadcrumbsFromAncestors';

interface CurrentPageInTree extends Partial<Breadcrumb> {
    ancestors: Partial<Breadcrumb>[];
}

interface Props extends Omit<React.ComponentProps<typeof Breadcrumbs>, 'breadcrumbs' | 'homeBreadcrumb'> {
    breadcrumbsQueryResults?: {
        currentPageInTree?: CurrentPageInTree;
    };
}

const ContentPageBreadcrumbs: React.FC<Props> = ({ breadcrumbsQueryResults, ...rest }) => {
    const { query } = useRouter();
    const { currentPageInTree } = breadcrumbsQueryResults || {};
    const breadcrumbs: React.ComponentProps<typeof Breadcrumbs>['breadcrumbs'] = useMemo(
        () =>
            breadcrumbsFromAncestors(
                Array.isArray(query.uri) ? query.uri : [query.uri],
                currentPageInTree,
                currentPageInTree?.ancestors
            ),
        [currentPageInTree, query.uri]
    );

    return <Breadcrumbs breadcrumbs={breadcrumbs} homeBreadcrumb={{ title: '', uri: '/' }} {...rest} />;
};

export default ContentPageBreadcrumbs;
