import { useTheme } from '@emotion/react';
import { Body, Button, ConfirmationTile, Heading, IconCheck, Stack } from 'designsystem';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { RecurringDonationInput, useRecurringDonationMutation } from '../../gql/api';
import {
    addressFormSection,
    contactFormSection,
    contributionFormSection,
    nameFormSection,
    bankFormSection,
} from '../../utils/donationFormSection';
import DonationFormSection from './DonationFormSection';
import { gtm } from 'shared';

interface RecurringDonationFormProps {
    formConfirmationHeading: string;
    formConfirmationMessage: string;
}

type RecurringDonationFormValues = RecurringDonationInput & { permission: boolean };

const RecurringDonationForm: FC<RecurringDonationFormProps> = ({
    formConfirmationHeading,
    formConfirmationMessage,
}) => {
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const formMethods = useForm<RecurringDonationFormValues>({
        defaultValues: {
            contributionInEuros: 3,
            permission: false,
        },
        mode: 'onBlur',
    });
    const theme = useTheme();

    const {
        handleSubmit,
        formState: { isSubmitted },
    } = formMethods;

    const { mutate, isSuccess, isLoading } = useRecurringDonationMutation({
        onSuccess(data) {
            // server returns 200 OK even if there's a backend validation error
            setSubmitSuccess(data?.sendRecurringDonationData.success);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            window.scrollTo(0, 0);
        }
    }, [isSuccess]);

    return (
        <>
            {(!isSubmitted || !isSuccess || !submitSuccess) && (
                <FormProvider {...formMethods}>
                    <form
                        onSubmit={handleSubmit((values: RecurringDonationFormValues) => {
                            if (isLoading) {
                                return;
                            }
                            gtm.event('form_submit', {
                                form_name: 'word-vriend-maandelijks',
                                value: values.contributionInEuros,
                            });
                            const submitValues = { ...values };
                            // permission should not be submitted as value to BE
                            delete submitValues.permission;
                            mutate({
                                input: submitValues,
                            });
                        })}
                    >
                        <Stack spacing={[8, null, 10]} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            <Heading variant={2}>
                                <FormattedMessage defaultMessage="Persoonlijke Gegevens" />
                            </Heading>
                            <DonationFormSection section={nameFormSection} />
                            <DonationFormSection section={addressFormSection} />
                            <DonationFormSection section={contactFormSection} />
                        </Stack>

                        <Stack spacing={10} mb={[8, null, 11]} maxW={['100%', null, '50%']}>
                            <Heading variant={2}>
                                <FormattedMessage defaultMessage="Bijdrage" />
                            </Heading>
                            <DonationFormSection section={contributionFormSection} contributionOptions={[3, 9, 12]} />
                        </Stack>

                        <Stack spacing={10} maxW={['100%', null, '50%']}>
                            <Heading variant={2}>
                                <FormattedMessage defaultMessage="Bank" />
                            </Heading>
                            <DonationFormSection section={bankFormSection} />
                        </Stack>

                        <Button type="submit" mt={[8, null, 11]} isLoading={isLoading}>
                            <FormattedMessage defaultMessage="Verstuur" />
                        </Button>
                        {isSubmitted && !submitSuccess && !isLoading && (
                            <Body
                                mt={2}
                                color={theme.tokens.ColorNegative40}
                                fontSize={theme.tokens.FontSize1}
                                lineHeight={theme.tokens.LineHeightXs}
                            >
                                <FormattedMessage defaultMessage="Er is iets misgegaan bij het opsturen van het formulier. Probeer het later nog eens." />
                            </Body>
                        )}
                    </form>
                </FormProvider>
            )}
            {isSubmitted && isSuccess && submitSuccess && (
                <ConfirmationTile
                    id="confirmation"
                    maxWidth={['100%', null, '50%']}
                    icon={<IconCheck color={theme.tokens.ColorRed60} />}
                >
                    <Heading variant={4}>{formConfirmationHeading}</Heading>
                    <Body>{formConfirmationMessage}</Body>
                </ConfirmationTile>
            )}
        </>
    );
};

export default RecurringDonationForm;
