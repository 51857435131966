import { ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';
import Body from '../typography/Body';
import Heading from '../typography/Heading';

interface Props {
    title: ReactNode;
    contents: {
        key: string;
        title: ReactNode;
        hash: string;
    }[];
}

const TableOfContents: React.FC<Props> = ({ title, contents }) => {
    const theme = useTheme();
    return (
        <Stack spacing={4} bg={theme.tokens.SyntaxBackgroundNeutralDark} p={[5, 7]}>
            <Heading as="h3" variant={5}>
                {title}
            </Heading>
            <UnorderedList>
                {contents.map(content => (
                    <ListItem key={content.key}>
                        <Body>
                            <a href={`#${content.hash}`}>{content.title}</a>
                        </Body>
                    </ListItem>
                ))}
            </UnorderedList>
        </Stack>
    );
};

export default TableOfContents;
