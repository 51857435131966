import { BoxProps } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import Heading, { Props as HeadingProps } from '../typography/Heading';
import kebabCase from 'lodash.kebabcase';

interface Props extends PropsWithChildren, BoxProps {
    variant?: HeadingProps['variant'];
    children: string;
}

const ContentBlockTitle: React.FC<Props> = ({ children, variant = 3, ...rest }) => (
    <Heading
        as="h2"
        variant={variant}
        mt={-15}
        pt={16}
        mb={4}
        id={kebabCase(children)}
        zIndex={-1}
        {...rest}
        data-content-title
    >
        {children}
    </Heading>
);

export default ContentBlockTitle;
