import React, { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
    direction?: 'up' | 'down' | 'left' | 'right';
}

const IconCaret: React.FC<Props> = ({ direction = 'right', ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        {direction === 'up' && (
            <path d="M16.59 16L12 11.42L7.41 16L6 14.59L12 8.59L18 14.59L16.59 16Z" fill="currentColor" />
        )}
        {direction === 'down' && (
            <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill="currentColor" />
        )}
        {direction === 'right' && (
            <path
                d="M8.75 16.885L13.33 12.295L8.75 7.70498L10.16 6.29498L16.16 12.295L10.16 18.295L8.75 16.885Z"
                fill="currentColor"
            />
        )}
        {direction === 'left' && (
            <path
                d="M15.705 7.70498L11.125 12.295L15.705 16.885L14.295 18.295L8.29499 12.295L14.295 6.29498L15.705 7.70498Z"
                fill="currentColor"
            />
        )}
    </svg>
);

export default IconCaret;
