import { Hero, Grid, Stack, Heading, Meta, Tag, Wrapper, Box, HtmlContent, Intro } from 'designsystem';
import { NextSeo, ArticleJsonLd } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import { ReactNode, useMemo } from 'react';
import striptags from 'striptags';
import useGetImageProps, { CmsImage } from '../../hooks/useGetCmsImageProps';
import { getMetaForArticle, getMetaForStory } from '../../lib/getMeta';
import ContentBlocks, { ContentBlock } from '../ContentBlocks';

interface Props {
    url: string;
    articleTitle: string;
    articleIntroText?: string;
    articleOpenGraphImages?: OpenGraphMedia[];
    articleAuthor?: string;
    articleCategory?: { title?: string }[];
    articlePostDate?: string;
    articleHeaderImage?: CmsImage;
    articleMeta?: ReturnType<typeof getMetaForArticle> | ReturnType<typeof getMetaForStory>;
    contentBlocks?: Omit<React.ComponentProps<typeof ContentBlocks>, 'stylesPerContentBlockType'>;
    sponsorBanner?: ReactNode;
    breadcrumbs: ReactNode;
}

const ArticleTemplate: React.FC<Props> = ({
    url,
    articleTitle,
    articleIntroText,
    articleOpenGraphImages,
    articleAuthor,
    articleCategory,
    articlePostDate,
    articleHeaderImage,
    articleMeta,
    contentBlocks,
    sponsorBanner,
    breadcrumbs,
}) => {
    const ogDescription = useMemo(
        () =>
            striptags(
                contentBlocks.contentBlocks?.find(
                    (cb): cb is Extract<ContentBlock, { __typename?: 'contentBlocks_paragraph_BlockType' }> =>
                        cb.__typename === 'contentBlocks_paragraph_BlockType'
                )?.text
            ),
        [contentBlocks]
    );
    const getImageProps = useGetImageProps();

    return (
        <>
            <NextSeo
                title={articleTitle}
                description={ogDescription}
                openGraph={{
                    type: 'article',
                    article: {
                        authors: [articleAuthor],
                        tags: [articleCategory?.[0].title],
                        publishedTime: articlePostDate,
                    },
                    images: articleOpenGraphImages,
                }}
            />
            <ArticleJsonLd
                url={url}
                title={articleTitle}
                images={articleOpenGraphImages.map(img => img.url)}
                datePublished={articlePostDate}
                authorName={[
                    {
                        name: articleAuthor,
                    },
                ]}
                description={ogDescription}
                isAccessibleForFree
            />
            <Hero
                topChildren={breadcrumbs}
                image={getImageProps(articleHeaderImage, articleTitle, `100vw`)}
                w={null}
                m={0}
                maxW="unset"
            >
                <Grid>
                    <Stack gridColumn={['1 / -1', null, null, null, '2 / span 8']} spacing={[4, null, 7]}>
                        <Heading mt={['72px', null, '112px']} variant={1} as="h1" clamp={4}>
                            {articleTitle}
                        </Heading>
                        <Meta size="m" tag={<Tag>{articleCategory?.[0].title}</Tag>} {...articleMeta} />
                    </Stack>
                </Grid>
            </Hero>
            <Wrapper mt={[10, null, null, 11]}>
                <Grid>
                    {articleIntroText && (
                        <Box gridColumnStart={[1, null, null, null, 2]} gridColumnEnd={[-1, null, 11, 9, 8]}>
                            <HtmlContent
                                html={articleIntroText}
                                override={{
                                    p: ({ children }) => <Intro>{children}</Intro>,
                                }}
                            />
                        </Box>
                    )}
                    <ContentBlocks
                        {...contentBlocks}
                        stylesPerContentBlockType={{
                            default: {
                                gridColumn: ['1 / -1', null, '1 / span 10', '1 / span 8', '2 / span 6'],
                                gridAutoRows: 'auto',
                            },
                            contentBlocks_image_BlockType_large: {
                                gridColumn: '1/-1',
                            },
                        }}
                    />
                    <Box
                        gridColumn={['1 / -1', null, null, '9 / -1']}
                        gridRow={['auto', null, null, 1]}
                        h={[null, null, null, '0']}
                        textAlign="center"
                    >
                        {sponsorBanner}
                    </Box>
                </Grid>
            </Wrapper>
        </>
    );
};

export default ArticleTemplate;
