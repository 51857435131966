import React, { PropsWithChildren } from 'react';

import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';

import Grid from '../layout/Grid';
import { useTheme } from '@emotion/react';

interface Props extends PropsWithChildren, BoxProps {
    citation?: string;
}

const ContentBlockQuote: React.FC<Props> = ({ children, citation, ...rest }) => {
    const theme = useTheme();
    return (
        <Grid as="figure" mb={4} {...rest} color={theme.tokens.SyntaxTextColorNeutral} position="relative">
            <Stack as="blockquote" spacing={8} gridColumn="2 / -1">
                <Text as="div" fontSize={7} lineHeight="xs" fontWeight="xxl" fontStyle="italic">
                    <Box position="absolute" left={0}>
                        “
                    </Box>
                    {children}”
                </Text>
                {citation && (
                    <Text as="cite" fontSize={2}>
                        {citation}
                    </Text>
                )}
            </Stack>
        </Grid>
    );
};

export default ContentBlockQuote;
