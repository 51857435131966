import { RecurringDonationInput, SingleDonationFriendInput, SingleDonationInput } from '../gql/api';
import { registerLocale, getNames } from 'i18n-iso-countries';
import * as enLocale from 'i18n-iso-countries/langs/en.json';
import * as nlLocale from 'i18n-iso-countries/langs/nl.json';
import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

registerLocale(enLocale);
registerLocale(nlLocale);

type DonationFormField = {
    label: ReactNode;
    name: keyof SingleDonationInput | keyof SingleDonationFriendInput | keyof RecurringDonationInput | 'permission';
    type: HTMLInputTypeAttribute | 'contribution';
    width?: string;
};

export type FormSection = { title?: ReactNode; fields: DonationFormField[] };

export const getCountryNamesByLocale = (currentLocale: string): { value: string; label: string }[] => {
    const countryNames = getNames(currentLocale, { select: 'official' });
    return [
        // NL should be first in the list
        { value: 'NL', label: countryNames.NL },
        ...Object.keys(countryNames)
            .filter(countryCode => countryCode !== 'NL')
            .map(countryCode => ({ value: countryCode, label: countryNames[countryCode] }))
            .sort(),
    ];
};

export const nameFormSection: FormSection = {
    title: <FormattedMessage defaultMessage="Naam" />,
    fields: [
        {
            label: <FormattedMessage defaultMessage="Voornaam" />,
            name: 'firstName',
            type: 'text',
        },
        {
            label: <FormattedMessage defaultMessage="Tussenvoegsel" />,
            name: 'lastNamePrefix',
            type: 'text',
            width: '33%',
        },
        {
            label: <FormattedMessage defaultMessage="Achternaam" />,
            name: 'lastName',
            type: 'text',
        },
    ],
};

export const addressFormSection: FormSection = {
    title: <FormattedMessage defaultMessage="Adresgegevens" />,
    fields: [
        {
            label: <FormattedMessage defaultMessage="Land" />,
            name: 'country',
            type: 'select',
        },
        {
            label: <FormattedMessage defaultMessage="Straat" />,
            name: 'street',
            type: 'text',
        },
        {
            label: <FormattedMessage defaultMessage="Huisnummer" />,
            name: 'houseNumber',
            type: 'text',
            width: '33%',
        },
        {
            label: <FormattedMessage defaultMessage="Postcode" />,
            name: 'zipCode',
            type: 'text',
            width: '33%',
        },
        {
            label: <FormattedMessage defaultMessage="Woonplaats" />,
            name: 'city',
            type: 'text',
        },
    ],
};

export const contactFormSection: FormSection = {
    title: <FormattedMessage defaultMessage="Contactgegevens" />,
    fields: [
        {
            label: <FormattedMessage defaultMessage="E-mail" />,
            name: 'email',
            type: 'email',
            width: '100%',
        },
        {
            label: <FormattedMessage defaultMessage="Telefoonnummer" />,
            name: 'phone',
            type: 'text',
            width: '67%',
        },
    ],
};

export const contributionFormSection: FormSection = {
    fields: [
        {
            label: <FormattedMessage defaultMessage="Hoeveel wil je bijdragen?" />,
            name: 'contributionInEuros',
            type: 'contribution',
        },
    ],
};

export const bankFormSection: FormSection = {
    fields: [
        {
            label: <FormattedMessage defaultMessage="Naam rekeninghouder" />,
            name: 'bankAccountOwner',
            type: 'text',
        },
        {
            label: <FormattedMessage defaultMessage="IBAN" />,
            name: 'iban',
            type: 'text',
        },
        {
            label: <FormattedMessage defaultMessage="Ja" />,
            name: 'permission',
            type: 'checkbox',
        },
    ],
};
