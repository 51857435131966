import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import IconClose from '../trinket/icon/IconClose';

interface FilterTagProps {
    children: ReactNode;
    onClear: () => void;
}

const FilterTag: FC<FilterTagProps> = ({ children, onClear }) => (
    <StyledFilterTag onClick={onClear}>
        <StyledIconClose /> {children}
    </StyledFilterTag>
);

const StyledFilterTag = styled.span(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        padding: ${theme.tokens.Sizing1} ${theme.tokens.Sizing3} ${theme.tokens.Sizing1} ${theme.tokens.Sizing2};
        gap: ${theme.tokens.Sizing1};
        background-color: ${theme.tokens.ButtonPrimaryHoverBackground};
        border-radius: ${theme.tokens.BorderRadiusL};
        width: fit-content;
        font-size: ${theme.tokens.FontSize3};
        line-height: ${theme.tokens.FontSize4};
        cursor: pointer;
    `
);

const StyledIconClose = styled(IconClose)(
    ({ theme }) => css`
        width: ${theme.tokens.Sizing5};
        height: ${theme.tokens.Sizing5};
    `
);

export default FilterTag;
