import { Box } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const WideGrid = styled(Box)(
    ({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr); // column: 56px | gap: 48px
        --gap: ${(24 / 376) * 100}vw;
        column-gap: var(--gap);

        @media screen and (min-width: ${theme.tokens.MediaQueryS}) {
            grid-template-columns: repeat(12, 1fr); // column: 56px | gap: 48px
            --gap: ${(48 / 1440) * 100}vw;
            column-gap: var(--gap);
        }
    `
);

export default WideGrid;
