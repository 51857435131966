import { FC, HTMLAttributes } from 'react';

const IconModal: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 2L18.9359 2C19.7316 2 20.4946 2.31607 21.0572 2.87868C21.6198 3.44129 21.9359 4.20435 21.9359 5V9H19.9359V5C19.9359 4.73478 19.8306 4.48043 19.643 4.29289C19.4555 4.10536 19.2011 4 18.9359 4L15 4V2ZM4.93591 4C4.6707 4 4.41634 4.10536 4.22881 4.29289C4.04127 4.48043 3.93591 4.73478 3.93591 5V8.93591H1.93591L1.93591 5C1.93591 4.20435 2.25198 3.44129 2.81459 2.87868C3.3772 2.31607 4.14026 2 4.93591 2L8.93591 2V4L4.93591 4ZM3.93591 15V19C3.93591 19.2652 4.04127 19.5196 4.22881 19.7071C4.41634 19.8946 4.6707 20 4.93591 20H8.87183V22H4.93591C4.14026 22 3.3772 21.6839 2.81459 21.1213C2.25198 20.5587 1.93591 19.7957 1.93591 19V15H3.93591ZM21.9359 15.0641V19C21.9359 19.7957 21.6198 20.5587 21.0572 21.1213C20.4946 21.6839 19.7316 22 18.9359 22H14.9359V20H18.9359C19.2011 20 19.4555 19.8946 19.643 19.7071C19.8306 19.5196 19.9359 19.2652 19.9359 19V15.0641H21.9359Z"
            fill="currentColor"
        />
    </svg>
);

export default IconModal;
