import { Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Body from '../typography/Body';

interface Props {
    title: ReactNode;
    value: ReactNode;
}

const SpecificationListItem: React.FC<Props> = ({ title, value }) => (
    <Stack spacing={2}>
        <Body fontWeight="xl">{title}</Body>
        <Body>{value}</Body>
    </Stack>
);

export default SpecificationListItem;
