import { Box, Grid, Heading, Hero, SkeletonText, Stack, Wrapper } from 'designsystem';
import React from 'react';
import { useTheme } from '@emotion/react';

const LoadingContentPageTemplate = () => {
    const theme = useTheme();
    return (
        <Wrapper>
            <Grid as="article" id="article" rowGap={[0, 0, 0, 0, 0]}>
                <Box gridColumn={['1 / -1', null, null, '1 / span 8', '2 / span 6']}>
                    <Hero
                        topChildren={
                            <Box py={2}>
                                <SkeletonText
                                    noOfLines={1}
                                    width="66%"
                                    skeletonHeight={[
                                        theme.tokens.SyntaxBodyTextSRegularFontSize,
                                        null,
                                        theme.tokens.SyntaxBodyTextLRegularFontSize,
                                    ]}
                                />
                            </Box>
                        }
                        w={null}
                        m={0}
                        maxW="unset"
                    >
                        <Grid>
                            <Stack gridColumn={['1 / -1', null, null, null, '1 / span 6']} spacing={[4, null, 7]}>
                                <Heading mt={['72px', null, '112px']} variant={1} as="h1">
                                    <SkeletonText
                                        noOfLines={1}
                                        width="100%"
                                        skeletonHeight={[
                                            theme.tokens.SyntaxHeadingTextS1FontSize,
                                            null,
                                            theme.tokens.SyntaxHeadingTextL1FontSize,
                                        ]}
                                    />
                                </Heading>
                                <Box>
                                    <SkeletonText noOfLines={3} width="88%" skeletonHeight={[theme.tokens.FontSize5]} />
                                </Box>
                            </Stack>
                        </Grid>
                    </Hero>
                </Box>
            </Grid>
        </Wrapper>
    );
};
export default LoadingContentPageTemplate;
