import { FC, ReactNode } from 'react';

import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import IconPlay from '../trinket/icon/IconPlay';
import Body from '../typography/Body';
import Title from '../typography/Heading';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import TextButton from '../action/TextButton';
import ColorSchemeProvider from '../ColorSchemeProvider';
import Wrapper from '../layout/Wrapper';

interface CollectionTileProps extends Omit<BoxProps, 'title'> {
    title: ReactNode;
    description: ReactNode;
    href: string;
}

const CollectionTile: FC<CollectionTileProps> = ({ title, description, href, ...rest }) => {
    const { tokens } = useTheme();
    return (
        <ColorSchemeProvider colorScheme="collection">
            <CollectionTileContainer padding={[6, null, null, 10]} flexDirection="column" flexWrap="wrap" {...rest}>
                <Wrapper>
                    <Box display={['none', null, null, 'block']} mb={5}>
                        <StyledTitle variant={2}>{title}</StyledTitle>
                    </Box>

                    <Box display={['none', null, null, 'block']} mb={[0, 6]}>
                        <Description>{description}</Description>
                    </Box>
                    <TextButton
                        size="s"
                        iconLeft={
                            <Box
                                bg={tokens.ColorNeutralWhite}
                                w={6}
                                h={6}
                                borderRadius={tokens.BorderRadiusL}
                                color={tokens.SyntaxTextColorDefault}
                                p={4}
                                mr={3}
                                width="fit-content"
                                height="fit-content"
                            >
                                <StyledIconPlay />
                            </Box>
                        }
                        iconRight={null}
                        href={href}
                    >
                        Watch on IDFA collection
                    </TextButton>
                </Wrapper>
            </CollectionTileContainer>
        </ColorSchemeProvider>
    );
};

const CollectionTileContainer = styled(Flex)(
    ({ theme }) => css`
        background-color: ${theme.tokens.TilePrimaryBackground};
        color: ${theme.tokens.TilePrimaryForeground};
        justify-content: center;

        a {
            text-decoration: none;
            color: inherit;
            &:visited {
                color: inherit;
            }
        }
    `
);

const StyledIconPlay = styled(IconPlay)(
    ({ theme }) => css`
        width: ${theme.tokens.Sizing6};
        height: ${theme.tokens.Sizing6};
    `
);

const StyledTitle = styled(Title)(
    ({ theme }) => css`
        color: ${theme.tokens.ColorNeutralBlack};
        padding-right: 16px;
    `
);

const Description = styled(Body)(
    ({ theme }) => css`
        font-weight: ${theme.tokens.FontWeightL};
    `
);

export default CollectionTile;
