import { modalAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type ModalTheme = Omit<MultiStyleConfig<typeof modalAnatomy>, 'parts'>;

/**
 * Theme for a Chakra Modal. Include this into your theme.
 */
const ModalTheming: ModalTheme = {
    baseStyle: ({ theme }) => ({
        overlay: {
            bg: theme.tokens.SyntaxOverlayColorDefault,
            zIndex: 1000,
        },
        dialogContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        },
        dialog: {
            bg: theme.tokens.SyntaxBackgroundNeutralDefault,
            w: '100%',
            maxHeight: '100%',
        },
        header: {
            display: 'flex',
            bg: theme.tokens.SyntaxBackgroundNeutralDark,
            w: '100%',
            minHeight: 12,
            m: 0,
            alignItems: 'stretch',
        },
        body: {
            p: 6,
            overflowY: 'auto',
        },
        closeButton: {
            display: 'flex',
            w: 'auto',
            h: 'auto',
            alignItems: 'center',
            flexGrow: 0,
            bg: theme.tokens.SyntaxColorActionEnabledBackground,
            color: theme.tokens.SyntaxColorActionEnabledForeground,
            fontFamily: 'inherit',
            fontWeight: theme.tokens.FontWeightL,
            fontSize: theme.tokens.FontSize3,
            transition: 'all 0.2s',
            cursor: 'pointer',
            border: 'none',
            padding: `${theme.tokens.Sizing6} ${theme.tokens.Sizing6} ${theme.tokens.Sizing6} ${theme.tokens.Sizing7}`,
            gap: 2,
            _hover: {
                backgroundColor: theme.tokens.ButtonPrimaryHoverBackground,
                color: theme.tokens.ButtonPrimaryHoverForeground,
            },
            _focus: {
                backgroundColor: theme.tokens.ButtonPrimaryFocusBackground,
                color: theme.tokens.ButtonPrimaryFocusForeground,
                outline: `${theme.tokens.BorderWidthM} solid ${theme.tokens.ButtonPrimaryFocusBackground}`,
                outlineOffset: '-1px',
            },
            _active: {
                backgroundColor: theme.tokens.ButtonPrimaryActiveBackground,
                color: theme.tokens.ButtonPrimaryActiveForeground,
            },
            _disabled: {
                backgroundColor: theme.tokens.ButtonPrimaryDisabledBackground,
                color: theme.tokens.ButtonPrimaryDisabledForeground,
            },
            '& > * + *': {
                marginLeft: 2,
            },

            '& > svg': {
                width: 6,
            },
        },
    }),
    sizes: {
        sm: {
            dialog: {
                maxW: '393px',
            },
        },
        md: {
            dialog: {
                maxW: '472px',
            },
        },
        lg: {
            dialog: {
                maxW: '80vw',
            },
        },
    },
    variants: {
        iframe: {
            dialog: {
                w: '90vw',
                h: '90vh',
                maxW: '1116px',
            },
            body: {
                p: 0,
                w: '100%',
                h: '100%',
            },
        },
        media: {
            closeButton: {
                position: 'fixed',
                top: 0,
                right: 0,
                zIndex: '1000',
            },
        },
        full: {
            dialog: {
                bg: 'transparent',
                w: '100%',
                h: '100%',
                maxW: 'none',
            },
        },
        newsletter: ({ theme }) => ({
            dialog: {
                bg: theme.tokens.ColorDarkBlue60,
                color: theme.tokens.ColorLightPurple60,
                maxW: ['100%', null, '375px'],
                p: 0,
            },
            body: {
                py: 9,
                px: 6,
            },
            dialogContainer: {
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
            },
            closeButton: {
                position: 'absolute',
                right: 0,
                top: 0,
                m: 6,
                py: 2,
                px: 1,
                background: 'transparent',
                color: 'white',
            },
        }),
    },
    defaultProps: {
        size: 'md',
    },
};

export default ModalTheming;
