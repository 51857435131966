import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import Body from '../typography/Body';
import Intro from '../typography/Intro';

interface Props extends PropsWithChildren, BoxProps {
    intro?: boolean;
}

const ContentBlockText: React.FC<Props> = ({ children, intro = false, ...rest }) => (
    <Box mb={6} data-content-text {...rest}>
        {intro ? <Intro as="div">{children}</Intro> : <Body as="div">{children}</Body>}
    </Box>
);

export default ContentBlockText;
