import { ContentBlockFilm, Meta } from 'designsystem';
import { getMetaSimplifiedForFilmOrProject, useGetApiImageProps } from '../../index';
import { useCinemaItemCardQuery } from '../../gql/api';
import Link from 'next/link';
import { useRouter } from 'next/router';

const CinemaFilmContentBlock: React.FC<{ id: string }> = ({ id, ...rest }) => {
    const getApiImageProps = useGetApiImageProps();
    const { data } = useCinemaItemCardQuery({
        id,
    });
    const { locale } = useRouter();
    return data?.film ? (
        <Link href={`${process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE}/${locale === 'en' ? 'en/' : ''}/cinema/${id}`}>
            <ContentBlockFilm
                image={getApiImageProps(
                    data.film?.publications.favoriteImage ?? data.film?.publications.stills[0],
                    data.film.fullPreferredTitle
                )}
                title={data.film?.fullPreferredTitle}
                meta={<Meta size="s" {...getMetaSimplifiedForFilmOrProject(data.film)} />}
                {...rest}
            />
        </Link>
    ) : (
        <Link href={`${process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE}/composition/${id}`}>
            <ContentBlockFilm
                image={getApiImageProps(
                    data?.composition?.publications?.favoriteImage ?? data?.composition?.publications.stills[0],
                    data?.composition?.fullTitle
                )}
                title={data?.composition?.fullTitle}
                meta={<Meta size="s" items={[]} />}
                {...rest}
            />
        </Link>
    );
};

export default CinemaFilmContentBlock;
