import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import IconAward from '../trinket/award/IconAward';
import Body from '../typography/Body';

const Award: React.FC<FlexProps> = ({ children, ...rest }) => (
    <Flex alignItems="center" {...rest}>
        <Box width={8} mr={2}>
            <IconAward />
        </Box>
        <StyledBody clamp={1}>{children}</StyledBody>
    </Flex>
);

const StyledBody = styled(Body)(
    ({ theme }) => css`
        font-weight: ${theme.tokens.FontWeightXl};
        font-size: ${theme.tokens.FontSize1};
        line-height: ${theme.tokens.LineHeightXxs};
    `
);

export default Award;
