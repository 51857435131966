import { Box, BoxProps } from '@chakra-ui/react';
import { LegacyRef, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren, BoxProps {
    wide?: boolean;
    innerRef?: LegacyRef<HTMLDivElement>;
}

const Wrapper: React.FC<Props> = ({ wide = false, innerRef, ...rest }) => (
    <Box
        ref={innerRef}
        mx="auto"
        maxW={[
            null,
            null,
            null,
            null,
            wide ? '1688px' : '1200px',
            // `${((wide ? 1296 : 1200) / parseFloat(theme.tokens.MediaQueryXl)) * 100}vw`,
            // wide ? '90vw' : '1200px',
        ]}
        width="100%"
        maxWidth="90vw"
        {...rest}
    />
);

export default Wrapper;
