import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { MultiStyleConfig } from '@chakra-ui/react';

type NumberInputTheme = Omit<MultiStyleConfig<typeof numberInputAnatomy>, 'parts'>;

/**
 * Theme for a Chakra NumberInput. Include this into your theme.
 */
const NumberInputTheming: NumberInputTheme = {
    baseStyle: ({ theme: T }) => ({
        field: {
            '& > *': {},
            font: 'inherit',
            fontSize: T.tokens.SyntaxBodyTextSRegularFontSize,
            lineHeight: T.tokens.SyntaxBodyTextSRegularLineHeight,
            boxSizing: 'border-box',
            borderWidth: 'm',
            borderStyle: 'solid',
            borderColor: T.tokens.SyntaxColorInputEnabledBorder,
            w: '100%',
            transition: 'all 0.2s',
            textAlign: 'right',
            '&:placeholder': {
                fontFamily: 'inherit',
            },
            _focus: {
                borderColor: T.tokens.SyntaxColorInputFocusBorder,
                boxShadow: `0 0 0 ${T.tokens.Sizing1} ${T.tokens.BorderColorPrimaryLight}`,
                outline: 'none',
            },
            _hover: {
                borderColor: T.tokens.SyntaxColorInputHoverBorder,
            },
            _invalid: {
                borderColor: T.tokens.SyntaxColorInputInvalidBorder,
                _focus: {
                    borderColor: T.tokens.SyntaxColorInputInvalidBorder,
                    boxShadow: `0 0 0 ${T.tokens.Sizing1} ${T.tokens.ColorRed40}`,
                },
            },
            _disabled: {
                bg: T.tokens.SyntaxColorInputDisabledBackground,
                color: T.tokens.SyntaxColorInputDisabledBorder,
                borderColor: T.tokens.SyntaxColorInputDisabledBorder,
                _hover: {
                    borderColor: T.tokens.SyntaxColorInputDisabledBorder,
                },
            },
        },
        stepperGroup: {
            top: '50%',
            right: 2,
            transform: 'translateY(-50%)',
            marginTop: '-1px',
            h: 'auto',
        },
        stepper: {
            w: 4,
            h: 4,
        },
    }),
    sizes: {
        md: {
            field: {
                px: 4,
                pr: 8,
                py: 2,
                h: 8,
            },
        },
        lg: {
            field: {
                fontFamily: 'heading',
                fontWeight: 'xxl',
                fontSize: 6,
                lineHeight: '1em',
                px: 11,
                py: 3,
                h: 13,
            },
            stepperGroup: {
                right: 6,
            },
            stepper: {
                w: 5,
                h: 5,
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
};

export default NumberInputTheming;
