import { useEffect } from 'react';
import { useScript } from 'usehooks-ts';

const Trengo: React.FC = () => {
    useEffect(() => {
        window.Trengo = {
            key: process.env.NEXT_PUBLIC_TRENGO_KEY,
        };

        return () => {
            // murder trengo
            window.document.querySelector('#trengo-web-widget')?.remove();
            delete window.Trengo;
        };
    }, []);

    useScript('https://static.widget.trengo.eu/embed.js');

    return null;
};

export default Trengo;
