import { TableContainer, TableContainerProps, TableProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props extends TableContainerProps {
    children: ReactElement<TableProps>;
}

const ContentBlockTable: React.FC<Props> = ({ children, ...rest }) => (
    <TableContainer colorScheme="neutral" mb={6} {...rest}>
        {children}
    </TableContainer>
);

export default ContentBlockTable;
