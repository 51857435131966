import { FC, FormHTMLAttributes, ReactNode } from 'react';
import { Box, Button, Container, useTheme } from '@chakra-ui/react';
import Heading from '../typography/Heading';
import { Body } from '../../index';
import Input from './Input';

interface Props {
    title: ReactNode;
    placeholder: string;
    buttonLabel: ReactNode;
    description?: ReactNode;
    variant?: 'full' | 'compact';
    formProps?: FormHTMLAttributes<unknown>;
}

const Newsletter: FC<Props> = ({ title, description, placeholder, buttonLabel, variant = 'full', formProps = {} }) => {
    const theme = useTheme();
    return (
        <Box bg="black" color="white" py={9} px={6}>
            <Container
                display="flex"
                flexDirection={variant === 'full' ? 'column' : 'row'}
                justifyContent={variant === 'full' ? 'center' : 'space-between'}
                gap={variant === 'full' ? 0 : 2}
                maxW={variant === 'full' ? '784px' : '1320px'}
                textAlign="center"
                alignItems="center"
                p={0}
            >
                <Heading variant={2} mb={[6, null, 4]}>
                    {title}
                </Heading>
                {description && variant === 'full' && <Body mb={[6, null, 7]}>{description}</Body>}
                <Box
                    maxW="576px"
                    w={variant === 'full' ? '100%' : 'auto'}
                    as="form"
                    pos="relative"
                    p={0}
                    color={theme.tokens.SyntaxTextColorDefault}
                    {...formProps}
                >
                    <Input
                        height={10}
                        pl={7}
                        pr={[15, null, 16]}
                        tabIndex={0}
                        borderRadius={theme.tokens.BorderRadiusL}
                        placeholder={placeholder}
                        type="email"
                        isRequired
                        name="EMAIL"
                        id="mce-EMAIL"
                        fontSize={[2, null, 3]}
                        _placeholder={{ color: theme.tokens.ColorGrey30 }}
                        rightElement={
                            <Button type="submit" px={[4, null, 6]} ml="auto" mr={2}>
                                {buttonLabel}
                            </Button>
                        }
                        rightElementProps={{ w: [15, null, 16], gap: '6px' }}
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default Newsletter;
