import React from 'react';
import SmallCard, { CardProps } from './SmallCard';

import IconDownload from '../trinket/icon/IconDownload';

const DownloadCard: React.FC<Omit<CardProps, 'icon'>> = ({ children, ...rest }) => (
    <SmallCard variant="white" size="s" icon={<IconDownload />} {...rest}>
        {children}
    </SmallCard>
);

export default DownloadCard;
