import React, { ReactNode } from 'react';
import {
    Accordion as ChakraAccordion,
    AccordionButton,
    AccordionItem as ChakraAccordionItem,
    AccordionItemProps,
    AccordionPanel,
    AccordionProps,
    Box,
    Flex
} from '@chakra-ui/react';
import IconRemove from '../trinket/icon/IconRemove';
import IconAdd from '../trinket/icon/IconAdd';

const Accordion: React.FC<AccordionProps> = ({ children, ...rest }) => (
    <ChakraAccordion allowMultiple allowToggle {...rest}>
        {children}
    </ChakraAccordion>
);

export interface ItemProps extends Omit<AccordionItemProps, 'title'> {
    title: ReactNode;
    description: ReactNode;
}

export const AccordionItem: React.FC<ItemProps> = ({ title, description, ...rest }) => (
    <ChakraAccordionItem {...rest}>
        {({ isExpanded }) => (
            <>
                <AccordionButton>
                    <Flex gap={9} w="100%">
                        <Box fontWeight="xl" flexGrow={1}>
                            {title}
                        </Box>
                        <Box w="6" h="6" flexShrink={0}>
                            {isExpanded ? <IconRemove /> : <IconAdd />}
                        </Box>
                    </Flex>
                </AccordionButton>
                <AccordionPanel>{description}</AccordionPanel>
            </>
        )}
    </ChakraAccordionItem>
);

export default Accordion;
