import { ContentBlockFilm, Meta } from 'designsystem';
import { getMetaSimplifiedForFilmOrProject, useGetApiImageProps } from '../../index';
import { useFilmCardQuery } from '../../gql/api';

const ArchiveFilmContentBlock: React.FC<{ id: string }> = ({ id, ...rest }) => {
    const getApiImageProps = useGetApiImageProps();
    const { data } = useFilmCardQuery({
        id,
    });
    return data?.film ? (
        <ContentBlockFilm
            image={getApiImageProps(data.film?.publications.favoriteImage, data.film.fullPreferredTitle)}
            title={data.film?.fullPreferredTitle}
            meta={<Meta size="s" {...getMetaSimplifiedForFilmOrProject(data.film)} />}
            {...rest}
        />
    ) : null;
};

export default ArchiveFilmContentBlock;
