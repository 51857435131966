import { listAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, MultiStyleConfig } from '@chakra-ui/react';

type ListTheme = Omit<MultiStyleConfig<typeof listAnatomy>, 'parts'>;
const { definePartsStyle } = createMultiStyleConfigHelpers(listAnatomy.keys);

const listTheme: ListTheme = {
    baseStyle: ({ theme }) => ({
        container: {
            pl: 4,
            fontSize: theme.tokens.SyntaxBodyTextLRegularFontSize,
        },
    }),
    sizes: {
        md: {
            container: {
                '& > * + *': {
                    mt: 2,
                },
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
    variants: {
        ranking: definePartsStyle({
            container: {
                pl: 8,
                '& > * + *': {
                    mt: 4,
                },
            },
            icon: {
                w: 6,
                h: 6,
                mr: 4,
                ml: -8,
            },
        }),
    },
};

export default listTheme;
