import { Breadcrumb, Breadcrumbs } from 'designsystem';
import { useRouter } from 'next/router';
import { useBreadcrumbsQuery } from '../gql/cms';
import { ContentPageBreadcrumbs, ObjectPageBreadcrumbs } from 'shared';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { useMemo } from 'react';

interface Props extends Omit<React.ComponentProps<typeof Breadcrumbs>, 'breadcrumbs' | 'homeBreadcrumb'> {
    parentUri?: string;
    currentPage?: Breadcrumb;
}

const InstituteBreadcrumbs: React.FC<Props> = ({ parentUri, currentPage, ...rest }) => {
    const { locale, query } = useRouter();
    const objectPage = useMemo(() => !!(parentUri && currentPage), [parentUri, currentPage]);
    const currentPageUri = Array.isArray(query.uri) ? query.uri.join('/') : query.uri;
    const { data } = useBreadcrumbsQuery({
        site: siteHandleByLocale[locale],
        uri: objectPage ? parentUri : currentPageUri,
    });

    return objectPage ? (
        <ObjectPageBreadcrumbs
            breadcrumbsQueryResults={data}
            parentUri={parentUri}
            currentPage={currentPage}
            {...rest}
        />
    ) : (
        <ContentPageBreadcrumbs breadcrumbsQueryResults={data} {...rest} />
    );
};

export default InstituteBreadcrumbs;
