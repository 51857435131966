import { useTheme } from '@emotion/react';
import {
    Body,
    Box,
    Button,
    ColorScheme,
    ColorSchemeProvider,
    Flex,
    Grid,
    Heading,
    IconButton,
    IconCaret,
    IconExternalLink,
    IconLogout,
    IconSettings,
    NavigationBlock,
    PageCard,
    Skeleton,
    Stack,
    Tag,
    Wrapper,
} from 'designsystem';
import Link from 'next/link';
import { ComponentProps, FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Session } from 'next-auth';
import { useRouter } from 'next/router';
import ConditionalWrap from 'conditional-wrap';
import { signOut } from 'next-auth/react';

type MyIdfaTile = {
    title: ReactNode;
    href?: string;
    onClick?: () => void;
    image: string;
    textButtonLabel: ReactNode;
    colorScheme?: ColorScheme;
};

interface Props {
    loggedIn: boolean;
    user: Session['user'];
    pageTitle: ReactNode;
    tiles: MyIdfaTile[];
    pillarTiles?: Omit<MyIdfaTile, 'image'>[];
}

const NavigationBlockProps: ComponentProps<typeof NavigationBlock> = {
    primary: false,
    colorScheme: 'neutral',
    size: 'm',
};

const MyIdfaOverviewTemplate: FC<Props> = ({ loggedIn, user, pageTitle, tiles, pillarTiles }) => {
    const { tokens } = useTheme();
    const { locale } = useRouter();
    return (
        <>
            <Box as="section" bg={tokens.SyntaxBackgroundPrimaryDefault} h={['300px', null, '420px']} display="flex">
                <Wrapper display="flex" position="relative">
                    <Stack
                        w="100%"
                        direction="row"
                        spacing={10}
                        justifyContent="space-between"
                        alignItems="center"
                        alignSelf="flex-end"
                    >
                        <Stack py={[8, null, 13]} spacing={[3, null, 6]}>
                            <Skeleton isLoaded={loggedIn !== undefined} minW="15">
                                <Heading as="h1" variant={1}>
                                    {loggedIn !== undefined ? user?.name ?? '' : 'loading'}
                                </Heading>
                            </Skeleton>
                            <Skeleton isLoaded={loggedIn !== undefined}>
                                {user?.person?.mainAccreditation?.badges?.length > 0 && (
                                    <Flex flexDir="row" gap={2} alignItems="baseline" wrap="wrap">
                                        <Body>
                                            <FormattedMessage defaultMessage="IDFA pass" />
                                        </Body>
                                        {user.person.mainAccreditation.badges.map(badge => (
                                            <Tag key={badge.id}>{badge.guestbookBadge}</Tag>
                                        ))}
                                    </Flex>
                                )}
                            </Skeleton>
                        </Stack>
                        <Stack
                            position={['absolute', null, null, 'static']}
                            top={0}
                            right={0}
                            direction="row"
                            py={[8, null, 13]}
                            spacing={3}
                        >
                            <a
                                href={
                                    locale === 'nl'
                                        ? process.env.NEXT_PUBLIC_MYIDFA_LOGIN_NL
                                        : process.env.NEXT_PUBLIC_MYIDFA_LOGIN_EN
                                }
                            >
                                <Button display={['none', null, 'flex']} variant="outline" rightIcon={<IconSettings />}>
                                    <FormattedMessage defaultMessage="MyIDFA Account" />
                                </Button>
                                <IconButton
                                    variant="outline"
                                    display={['flex', null, 'none']}
                                    aria-label="MyIDFA Account"
                                    icon={<IconSettings />}
                                />
                            </a>
                            <Button
                                as="a"
                                display={['none', null, 'flex']}
                                variant="outline"
                                rightIcon={<IconLogout />}
                                href={
                                    process.env.NEXT_PUBLIC_MYIDFA_LOGOUT ??
                                    'https://idfa-core.test.oberon.dev/nl/logout'
                                }
                                onClick={() => signOut()}
                            >
                                <FormattedMessage defaultMessage="Log out" />
                            </Button>
                            <IconButton
                                as="a"
                                variant="outline"
                                display={['flex', null, 'none']}
                                aria-label="Log out"
                                href={
                                    process.env.NEXT_PUBLIC_MYIDFA_LOGOUT ??
                                    'https://idfa-core.test.oberon.dev/nl/logout'
                                }
                                onClick={() => signOut()}
                                icon={<IconLogout />}
                            />
                        </Stack>
                    </Stack>
                </Wrapper>
            </Box>
            <Stack py={[9, null, 13]} spacing={[9, null, 13]}>
                {tiles?.length > 0 && (
                    <Box as="section">
                        <Wrapper>
                            <Stack spacing={[7, null, 9]}>
                                <Skeleton isLoaded={loggedIn !== undefined}>
                                    <Heading variant={2}>{pageTitle}</Heading>
                                </Skeleton>
                                <Grid>
                                    {tiles.map((tile, i) => (
                                        <Box key={`tile-${i}`} gridColumn={['1 / -1', 'span 6', null, 'span 4']}>
                                            <Skeleton isLoaded={loggedIn !== undefined}>
                                                <ConditionalWrap
                                                    condition={!!tile.href}
                                                    wrap={children => <Link href={tile.href}>{children}</Link>}
                                                >
                                                    <NavigationBlock
                                                        {...NavigationBlockProps}
                                                        image={{ type: 'next', src: tile.image }}
                                                        textButtonProps={{
                                                            children: tile.textButtonLabel,
                                                            iconRight: tile.href ? (
                                                                <IconCaret direction="right" />
                                                            ) : (
                                                                <IconExternalLink />
                                                            ),
                                                        }}
                                                        onClick={!tile.href ? tile.onClick : null}
                                                        cursor="pointer"
                                                    >
                                                        {tile.title}
                                                    </NavigationBlock>
                                                </ConditionalWrap>
                                            </Skeleton>
                                        </Box>
                                    ))}
                                </Grid>
                            </Stack>
                        </Wrapper>
                    </Box>
                )}
                {pillarTiles?.length > 0 && (
                    <Box as="section">
                        <Wrapper>
                            <Grid>
                                {pillarTiles.map(({ href, textButtonLabel, title, colorScheme }, i) => (
                                    <Box key={`pillarTile-${i}`} gridColumn={['1 / -1', 'span 6', null, 'span 4']}>
                                        <ColorSchemeProvider colorScheme={colorScheme ?? 'neutral'}>
                                            <Skeleton isLoaded={loggedIn !== undefined}>
                                                <Link href={href}>
                                                    <PageCard
                                                        variant="primary"
                                                        size="m"
                                                        title={title}
                                                        textButtonProps={{
                                                            children: textButtonLabel,
                                                            iconRight: <IconExternalLink />,
                                                        }}
                                                    />
                                                </Link>
                                            </Skeleton>
                                        </ColorSchemeProvider>
                                    </Box>
                                ))}
                            </Grid>
                        </Wrapper>
                    </Box>
                )}
            </Stack>
        </>
    );
};

export default MyIdfaOverviewTemplate;
