import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, HStack } from '@chakra-ui/react';
import IconInfo from '../trinket/icon/IconInfo';

const Note: FC<PropsWithChildren & BoxProps> = ({ children, ...rest }) => (
    <Box p={4} fontSize={3} lineHeight="m" background="neutral.10" {...rest}>
        <HStack spacing={2} alignItems="start">
            <Box as={IconInfo} minW="24px" />
            <span>{children}</span>
        </HStack>
    </Box>
);
export default Note;
