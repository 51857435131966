import { Box, BoxProps, chakra, shouldForwardProp, Stack, Text, useInterval } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { isValidMotionProp, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { ReactNode, useCallback, useState } from 'react';
import BackgroundCarousel, { CarouselDots } from '../content/BackgroundCarousel';
import Wrapper from '../layout/Wrapper';
import { ImgProps } from '../media/Img';
import Body from '../typography/Body';
import Heading from '../typography/Heading';
// importing react-player directly causes hydration error in Next: https://github.com/cookpete/react-player/issues/1428
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface Slide {
    key: string;
    image: ImgProps;
    title: string;
}

interface Props extends Omit<BoxProps, 'title'> {
    live?: boolean;
    subTitle: ReactNode;
    title: ReactNode;
    buttons?: ReactNode;
    images: Slide[];
    video?: string;
    variant?: 'split' | 'default';
}

const FestivalHero: React.FC<Props> = ({
    variant = 'default',
    live = false,
    subTitle,
    title,
    buttons,
    images,
    video,
    ...rest
}) => {
    const theme = useTheme();
    const [imageIndex, setImageIndex] = useState(0);
    const onNext = useCallback(() => setImageIndex((imageIndex + 1) % images.length), [imageIndex, images.length]);
    useInterval(onNext, 5000);

    return (
        <Box
            h={variant === 'split' ? [null, null, null, '90vh'] : '80vh'}
            color={variant === 'split' ? theme.tokens.SyntaxTextColorOnPrimary : theme.tokens.SyntaxTextColorOnDark}
            bg={
                variant === 'split'
                    ? theme.tokens.SyntaxBackgroundPrimaryDefault
                    : theme.tokens.SyntaxBackgroundNeutralDarkest
            }
            pos="relative"
            overflow="hidden"
            {...rest}
        >
            <Wrapper
                position={variant === 'split' ? ['static', null, null, 'relative'] : 'relative'}
                zIndex="2"
                h={variant === 'split' ? ['auto', null, null, '100%'] : '100%'}
            >
                <Box
                    position={variant === 'split' ? ['static', null, null, 'absolute'] : 'absolute'}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="flex-end"
                    py={[8, 10, 15]}
                    zIndex={2}
                >
                    <Stack spacing={6} maxW={variant === 'split' ? ['auto', null, null, '40%'] : '576px'}>
                        <Stack spacing={4}>
                            {subTitle && (
                                <AnimationBox display="flex" flexDir="row" gap={3} alignItems="center" layoutId="live">
                                    {live && (
                                        <AnimationBox
                                            w={6}
                                            h={6}
                                            rounded="lg"
                                            bg="#C52B1E"
                                            animate={{ opacity: [0, 1, 0] }}
                                            transition={
                                                {
                                                    duration: 2,
                                                    repeat: Infinity,
                                                    repeatType: 'reverse',
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                } as any
                                            }
                                        />
                                    )}
                                    <Text fontSize={[5, null, null, 7]}>{subTitle}</Text>
                                </AnimationBox>
                            )}
                            <Heading variant={1}>{title}</Heading>
                        </Stack>
                        {buttons && (
                            <Stack direction={variant === 'split' ? 'column' : 'row'} spacing={4} flexWrap="wrap">
                                {buttons}
                            </Stack>
                        )}
                    </Stack>
                </Box>
                {!video && images.length > 1 && (
                    <Stack
                        spacing={2}
                        position="absolute"
                        top={variant === 'split' ? 'auto' : [8, 10, null, 'auto']}
                        bottom={variant === 'split' ? [8, 10, null, 15] : ['auto', null, null, 15]}
                        right={['auto', null, null, 0]}
                        alignItems={[null, null, null, 'flex-end']}
                        zIndex={1}
                        color={theme.tokens.SyntaxTextColorOnDark}
                    >
                        <Body>{images[imageIndex].title}</Body>
                        <CarouselDots
                            currentSlide={imageIndex}
                            slides={images.length}
                            onClick={index => setImageIndex(index)}
                        />
                    </Stack>
                )}
            </Wrapper>
            <Box
                position={variant === 'split' ? ['relative', null, null, 'absolute'] : 'absolute'}
                h={variant === 'split' ? [null, null, null, '100%'] : '100%'}
                top={0}
                left={variant === 'split' ? ['0', null, null, '50%'] : '0'}
                w={variant === 'split' ? ['100%', null, null, '50%'] : '100%'}
                sx={{
                    aspectRatio: variant === 'split' ? ['16/9', null, null, 'auto'] : 'auto',
                }}
                overflow="hidden"
                _before={
                    variant !== 'split' && {
                        content: "''",
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bg: `linear-gradient(84.41deg, ${theme.tokens.SyntaxOverlayColorDefault} 4.32%, rgba(0, 0, 0, 0) 119.35%)`,
                        zIndex: 1,
                    }
                }
            >
                {video ? (
                    <ChakraReactPlayer
                        url={video}
                        muted
                        playsinline
                        playing
                        controls={false}
                        loop
                        pos="relative"
                        sx={
                            variant === 'split'
                                ? {
                                      width: ['100% !important', null, null, '300% !important'],
                                      height: '100% !important',
                                      left: [null, null, null, '-100% !important'],
                                  }
                                : {
                                      '@media (min-aspect-ratio: 263/185)': {
                                          width: '100% !important',
                                          height: '300% !important',
                                          top: '-100% !important',
                                      },
                                      '@media (max-aspect-ratio: 263/185)': {
                                          width: '300% !important',
                                          height: '100% !important',
                                          left: '-100% !important',
                                      },
                                  }
                        }
                    />
                ) : (
                    images.length > 0 && <BackgroundCarousel slides={images} slideIndex={imageIndex} />
                )}
            </Box>
        </Box>
    );
};

const ChakraReactPlayer = chakra(ReactPlayer);

const AnimationBox = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default FestivalHero;
