import { defineStyleConfig } from '@chakra-ui/react';

/**
 * Theme for a Chakra Table. Include this into your theme.
 */
const TableTheming: ReturnType<typeof defineStyleConfig> = defineStyleConfig({
    baseStyle: ({ theme }) => ({
        table: {
            fontFamily: 'inherit',
            width: '100%',
        },
        th: {
            textAlign: 'left',
            fontWeight: 'l',
            bg: theme.tokens.SyntaxBackgroundNeutralDark,
            '&[data-is-numeric=true]': {
                textAlign: 'right',
            },
        },
        td: {
            '&[data-is-numeric=true]': {
                textAlign: 'right',
            },
        },
        tr: {
            borderStyle: 'solid',
            borderBottomWidth: theme.tokens.BorderWidthM,
            borderBottomColor: theme.tokens.BorderColorNeutralDefault,
        },
        tbody: {
            '& > tr:last-child': {
                borderBottomWidth: 0,
            },
        },
    }),
    sizes: {
        md: ({ theme }) => ({
            th: {
                px: 4,
                py: 3,
                fontSize: theme.tokens.SyntaxBodyTextLRegularFontSize,
                lineHeight: 'm',
            },
            td: {
                px: 4,
                py: 3,
                fontSize: theme.tokens.SyntaxBodyTextLRegularFontSize,
                lineHeight: 'm',
            },
        }),
    },
    defaultProps: {
        size: 'md',
    },
});

export default TableTheming;
