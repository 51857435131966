import React, { FC, useMemo } from 'react';
import { Box, CarouselTile, Grid, NavigationTile, useTheme } from 'designsystem';
import { ArchiveFilterEnum, useSearchArchiveCountsQuery } from '../gql/api';
import { useQueryParams } from 'use-query-params';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { ArchiveFilterQueryParams } from './pages/ArchivePage';
import { FilmCardQuery, useGetApiImageProps } from 'shared';

interface Props {
    featuredFilms: FilmCardQuery['film'][];
}

type ArchiveTypeAmountKey = 'person' | 'report' | 'film';

const archiveTypeLocaleMap: Record<string, Record<ArchiveTypeAmountKey, string>> = {
    nl: {
        film: 'Film',
        report: 'Report',
        person: 'Persoon',
    },
    en: {
        film: 'Film',
        report: 'Report',
        person: 'Person',
    },
};

const SearchNavTiles: FC<Props> = ({ featuredFilms }) => {
    const { breakpoints } = useTheme();
    const { data: countsData } = useSearchArchiveCountsQuery();
    const { locale } = useRouter();
    const { formatMessage } = useIntl();
    const getImageProps = useGetApiImageProps();
    const archiveTypeMap = useMemo(() => archiveTypeLocaleMap[locale], [locale]);

    const [filmCount, personCount, reportCount] = useMemo(() => {
        const archiveTypeFilterAmounts = countsData?.searchArchive?.filters?.find(
            ({ filter }) => filter === ArchiveFilterEnum.ArchiveType
        )?.amounts;

        return [
            archiveTypeFilterAmounts?.find(amount => amount.key === archiveTypeMap.film)?.amount,
            archiveTypeFilterAmounts?.find(amount => amount.key === archiveTypeMap.person)?.amount,
            archiveTypeFilterAmounts?.find(amount => amount.key === archiveTypeMap.report)?.amount,
        ] as const;
    }, [archiveTypeMap.film, archiveTypeMap.person, archiveTypeMap.report, countsData?.searchArchive?.filters]);
    const [, setQueryParams] = useQueryParams(ArchiveFilterQueryParams);

    const slides: React.ComponentProps<typeof CarouselTile>['slides'] = useMemo(
        () =>
            featuredFilms?.map(featuredFilm => ({
                key: featuredFilm.id,
                image: getImageProps(
                    featuredFilm.publications?.favoriteImage,
                    featuredFilm.fullPreferredTitle,
                    `(min-width: ${breakpoints.xl}) 50vw, (min-width: ${breakpoints.l}) 50vw, (min-width: ${breakpoints.m}) 447px, (min-width: ${breakpoints.s}) 50vw, 100vw`
                ),
                title: featuredFilm.fullPreferredTitle,
            })),
        [breakpoints.l, breakpoints.m, breakpoints.s, breakpoints.xl, featuredFilms, getImageProps]
    );

    return (
        <Grid h={['630px']} rowGap={[0, 0, 0, 0, 0]}>
            <Box
                gridColumnStart={1}
                gridColumnEnd={[-1, -1, 7, 8]}
                gridRowStart={1}
                gridRowEnd={[2, 2, 3]}
                mr={[null, null, 'calc(var(--gap) * -1)']}
            >
                <CarouselTile
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        setQueryParams({
                            [ArchiveFilterEnum.ArchiveType]: [archiveTypeMap.film],
                        });
                    }}
                    title={formatMessage({ defaultMessage: 'Films' })}
                    description={formatMessage(
                        { defaultMessage: '{count, plural, =0 {# resultaten} one {# resultaat} other {# resultaten}}' },
                        { count: filmCount }
                    )}
                    slides={slides || []}
                />
            </Box>

            <Box gridColumnStart={[1, 1, 7, 8]} gridColumnEnd={-1} gridRowStart={[2, 2, 1]} gridRowEnd={[3, 3, 1]}>
                <NavigationTile
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        setQueryParams({
                            [ArchiveFilterEnum.ArchiveType]: [archiveTypeMap.person],
                        });
                    }}
                    title={formatMessage({ defaultMessage: 'Personen' })}
                    description={formatMessage(
                        { defaultMessage: '{count, plural, =0 {# resultaten} one {# resultaat} other {# resultaten}}' },
                        { count: personCount }
                    )}
                    variant="neutral"
                />
            </Box>
            <Box gridColumnStart={[1, 1, 7, 8]} gridColumnEnd={-1} gridRowStart={[3, 3, 2]} gridRowEnd={[4, 4, 3]}>
                <NavigationTile
                    onClick={e => {
                        e.preventDefault();
                        setQueryParams({
                            [ArchiveFilterEnum.ArchiveType]: [archiveTypeMap.report],
                        });
                    }}
                    href="#"
                    title={formatMessage({ defaultMessage: 'Talks' })}
                    description={formatMessage(
                        { defaultMessage: '{count, plural, =0 {# resultaten} one {# resultaat} other {# resultaten}}' },
                        { count: reportCount }
                    )}
                    variant="pillar"
                />
            </Box>
        </Grid>
    );
};

export default SearchNavTiles;
