import React, { HTMLAttributes } from 'react';

const IconFilter: React.FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 6H20V8H4V6ZM6 11H18V13H6V11ZM10 16H14V18H10V16Z"
            fill="currentColor"
        />
    </svg>
);

export default IconFilter;
