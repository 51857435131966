import { QueryClient } from '@tanstack/react-query';
import { fetchApiData } from '../hooks/useApi';
import {
    FestivalItemCardQuery,
    FestivalItemCardQueryVariables,
    FilmCardQuery,
    FilmCardQueryVariables,
    TicketStatusQuery,
    TicketStatusQueryVariables,
    ProfessionalsFilmCardQuery,
    ProfessionalsFilmCardQueryVariables,
    useFestivalItemCardQuery,
    useFilmCardQuery,
    useTicketStatusQuery,
    useProfessionalsFilmCardQuery,
    useCinemaItemCardQuery,
    CinemaItemCardQuery,
    CinemaItemCardQueryVariables,
} from '../gql/api';
import { ContentBlock } from '../components/ContentBlocks';

/*
 * NOTE: any fields you query here, should be available on all endpoints!!!
 * If you query a field that is only available on one endpoint, the prefetch will fail.
 */

const prefetchContentBlocks = async (queryClient: QueryClient, contentBlocks: ContentBlock[], locale: string) => {
    await Promise.all(
        contentBlocks.map(async contentBlock => {
            if (contentBlock.__typename === 'contentBlocks_archiveFilm_BlockType') {
                const vars: FilmCardQueryVariables = {
                    id: contentBlock.filmId[0],
                };
                await queryClient.prefetchQuery(useFilmCardQuery.getKey(vars), () =>
                    fetchApiData<FilmCardQuery, FilmCardQueryVariables>({
                        locale,
                        query: useFilmCardQuery.document,
                        variables: vars,
                    })
                );
            }

            if (contentBlock.__typename === 'contentBlocks_cinemaItem_BlockType') {
                const vars: CinemaItemCardQueryVariables = {
                    id: contentBlock.itemId[0],
                };
                await queryClient.prefetchQuery(useCinemaItemCardQuery.getKey(vars), () =>
                    fetchApiData<CinemaItemCardQuery, CinemaItemCardQueryVariables>({
                        locale,
                        query: useCinemaItemCardQuery.document,
                        variables: vars,
                    })
                );
            }

            if (contentBlock.__typename === 'contentBlocks_professionalsFilm_BlockType') {
                const vars: ProfessionalsFilmCardQueryVariables = {
                    id: contentBlock.filmId[0],
                };
                await queryClient.prefetchQuery(useProfessionalsFilmCardQuery.getKey(vars), () =>
                    fetchApiData<ProfessionalsFilmCardQuery, ProfessionalsFilmCardQueryVariables>({
                        locale,
                        query: useProfessionalsFilmCardQuery.document,
                        variables: vars,
                    })
                );
            }
            if (contentBlock.__typename === 'contentBlocks_festivalItem_BlockType') {
                const vars: FestivalItemCardQueryVariables = {
                    id: contentBlock.itemId[0],
                };
                await queryClient.prefetchQuery(useFestivalItemCardQuery.getKey(vars), () =>
                    fetchApiData<FestivalItemCardQuery, FestivalItemCardQueryVariables>({
                        locale,
                        query: useFestivalItemCardQuery.document,
                        variables: vars,
                    })
                );
            }
            if (contentBlock.__typename === 'contentBlocks_ticketBlock_BlockType') {
                const vars: TicketStatusQueryVariables = {
                    fionaId: contentBlock.showOrTicketId,
                };
                await queryClient.prefetchQuery(useTicketStatusQuery.getKey(vars), () =>
                    fetchApiData<TicketStatusQuery, TicketStatusQueryVariables>({
                        locale,
                        query: useTicketStatusQuery.document,
                        variables: vars,
                    })
                );
            }
        })
    );
};
export default prefetchContentBlocks;
