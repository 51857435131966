import {
    AccordionItem,
    AspectRatio,
    Body,
    Box,
    BoxProps,
    ContentBlockAccordion,
    ContentBlockButton,
    ContentBlockCarousel,
    ContentBlockImage,
    ContentBlockImages,
    ContentBlockNavigationBlocks,
    ContentBlockQuote,
    ContentBlockRanking,
    ContentBlockSponsors,
    ContentBlockTable,
    ContentBlockText,
    ContentBlockTitle,
    ContentBlockVideo,
    getColorSchemeFromString,
    HtmlContent,
    IconExternalLink,
    ListItem,
    NavigationBlock,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from 'designsystem';
import Link from 'next/link';
import React, { FC, ReactElement, useMemo } from 'react';
import { Intersection } from 'utility-types';
import { ContentBlocksQuery } from '../gql/cms';
import useGetCmsImageProps, { CmsImage } from '../hooks/useGetCmsImageProps';
import { ContentBlockTickets } from '../index';
import ArchiveFilmContentBlock from './contentblocks/ArchiveFilmContentBlock';
import ProfessionalFilmContentBlock from './contentblocks/ProfessionalFilmContentBlock';
import FestivalFilmContentBlock from './contentblocks/FestivalItemContentBlock';
import DocschoolFilmContentBlock from './contentblocks/DocschoolFilmContentBlock';
import CinemaFilmContentBlock from './contentblocks/CinemaFilmContentBlock';
import { FormattedMessage } from 'react-intl';
import { ContentBlockTicketsTooltipLink } from './contentblocks/ContentBlockTickets';

type InstituteInformationPage = Extract<
    ContentBlocksQuery['entry'],
    { __typename: 'instituteContentPages_informationPage_Entry' }
>;

type ProfessionalsInformationPage = Extract<
    ContentBlocksQuery['entry'],
    { __typename: 'professionalsContentPages_informationPage_Entry' }
>;

export type ContentBlocksData = Intersection<InstituteInformationPage, ProfessionalsInformationPage>['contentBlocks'];

export type ContentBlock = ContentBlocksData[number];

type StylesPerContentBlockType = Partial<
    Record<ContentBlock['__typename'] | 'contentBlocks_image_BlockType_large' | 'default', BoxProps>
>;

type OverrideComponentForContentBlock = Partial<
    Record<
        ContentBlock['__typename'] | 'contentBlocks_image_BlockType_large' | string,
        (contentBlockData: ContentBlock, styles: BoxProps) => ReactElement
    >
>;

const stylesForBlockType = (
    type: keyof StylesPerContentBlockType,
    stylesPerContentBlockType: StylesPerContentBlockType
) => stylesPerContentBlockType[type] || stylesPerContentBlockType.default || {};

export const componentForContentBlock = ({
    contentBlock,
    stylesPerContentBlockType,
    override,
    getCmsImageProps,
}: {
    contentBlock: ContentBlock;
    stylesPerContentBlockType: StylesPerContentBlockType;
    override: OverrideComponentForContentBlock;
    getCmsImageProps: ReturnType<typeof useGetCmsImageProps>;
}) => {
    const styles = { ...stylesForBlockType(contentBlock.__typename, stylesPerContentBlockType) };

    if (override[contentBlock.__typename]) {
        return override[contentBlock.__typename](contentBlock, styles);
    }
    switch (contentBlock.__typename) {
        case 'contentBlocks_paragraph_BlockType':
            return (
                <ContentBlockText {...styles}>
                    <HtmlContent html={contentBlock.text} />
                </ContentBlockText>
            );
        case 'contentBlocks_heading_BlockType':
            return <ContentBlockTitle {...styles}>{contentBlock.text}</ContentBlockTitle>;
        case 'contentBlocks_image_BlockType': {
            const image = contentBlock.image[0] as CmsImage;

            return (
                <ContentBlockImage
                    image={getCmsImageProps(image, image.title, '90vw')}
                    caption={contentBlock.caption}
                    large={contentBlock.width}
                    {...(contentBlock.width
                        ? stylesForBlockType('contentBlocks_image_BlockType_large', stylesPerContentBlockType)
                        : styles)}
                />
            );
        }
        case 'contentBlocks_images_BlockType':
            return (
                <ContentBlockImages
                    images={
                        contentBlock.images
                            .slice(0, 2)
                            .map(image => getCmsImageProps(image as CmsImage)) as React.ComponentProps<
                            typeof ContentBlockImages
                        >['images']
                    }
                    caption={contentBlock.caption}
                    {...styles}
                />
            );
        case 'contentBlocks_videoPlayer_BlockType':
            return <ContentBlockVideo url={contentBlock.videoUrl} caption={contentBlock.caption} {...styles} />;
        case 'contentBlocks_button_BlockType':
            return (
                <Box {...styles}>
                    <Link
                        href={contentBlock.linkObject.element?.uri ?? contentBlock.linkObject.url ?? '#'}
                        target={contentBlock.linkObject.target}
                    >
                        <ContentBlockButton {...styles}>{contentBlock.label}</ContentBlockButton>
                    </Link>
                </Box>
            );
        case 'contentBlocks_archiveFilm_BlockType':
            return (
                <Box {...styles}>
                    <Link href={`${process.env.NEXT_PUBLIC_PILLAR_URL_INSTITUTE}/film/${contentBlock.filmId[0]}`}>
                        <ArchiveFilmContentBlock id={contentBlock.filmId[0]} />
                    </Link>
                </Box>
            );
        case 'contentBlocks_cinemaItem_BlockType':
            return (
                <Box {...styles} className="IM THE BOX OVER THE LINK">
                    <CinemaFilmContentBlock id={contentBlock.itemId[0]} />
                </Box>
            );
        case 'contentBlocks_docSchoolFilmTile_BlockType':
            return (
                <Box {...styles}>
                    <DocschoolFilmContentBlock id={contentBlock.filmId[0]} />
                </Box>
            );
        case 'contentBlocks_professionalsFilm_BlockType':
            return (
                <Box {...styles}>
                    <ProfessionalFilmContentBlock id={contentBlock.filmId[0]} />
                </Box>
            );
        case 'contentBlocks_festivalItem_BlockType':
            return (
                <Box {...styles}>
                    <FestivalFilmContentBlock id={contentBlock.itemId[0]} />
                </Box>
            );
        case 'contentBlocks_carousel_BlockType':
            return (
                <ContentBlockCarousel
                    images={contentBlock.slides.map(slide => {
                        const image = slide.image[0] as CmsImage;
                        return {
                            ...getCmsImageProps(image, image.title, '90vw'),
                            key: slide.id,
                            caption: slide.caption,
                        };
                    })}
                    {...styles}
                />
            );
        case 'contentBlocks_dataTable_BlockType': {
            const hasHeading = contentBlock.table.columns.find(column => column.heading);
            return contentBlock.table.rows.length > 0 ? (
                <ContentBlockTable {...styles}>
                    <Table>
                        {hasHeading && (
                            <Thead>
                                <Tr>
                                    {contentBlock.table.columns.map((column, columnIndex) => (
                                        // no unique identifier for column available
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Th key={`column-${columnIndex}`}>{column.heading || ''}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                        )}
                        <Tbody>
                            {contentBlock.table.rows.map((row, rowIndex) => (
                                // no unique identifier for table rows available
                                // eslint-disable-next-line react/no-array-index-key
                                <Tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <Td
                                            // no unique identifier for table rows or cells available
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`${rowIndex}-${
                                                contentBlock.table.columns[cellIndex].heading || cellIndex
                                            }`}
                                        >
                                            {cell}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ContentBlockTable>
            ) : null;
        }
        case 'contentBlocks_styledTable_BlockType': {
            const { heading1, heading2, heading3 } = contentBlock.styledTableHeadings?.[0] || {};
            const hasHeading = !!heading1 || !!heading2 || !!heading3;
            return contentBlock.styledTableRows.length > 0 ? (
                <ContentBlockTable {...styles}>
                    <Table>
                        {hasHeading && (
                            <Thead>
                                <Tr>
                                    {heading1 && <Th>{heading1}</Th>}
                                    {heading2 && <Th>{heading2}</Th>}
                                    {heading3 && <Th>{heading3}</Th>}
                                </Tr>
                            </Thead>
                        )}
                        <Tbody>
                            {contentBlock.styledTableRows.map(row => (
                                <Tr key={row.id}>
                                    {row.column1 && (
                                        <Td>
                                            <HtmlContent html={row.column1} />
                                        </Td>
                                    )}
                                    {row.column2 && (
                                        <Td>
                                            <HtmlContent html={row.column2} />
                                        </Td>
                                    )}
                                    {row.column3 && (
                                        <Td>
                                            <HtmlContent html={row.column3} />
                                        </Td>
                                    )}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ContentBlockTable>
            ) : null;
        }
        case 'contentBlocks_navigation_BlockType':
            return (
                <ContentBlockNavigationBlocks
                    blocks={contentBlock.blocks.map(block => {
                        const thumbnail = block.thumbnail[0] as CmsImage;
                        return (
                            <Link key={block.id} href={block.linkObject.element?.uri ?? block.linkObject.url ?? '#'}>
                                <NavigationBlock
                                    colorScheme={getColorSchemeFromString(block.color)}
                                    image={getCmsImageProps(thumbnail)}
                                >
                                    {block.label}
                                </NavigationBlock>
                            </Link>
                        );
                    })}
                    {...styles}
                />
            );
        case 'contentBlocks_quote_BlockType':
            return (
                <ContentBlockQuote citation={contentBlock.source} {...styles}>
                    {contentBlock.quote}
                </ContentBlockQuote>
            );
        case 'contentBlocks_ranking_BlockType':
            return (
                <ContentBlockRanking
                    items={contentBlock.rankingItems.map(item => (
                        <ListItem key={item.id}>
                            <Link
                                href={item.linkObject.element?.uri ?? item.linkObject.url ?? '#'}
                                target={!item.linkObject.element?.uri ? '_blank' : undefined}
                            >
                                {item.label}
                                {!item.linkObject.element?.uri && (
                                    <>
                                        {' '}
                                        <IconExternalLink display="inline" verticalAlign="middle" w={6} h={6} />
                                    </>
                                )}
                            </Link>
                        </ListItem>
                    ))}
                    {...styles}
                />
            );
        case 'contentBlocks_accordion_BlockType':
            return (
                <ContentBlockAccordion
                    items={contentBlock.accordionItems.map(accordionItem => (
                        <AccordionItem
                            key={accordionItem.id}
                            title={accordionItem.heading}
                            description={<HtmlContent html={accordionItem.body} />}
                        />
                    ))}
                    {...styles}
                />
            );
        case 'contentBlocks_ticketBlock_BlockType':
            return (
                contentBlock.showOrTicketId && (
                    <ContentBlockTickets
                        tickets={[
                            {
                                id: contentBlock.showOrTicketId,
                                ticketType: contentBlock.ticketType,
                                location: contentBlock.location,
                                title: contentBlock.heading,
                                startDate: contentBlock.startDate && new Date(contentBlock.startDate),
                                endDate: contentBlock.endDate && new Date(contentBlock.endDate),
                            },
                        ]}
                        tooltipContent={
                            <Body>
                                <FormattedMessage
                                    defaultMessage="Read <a>here</a> about the various festival locations in Amsterdam."
                                    id="accessibility.tooltip"
                                    values={{
                                        a: children => (
                                            <ContentBlockTicketsTooltipLink>{children}</ContentBlockTicketsTooltipLink>
                                        ),
                                    }}
                                />
                            </Body>
                        }
                        {...styles}
                    />
                )
            );
        case 'contentBlocks_sponsorBlock_BlockType':
            return (
                <ContentBlockSponsors
                    sponsors={
                        contentBlock.sponsors.map(sponsor => ({
                            ...getCmsImageProps(sponsor.logo?.[0], sponsor.title),
                            href: sponsor.linkTo,
                        })) as React.ComponentProps<typeof ContentBlockSponsors>['sponsors']
                    }
                    {...styles}
                />
            );
        case 'contentBlocks_googleMapEmbed_BlockType':
            return (
                contentBlock.embedUrl.startsWith('https://www.google.com/maps/embed') && (
                    <Box {...styles}>
                        <AspectRatio ratio={4 / 3}>
                            <iframe title="Google Maps" src={contentBlock.embedUrl} width="100%" height="100%" />
                        </AspectRatio>
                    </Box>
                )
            );
        default:
            return null;
    }
};

interface Props {
    contentBlocks: ContentBlocksData;
    stylesPerContentBlockType?: StylesPerContentBlockType;
    override?: OverrideComponentForContentBlock;
}

export const componentsForContentBlocks = ({
    contentBlocks,
    stylesPerContentBlockType = {},
    override = {},
    getCmsImageProps,
}: Props & {
    getCmsImageProps: ReturnType<typeof useGetCmsImageProps>;
}) =>
    contentBlocks?.map(contentBlock =>
        componentForContentBlock({ contentBlock, stylesPerContentBlockType, override, getCmsImageProps })
    );

const ContentBlocks: FC<Props> = ({ contentBlocks, stylesPerContentBlockType = {}, override = {} }) => {
    const getCmsImageProps = useGetCmsImageProps();

    const components = useMemo(
        () =>
            componentsForContentBlocks({
                contentBlocks,
                stylesPerContentBlockType,
                override,
                getCmsImageProps,
            }) || [],
        [contentBlocks, getCmsImageProps, override, stylesPerContentBlockType]
    );
    return <>{components.map(component => component)}</>;
};

export default ContentBlocks;
