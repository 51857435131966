import {
    Box,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverProps,
    PopoverTrigger,
} from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { TextButton } from '../../index';

const Tooltip: FC<PopoverProps> = props => <Popover placement="top-start" offset={[0, 20]} {...props} />;
const TooltipCloseButton: FC<PropsWithChildren> = ({ children }) => (
    <PopoverCloseButton as={Box} width="auto" height="auto" display="inline">
        <TextButton size="s" iconRight={null}>
            {children}
        </TextButton>
    </PopoverCloseButton>
);

// Tooltip arguably is a shitty name, but we try to stick to DS naming as much as possible
export {
    Tooltip,
    TooltipCloseButton,
    PopoverTrigger as TooltipTrigger,
    PopoverContent as TooltipContent,
    PopoverBody as TooltipBody,
    PopoverArrow as TooltipArrow,
};
