import React, { PropsWithChildren } from 'react';
import { CartProvider } from './cartContext';
import { OrderHistoryProvider } from './orderHistoryContext';
import { TicketProvider } from './ticketContext';

const ATProviders: React.FC<PropsWithChildren> = ({ children }) => (
    <TicketProvider>
        <CartProvider>
            <OrderHistoryProvider>{children}</OrderHistoryProvider>
        </CartProvider>
    </TicketProvider>
);

export default ATProviders;
