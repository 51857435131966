import { FC, HTMLAttributes } from 'react';

const IconYoutube: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.17171 7.6927C1.07441 8.93962 1 10.4244 1 12C1 13.6321 1.07984 15.1666 1.18225 16.4404C1.32835 18.2574 2.71318 19.6456 4.49955 19.7698C6.29357 19.8945 8.805 20 12 20C15.195 20 17.7064 19.8945 19.5004 19.7698C21.2868 19.6456 22.6717 18.2574 22.8177 16.4404C22.9202 15.1666 23 13.6321 23 12C23 10.4244 22.9256 8.93962 22.8283 7.6927C22.6815 5.81135 21.2169 4.36463 19.3678 4.23336C17.5913 4.10723 15.1642 4 12 4C8.83577 4 6.40874 4.10723 4.63216 4.23336C2.78307 4.36463 1.31852 5.81135 1.17171 7.6927ZM15.5 12L10 9V15L15.5 12Z"
            clipRule="evenodd"
        />
    </svg>
);

export default IconYoutube;
