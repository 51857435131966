import { theme as defaultChakraTheme } from '@chakra-ui/react';
import * as neutralTokens from './generated/tokens';
import components from './components/chakraComponents';

const getChakraThemeFromTokens = (tokens: Record<keyof typeof neutralTokens, string>) => {
    const sizes = {
        1: tokens.Sizing1,
        2: tokens.Sizing2,
        3: tokens.Sizing3,
        4: tokens.Sizing4,
        5: tokens.Sizing5,
        6: tokens.Sizing6,
        7: tokens.Sizing7,
        8: tokens.Sizing8,
        9: tokens.Sizing9,
        10: tokens.Sizing10,
        11: tokens.Sizing11,
        12: tokens.Sizing12,
        13: tokens.Sizing13,
        14: tokens.Sizing14,
        15: tokens.Sizing15,
        16: tokens.Sizing16,
        17: tokens.Sizing17,
        18: tokens.Sizing18,
        19: tokens.Sizing19,
        20: tokens.Sizing20,
    };

    const colors = Object.entries(tokens)
        .filter(([key]) => key.startsWith('Color'))
        .reduce<{ [key: string]: { [key: string]: string } }>((prev, [key, value]) => {
            const c = { ...prev };
            const colorName = key.replace('Color', '').replace(/[0-9]/g, '').toLowerCase();
            const intensity = key.replace(/[^0-9]/g, '');

            c[colorName] = prev[colorName] || {};
            c[colorName][intensity] = value;

            return c;
        }, {});

    return {
        components: { ...defaultChakraTheme.components, ...components },
        sizes,
        shadows: {},
        space: sizes,
        borderWidths: {
            s: tokens.BorderWidthS,
            m: tokens.BorderWidthM,
            l: tokens.BorderWidthL,
            xl: tokens.BorderWidthXl,
            xxl: tokens.BorderWidthXxl,
        },
        transition: {},
        letterSpacings: {},
        lineHeights: {
            xxs: tokens.LineHeightXxs,
            xs: tokens.LineHeightXs,
            s: tokens.LineHeightS,
            m: tokens.LineHeightM,
        },
        fontWeights: {
            s: tokens.FontWeightS,
            m: tokens.FontWeightM,
            l: tokens.FontWeightL,
            xl: tokens.FontWeightXl,
            xxl: tokens.FontWeightXxl,
        },
        fonts: {
            heading: tokens.FontFamilySansSerif,
            body: tokens.FontFamilySansSerif,
        },
        fontSizes: {
            1: tokens.FontSize1,
            2: tokens.FontSize2,
            3: tokens.FontSize3,
            4: tokens.FontSize4,
            5: tokens.FontSize5,
            6: tokens.FontSize6,
            7: tokens.FontSize7,
            8: tokens.FontSize8,
            9: tokens.FontSize9,
            10: tokens.FontSize10,
        },
        breakpoints: {
            xs: tokens.MediaQueryXs,
            s: tokens.MediaQueryS,
            m: tokens.MediaQueryM,
            l: tokens.MediaQueryL,
            xl: tokens.MediaQueryXl,
        },
        zIndices: defaultChakraTheme.zIndices,
        radii: {
            lg: tokens.BorderRadiusL,
        },
        blur: {},
        colors,
        tokens: { ...tokens },
    };
};

export default getChakraThemeFromTokens;
