import {
    Box,
    BoxProps,
    Button,
    Flex,
    IconExternalLink,
    IconPlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Show,
    Stack,
    useTheaterModal,
} from 'designsystem';
import { FormattedMessage } from 'react-intl';
import ConditionalWrap from 'conditional-wrap';
import { ReactNode } from 'react';
import ReactPlayer from 'react-player';

interface Video {
    id: string;
    title: ReactNode;
    src: string;
}

interface Props extends BoxProps, Pick<ReturnType<typeof useTheaterModal>, 'handleVideoClick'> {
    films?: Video[];
    trailers?: Video[];
    filmExternal?: boolean;
    children?: ReactNode;
}

const VideoButtons: React.FC<Props> = ({
    films,
    trailers,
    filmExternal = !ReactPlayer.canPlay(films?.[0]?.src),
    handleVideoClick,
    children,
    ...props
}) =>
    (films.length > 0 || trailers.length > 0) && (
        <Stack direction="row" spacing={6} {...props}>
            {children}
            {films.length > 0 &&
                (films.length === 1 ? (
                    <ConditionalWrap
                        condition={filmExternal}
                        wrap={child => (
                            <a href={films[0].src} target="_blank" rel="noreferrer">
                                {child}
                            </a>
                        )}
                    >
                        <Button
                            leftIcon={!filmExternal ? <IconPlay /> : undefined}
                            variant="solidOnDark"
                            onClick={() => !filmExternal && handleVideoClick(films[0].src)}
                        >
                            <Show above="s">
                                <FormattedMessage defaultMessage="Film bekijken" id="watch-movie" />
                            </Show>
                            <Show below="s">
                                <FormattedMessage defaultMessage="Film" />
                            </Show>
                        </Button>
                    </ConditionalWrap>
                ) : (
                    <Menu variant="solidOnDark">
                        <>
                            <MenuButton>
                                <Show above="s">
                                    <FormattedMessage defaultMessage="Film bekijken" id="watch-movie" />
                                </Show>
                                <Show below="s">
                                    <FormattedMessage defaultMessage="Film" />
                                </Show>
                            </MenuButton>
                            <MenuList>
                                {films.map(film => (
                                    <MenuItem
                                        key={film.id}
                                        onClick={
                                            ReactPlayer.canPlay(film.src) && !filmExternal
                                                ? () => handleVideoClick(film.src)
                                                : undefined
                                        }
                                    >
                                        <ConditionalWrap
                                            condition={!ReactPlayer.canPlay(film.src) || filmExternal}
                                            wrap={child => (
                                                <a href={film.src} target="_blank" rel="noreferrer">
                                                    {child}
                                                </a>
                                            )}
                                        >
                                            <Flex flexDir="row" gap={2} alignItems="center">
                                                <Box
                                                    w={17}
                                                    whiteSpace="nowrap"
                                                    textOverflow="ellipsis"
                                                    overflow="hidden"
                                                >
                                                    {film.title}
                                                </Box>
                                                <Box width={5} height={5}>
                                                    {filmExternal ? <IconPlay /> : <IconExternalLink />}
                                                </Box>
                                            </Flex>
                                        </ConditionalWrap>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </>
                    </Menu>
                ))}
            {trailers.length > 0 &&
                (trailers.length === 1 ? (
                    <Button leftIcon={<IconPlay />} variant="outline" onClick={() => handleVideoClick(trailers[0].src)}>
                        <Box as="span" display={['none', null, 'inline']}>
                            <FormattedMessage defaultMessage="Trailer bekijken" id="watch-trailer" />
                        </Box>
                        <Box as="span" display={['inline', null, 'none']}>
                            <FormattedMessage defaultMessage="Trailer" />
                        </Box>
                    </Button>
                ) : (
                    'multiple'
                ))}
        </Stack>
    );

export default VideoButtons;
