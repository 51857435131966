import { NextButton, PageButton, Pagination as PaginationComponent, PrevButton } from 'react-headless-pagination';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import IconCaret from '../trinket/icon/IconCaret';
import useBrowserSize, { browserSizes } from '../../hooks/useBrowserSize';
import { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

const Pagination: React.FC<
    Omit<React.ComponentProps<typeof PaginationComponent>, 'edgePageCount' | 'middlePagesSiblingCount'>
> = props => {
    const size = useBrowserSize();
    const small = useMemo(() => browserSizes.indexOf(size) <= browserSizes.indexOf('s'), [size]);

    return (
        <PaginationContainer>
            <PaginationComponent
                truncableClassName="ellipses"
                edgePageCount={1}
                middlePagesSiblingCount={small ? 1 : 2}
                {...props}
            >
                <Pages>
                    <PrevButton className="button prev">
                        <IconCaret direction="left" />
                    </PrevButton>
                    <PageButton className="page" activeClassName="active" />
                    <NextButton className="button next">
                        <IconCaret direction="right" />
                    </NextButton>
                </Pages>
            </PaginationComponent>
        </PaginationContainer>
    );
};

const PaginationContainer = styled(Box)(
    ({ theme }) => css`
        font-size: ${theme.tokens.SyntaxBodyTextLRegularFontSize};
        line-height: ${theme.tokens.SyntaxBodyTextLRegularLineHeight};
        font-family: ${theme.tokens.SyntaxBodyTextLRegularFontFamily};
        font-weight: ${theme.tokens.FontWeightXl};

        .page,
        .ellipses,
        .button {
            display: flex;
            width: ${theme.tokens.Sizing9};
            height: ${theme.tokens.Sizing9};
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            border-radius: ${theme.tokens.BorderRadiusL};
            padding: 0;

            @media (max-width: ${theme.tokens.MediaQueryS}) {
                width: ${theme.tokens.Sizing8};
                height: ${theme.tokens.Sizing8};
            }
        }

        .page {
            cursor: pointer;

            &.active {
                border: ${theme.tokens.BorderWidthL} solid currentColor;
            }
            &:hover,
            &:focus {
                border: ${theme.tokens.BorderWidthL} solid ${theme.tokens.ColorNeutral50};
            }
        }

        .ellipses {
            color: ${theme.tokens.ColorNeutral40};
            @media (max-width: ${theme.tokens.MediaQueryS}) {
                width: auto;
                margin: 0 ${theme.tokens.Sizing1};
            }
        }

        .button {
            -webkit-appearance: none;
            border: none;
            background: none;
            cursor: pointer;

            &[disabled] {
                cursor: not-allowed;
                opacity: 0.3;
            }

            &:hover,
            &:focus {
                border: ${theme.tokens.BorderWidthL} solid ${theme.tokens.ColorNeutral50};
            }

            svg {
                width: ${theme.tokens.Sizing8};
                height: ${theme.tokens.Sizing8};

                @media (max-width: ${theme.tokens.MediaQueryS}) {
                    width: ${theme.tokens.Sizing7};
                    height: ${theme.tokens.Sizing7};
                }
            }
        }
    `
);

const Pages = styled(Flex)(
    ({ theme }) => css`
        gap: ${theme.tokens.Sizing1};
    `
);

export default Pagination;
