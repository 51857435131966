import Link from 'next/link';
import React, { FC, ReactElement } from 'react';

import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ConditionalWrap from 'conditional-wrap';

import IconHome from '../trinket/icon/IconHome';
import IconCaret from '../trinket/icon/IconCaret';
import { Box, BoxProps, HStack } from '@chakra-ui/react';
import Body from '../typography/Body';

export type Breadcrumb = {
    title: string | ReactElement;
    uri?: string;
};

export interface Props extends BoxProps {
    disabled?: boolean;
    breadcrumbs: Breadcrumb[];
    homeBreadcrumb: Breadcrumb;
    onImage?: boolean;
    size?: 's' | 'm';
}

const Breadcrumbs: FC<Props> = ({
    size = 'm',
    disabled = false,
    breadcrumbs,
    homeBreadcrumb,
    onImage = false,
    ...rest
}) => {
    const theme = useTheme();
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 2];

    return (
        <Box {...rest}>
            <Box display={!disabled ? ['none', 'none', 'block'] : 'block'}>
                <BreadcrumbContainer
                    ml={size === 'm' ? 1 : '0'}
                    spacing={size === 'm' ? 1 : 0}
                    color={onImage ? theme.tokens.SyntaxTextColorOnDark : theme.tokens.SyntaxTextColorMuted}
                    sx={{
                        '& > *:last-child': !disabled && {
                            color: onImage ? theme.tokens.SyntaxTextColorOnDark : theme.tokens.SyntaxTextColorDefault,
                        },
                    }}
                >
                    {homeBreadcrumb && (
                        <>
                            <Box height={size === 'm' ? 5 : 4} width={size === 'm' ? 5 : 4} flexShrink="0">
                                <ConditionalWrap
                                    condition={!disabled}
                                    wrap={children => <Link href={homeBreadcrumb.uri || '/'}>{children}</Link>}
                                >
                                    <Crumb onImage={onImage} disabled={disabled}>
                                        <IconHome />
                                    </Crumb>
                                </ConditionalWrap>
                            </Box>
                            <Box height={size === 'm' ? 5 : 4} width={size === 'm' ? 5 : 4} flexShrink="0">
                                <IconCaret direction="right" />
                            </Box>
                        </>
                    )}

                    {breadcrumbs.map((breadcrumb, i) => {
                        const currentCrumb = i === breadcrumbs.length - 1;
                        return (
                            <React.Fragment key={breadcrumb.uri}>
                                <ConditionalWrap
                                    condition={!disabled}
                                    wrap={children => <Link href={breadcrumb.uri || '/'}>{children}</Link>}
                                >
                                    <Crumb onImage={onImage} disabled={disabled} title={breadcrumb.title.toString()}>
                                        <Body
                                            fontSize={size === 's' ? 1 : undefined}
                                            nowrap
                                            maxW={currentCrumb ? 20 : 16}
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            fontWeight="l"
                                        >
                                            {breadcrumb.title}
                                        </Body>
                                    </Crumb>
                                </ConditionalWrap>

                                {i < breadcrumbs.length - 1 && (
                                    <Box height={size === 'm' ? 5 : 4} width={size === 'm' ? 5 : 4} flexShrink="0">
                                        <IconCaret direction="right" />
                                    </Box>
                                )}
                            </React.Fragment>
                        );
                    })}
                </BreadcrumbContainer>
            </Box>

            {!disabled && (
                <Box display={['block', 'block', 'none']}>
                    <ConditionalWrap
                        condition={!disabled}
                        wrap={children => (
                            <Link href={lastBreadcrumb ? lastBreadcrumb.uri : homeBreadcrumb.uri}>{children}</Link>
                        )}
                    >
                        <Crumb onImage={onImage} disabled={disabled}>
                            <BreadcrumbContainer ml={size === 'm' ? 1 : '0px'}>
                                <Box height={5} width={5}>
                                    <IconCaret direction="left" />
                                </Box>
                                <Box mt="2px">
                                    {lastBreadcrumb ? (
                                        lastBreadcrumb.title
                                    ) : (
                                        <Box height={5} width={5}>
                                            <IconHome />
                                        </Box>
                                    )}
                                </Box>
                            </BreadcrumbContainer>
                        </Crumb>
                    </ConditionalWrap>
                </Box>
            )}
        </Box>
    );
};

const Crumb = styled.span<Pick<Props, 'onImage' | 'disabled'>>(
    ({ theme, onImage, disabled }) => css`
        text-decoration: none;
        color: currentColor;
        line-height: ${theme.tokens.LineHeightS};
        font-weight: ${theme.tokens.FontWeightL};
        margin-top: 0.075em;
        text-shadow: ${onImage && '0px 4px 48px rgba(0, 0, 0, 0.4)'};

        ${!disabled &&
        css`
            &:hover {
                color: ${onImage ? theme.tokens.SyntaxTextColorOnDark : theme.tokens.SyntaxTextColorDefault};
            }
        `}
    `
);

const BreadcrumbContainer = styled(HStack)`
    display: flex;
`;

export default Breadcrumbs;
