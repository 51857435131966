import React from 'react';

import { Box, BoxProps, Button, ButtonProps } from '@chakra-ui/react';

interface Props extends BoxProps {
    buttonProps?: ButtonProps;
}

const ContentBlockButton: React.FC<Props> = ({ buttonProps, children, ...rest }) => (
    <Box {...rest}>
        <Button mb={6} maxWidth="100%" textOverflow="ellipsis" overflow="hidden" display="block" {...buttonProps}>
            {children}
        </Button>
    </Box>
);

export default ContentBlockButton;
