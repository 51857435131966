import React, { HTMLAttributes } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const IconShoppingCart: React.FC<HTMLAttributes<HTMLOrSVGElement> & BoxProps> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box as="svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3 11.97C16.96 12.59 16.3 13 15.55 13H8.1L7 15H19V17H7C5.48 17 4.52 15.37 5.25 14.03L6.6 11.59L3 4H1V2H4.27L5.21 4H20.01C20.77 4 21.25 4.82 20.88 5.48L17.3 11.97ZM18.31 6H6.16L8.53 11H15.55L18.31 6ZM7 18C5.9 18 5.01 18.9 5.01 20C5.01 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18ZM15.01 20C15.01 18.9 15.9 18 17 18C18.1 18 19 18.9 19 20C19 21.1 18.1 22 17 22C15.9 22 15.01 21.1 15.01 20Z"
            fill="currentColor"
        />
    </Box>
);

export default IconShoppingCart;
