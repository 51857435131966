import emotionReset from 'emotion-reset';
import React from 'react';
import { css, Global, useTheme } from '@emotion/react';
import graphikFontFaceCss from '../constants/graphikFontFaceCss';

const GlobalStyle: React.FC = props => {
    const theme = useTheme();

    return (
        <Global
            styles={css`
                ${emotionReset}
                ${graphikFontFaceCss}

                *,
                *::after,
                *::before {
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-smoothing: antialiased;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }

                html {
                    scroll-behavior: smooth;
                }

                body {
                    font-family: ${theme.tokens.SyntaxBodyTextLRegularFontFamily}, sans-serif;
                    font-size: 10px;
                    color: ${theme.tokens.SyntaxTextColorDefault};
                    -webkit-font-smoothing: antialiased;

                    --modal-max-height: -moz-available;
                    overflow-x: hidden;
                }

                ::selection {
                    background: ${theme.tokens.BorderColorPrimaryLight};
                }

                @media (max-width: ${theme.tokens.MediaQueryS}) {
                    .TrengoWidget--position-left {
                        right: 20px !important;
                        left: auto !important;
                    }
                }
            `}
            {...props}
        />
    );
};

export default GlobalStyle;
