import { Box, Flex, FlexProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import IconExternalLink from '../trinket/icon/IconExternalLink';
import IconTicket from '../trinket/icon/IconTicket';
import Body from '../typography/Body';

interface Props extends FlexProps {
    onClick: () => void;
    externalTicketLink?: string;
    buttonLabel?: string;
    iconColor?: string;
    isLoading: boolean;
}

const TicketButton: FC<Props> = ({ externalTicketLink, onClick, iconColor, buttonLabel, isLoading, ...rest }) => (
    <Flex
        as={externalTicketLink ? 'a' : 'button'}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        href={externalTicketLink!}
        target={externalTicketLink ? '_blank' : null}
        onClick={() => !externalTicketLink && onClick()}
        alignItems="center"
        justifyContent="center"
        borderRadius="9999px"
        border="1px solid"
        bgColor="transparent"
        w={9}
        h={9}
        color={iconColor}
        opacity={!isLoading ? 1 : 0.5}
        pointerEvents={!isLoading ? 'auto' : 'none'}
        {...rest}
    >
        {buttonLabel && (
            <Body mr={2}>
                {buttonLabel} <IconExternalLink />
            </Body>
        )}
        <Box w={6} h={6}>
            <IconTicket />
        </Box>
    </Flex>
);
export default TicketButton;
