import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Container = styled(Flex)``;

Container.defaultProps = {
    maxWidth: '1320px',
    p: 6,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
};

export default Container;
