import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalProps } from '@chakra-ui/react';
import { FC } from 'react';

const NewsletterModal: FC<ModalProps> = ({ children, ...rest }) => (
    <Modal {...rest} variant="newsletter" motionPreset="slideInBottom" blockScrollOnMount={false} autoFocus={false}>
        <ModalContent
            containerProps={{
                height: '100%',
                width: '100%',
                maxWidth: '357px',
                maxHeight: '352px',
                bottom: 0,
                top: 'unset',
            }}
        >
            <ModalCloseButton tabIndex={-1} />
            <ModalBody>{children}</ModalBody>
        </ModalContent>
    </Modal>
);

export default NewsletterModal;
