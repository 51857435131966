import { BorderProps, StackDivider, StackDividerProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

const ListDivider: React.FC<StackDividerProps> = ({ ...rest }) => {
    const theme = useTheme();
    const dividerStyles: BorderProps = {
        borderTopWidth: 'm',
        borderTopStyle: 'solid',
        borderTopColor: theme.tokens.BorderColorNeutralDefault,
        borderLeftWidth: 'm',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.tokens.BorderColorNeutralDefault,
    };
    return <StackDivider {...dividerStyles} {...rest} />;
};

export default ListDivider;
