import React from 'react';

export declare type FormatDateOptions = Intl.DateTimeFormatOptions;

export interface DateProps {
    date: Date;

    // when react-intl is available, pass it's formatDate function. If it isn't, use storyDateFormatter.
    formatter: (value: Date, opts?: FormatDateOptions) => string;
}

const DateText: React.FC<DateProps> = ({ date, formatter }) => (
    <span suppressHydrationWarning>
        {formatter(date, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).replace(/ /g, ' ')}
    </span>
);

export default DateText;
