import { useCallback } from 'react';
import { ArchiveFilterEnum } from '../gql/api';
import { QueryParamConfigMap, useQueryParams } from 'use-query-params';

const useRemoveQueryFilters = (queryParams: QueryParamConfigMap) => {
    const [, setQueryFilters] = useQueryParams(queryParams);
    return useCallback(
        (ignoreArchiveType = false) =>
            setQueryFilters(vals =>
                Object.assign(
                    {},
                    ...Object.keys(vals)
                        .filter(key => (ignoreArchiveType ? key !== ArchiveFilterEnum.ArchiveType : true))
                        .map(key => ({ [key]: [] }))
                )
            ),
        [setQueryFilters]
    );
};

export default useRemoveQueryFilters;
