import { FC, useMemo } from 'react';
import CinemaScheduleFilters from './CinemaScheduleFilters';
import { Heading, Meta, Note, ProgrammeListItem, SkeletonListItem, Stack } from 'designsystem';
import useCinemaSearch from '../../hooks/useCinemaSearch';
import { FormattedMessage } from 'react-intl';
import { stripHtml, slugifyTitle, useGetApiImageProps, useTickets, FormattedDate, FormattedTimeRange } from 'shared';
import { isAfter, isBefore } from 'date-fns';

const ITEMS_PER_PAGE = 30;

const sortByDateDesc = (a: string, b: string) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    if (isBefore(aDate, bDate)) {
        return 1;
    }
    if (isAfter(aDate, bDate)) {
        return -1;
    }
    return 0;
};

const CinemaSchedule: FC = () => {
    const { filterParams, hitsLoading, hitsByDay, totalHits } = useCinemaSearch();
    const hasHits = totalHits > 0;
    const getImgProps = useGetApiImageProps();
    const { onOpenTicket, isLoadingTicket } = useTickets();
    const hitsByDayKeys = useMemo(
        () => (filterParams.past ? Object.keys(hitsByDay ?? {}).sort(sortByDateDesc) : Object.keys(hitsByDay ?? {})),
        [hitsByDay, filterParams]
    );

    return (
        <>
            <CinemaScheduleFilters />

            <Stack as="section" direction="column" spacing={6} mt={[7, null, null, 9]}>
                {!hitsLoading &&
                    hasHits &&
                    hitsByDayKeys.map(day => (
                        <>
                            <Heading variant={4}>
                                <FormattedDate date={new Date(hitsByDay[day][0].startOn)} variant="DATE-MEDIUM" />
                            </Heading>
                            {hitsByDay[day].map(hit => (
                                <ProgrammeListItem
                                    key={hit.id}
                                    name={hit.fullTitle}
                                    meta={
                                        <Meta
                                            size="s"
                                            items={[
                                                {
                                                    key: 'show-time',
                                                    value: hit.isOngoingProgram ? (
                                                        <>
                                                            <FormattedMessage defaultMessage="Meerdere tijdsblokken tussen" />{' '}
                                                            <FormattedTimeRange
                                                                from={new Date(hit.startOn)}
                                                                to={new Date(hit.endOn)}
                                                                variant="TIME-SHORT"
                                                            />
                                                        </>
                                                    ) : (
                                                        <FormattedTimeRange
                                                            from={new Date(hit.startOn)}
                                                            to={new Date(hit.endOn)}
                                                            variant="TIME-SHORT"
                                                        />
                                                    ),
                                                },
                                                hit.location && {
                                                    key: 'location',
                                                    value: hit.location,
                                                },
                                            ].filter(Boolean)}
                                        />
                                    }
                                    tags={hit.audience && [{ key: hit.audience.key, value: hit.audience.translation }]}
                                    description={stripHtml(
                                        hit.intro?.translation ??
                                            hit.composition?.intro?.translation ??
                                            hit.film?.intro?.translation
                                    )}
                                    image={getImgProps(
                                        hit.film?.publications?.favoriteImage ??
                                            hit.film?.publications?.stills?.[0] ??
                                            hit.composition?.publications.favoriteImage ??
                                            hit.composition?.publications?.stills?.[0],
                                        hit.fullTitle
                                    )}
                                    href={
                                        hit.composition
                                            ? `/composition/${hit.composition.id}/${slugifyTitle(
                                                  hit.composition.fullTitle
                                              )}`
                                            : hit.film
                                            ? `/cinema/${hit.film.id}/${slugifyTitle(hit.film.fullPreferredTitle)}`
                                            : '#'
                                    }
                                    isLoadingTicket={isLoadingTicket}
                                    externalTicketLink={hit.externalSaleLink}
                                    onTicketClick={
                                        hit.ticketAvailabilityStatus !== 'SOLD_OUT' &&
                                        !hit.noSale &&
                                        (() =>
                                            onOpenTicket({
                                                title: hit.fullTitle,
                                                ticketId: hit.id,
                                                ticketType: 'show',
                                            }))
                                    }
                                    soldOut={hit.ticketAvailabilityStatus === 'SOLD_OUT'}
                                    showTicketIcon={!filterParams.past}
                                    showCalendarIcon={false}
                                    showFavoriteIcon={false}
                                />
                            ))}
                        </>
                    ))}
                {!hitsLoading && !hasHits && (
                    <Note mt={[6, null, null, 13]}>
                        <FormattedMessage defaultMessage="Geen shows gevonden" />
                    </Note>
                )}

                {hitsLoading && (
                    <>
                        {new Array(ITEMS_PER_PAGE).fill('').map((_, i) => (
                            <SkeletonListItem key={`skeleton-${i}`} />
                        ))}
                    </>
                )}
            </Stack>
        </>
    );
};

export default CinemaSchedule;
