import React, { PropsWithChildren, ReactElement } from 'react';

import { AspectRatio, Box, BoxProps, Flex, Grid, Spacer, Stack } from '@chakra-ui/react';

import TextButton, { Props as TextButtonProps } from '../action/TextButton';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import ColorSchemeProvider, { Props as ColorSchemeProps } from '../ColorSchemeProvider';
import Img, { ImgProps } from '../media/Img';
import Heading from '../typography/Heading';

interface SmallBlockProps {
    size?: 's';
    textButtonProps?: never;
}

interface MediumBlockProps {
    size?: 'm';
    textButtonProps?: Omit<TextButtonProps, 'size'>;
}

type Block = (SmallBlockProps | MediumBlockProps) &
    BoxProps &
    Omit<ColorSchemeProps, 'children'> &
    PropsWithChildren & {
        primary?: boolean;
        image?: ImgProps;
    };

interface Props extends BoxProps {
    blocks: ReactElement<Block>[];
}

const ContentBlockNavigationBlocks: React.FC<Props> = ({ blocks, ...rest }) => (
    <Grid templateColumns={['1 / -1', 'repeat(2, 1fr)']} mb={6} gap={4} {...rest}>
        {blocks.map(block => block)}
    </Grid>
);

export const NavigationBlock: React.FC<Block> = ({
    size = 's',
    primary = true,
    children,
    image,
    textButtonProps,
    colorScheme,
    ...rest
}) => {
    const theme = useTheme();
    return (
        <ColorSchemeProvider colorScheme={colorScheme} {...rest}>
            <Box
                bg={primary ? theme.tokens.TilePrimaryBackground : theme.tokens.SyntaxBackgroundNeutralDark}
                color={primary ? theme.tokens.SyntaxTextColorOnPrimary : theme.tokens.SyntaxTextColorDefault}
                p={size === 's' ? [7] : [7, null, 9]}
            >
                <AspectRatio ratio={[3 / 2, 1]}>
                    <Flex flexDir="column">
                        {image && (
                            <Box
                                w={[10, 11, 13]}
                                h={[10, 11, 13]}
                                borderRadius="100%"
                                overflow="hidden"
                                pos="relative"
                                flexShrink={0}
                                alignSelf="flex-start"
                            >
                                <Img fill {...image} style={{ objectFit: 'cover' }} />
                            </Box>
                        )}
                        <Spacer />
                        {size === 'm' ? (
                            <Stack alignSelf="stretch" spacing={4}>
                                <Heading variant={3}>{children}</Heading>
                                <StyledTextButton as="span" size="s" {...textButtonProps} />
                            </Stack>
                        ) : (
                            <StyledTextButton as="span" size="l" clamp={3}>
                                {children}
                            </StyledTextButton>
                        )}
                    </Flex>
                </AspectRatio>
            </Box>
        </ColorSchemeProvider>
    );
};

const StyledTextButton = styled(TextButton)(
    () => css`
        width: 100%;

        & > *:first-child {
            flex-grow: 1;
        }
    `
);

export default ContentBlockNavigationBlocks;
