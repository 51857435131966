import { Banner } from 'designsystem';
import React, { ComponentProps } from 'react';
import { SponsorsQuery, useSponsorsQuery } from '../../gql/cms';
import useGetCmsImageProps, { CmsImage } from '../../hooks/useGetCmsImageProps';

export type SponsorSet = {
    sponsorBanners: Array<{
        id?: string;
        bannerImage?: CmsImage[];
        linkUrl?: string;
        title?: string;
    }>;
};

interface Props extends Omit<ComponentProps<typeof Banner>, 'sponsors'> {
    sponsorSet?: SponsorSet;
}

type SponsorBannerSet = Extract<
    SponsorsQuery['globalSet'],
    { __typename: 'defaultSponsorBannerSet_GlobalSet' }
>['sponsorBannerSet'][number];
type BannerSetEntry = Extract<SponsorBannerSet, { __typename: 'sponsorBannerSets_bannerSet_Entry' }>;
type BannerEntry = Extract<BannerSetEntry['sponsorBanners'][number], { __typename: 'sponsorBanners_banner_Entry' }>;

const isBannerSet = (
    set: SponsorBannerSet
): set is Extract<SponsorBannerSet, { __typename: 'sponsorBannerSets_bannerSet_Entry' }> =>
    set.__typename === 'sponsorBannerSets_bannerSet_Entry';

const SponsorsBanner: React.FC<Props> = ({ sponsorSet, ...props }) => {
    const { data } = useSponsorsQuery({}, { enabled: !sponsorSet });
    const getImgProps = useGetCmsImageProps();

    const globalBannersSet =
        data?.globalSet?.__typename === 'defaultSponsorBannerSet_GlobalSet' && data?.globalSet?.sponsorBannerSet?.[0];

    const globalBanners =
        isBannerSet(globalBannersSet) &&
        (globalBannersSet.sponsorBanners.filter(
            banner => banner.__typename === 'sponsorBanners_banner_Entry'
        ) as BannerEntry[]);

    const banners = sponsorSet?.sponsorBanners ?? globalBanners;

    if (!banners || banners.length === 0) {
        return null;
    }

    return (
        <Banner
            sponsors={
                banners?.map(sponsor => ({
                    key: sponsor.id,
                    title: sponsor.title,
                    alt: sponsor.title,
                    href: sponsor.linkUrl,
                    ...getImgProps(sponsor.bannerImage?.[0]),
                })) as ComponentProps<typeof Banner>['sponsors']
            }
            {...props}
        />
    );
};

export default SponsorsBanner;
