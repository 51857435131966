import { FC, HTMLAttributes } from 'react';

const IconPlay: FC<HTMLAttributes<HTMLOrSVGElement>> = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M21.8333 12L7.25 20.75L7.25 3.25L21.8333 12Z" fill="currentColor" />
    </svg>
);

export default IconPlay;
